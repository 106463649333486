import React, { Component } from 'react';
import downloadCsv from 'download-csv';
import { Segment, Button } from 'semantic-ui-react';
import { fetchUserActivitiesPromise } from '../../../actions/promises';

const FILE_NAME = {
  signups: 'newSignups.csv',
  active: 'activeUsers.csv',
};
const NUM_ROWS = 1000;

const COLUMNS = {};

class DownloadCSV extends Component {
  state = {
    showMainPanel: false,
    isFetchingSignUpData: true,
    isFetchingActiveData: true,
    data: {
      signups: null,
      active: null,
    },
  };

  componentDidMount() {
    fetchUserActivitiesPromise('signups', NUM_ROWS, 0)
      .then(r => r.data)
      .then(data => {
        const signups = data.map(e => ({ ...e, usage_duration: JSON.stringify(e.usage_duration) }));
        this.setState({
          // eslint-disable-next-line react/no-access-state-in-setstate
          ...this.state,
          isFetchingSignUpData: false,
          data: {
            // eslint-disable-next-line react/no-access-state-in-setstate
            ...this.state.data,
            signups,
          },
        });
      });
    fetchUserActivitiesPromise('active', NUM_ROWS, 0)
      .then(r => r.data)
      .then(data => {
        const active = data.map(e => ({ ...e, usage_duration: JSON.stringify(e.usage_duration) }));
        this.setState({
          // eslint-disable-next-line react/no-access-state-in-setstate
          ...this.state,
          isFetchingActiveData: false,
          data: {
            // eslint-disable-next-line react/no-access-state-in-setstate
            ...this.state.data,
            active,
          },
        });
      });
  }

  download(mode) {
    const { data } = this.state;
    const target = data[mode];
    const file = FILE_NAME[mode];
    downloadCsv(target, COLUMNS, file);
  }

  render() {
    const { isFetchingSignUpData, isFetchingActiveData } = this.state;
    return (
      <Segment raised>
        <Button
          color="green"
          onClick={() => this.download('signups')}
          loading={isFetchingSignUpData}
        >
          New Signups Activities
        </Button>
        <Button color="blue" onClick={() => this.download('active')} loading={isFetchingActiveData}>
          Active Users Activities
        </Button>
      </Segment>
    );
  }
}

export default DownloadCSV;
