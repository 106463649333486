import { showErrorMessage } from '@Utils';
import { useEffect } from 'react';
import { persistGetToUrl, mutateToUrl, useCache, useStore, axiosInstance } from './helpers';

const ENDPOINT = {
  LOGIN: {
    GOOGLE: '/loginGoogle',
    APPLE: '/loginApple',
    CM: '/login',
  },
  LOGOUT: '/logout',
  AUTH: '/user/auth',
  USER: '/user',
  RESEND_CODE: '/login/resend-code',
  PUSH_TOKEN: '/user/push-token',
  CHECK: '/admin/check',
};

type CMLogin = {
  username: string;
  password: string;
  code?: string;
};

export type User = {
  id: number;
  image_url: string | null;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  company: string;
  role: string;
  notificationEmailSettings: {
    sendEmail: boolean;
  };
  verified: boolean;
  trusted: boolean;
  ip_addresses: string[];
  source: string | null;
  register_date: string;
  emailReportConfigs: {
    notification: number;
    day_of_the_week: string;
    min_following_spotify_playlist_followers: number;
    sections: {
      general: {
        day_of_the_week: string;
        notification: number;
        min_following_spotify_playlist_followers: number;
      };
      tiktok: {
        notification: number;
      };
      noteworthy_insights: {
        dod_flag: boolean;
        wow_flag: boolean;
      };
    };
    frequency: {
      daily_general: boolean;
      weekly_general: boolean;
      weekly_trending: boolean;
    };
    playlist_additions: boolean;
    playlist_removals: boolean;
  };
  customerInfo: {
    id: string;
    is_deleted_account: boolean;
    account_balance: number;
    discount: string | null;
    description: string | null;
    tax_ids: {
      object: string;
      data: any[];
      has_more: boolean;
      total_count: number;
      url: string;
    };
    addOns: any[];
  };
  sessions: {
    last_date: string;
    avg_daily: number;
    total: number;
  };
  addresses: {
    address: {
      city: string | null;
      country: string;
      line1: string | null;
      line2: string | null;
      postal_code: string | null;
      state: string | null;
    } | null;
    shipping: any | null;
  };
  inTeam: boolean;
  churnInfo: {
    customer_email: string;
    plan: string;
    status: string;
    mrr: number;
    churned_on: string | null;
    activated_on: string | null;
    total_spend: number;
  } | null;
  ccLists: {
    cc: string | null;
    bcc: string | null;
  };
  refreshToken: {
    refresh_token: string | null;
  };
  user_hash: string;
  artistsPerAccount: number;
  teamInfo: {
    admin: boolean;
    verified: boolean;
    maxAccounts: number;
    artistsPerAccount: number;
    managerId: number;
    managerEmail: string;
  };
  subscription_level: number;
  subscription_type: string;
  subscriptionName: string | null;
  spotifyIntegrated: boolean;
  spotifyForArtistsPermissions: {
    isPermitted: boolean;
    isRestricted: boolean;
  };
  last_added_at: string;
  last_removed_at: string;
};

const useAuthLogin = mutateToUrl<{ data: CMLogin }>('POST', ENDPOINT.LOGIN.CM);

const useAuthLogout = persistGetToUrl<NonNullable<unknown>, any>(ENDPOINT.LOGOUT, {
  manual: true,
});

const useGetUser = persistGetToUrl<NonNullable<unknown>, User>(ENDPOINT.USER, {
  isPermanentData: true,
  retry: 1,
});

const useAuth = persistGetToUrl<NonNullable<unknown>, any>(ENDPOINT.AUTH, {
  isPermanentData: true,
  key: 'AUTH',
  manual: true,
  retry: 1,
});

export const useUser = () => {
  const { execute: executeCMLogin, isLoading: isLoadingCMLogin } = useAuthLogin();
  const { data: isAuthed } = useCache('IS_AUTHED');
  const {
    data: authData,
    refetch: fetchAuth,
    isLoading: isLoadingAuth,
    isFetching: isFetchingAuth,
    isFetched: isFetchedAuth,
  } = useAuth();
  const { data: userData } = useGetUser(
    {},
    {
      disable: !isAuthed,
    }
  );

  const updateCache = useStore();

  const { refetch: executeLogout } = useAuthLogout();

  const checkIsAdmin = async () => {
    return true;
  };

  const checkAdminUser = async () => {
    const checkAdmin = await checkIsAdmin();
    if (!checkAdmin) {
      showErrorMessage('You are not an admin');
    }
    updateCache('IS_AUTHED', Boolean(checkAdmin));
  };

  useEffect(() => {
    if (!isFetchedAuth) fetchAuth();
    if (isFetchedAuth && authData?.email) checkAdminUser();
  }, [isFetchedAuth, authData?.email]);

  const logout = async () => {
    executeLogout();
    updateCache('AUTH', null);
    updateCache('IS_AUTHED', false);
    document.cookie = 'Cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  };

  const login = async (params: CMLogin) => {
    const request = () => {
      // @ts-ignore
      const { username, password, code } = params;
      return executeCMLogin({ data: { username, password, code } });
    };

    try {
      const response = await request();
      const statusCode = Number(response.status);

      if (statusCode === 200) {
        checkAdminUser();
      }
    } catch {
      logout();
    }
  };

  return {
    login,
    authData,
    userData,
    isAuthed: isAuthed,
    // (process.env.NODE_ENV === 'development' && process.env.REACT_APP_ENV !== 'staging'),
    isLoggingIn: isLoadingCMLogin || isLoadingAuth || isFetchingAuth,
    logout,
  };
};
