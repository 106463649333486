import React from 'react';
import { LinkCompletenessStatsProps } from './Statistics';
import {
  LinkCompletenessStatisticsHistoryResponse,
  useGetLinkCompletenessStatisticsHistory,
} from '@Services/linkCompleteness';
import { Flex, Skeleton } from 'antd';
import { PLATFORM_COLORS } from '@Constants/platform/colors';
import { SUPPORT_PLATFORMS } from '../../constants';
import { getPlatformFromString } from '@Constants/platform/utils';
import { addComma, numericalScaleFormatter } from '@Utils/number';
import LineGraph from '@Shared/LineGraph/LineGraph';

const LineChart = ({
  data: rawData,
  target,
}: {
  data: LinkCompletenessStatisticsHistoryResponse;
  target: 'artist' | 'track';
}) => {
  const data = (rawData as unknown as any[])
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .reduce(
      (acc, item) => {
        acc.dates.push(item.date);
        acc.spotifyValues.push(item.spotify_count);
        acc.instagramValues.push(item.instagram_count);
        acc.pandoraValues.push(item.pandora_count);
        acc.shazamValues.push(item.shazam_count);
        acc.youtubeValues.push(item.youtube_count);
        acc.tiktokValues.push(item.tiktok_count);
        return acc;
      },
      {
        dates: [],
        spotifyValues: [],
        instagramValues: [],
        pandoraValues: [],
        shazamValues: [],
        youtubeValues: [],
        tiktokValues: [],
      }
    );

  const getBorderColor = (platform: string) => {
    return PLATFORM_COLORS[platform.toLowerCase()];
  };

  const chartData = {
    labels: data.dates, // X-axis labels (dates)
    datasets: SUPPORT_PLATFORMS[target].map(platform => ({
      label: getPlatformFromString(platform, true)!,
      data: data[`${platform.toLowerCase()}Values`], // Y-axis values for platform
      borderColor: getBorderColor(platform),
      fill: false,
    })),
  };

  return (
    <LineGraph
      height={400}
      chartData={chartData}
      renderLabel={(dataLabel: string) => {
        return `${numericalScaleFormatter(Number(dataLabel))} (${(
          (Number(dataLabel) / rawData[0].total) *
          100
        ).toFixed(2)}%)`;
      }}
      renderTooltip={(tooltipItem, data) => {
        const label = data.datasets![tooltipItem.datasetIndex!].label;
        return (
          label +
          ': ' +
          addComma(Number(tooltipItem.value!)) +
          ' (' +
          ((Number(tooltipItem.value!) / rawData[0].total) * 100).toFixed(2) +
          '%)'
        );
      }}
    />
  );
};

const History = ({ state, target }: LinkCompletenessStatsProps) => {
  const { data, isLoading } = useGetLinkCompletenessStatisticsHistory(
    {
      data: {
        tier: state.tier,
        fromDaysAgo: 31,
      },
      path: {
        target,
      },
    },
    {
      disable: state.tier?.length === 0,
    }
  );

  return isLoading ? (
    <Skeleton.Input active style={{ width: '100%', height: 300 }} />
  ) : (
    <Flex wrap="wrap">{data && <LineChart data={data} target={target} />}</Flex>
  );
};
export default History;
