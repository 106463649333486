import React, { useState, useEffect, useCallback, useRef } from 'react';
import { isEmpty, capitalize, isNumber } from 'lodash';
import { Input, Flex, Button, Typography, Segmented, List, Avatar, Skeleton } from 'antd';
import uuid4 from 'uuid4';
import AntdIcon, { ANTD_ICON_TYPES } from '@Shared/Style/AntdIcon';
import Country from '@Shared/Style/Country';
import styles from './GlobalSearch.module.scss';
import { generateCmUrl } from '../../utilities';
import { G_CLOUD_STORAGE } from '../../resource';
import useDebounceValue from '../../hooks/useDebounceValue';
import useOnClickOut from '../../hooks/useOnClickOut';
import {
  SuggestionItem,
  useGetDspSuggestions,
  useGetSuggestions,
  useGetUserSuggestions,
  UserSuggestionItem,
} from '../../services/system';
import { BrandIcon } from '@Shared/Style';
import ItemList from './Components/ItemList';
import UserItemList from './Components/UserItemList';
import { Types } from './types';
import FAIcon from '@Shared/Style/FAIcon';
import { ENTITY_ICON } from '@Constants/entity/index';

interface Props {
  types: Types[];
  onSubmit?: (value: string | { id: number; email: string }) => void;
  onChange?: (value: string) => void;
  loading?: boolean;
  buttonLabel?: string;
  name?: string;
  buttonIcon?: ANTD_ICON_TYPES;
  icon?: ANTD_ICON_TYPES;
  disabled?: boolean;
  placeholder?: string;
  size?: 'small' | 'middle' | 'large';
  variant?: 'outlined' | 'borderless' | 'filled';
  label?: string;
  style?: React.CSSProperties;
  className?: string;
  defaultValue?: string;
  clearAfterSubmit?: boolean;
}

type BaseSuggestions = {
  [key in Exclude<Types, 'user'>]?: SuggestionItem[];
};

type Suggestions = BaseSuggestions & {
  user?: UserSuggestionItem[];
};

function GlobalSearch({
  label,
  types = ['artist', 'track', 'album', 'playlist', 'curator'],
  onSubmit,
  onChange,
  loading = false,
  // TODO: integrate to button object
  buttonLabel = 'Search',
  name,
  buttonIcon = 'search',
  icon = 'search',
  disabled,
  placeholder: placeholderProps,
  size = 'large',
  variant = 'outlined',
  style,
  className,
  defaultValue,
  clearAfterSubmit = false,
}: Props) {
  const placeholder =
    placeholderProps ||
    (types.includes('user') && types.length === 1
      ? 'User Email or ID'
      : `Chartmetric / external URL, Entity name ${
          types.includes('user') ? ' or User Email' : ''
        }`);
  const [value, setValue] = useState('');
  const debouncedValue = useDebounceValue(value, 500);
  const [suggestions, setSuggestions] = useState<Suggestions | undefined>(undefined);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [selectedType, setSelectedType] = useState<Types>('all');
  const [disableSuggestions, setDisableSuggestions] = useState(false);

  const { refetch: fetchSuggestions } = useGetSuggestions({
    data: {
      q: debouncedValue,
      offset: 0,
      limit: 20,
      // targets: types.filter(type => type !== 'user'),
    },
  });
  const { refetch: fetchSuggestionsUser } = useGetUserSuggestions({
    data: { q: debouncedValue },
  });
  const { refetch: fetchSuggestionsByDsp } = useGetDspSuggestions({
    data: { q: debouncedValue },
  });

  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);
  const closeDropdown = useCallback(() => {
    if (!isOpen) {
      return;
    }
    setIsOpen(false);
  }, [isOpen, setIsOpen]);

  useOnClickOut(closeDropdown, containerRef);

  const getSuggestions = (value: string) => {
    if (loading) return;
    if (value.includes('chartmetric.com/')) return;
    setIsOpen(true);
    setIsLoadingSuggestions(true);

    if (value.includes('http') && !isNumber(value)) {
      return fetchSuggestionsByDsp()
        .then(data => {
          setSuggestions(data.data as any);
          setIsLoadingSuggestions(false);
        })
        .catch(() => {
          setIsLoadingSuggestions(false);
        });
    }

    Promise.all([
      types.includes('user') ? fetchSuggestionsUser() : Promise.resolve(null),

      !value.includes('http') && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
        ? fetchSuggestions()
        : Promise.resolve(null),
    ])
      .then(([users, entities]) => {
        setSuggestions({
          ...(entities?.data?.suggestions ?? []).reduce((acc, curr) => {
            if (acc[curr.target]) {
              acc[curr.target].push(curr);
            } else {
              acc[curr.target] = [curr];
            }
            return acc;
          }, {}),
          user: users?.data ?? [],
        });
        setIsLoadingSuggestions(false);
      })
      .catch(() => {
        setIsLoadingSuggestions(false);
      });
  };

  useEffect(() => {
    defaultValue && setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (!debouncedValue) {
      setSuggestions(undefined);
      setIsOpen(false);
    }
    if (debouncedValue && !disableSuggestions) getSuggestions(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    setIsOpen(false);
  }, [loading]);

  const handleChangeInput = useCallback(
    ({ target: { value } }) => {
      setValue(value);
      onChange?.(value);
    },
    [setValue, onChange, name]
  );

  const handleClickSubmit = () => {
    if (loading) return;

    setValue(value);
    Promise.resolve(onSubmit?.(value)).finally(() => {
      clearAfterSubmit && setValue('');
    });
    onChange?.(value);

    setSuggestions(undefined);
    setIsOpen(false);
  };

  const handleChangeSuggestionType = (name: string) => {
    setSelectedType(name.toLowerCase() as Types);
  };

  const handleClickSuggestionOption = useCallback(
    (type, id, service?: string) => e => {
      if (loading) return;

      setDisableSuggestions(true);
      const q = generateCmUrl(type, { id, service });
      onSubmit?.(q);
      onChange?.(q);
      setSuggestions(undefined);
      setIsOpen(false);
      setValue(q);
      setTimeout(() => {
        setDisableSuggestions(false);
      }, 600);
    },
    [loading]
  );

  const handleClickSuggestionUser = (q: string) => e => {
    if (loading) return;
    setDisableSuggestions(true);

    Promise.resolve(onSubmit?.(q)).finally(() => {
      clearAfterSubmit && setValue('');
    });
    onChange?.(q);
    setSuggestions(undefined);
    setIsOpen(false);
    setValue(q);
    setTimeout(() => {
      setDisableSuggestions(false);
    }, 600);
  };

  return (
    <div className={styles.wrapper} style={style}>
      <Typography.Text type="secondary">{label}</Typography.Text>
      <Flex gap="middle">
        <Input
          variant={variant}
          prefix={icon && <AntdIcon color="lightgrey" name={icon} />}
          onClick={() => suggestions && setIsOpen(true)}
          value={value}
          onChange={handleChangeInput}
          placeholder={placeholder}
          disabled={disabled || loading}
          size={size}
          allowClear
          name={name}
        />
        {onSubmit && (
          <Button
            type="primary"
            icon={<AntdIcon name={buttonIcon} />}
            className="green"
            onClick={handleClickSubmit}
            disabled={disabled || isEmpty(value)}
            loading={loading}
            size={size}
          >
            {buttonLabel}
          </Button>
        )}
      </Flex>
      {isOpen && (
        <ul className={styles.suggestionContainer} ref={containerRef}>
          <Segmented
            defaultValue="center"
            style={{ width: 'fit-content' }}
            onChange={handleChangeSuggestionType}
            options={['all', ...types].map(type => ({
              label: (
                <Flex align="center" gap={4}>
                  <FAIcon name={ENTITY_ICON[type.toUpperCase()] as any} />
                  {capitalize(type)}
                </Flex>
              ),
              value: type,
            }))}
            value={capitalize(selectedType)}
          />
          <br />
          {(selectedType === 'all' ? types : [selectedType]).map(type => (
            <>
              <li>
                <Flex gap={4} align="center">
                  <FAIcon name={ENTITY_ICON[type.toUpperCase()] as any} />
                  <Typography.Text style={{ fontSize: 14 }} strong>
                    {capitalize(`${type}s`)}
                  </Typography.Text>
                </Flex>
              </li>
              {type === 'user' && (
                <UserItemList
                  list={suggestions?.user ?? []}
                  loading={isLoadingSuggestions}
                  onClick={handleClickSuggestionUser}
                />
              )}
              {type !== 'playlist' && type !== 'user' && type !== 'curator' && (
                <ItemList
                  list={suggestions?.[type] ?? []}
                  isLoading={isLoadingSuggestions}
                  onClick={handleClickSuggestionOption}
                  type={type}
                />
              )}
              {(type === 'playlist' || type === 'curator') &&
                Object.entries(
                  suggestions?.[type]?.reduce((all, one) => {
                    const { platform } = one;
                    if (!all[platform]) all[platform] = [];
                    all[platform].push(one);
                    return all;
                  }, {}) ?? {}
                ).map(([platform, list]) => (
                  <>
                    <li key={uuid4()}>
                      <Flex gap={4} align="center">
                        <BrandIcon platform={platform as any} />
                        <Typography.Text style={{ fontSize: 14 }} strong>
                          {capitalize(platform)}
                        </Typography.Text>
                      </Flex>
                    </li>
                    <ItemList
                      isLoading={isLoadingSuggestions}
                      onClick={handleClickSuggestionOption}
                      platform={platform}
                      list={list as SuggestionItem[]}
                      type={type}
                    />
                  </>
                ))}
            </>
          ))}
        </ul>
      )}
    </div>
  );
}

export default GlobalSearch;
