import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { capitalize } from 'lodash';
import * as actions from '../../../../actions';
import { generateCmUrl } from '../../../../utilities';
import GlobalSearch from '../../../../shared/GlobalSearch/GlobalSearch';
import WhatsThis from '../../../../shared/Style/WhatsThis';
import {
  useGetArtistAlbums,
  useGetArtistTracks,
  useUpdateArtistAlbums,
  useUpdateArtistTracks,
} from '@Services/artist';
import { DataTable, Panel } from '@Shared/Style';
import { Button, Flex, Typography } from 'antd';

const COLUMNS = {
  tracks: [
    {
      Header: 'Name',
      accessor: 'name', // String-based value accessors!
      Cell: props => (
        <Flex gap="small" align="center">
          <img
            style={{ width: '38px', height: '38px', borderRadius: 6 }}
            src={props.original.image_url}
          />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={generateCmUrl('track', { id: props.original.id })}
          >
            {props.value}
          </a>
        </Flex>
      ),
    },
    {
      Header: 'Album',
      accessor: 'album',
      Cell: props => <span>{Array.isArray(props.value) ? props.value[0].label : ''}</span>,
    },
    {
      Header: 'ISRC',
      accessor: 'isrc',
    },
    {
      Header: 'Artist',
      accessor: 'artist_names',
      Cell: props => <span>{props.value && props.value.join(', ')}</span>,
    },
    {
      Header: 'Release Date',
      accessor: 'release_dates',
      Cell: props => <span>{!props.value ? '' : props.value[0]?.split('T')[0]}</span>,
    },
  ],
  albums: [
    {
      Header: 'Name',
      accessor: 'name', // String-based value accessors!
      Cell: props => (
        <Flex gap="small" align="center">
          <img
            style={{ width: '38px', height: '38px', borderRadius: 6 }}
            src={props.original.image_url}
          />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={generateCmUrl('album', { id: props.original.cm_album })}
          >
            {props.value}
          </a>
        </Flex>
      ),
    },
    {
      Header: 'Label',
      accessor: 'label',
    },
    {
      Header: 'Number of tracks',
      accessor: 'num_tracks',
    },
    {
      Header: 'Release Date',
      accessor: 'release_date',
      Cell: props => <span>{!props.value ? '' : props.value.split('T')[0]}</span>,
    },
  ],
};

interface Props {
  type: 'albums' | 'tracks';
  q: string;
}

const UpdateTrackNAlbum = ({ type, q }: Props) => {
  const { data, isLoading } = {
    tracks: useGetArtistTracks,
    albums: useGetArtistAlbums,
  }[type](
    { data: { q } },
    {
      disable: !q,
    }
  );

  const { execute: updateArtistTracks, isLoading: isLoadingUpdateTrack } = useUpdateArtistTracks();
  const { execute: updateArtistAlbums, isLoading: isLoadingUpdateAlbum } = useUpdateArtistAlbums();

  const handleUpdate = (useApi: boolean) => () => {
    if (type === 'tracks') {
      updateArtistTracks({ data: { q, useApi } });
    } else if (type === 'albums') {
      updateArtistAlbums({ data: { q } });
    }
  };

  return (
    <>
      {data && (
        <Panel
          title={`Update ${capitalize(type)}`}
          description="Displaying up to 100 results"
          extra={
            type === 'tracks' ? (
              <Flex gap="middle" align="center">
                <Button
                  onClick={handleUpdate(false)}
                  loading={isLoadingUpdateTrack}
                  className="green"
                >
                  <Flex align="center">
                    Update Tracks
                    <WhatsThis title="Update Track" color="white">
                      It will update Tracks using the Spotify data held by Chartmetric. Unnecessary
                      data (such as albums and deleted data) will not be updated. The data from
                      Spotify and the updated results may not perfectly match.
                    </WhatsThis>
                  </Flex>
                </Button>
                <Button
                  onClick={handleUpdate(true)}
                  loading={isLoadingUpdateTrack}
                  className="blue"
                >
                  <Flex align="center">
                    Sync tracks with Spotify
                    <WhatsThis title="Sync tracks with Spotify" color="white">
                      It will retrieve all Track data of the corresponding Artist from Spotify and
                      update the Chartmetric database. There is a possibility that unnecessary data,
                      including deleted data, may be inserted. Please use this if a specific Track
                      data is not in the Chartmetric database.
                    </WhatsThis>
                  </Flex>
                </Button>
              </Flex>
            ) : (
              <Button
                onClick={handleUpdate(false)}
                loading={isLoadingUpdateAlbum}
                className="green"
              >
                Update Albums
              </Button>
            )
          }
        >
          <DataTable
            columns={COLUMNS[type]}
            data={data ?? []}
            loading={isLoading}
            defaultPageSize={10}
          />
        </Panel>
      )}
    </>
  );
};

export default UpdateTrackNAlbum;
