import { AntdIcon, Table } from '@Shared/Style';
import ModalButton from '@Shared/Style/ModalButton';
import { Button, Flex, Input, Select } from 'antd';
import React, { useState } from 'react';
import AddHierarchyModal, { Item } from '../../../../shared/Hierarchy/AddHierarchyModalButton';
import { useStates } from '@Hooks';
import { useCreateTag, useGetAllTags } from '@Services/tag';
import { capitalize } from 'lodash';
import { showInfoMessage } from '@Utils';
import { useEditGenreHierarchy } from '../Context';
import AddGenreHierarchyModalButton from './AddGenreHierarchyModalButton';

const INITIAL_STATES = {
  name: null,
  type: null,
  imageUrl: null,
};

const AddNewGenreModal = () => {
  const [selectedRelatedTags, setSelectedRelatedTags] = useState<Item[]>([]);
  const { data, isLoading, refetch } = useGetAllTags();
  const { execute, isLoading: isLoadingCreate } = useCreateTag();

  const TypeOptions = [
    {
      label: 'Main',
      value: 'genre',
    },
  ].concat(
    Array.from(new Set(data?.map(tag => tag.type)))
      .map(type => ({
        label: capitalize(type),
        value: type,
      }))
      .filter(type => type.value !== 'genre')
  );

  return (
    <ModalButton
      style={{
        minWidth: '65vw',
      }}
      title="Add New Genre(Tag)"
      okButtonProps={{
        style: {
          display: 'none',
        },
      }}
      cancelButtonProps={{
        style: {
          display: 'none',
        },
      }}
      Button={
        <Button className="green" loading={isLoadingCreate} icon={<AntdIcon name="plus" />}>
          Create a New Genre
        </Button>
      }
    >
      <Table
        layout="horizontal"
        form={{
          loading: isLoadingCreate,
          initialValues: INITIAL_STATES,
          onSubmit: async values =>
            execute({
              data: {
                children: selectedRelatedTags
                  .filter(tag => tag.position === 'child')
                  .map(tag => tag.id),
                parents: selectedRelatedTags
                  .filter(tag => tag.position === 'parent')
                  .map(tag => tag.id),
                name: values.name!,
                type: values.type!,
                imageUrl: values.imageUrl ?? undefined,
              },
            })
              .then(() => showInfoMessage('Successfully created a new genre'))
              .then(() => refetch()),
        }}
        options={[
          {
            label: 'Name',
            component: <Input placeholder="Name" />,
            value: 'name',
            rules: [
              {
                required: true,
                message: 'Please enter genre name',
              },
            ],
            span: 2,
          },
          {
            label: 'Type',
            value: 'type',
            tooltip: 'Not supported to create as the sub genre which is Spotify`s',
            component: (
              <Select placeholder="Select Type" options={TypeOptions} loading={isLoading} />
            ),
            rules: [
              {
                required: true,
                message: 'Please select genre type',
              },
            ],
            span: 2,
          },
          {
            label: 'Image URL',
            value: 'imageUrl',
            component: <Input placeholder="Image URL" />,
            rules: [
              {
                type: 'url',
              },
            ],
          },
        ]}
      >
        <AddGenreHierarchyModalButton />
        <br />
        <Flex justify="flex-end">
          <Table.SubmitButton />
        </Flex>
      </Table>
    </ModalButton>
  );
};

export default AddNewGenreModal;
