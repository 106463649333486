import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Empty, Flex, Skeleton } from 'antd';
import { capitalize } from 'lodash';
import { ResponsiveContainer } from 'recharts';
import { useGetRecentlyVisited, useGetUserSessionCounts } from '../../../../services/user';
import { getDate, generateCmUrl, getDateToString } from '../../../../utilities';
import { DataTable, Panel } from '../../../../shared/Style';

import useStates from '../../../../hooks/useStates';
import { DEFAULT_CHART_PROPS } from './constants';
import LineGraph from '@Shared/LineGraph/LineGraph';
import dayjs from 'dayjs';

const COLUMNS = [
  {
    Header: 'Name',
    Cell: props => (
      <a
        href={generateCmUrl(props.original.type, { id: props.original.id })}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.original.name}
      </a>
    ),
  },
  {
    Header: 'type',
    accessor: 'type',
    Cell: ({ value }) => capitalize(value),
  },
];

interface Props {
  q: string;
  userId: number;
}

function SessionCounts({ q, userId }: Props) {
  const { setAllStates, states } = useStates<{
    data: any[];
    left: string | number;
    right: string | number;
    refAreaLeft: string;
    refAreaRight: string;
    top: string | number;
    bottom: string | number;
    animation: boolean;
  }>({
    data: [],
    // This is for the zooming graph
    ...DEFAULT_CHART_PROPS,
    animation: true,
  });

  const [duration, setDuration] = useState<'day' | 'minute'>('day');
  const [date, setDate] = useState(dayjs());
  const { data, isLoading, refetch } = useGetUserSessionCounts({
    path: { type: duration },
    data: {
      email: q,
      date: date.toDate(),
    },
  });
  const convertData = () => {
    if (isLoading) return [];
    if (duration === 'day') {
      return data.map(({ timestp, count }) => count).reverse();
    }
    return data.map(({ hm, count }) => count);
  };

  // TODO:
  // const {
  //   data: recentlyVisitedData,
  //   refetch: getRecentlyVisited,
  //   isLoading: isLoadingRecentlyVisited,
  //   isFetching: isFetchingRecentlyVisited,
  // } = useGetRecentlyVisited(
  //   duration === 'day'
  //     ? {
  //         path: {
  //           id: userId,
  //         },
  //         data: {
  //           since: getDate(convertData()?.[states.left]?.name),
  //           until: getDate(convertData()?.[states.right]?.name),
  //         },
  //       }
  //     : {
  //         path: {
  //           id: userId,
  //         },
  //         data: {
  //           since: getDate(date),
  //           until: getDate(new Date(date).setDate(date.getDate() + 1)),
  //         },
  //       },
  //   {
  //     disable:
  //       !userId ||
  //       (duration === 'day' && typeof states.left !== 'number' && typeof states.right !== 'number'),
  //   }
  // );

  // useEffect(() => {
  //   getRecentlyVisited();
  // }, [date]);

  // useEffect(() => {
  //   refetch();
  //   if (userId) {
  //     getRecentlyVisited();
  //   }
  // }, [duration, date, states.left, states.right]);

  useEffect(() => {
    setAllStates({ data: convertData() });
  }, [data]);

  return (
    <Panel title="User Session Counts">
      <Flex justify="center" gap="small">
        <Button
          className={duration === 'day' ? 'green' : 'lightGray'}
          onClick={() => {
            setDuration('day');
          }}
        >
          Per Day
        </Button>
        <Button
          className={duration === 'minute' ? 'green' : ''}
          onClick={() => {
            setDuration('minute');
          }}
        >
          Per Minute
        </Button>
        {duration === 'minute' && (
          <DatePicker
            value={date}
            onChange={date => {
              setDate(date);
            }}
          />
        )}
        {/* <DatePicker
          selected={userSessionDate}
          onChange={date => {
            setUserSessionType('minute');
            setUserSessionDate(date);
            zoomOut();
          }}
          dateFormat="yyyy-MM-dd"
          customInput={
            <Button color={userSessionType === 'minute' ? 'green' : 'lightGray'}>
              Per Minute {userSessionDate && `(${getDateFormatFromDatePicker(userSessionDate)})`}
            </Button>
          }
        /> */}
      </Flex>
      <br />
      {!isLoading && data?.length === 0 ? (
        <Flex justify="center">
          <Empty />
        </Flex>
      ) : isLoading ? (
        <Flex justify="center" style={{ width: '100% !important' }}>
          <Skeleton.Node
            active
            style={{
              width: '100% !important',
              height: '300px',
            }}
          >
            <span />
          </Skeleton.Node>
        </Flex>
      ) : (
        <ResponsiveContainer height={300}>
          <LineGraph
            renderYAxisLabel={(dataLabel: string, index) => {
              if (duration === 'day') {
                const day = dataLabel.split('-')[2];
                const month = dataLabel.split('-')[1];
                return `${month}/${day}`;
              }
              return dataLabel;
            }}
            chartData={{
              labels:
                duration === 'day'
                  ? data.map(({ timestp }) => getDateToString(new Date(timestp)))
                  : data.map(value => {
                      return value.hm;
                    }),
              datasets: [
                {
                  label: 'Session Counts',
                  data: convertData(),
                  fill: false,
                  backgroundColor: 'rgb(9, 95, 253)',
                  borderColor: 'rgb(9, 95, 253)',
                },
              ],
            }}
            height={300}
          />
        </ResponsiveContainer>
      )}
      <br />
      {/* {((typeof states.left === 'number' && typeof states.right === 'number') ||
        duration === 'minute') && (
        <Panel
          title={`Recently Visited 
          (${
            duration === 'day'
              ? `${convertData()?.[states.left]?.name} ~${convertData()?.[states.right]?.name}`
              : getDate(date)
          })
          `}
          innerStyle
        >
          <DataTable
            loading={isLoadingRecentlyVisited || isFetchingRecentlyVisited}
            data={recentlyVisitedData || []}
            columns={COLUMNS}
          />
        </Panel>
      )} */}
    </Panel>
  );
}

export default SessionCounts;
