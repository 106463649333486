import { mutateToUrl, persistGetToUrl } from './helpers';

const ENDPOINT = {
  COUPON: '/admin/coupon',
  CHURNED_USER: '/admin/churned-users',
  UPDATE_CHURNED_USER_COMMENT: `/admin/churned-users/:id/comment`,
  ADD_TRIAL_SUBSCRIPTION: `/admin/user/free-trial`,
};

export const useGetCoupons = persistGetToUrl<{
  data: {
    expired?: boolean;
  };
}>(ENDPOINT.COUPON);
export const useCreateCoupon = mutateToUrl<{
  data: {
    code: string;
    expirationDate: string;
    numOfDays: number;
  };
}>('POST', ENDPOINT.COUPON);

export const useUpdateCoupon = mutateToUrl<{
  query: {
    id: number;
  };
  data: {
    code: string;
    expirationDate: string;
    numOfDays: number;
  };
}>('PUT', ENDPOINT.COUPON);
export const useDeleteCoupon = mutateToUrl<{
  query: {
    id: string;
  };
}>('DELETE', ENDPOINT.COUPON);

export const useGetChurnedUsers = persistGetToUrl(ENDPOINT.CHURNED_USER);

export const useUpdateChurnedUserComment = mutateToUrl<{
  data: {
    comment: string;
  };
  path: {
    id: string;
  };
}>('PATCH', ENDPOINT.UPDATE_CHURNED_USER_COMMENT);

export const useAddTrialSubscription = mutateToUrl<{
  data: {
    email: string;
    days: number;
    type: 'premium' | 'api';
  };
}>('POST', ENDPOINT.ADD_TRIAL_SUBSCRIPTION);
