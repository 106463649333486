import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Table, Divider, Button, Icon } from 'semantic-ui-react';
import * as actions from '../../../actions';
import GlobalSearch from '../../../shared/GlobalSearch/GlobalSearch';

class MergeArtist extends Component {
  handleChange = ({ name, value }) => this.setState({ [name]: value });

  handleClick = () => {
    const { mergeArtists } = this.props;
    const { primaryArtistUrl, secondaryArtistUrl } = this.state;
    if (primaryArtistUrl && secondaryArtistUrl) {
      mergeArtists(primaryArtistUrl, secondaryArtistUrl);
    }
  };

  render() {
    const { response } = this.props;
    return (
      <Segment raised>
        <h3>Merge Duplicated Artist</h3>

        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Primary</Table.Cell>
              <Table.Cell>
                <GlobalSearch
                  types={['artist']}
                  onChange={this.handleChange}
                  name="primaryArtistUrl"
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Secondary</Table.Cell>
              <Table.Cell>
                <GlobalSearch
                  types={['artist']}
                  onChange={this.handleChange}
                  name="secondaryArtistUrl"
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Button
          fluid
          size="large"
          color="blue"
          loading={response.isFetching}
          onClick={this.handleClick}
        >
          Submit
        </Button>
      </Segment>
    );
  }
}

const mapStateToProps = state => ({ response: state.metadata.update });
export default connect(mapStateToProps, actions)(MergeArtist);
