import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Flex } from 'antd';
import * as actions from '../../../actions';
import GlobalSearch from '../../../shared/GlobalSearch/GlobalSearch';
import { Panel, AntdIcon } from '../../../shared/Style';

class TransferFavorites extends Component {
  state = {
    from: '',
    to: '',
  };

  onChange = e => {
    this.setState({
      [e.name]: e.value,
    });
  };

  onSubmit = () => {
    const { from, to } = this.state;
    this.props
      .transferFavorites({
        from_email: from,
        to_email: to,
      })
      .then(() => {
        this.setState({
          from: '',
          to: '',
        });
      });
  };

  render() {
    const {
      action: { isProceeding },
    } = this.props;

    return (
      <Panel title="Transfer Account's Favorites To Another Account">
        <Flex gap="small" justify="center">
          <GlobalSearch
            placeholder="User Email Address"
            name="from"
            onChange={this.onChange}
            label="From"
            icon="user"
            types={['user']}
            size="middle"
            style={{ width: '100%' }}
          />
          <AntdIcon name="rightArrow" color="lightgrey" />
          <GlobalSearch
            placeholder="User Email Address"
            name="to"
            onChange={this.onChange}
            label="To"
            icon="user"
            types={['user']}
            size="middle"
            style={{ width: '100%' }}
          />
        </Flex>
        <br />
        <Flex justify="center">
          <Button
            icon={<AntdIcon name="toTop" />}
            loading={isProceeding}
            className="blue"
            onClick={this.onSubmit}
            disabled={!this.state.from || !this.state.to}
          >
            Transfer
          </Button>
        </Flex>
      </Panel>
    );
  }
}

const mapStateToProps = state => ({ action: state.action });
export default connect(mapStateToProps, actions)(TransferFavorites);
