import { ChartOptions, ChartTooltipItem } from 'chart.js';
import React, { useEffect } from 'react';
import { Line, ChartData } from 'react-chartjs-2';

const LineGraph = ({
  chartData,
  height = 400,
  renderTooltip,
  renderLabel,
  renderYAxisLabel,
}: {
  chartData: ChartData<any>;
  height?: number;
  renderTooltip?: (tooltipItem: ChartTooltipItem, data: ChartData<any>) => string | string[];
  renderLabel?: (
    value: number | string,
    index: number,
    values: number[] | string[]
  ) => string | number | null | undefined;
  renderYAxisLabel?: (
    value: number | string,
    index: number,
    values: number[] | string[]
  ) => string | number | null | undefined;
}) => {
  const options: ChartOptions = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    responsive: true,
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          enabled: true,
          drag: true,
          mode: 'xy',
        },
      },
      legend: {
        position: 'top',
      },
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: renderTooltip
        ? {
            label: renderTooltip,
          }
        : {},
      titleFontSize: 16,
      bodyFontSize: 14,
      displayColors: true,
      bodySpacing: 4,
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            callback:
              renderYAxisLabel ??
              function (dataLabel: string, index) {
                const day = dataLabel.split('-')[2];
                const month = dataLabel.split('-')[1];
                return `${month}/${day}`;
              },
          },
        },
      ],
      yAxes: renderLabel
        ? [
            {
              ticks: {
                callback: renderLabel,
              },
            },
          ]
        : [],
    },
  };
  const plugins = [
    {
      afterDraw: (chart: { tooltip?: any; scales?: any; ctx?: any }) => {
        // eslint-disable-next-line no-underscore-dangle
        if (chart.tooltip._active && chart.tooltip._active.length > 0) {
          // find coordinates of tooltip
          const activePoint = chart.tooltip._active[0]['_view'];
          const { ctx } = chart;
          const { x } = activePoint;
          const topY = chart.scales['y-axis-0'].top;
          const bottomY = chart.scales['y-axis-0'].bottom;

          // draw vertical line
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#1c2128a7';
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  ];

  return <Line height={height} type="line" data={chartData} options={options} plugins={plugins} />;
};

export default LineGraph;
