import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Input, Label, Divider, Button } from 'semantic-ui-react';
import * as actions from '../../../actions';
import { Flex } from 'antd';

class DeleteStripeInvocies extends Component {
  onSubmit = () => {
    this.props.cleanUpStripe();
  };

  render() {
    const {
      action: { isProceeding },
    } = this.props;

    return (
      <Segment raised>
        <Label>Delete Stripe overdue invoices for cancelled subscriptions</Label>
        <h4>Please use with care. Can take up to 45 minutes</h4>
        <Flex style={{ width: '100%' }} gap="middle">
          <Button
            loading={isProceeding}
            className="button-center"
            secondary
            size="large"
            onClick={this.onSubmit}
          >
            Submit
          </Button>
        </Flex>
      </Segment>
    );
  }
}

const mapStateToProps = state => ({ action: state.action });
export default connect(mapStateToProps, actions)(DeleteStripeInvocies);
