import React, { useEffect, useState } from 'react';
import {
  useGetUserFollowedArtist,
  useAddUserFollowedArtist,
  useDeleteUserFollowedArtist,
  useGetUserViewableArtist,
  useAddUserViewableArtist,
  useDeleteUserViewableArtist,
} from '@Services/user';
import { Panel, AntdIcon } from '@Shared/Style';
import { Button, Divider, Empty, Flex, Input, Select, Skeleton, Tag } from 'antd';
import GlobalSearch from '@Shared/GlobalSearch/GlobalSearch';
import uuid4 from 'uuid4';
import { connect } from 'react-redux';
import * as actions from '../../../../actions';
import FAIcon from '@Shared/Style/FAIcon';

function UserArtists({ userId: id, useGet, useAdd, useDelete, title }) {
  const { data, refetch, isLoading } = useGet({ path: { id } });
  const { execute: addArtist, isLoading: isFetchingAdd } = useAdd();
  const { execute: deleteArtist, isLoading: isFetchingDelete } = useDelete();

  useEffect(() => {
    refetch();
  }, [id]);

  const addNewArtist = url => {
    addArtist({
      data: { cm_artist: url.split('/artist/')[1].split('/about')[0] },
      path: { id },
    }).then(() => refetch());
  };
  const removeArtist = artist => {
    deleteArtist({
      path: { id },
      data: { cm_artist: artist.cm_artist },
    }).then(() => refetch());
  };

  return (
    <Panel title={title}>
      <GlobalSearch
        loading={isFetchingAdd}
        icon="user"
        types={['artist']}
        onSubmit={addNewArtist}
        name="artist-search"
        size="middle"
        buttonIcon="plus"
        buttonLabel="Add Artist"
      />
      <Divider />
      <Flex wrap="wrap" gap={8}>
        {isLoading ? (
          <Flex vertical gap="small">
            {Array.from({ length: 5 }).map((_, i) => (
              <Skeleton.Input key={uuid4()} active size="small" />
            ))}
          </Flex>
        ) : data?.length ? (
          data?.map(artist => (
            <a target="_blank" href={`https://app.chartmetric.com/artist?id=${artist.cm_artist}`}>
              <Tag
                key={artist.cm_artist}
                color="green"
                className="gap-2 flex flex-row items-center"
              >
                {`${artist.name} - ${artist.cm_artist}`}
                <FAIcon
                  name="farTrash"
                  loading={isFetchingDelete}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    removeArtist(artist);
                  }}
                />
              </Tag>
            </a>
          ))
        ) : (
          <Flex justify="center" align="center" style={{ width: '100%' }}>
            <Empty />
          </Flex>
        )}
      </Flex>
    </Panel>
  );
}

const FollowEntityBatch = connect(
  null,
  actions
)(({ q, followArtists, fetchUserInfo }) => {
  const handleClick = () => {
    if (type === '') {
      window.alert('Please select a type to follow');
    } else {
      followArtists(q, ids, type).then(() => fetchUserInfo(q, true));
    }
  };
  const [ids, setIds] = React.useState('');
  const [type, setType] = React.useState('artists');
  const cmTypes = [
    { label: 'Artists', value: 'artists' },
    { label: 'Tracks', value: 'tracks' },
    { label: 'Albums', value: 'albums' },
  ];
  return (
    <Panel
      title="Follow Entity Batch"
      description="Artists / Tracks / Albums to follow (Chartmetric IDs seperated by comma)"
    >
      <Input.TextArea
        rows={10}
        cols={100}
        name="follow"
        value={ids}
        onChange={e => setIds(e.target.value)}
      />
      <br />

      <Flex style={{ width: '100%' }} flex={1} justify="center" gap="small">
        <Select
          style={{ width: '100px' }}
          options={cmTypes}
          onChange={e => {
            setType(e);
          }}
          value={type}
        />
        <Button type="primary" icon={<AntdIcon name="plus" />} onClick={() => handleClick()}>
          Batch Follow
        </Button>
      </Flex>
    </Panel>
  );
});

export function FollowedArtist({ userId: id }) {
  return (
    <>
      <UserArtists
        title="Followed Artists"
        userId={id}
        useGet={useGetUserFollowedArtist}
        useAdd={useAddUserFollowedArtist}
        useDelete={useDeleteUserFollowedArtist}
      />
      <FollowEntityBatch q={id} />
    </>
  );
}

export function ViewableArtist({ userId: id }) {
  return (
    <UserArtists
      title="Viewable Artists (Artist Plan)"
      userId={id}
      useGet={useGetUserViewableArtist}
      useAdd={useAddUserViewableArtist}
      useDelete={useDeleteUserViewableArtist}
    />
  );
}
