import React from 'react';
import { Tabs } from '@Shared/Style';
import TeamManagers from './Managers';
import ManageTeam from './ManageTeam';

const TeamManager = () => {
  return (
    <Tabs
      tabs={[
        {
          content: <ManageTeam />,
          title: 'Search Team',
        },
        {
          content: <TeamManagers />,
          title: 'Managers',
        },
      ]}
    />
  );
};

export default TeamManager;
