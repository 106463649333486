export const CREATE_PORTAL_SESSION = 'CREATE_PORTAL_SESSION';
export const CREATE_PORTAL_SESSION_FULFILLED = 'CREATE_PORTAL_SESSION_FULFILLED';

export const AUTH_USER = 'AUTH_USER';
export const AUTH_USER_PENDING = 'AUTH_USER_PENDING';
export const AUTH_USER_FULFILLED = 'AUTH_USER_FULFILLED';
export const AUTH_USER_REJECTED = 'AUTH_USER_REJECTED';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_PENDING = 'LOGIN_USER_PENDING';
export const LOGIN_USER_FULFILLED = 'LOGIN_USER_FULFILLED';
export const LOGIN_USER_REJECTED = 'LOGIN_USER_REJECTED';
export const LOGOUT_USER = 'LOGOUT_USER';

export const SEARCH_CM_DATA = 'SEARCH_CM_DATA';
export const SEARCH_CM_DATA_PENDING = 'SEARCH_CM_DATA_PENDING';
export const SEARCH_CM_DATA_FULFILLED = 'SEARCH_CM_DATA_FULFILLED';
export const SEARCH_CM_DATA_REJECTED = 'SEARCH_CM_DATA_REJECTED';

export const UPDATE_CM_URLS = 'UPDATE_CM_URLS';
export const UPDATE_CM_URLS_PENDING = 'UPDATE_CM_URLS_PENDING';
export const UPDATE_CM_URLS_FULFILLED = 'UPDATE_CM_URLS_FULFILLED';
export const UPDATE_CM_URLS_REJECTED = 'UPDATE_CM_URLS_REJECTED';

export const UPDATE_PLAYLIST = 'UPDATE_PLAYLIST';
export const UPDATE_PLAYLIST_PENDING = 'UPDATE_PLAYLIST_PENDING';
export const UPDATE_PLAYLIST_FULFILLED = 'UPDATE_PLAYLIST_FULFILLED';
export const UPDATE_PLAYLIST_REJECTED = 'UPDATE_PLAYLIST_REJECTED';

export const UPDATE_ARTIST = 'UPDATE_ARTIST';
export const UPDATE_ARTIST_PENDING = 'UPDATE_ARTIST_PENDING';
export const UPDATE_ARTIST_FULFILLED = 'UPDATE_ARTIST_FULFILLED';
export const UPDATE_ARTIST_REJECTED = 'UPDATE_ARTIST_REJECTED';

export const UPDATE_TRACK = 'UPDATE_TRACK';
export const UPDATE_TRACK_PENDING = 'UPDATE_TRACK_PENDING';
export const UPDATE_TRACK_FULFILLED = 'UPDATE_TRACK_FULFILLED';
export const UPDATE_TRACK_REJECTED = 'UPDATE_TRACK_REJECTED';

export const ADD_SIMILAR_TRACK = 'ADD_SIMILAR_TRACK';
export const ADD_SIMILAR_TRACK_PENDING = 'ADD_SIMILAR_TRACK_PENDING';
export const ADD_SIMILAR_TRACK_FULFILLED = 'ADD_SIMILAR_TRACK_FULFILLED';
export const ADD_SIMILAR_TRACK_REJECTED = 'ADD_SIMILAR_TRACK_REJECTED';

export const DELETE_SIMILAR_TRACK = 'DELETE_SIMILAR_TRACK';
export const DELETE_SIMILAR_TRACK_PENDING = 'DELETE_SIMILAR_TRACK_PENDING';
export const DELETE_SIMILAR_TRACK_FULFILLED = 'DELETE_SIMILAR_TRACK_FULFILLED';
export const DELETE_SIMILAR_TRACK_REJECTED = 'DELETE_SIMILAR_TRACK_REJECTED';

export const UPDATE_ARTIST_TRACKS = 'UPDATE_ARTIST_TRACKS';
export const UPDATE_ARTIST_TRACKS_PENDING = 'UPDATE_ARTIST_TRACKS_PENDING';
export const UPDATE_ARTIST_TRACKS_FULFILLED = 'UPDATE_ARTIST_TRACKS_FULFILLED';
export const UPDATE_ARTIST_TRACKS_REJECTED = 'UPDATE_ARTIST_TRACKS_REJECTED';

export const UPDATE_ARTIST_ALBUMS = 'UPDATE_ARTIST_ALBUMS';
export const UPDATE_ARTIST_ALBUMS_PENDING = 'UPDATE_ARTIST_ALBUMS_PENDING';
export const UPDATE_ARTIST_ALBUMS_FULFILLED = 'UPDATE_ARTIST_ALBUMS_FULFILLED';
export const UPDATE_ARTIST_ALBUMS_REJECTED = 'UPDATE_ARTIST_ALBUMS_REJECTED';

export const ADD_ARTIST = 'ADD_ARTIST';
export const ADD_ARTIST_PENDING = 'ADD_ARTIST_PENDING';
export const ADD_ARTIST_FULFILLED = 'ADD_ARTIST_FULFILLED';
export const ADD_ARTIST_REJECTED = 'ADD_ARTIST_REJECTED';

export const ADD_TRACK = 'ADD_TRACK';
export const ADD_TRACK_PENDING = 'ADD_TRACK_PENDING';
export const ADD_TRACK_FULFILLED = 'ADD_TRACK_FULFILLED';
export const ADD_TRACK_REJECTED = 'ADD_TRACK_REJECTED';

export const UPDATE_ALBUM = 'UPDATE_ALBUM';
export const UPDATE_ALBUM_PENDING = 'UPDATE_ALBUM_PENDING';
export const UPDATE_ALBUM_FULFILLED = 'UPDATE_ALBUM_FULFILLED';
export const UPDATE_ALBUM_REJECTED = 'UPDATE_ALBUM_REJECTED';

export const DELETE_PLAYLIST = 'DELETE_PLAYLIST';
export const DELETE_PLAYLIST_PENDING = 'DELETE_PLAYLIST_PENDING';
export const DELETE_PLAYLIST_FULFILLED = 'DELETE_PLAYLIST_FULFILLED';
export const DELETE_PLAYLIST_REJECTED = 'DELETE_PLAYLIST_REJECTED';

export const MERGE_ARTISTS = 'MERGE_ARTISTS';
export const MERGE_ARTISTS_PENDING = 'MERGE_ARTISTS_PENDING';
export const MERGE_ARTISTS_FULFILLED = 'MERGE_ARTISTS_FULFILLED';
export const MERGE_ARTISTS_REJECTED = 'MERGE_ARTISTS_REJECTED';

export const GRANT_API_ACCESS = 'GRANT_API_ACCESS';
export const GRANT_API_ACCESS_PENDING = 'GRANT_API_ACCESS_PENDING';
export const GRANT_API_ACCESS_FULFILLED = 'GRANT_API_ACCESS_FULFILLED';
export const GRANT_API_ACCESS_REJECTED = 'GRANT_API_ACCESS_REJECTED';

export const REMOVE_API_ACCESS = 'REMOVE_API_ACCESS';
export const REMOVE_API_ACCESS_PENDING = 'REMOVE_API_ACCESS_PENDING';
export const REMOVE_API_ACCESS_FULFILLED = 'REMOVE_API_ACCESS_FULFILLED';
export const REMOVE_API_ACCESS_REJECTED = 'REMOVE_API_ACCESS_REJECTED';

export const REMOVE_SSO_LOGIN = 'REMOVE_SSO_LOGIN';
export const REMOVE_SSO_LOGIN_PENDING = 'REMOVE_SSO_LOGIN_PENDING';
export const REMOVE_SSO_LOGIN_FULFILLED = 'REMOVE_SSO_LOGIN_FULFILLED';
export const REMOVE_SSO_LOGIN_REJECTED = 'REMOVE_SSO_LOGIN_REJECTED';

export const VERIFY_USER_EMAIL = 'VERIFY_USER_EMAIL';
export const VERIFY_USER_EMAIL_PENDING = 'VERIFY_USER_EMAIL_PENDING';
export const VERIFY_USER_EMAIL_FULFILLED = 'VERIFY_USER_EMAIL_FULFILLED';
export const VERIFY_USER_EMAIL_REJECTED = 'VERIFY_USER_EMAIL_REJECTED';

export const CHANGE_USER_EMAIL = 'CHANGE_USER_EMAIL';
export const CHANGE_USER_EMAIL_PENDING = 'CHANGE_USER_EMAIL_PENDING';
export const CHANGE_USER_EMAIL_FULFILLED = 'CHANGE_USER_EMAIL_FULFILLED';
export const CHANGE_USER_EMAIL_REJECTED = 'CHANGE_USER_EMAIL_REJECTED';

export const FOLLOW_ARTISTS = 'FOLLOW_ARTISTS';
export const FOLLOW_ARTISTS_PENDING = 'FOLLOW_ARTISTS_PENDING';
export const FOLLOW_ARTISTS_FULFILLED = 'FOLLOW_ARTISTS_FULFILLED';
export const FOLLOW_ARTISTS_REJECTED = 'FOLLOW_ARTISTS_REJECTED';

export const RESET_ARTIST_PLAN_SELECTION = 'RESET_ARTIST_PLAN_SELECTION';
export const RESET_ARTIST_PLAN_SELECTION_PENDING = 'RESET_ARTIST_PLAN_SELECTION_PENDING';
export const RESET_ARTIST_PLAN_SELECTION_FULFILLED = 'RESET_ARTIST_PLAN_SELECTION_FULFILLED';
export const RESET_ARTIST_PLAN_SELECTION_REJECTED = 'RESET_ARTIST_PLAN_SELECTION_REJECTED';

export const MERGE_SOCIAL_STAT = 'MERGE_SOCIAL_STAT';
export const MERGE_SOCIAL_STAT_PENDING = 'MERGE_SOCIAL_STAT_PENDING';
export const MERGE_SOCIAL_STAT_FULFILLED = 'MERGE_SOCIAL_STAT_FULFILLED';
export const MERGE_SOCIAL_STAT_REJECTED = 'MERGE_SOCIAL_STAT_REJECTED';

export const EDIT_LABEL_GROUP = 'EDIT_LABEL_GROUP';
export const EDIT_LABEL_GROUP_PENDING = 'EDIT_LABEL_GROUP_PENDING';
export const EDIT_LABEL_GROUP_FULFILLED = 'EDIT_LABEL_GROUP_FULFILLED';
export const EDIT_LABEL_GROUP_REJECTED = 'EDIT_LABEL_GROUP_REJECTED';

export const GET_LABEL_GROUP = 'GET_LABEL_GROUP';
export const GET_LABEL_GROUP_PENDING = 'GET_LABEL_GROUP_PENDING';
export const GET_LABEL_GROUP_FULFILLED = 'GET_LABEL_GROUP_FULFILLED';
export const GET_LABEL_GROUP_REJECTED = 'GET_LABEL_GROUP_REJECTED';

export const FETCH_TEAM_MANAGERS = 'FETCH_TEAM_MANAGERS';
export const FETCH_TEAM_MANAGERS_PENDING = 'FETCH_TEAM_MANAGERS_PENDING';
export const FETCH_TEAM_MANAGERS_FULFILLED = 'FETCH_TEAM_MANAGERS_FULFILLED';
export const FETCH_TEAM_MANAGERS_REJECTED = 'FETCH_TEAM_MANAGERS_REJECTED';
export const ADD_TEAM_MANAGER = 'ADD_TEAM_MANAGER';
export const ADD_TEAM_MANAGER_PENDING = 'ADD_TEAM_MANAGER_PENDING';
export const ADD_TEAM_MANAGER_FULFILLED = 'ADD_TEAM_MANAGER_FULFILLED';
export const ADD_TEAM_MANAGER_REJECTED = 'ADD_TEAM_MANAGER_REJECTED';
export const DELETE_TEAM_MANAGER = 'DELETE_TEAM_MANAGER';
export const DELETE_TEAM_MANAGER_PENDING = 'DELETE_TEAM_MANAGER_PENDING';
export const DELETE_TEAM_MANAGER_FULFILLED = 'DELETE_TEAM_MANAGER_FULFILLED';
export const DELETE_TEAM_MANAGER_REJECTED = 'DELETE_TEAM_MANAGER_REJECTED';
export const FETCH_TEAM_MEMBERS = 'FETCH_TEAM_MEMBERS';
export const FETCH_TEAM_MEMBERS_PENDING = 'FETCH_TEAM_MEMBERS_PENDING';
export const FETCH_TEAM_MEMBERS_FULFILLED = 'FETCH_TEAM_MEMBERS_FULFILLED';
export const FETCH_TEAM_MEMBERS_REJECTED = 'FETCH_TEAM_MEMBERS_REJECTED';
export const UPDATE_TEAM_MEMBERS = 'UPDATE_TEAM_MEMBERS';
export const UPDATE_TEAM_MEMBERS_PENDING = 'UPDATE_TEAM_MEMBERS_PENDING';
export const UPDATE_TEAM_MEMBERS_FULFILLED = 'UPDATE_TEAM_MEMBERS_FULFILLED';
export const UPDATE_TEAM_MEMBERS_REJECTED = 'UPDATE_TEAM_MEMBERS_REJECTED';

export const FETCH_COUPON_CODES = 'FETCH_COUPON_CODES';
export const FETCH_COUPON_CODES_PENDING = 'FETCH_COUPON_CODES_PENDING';
export const FETCH_COUPON_CODES_FULFILLED = 'FETCH_COUPON_CODES_FULFILLED';
export const FETCH_COUPON_CODES_REJECTED = 'FETCH_COUPON_CODES_REJECTED';
export const UPDATE_COUPON_CODE = 'UPDATE_COUPON_CODE';
export const UPDATE_COUPON_CODE_PENDING = 'UPDATE_COUPON_CODE_PENDING';
export const UPDATE_COUPON_CODE_FULFILLED = 'UPDATE_COUPON_CODE_FULFILLED';
export const UPDATE_COUPON_CODE_REJECTED = 'UPDATE_COUPON_CODE_REJECTED';

export const FETCH_USER_INFO = 'FETCH_USER_INFO';
export const FETCH_USER_INFO_PENDING = 'FETCH_USER_INFO_PENDING';
export const FETCH_USER_INFO_FULFILLED = 'FETCH_USER_INFO_FULFILLED';
export const FETCH_USER_INFO_REJECTED = 'FETCH_USER_INFO_REJECTED';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_PENDING = 'DELETE_USER_PENDING';
export const DELETE_USER_FULFILLED = 'DELETE_USER_FULFILLED';
export const DELETE_USER_REJECTED = 'DELETE_USER_REJECTED';

export const FETCH_USER_FOLLOWED_ARTISTS = 'FETCH_USER_FOLLOWED_ARTISTS';
export const FETCH_USER_FOLLOWED_ARTISTS_PENDING = 'FETCH_USER_FOLLOWED_ARTISTS_PENDING';
export const FETCH_USER_FOLLOWED_ARTISTS_FULFILLED = 'FETCH_USER_FOLLOWED_ARTISTS_FULFILLED';
export const FETCH_USER_FOLLOWED_ARTISTS_REJECTED = 'FETCH_USER_FOLLOWED_ARTISTS_REJECTED';
export const FOLLOW_ARTIST_BY_USER = 'FOLLOW_ARTIST_BY_USER';
export const FOLLOW_ARTIST_BY_USER_PENDING = 'FOLLOW_ARTIST_BY_USER_PENDING';
export const FOLLOW_ARTIST_BY_USER_FULFILLED = 'FOLLOW_ARTIST_BY_USER_FULFILLED';
export const FOLLOW_ARTIST_BY_USER_REJECTED = 'FOLLOW_ARTIST_BY_USER_REJECTED';
export const DELETE_FOLLOWED_ARTIST = 'DELETE_FOLLOWED_ARTIST';
export const DELETE_FOLLOWED_ARTIST_PENDING = 'DELETE_FOLLOWED_ARTIST_PENDING';
export const DELETE_FOLLOWED_ARTIST_FULFILLED = 'DELETE_FOLLOWED_ARTIST_FULFILLED';
export const DELETE_FOLLOWED_ARTIST_REJECTED = 'DELETE_FOLLOWED_ARTIST_REJECTED';

export const FETCH_USER_VIEWABLE_ARTISTS = 'FETCH_USER_VIEWABLE_ARTISTS';
export const FETCH_USER_VIEWABLE_ARTISTS_PENDING = 'FETCH_USER_VIEWABLE_ARTISTS_PENDING';
export const FETCH_USER_VIEWABLE_ARTISTS_FULFILLED = 'FETCH_USER_VIEWABLE_ARTISTS_FULFILLED';
export const FETCH_USER_VIEWABLE_ARTISTS_REJECTED = 'FETCH_USER_VIEWABLE_ARTISTS_REJECTED';
export const SUBSCRIBE_TO_ARTIST = 'SUBSCRIBE_TO_ARTIST';
export const SUBSCRIBE_TO_ARTIST_PENDING = 'SUBSCRIBE_TO_ARTIST_PENDING';
export const SUBSCRIBE_TO_ARTIST_FULFILLED = 'SUBSCRIBE_TO_ARTIST_FULFILLED';
export const SUBSCRIBE_TO_ARTIST_REJECTED = 'SUBSCRIBE_TO_ARTIST_REJECTED';
export const DELETE_VIEWABLE_ARTIST = 'DELETE_VIEWABLE_ARTIST';
export const DELETE_VIEWABLE_ARTIST_PENDING = 'DELETE_VIEWABLE_ARTIST_PENDING';
export const DELETE_VIEWABLE_ARTIST_FULFILLED = 'DELETE_VIEWABLE_ARTIST_FULFILLED';
export const DELETE_VIEWABLE_ARTIST_REJECTED = 'DELETE_VIEWABLE_ARTIST_REJECTED';

export const FETCH_USER_API_USAGE_STATS = 'FETCH_USER_API_USAGE_STATS';
export const FETCH_USER_API_USAGE_STATS_PENDING = 'FETCH_USER_API_USAGE_STATS_PENDING';
export const FETCH_USER_API_USAGE_STATS_FULFILLED = 'FETCH_USER_API_USAGE_STATS_FULFILLED';
export const FETCH_USER_API_USAGE_STATS_REJECTED = 'FETCH_USER_API_USAGE_STATS_REJECTED';
export const FETCH_USER_API_USAGE_DETAILS = 'FETCH_USER_API_USAGE_DETAILS';
export const FETCH_USER_API_USAGE_DETAILS_PENDING = 'FETCH_USER_API_USAGE_DETAILS_PENDING';
export const FETCH_USER_API_USAGE_DETAILS_FULFILLED = 'FETCH_USER_API_USAGE_DETAILS_FULFILLED';
export const FETCH_USER_API_USAGE_DETAILS_REJECTED = 'FETCH_USER_API_USAGE_DETAILS_REJECTED';

export const FETCH_LINKS_BY_USER_DETAILS = 'FETCH_LINKS_BY_USER_DETAILS';
export const FETCH_LINKS_BY_USER_DETAILS_PENDING = 'FETCH_LINKS_BY_USER_DETAILS_PENDING';
export const FETCH_LINKS_BY_USER_DETAILS_FULFILLED = 'FETCH_LINKS_BY_USER_DETAILS_FULFILLED';
export const FETCH_LINKS_BY_USER_DETAILS_REJECTED = 'FETCH_LINKS_BY_USER_DETAILS_REJECTED';
export const FETCH_LINKS_BY_USER_HISTORY = 'FETCH_LINKS_BY_USER_HISTORY';
export const FETCH_LINKS_BY_USER_HISTORY_PENDING = 'FETCH_LINKS_BY_USER_HISTORY_PENDING';
export const FETCH_LINKS_BY_USER_HISTORY_FULFILLED = 'FETCH_LINKS_BY_USER_HISTORY_FULFILLED';
export const FETCH_LINKS_BY_USER_HISTORY_REJECTED = 'FETCH_LINKS_BY_USER_HISTORY_REJECTED';

export const USER_BLOCKLIST = 'USER_BLOCKLIST';
export const USER_BLOCKLIST_PENDING = 'USER_BLOCKLIST_PENDING';
export const USER_BLOCKLIST_FULFILLED = 'USER_BLOCKLIST_FULFILLED';
export const USER_BLOCKLIST_REJECTED = 'USER_BLOCKLIST_REJECTED';
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export const RESET_USER_PASSWORD_PENDING = 'RESET_USER_PASSWORD_PENDING';
export const RESET_USER_PASSWORD_FULFILLED = 'RESET_USER_PASSWORD_FULFILLED';
export const RESET_USER_PASSWORD_REJECTED = 'RESET_USER_PASSWORD_REJECTED';

export const FETCH_POWER_USER_CURVE = 'FETCH_POWER_USER_CURVE';
export const FETCH_POWER_USER_CURVE_PENDING = 'FETCH_POWER_USER_CURVE_PENDING';
export const FETCH_POWER_USER_CURVE_FULFILLED = 'FETCH_POWER_USER_CURVE_FULFILLED';
export const FETCH_POWER_USER_CURVE_REJECTED = 'FETCH_POWER_USER_CURVE_REJECTED';

export const FETCH_MAIN_DB_TABLE_STATS = 'FETCH_MAIN_DB_TABLE_STATS';
export const FETCH_MAIN_DB_TABLE_STATS_PENDING = 'FETCH_MAIN_DB_TABLE_STATS_PENDING';
export const FETCH_MAIN_DB_TABLE_STATS_FULFILLED = 'FETCH_MAIN_DB_TABLE_STATS_FULFILLED';
export const FETCH_MAIN_DB_TABLE_STATS_REJECTED = 'FETCH_MAIN_DB_TABLE_STATS_REJECTED';

export const FETCH_RECENT_API_USAGE = 'FETCH_RECENT_API_USAGE';
export const FETCH_RECENT_API_USAGE_PENDING = 'FETCH_RECENT_API_USAGE_PENDING';
export const FETCH_RECENT_API_USAGE_FULFILLED = 'FETCH_RECENT_API_USAGE_FULFILLED';
export const FETCH_RECENT_API_USAGE_REJECTED = 'FETCH_RECENT_API_USAGE_REJECTED';

export const FETCH_URL_CHANGE_REQUEST = 'FETCH_URL_CHANGE_REQUEST';
export const FETCH_URL_CHANGE_REQUEST_PENDING = 'FETCH_URL_CHANGE_REQUEST_PENDING';
export const FETCH_URL_CHANGE_REQUEST_FULFILLED = 'FETCH_URL_CHANGE_REQUEST_FULFILLED';

export const FETCH_DSP_LINKING_REQUEST = 'FETCH_DSP_LINKING_REQUEST';
export const FETCH_DSP_LINKING_REQUEST_PENDING = 'FETCH_DSP_LINKING_REQUEST_PENDING';
export const FETCH_DSP_LINKING_REQUEST_FULFILLED = 'FETCH_DSP_LINKING_REQUEST_FULFILLED';

export const BULK_UPDATE_URL_CHANGE_REQUEST = 'BULK_UPDATE_URL_CHANGE_REQUEST';
export const BULK_UPDATE_URL_CHANGE_REQUEST_PENDING = 'BULK_UPDATE_URL_CHANGE_REQUEST_PENDING';
export const BULK_UPDATE_URL_CHANGE_REQUEST_FULFILLED = 'BULK_UPDATE_URL_CHANGE_REQUEST_FULFILLED';

export const BULK_UPDATE_DSP_LINKING_REQUEST = 'BULK_UPDATE_DSP_LINKING_REQUEST';
export const BULK_UPDATE_DSP_LINKING_REQUEST_PENDING = 'BULK_UPDATE_DSP_LINKING_REQUEST_PENDING';
export const BULK_UPDATE_DSP_LINKING_REQUEST_FULFILLED =
  'BULK_UPDATE_DSP_LINKING_REQUEST_FULFILLED';

export const FETCH_ARTIST_IDENTITY_CHANGE_REQUEST = 'FETCH_ARTIST_IDENTITY_CHANGE_REQUEST';
export const FETCH_ARTIST_IDENTITY_CHANGE_REQUEST_PENDING =
  'FETCH_ARTIST_IDENTITY_CHANGE_REQUEST_PENDING';
export const FETCH_ARTIST_IDENTITY_CHANGE_REQUEST_FULFILLED =
  'FETCH_ARTIST_IDENTITY_CHANGE_REQUEST_FULFILLED';

export const BULK_UPDATE_ARTIST_IDENTITY_CHANGE_REQUEST =
  'BULK_UPDATE_ARTIST_IDENTITY_CHANGE_REQUEST';
export const BULK_UPDATE_ARTIST_IDENTITY_CHANGE_REQUEST_PENDING =
  'BULK_UPDATE_ARTIST_IDENTITY_CHANGE_REQUEST_PENDING';
export const BULK_UPDATE_ARTIST_IDENTITY_CHANGE_REQUEST_FULFILLED =
  'BULK_UPDATE_ARTIST_IDENTITY_CHANGE_REQUEST_FULFILLED';

export const ADD_CAMPAIGN = 'ADD_CAMPAIGN';
export const ADD_CAMPAIGN_PENDING = 'ADD_CAMPAIGN_PENDING';
export const ADD_CAMPAIGN_REJECTED = 'ADD_CAMPAIGN_REJECTED';
export const ADD_CAMPAIGN_FULFILLED = 'ADD_CAMPAIGN_FULFILLED';

export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const DELETE_CAMPAIGN_PENDING = 'DELETE_CAMPAIGN_PENDING';
export const DELETE_CAMPAIGN_REJECTED = 'DELETE_CAMPAIGN_REJECTED';
export const DELETE_CAMPAIGN_FULFILLED = 'DELETE_CAMPAIGN_FULFILLED';

export const GET_CAMPAIGN_LIST = 'GET_CAMPAIGN_LIST';
export const GET_CAMPAIGN_LIST_PENDING = 'GET_CAMPAIGN_LIST_PENDING';
export const GET_CAMPAIGN_LIST_REJECTED = 'GET_CAMPAIGN_LIST_REJECTED';
export const GET_CAMPAIGN_LIST_FULFILLED = 'GET_CAMPAIGN_LIST_FULFILLED';

export const CREATE_STRIPE_CHECKOUT_SESSION = 'CREATE_STRIPE_CHECKOUT_SESSION';
export const CREATE_STRIPE_CHECKOUT_SESSION_PENDING = 'CREATE_STRIPE_CHECKOUT_SESSION_PENDING';
export const CREATE_STRIPE_CHECKOUT_SESSION_REJECTED = 'CREATE_STRIPE_CHECKOUT_SESSION_REJECTED';
export const CREATE_STRIPE_CHECKOUT_SESSION_FULFILLED = 'CREATE_STRIPE_CHECKOUT_SESSION_FULFILLED';

export const GET_STRIPE_COUPON_LIST = 'GET_STRIPE_COUPON_LIST';
export const GET_STRIPE_COUPON_LIST_PENDING = 'GET_STRIPE_COUPON_LIST_PENDING';
export const GET_STRIPE_COUPON_LIST_REJECTED = 'GET_STRIPE_COUPON_LIST_REJECTED';
export const GET_STRIPE_COUPON_LIST_FULFILLED = 'GET_STRIPE_COUPON_LIST_FULFILLED';

export const GET_STRIPE_PRODUCT_LIST = 'GET_STRIPE_PRODUCT_LIST';
export const GET_STRIPE_PRODUCT_LIST_PENDING = 'GET_STRIPE_PRODUCT_LIST_PENDING';
export const GET_STRIPE_PRODUCT_LIST_FULFILLED = 'GET_STRIPE_PRODUCT_LIST_FULFILLED';
export const GET_STRIPE_PRODUCT_LIST_REJECTED = 'GET_STRIPE_PRODUCT_LIST_REJECTED';

export const GET_CACHE_KEY = 'GET_CACHE_KEY';
export const GET_CACHE_KEY_REJECTED = 'GET_CACHE_KEY_REJECTED';
export const GET_CACHE_KEY_FULFILLED = 'GET_CACHE_KEY_FULFILLED';
export const DELETE_CACHE_KEY = 'DELETE_CACHE_KEY';
export const DELETE_CACHE_KEY_FULFILLED = 'DELETE_CACHE_KEY_FULFILLED';
export const DELETE_CACHE_KEY_REJECTED = 'DELETE_CACHE_KEY_REJECTED';

// CLAIM
export const GET_CLAIM_REQUEST = 'GET_CLAIM_REQUEST';
export const GET_CLAIM_REQUEST_PENDING = 'GET_CLAIM_REQUEST_PENDING';
export const GET_CLAIM_REQUEST_REJECTED = 'GET_CLAIM_REQUEST_REJECTED';
export const GET_CLAIM_REQUEST_FULFILLED = 'GET_CLAIM_REQUEST_FULFILLED';

export const GET_CLAIM_REQUEST_STATUS = 'GET_CLAIM_REQUEST_STATUS';
export const GET_CLAIM_REQUEST_STATUS_PENDING = 'GET_CLAIM_REQUEST_STATUS_PENDING';
export const GET_CLAIM_REQUEST_STATUS_REJECTED = 'GET_CLAIM_REQUEST_STATUS_REJECTED';
export const GET_CLAIM_REQUEST_STATUS_FULFILLED = 'GET_CLAIM_REQUEST_STATUS_FULFILLED';

export const APPROVE_CLAIM_REQUEST = 'APPROVE_CLAIM_REQUEST';
export const APPROVE_CLAIM_REQUEST_PENDING = 'APPROVE_CLAIM_REQUEST_PENDING';
export const APPROVE_CLAIM_REQUEST_REJECTED = 'APPROVE_CLAIM_REQUEST_REJECTED';
export const APPROVE_CLAIM_REQUEST_FULFILLED = 'APPROVE_CLAIM_REQUEST_FULFILLED';

export const DECLINE_CLAIM_REQUEST = 'DECLINE_CLAIM_REQUEST';
export const DECLINE_CLAIM_REQUEST_PENDING = 'DECLINE_CLAIM_REQUEST_PENDING';
export const DECLINE_CLAIM_REQUEST_REJECTED = 'DECLINE_CLAIM_REQUEST_REJECTED';
export const DECLINE_CLAIM_REQUEST_FULFILLED = 'DECLINE_CLAIM_REQUEST_FULFILLED';

// TRUSTED USERS
export const GET_TRUSTED_USER = 'GET_TRUSTED_USERS';
export const GET_TRUSTED_USER_PENDING = 'GET_TRUSTED_USERS_PENDING';
export const GET_TRUSTED_USER_REJECTED = 'GET_TRUSTED_USERS_REJECTED';
export const GET_TRUSTED_USER_FULFILLED = 'GET_TRUSTED_USERS_FULFILLED';

export const ADD_TRUSTED_USER = 'ADD_TRUSTED_USER';
export const ADD_TRUSTED_USER_PENDING = 'ADD_TRUSTED_USER_PENDING';
export const ADD_TRUSTED_USER_FULFILLED = 'ADD_TRUSTED_USER_FULFILLED';
export const ADD_TRUSTED_USER_REJECTED = 'ADD_TRUSTED_USER_REJECTED';

export const DELETE_TRUSTED_USER = 'DELETE_TRUSTED_USERS';
export const DELETE_TRUSTED_USER_PENDING = 'DELETE_TRUSTED_USERS_PENDING';
export const DELETE_TRUSTED_USER_REJECTED = 'DELETE_TRUSTED_USERS_REJECTED';
export const DELETE_TRUSTED_USER_FULFILLED = 'DELETE_TRUSTED_USERS_FULFILLED';

// ADMIN USERS
export const GET_ADMIN_USER = 'GET_ADMIN_USERS';
export const GET_ADMIN_USER_PENDING = 'GET_ADMIN_USERS_PENDING';
export const GET_ADMIN_USER_REJECTED = 'GET_ADMIN_USERS_REJECTED';
export const GET_ADMIN_USER_FULFILLED = 'GET_ADMIN_USERS_FULFILLED';

export const ADD_ADMIN_USER = 'ADD_ADMIN_USERS';
export const ADD_ADMIN_USER_PENDING = 'ADD_ADMIN_USERS_PENDING';
export const ADD_ADMIN_USER_REJECTED = 'ADD_ADMIN_USERS_REJECTED';
export const ADD_ADMIN_USER_FULFILLED = 'ADD_ADMIN_USERS_FULFILLED';

export const DELETE_ADMIN_USER = 'DELETE_ADMIN_USERS';
export const DELETE_ADMIN_USER_PENDING = 'DELETE_ADMIN_USERS_PENDING';
export const DELETE_ADMIN_USER_REJECTED = 'DELETE_ADMIN_USERS_REJECTED';
export const DELETE_ADMIN_USER_FULFILLED = 'DELETE_ADMIN_USERS_FULFILLED';

export const REQUEST_RESENT_CLAIM_EMAIL = 'REQUEST_RESENT_CLAIM_EMAIL';
export const REQUEST_RESENT_CLAIM_EMAIL_PENDING = 'REQUEST_RESENT_CLAIM_EMAIL_PENDING';
export const REQUEST_RESENT_CLAIM_EMAIL_REJECTED = 'REQUEST_RESENT_CLAIM_EMAIL_REJECTED';
export const REQUEST_RESENT_CLAIM_EMAIL_FULFILLED = 'REQUEST_RESENT_CLAIM_EMAIL_FULFILLED';

export const GET_GENRE_REQUEST = 'GET_GENRE_REQUEST';
export const GET_GENRE_REQUEST_PENDING = 'GET_GENRE_REQUEST_PENDING';
export const GET_GENRE_REQUEST_REJECTED = 'GET_GENRE_REQUEST_REJECTED';
export const GET_GENRE_REQUEST_FULFILLED = 'GET_GENRE_REQUEST_FULFILLED';

export const APPROVE_GENRE_REQUEST = 'APPROVE_GENRE_REQUEST';
export const APPROVE_GENRE_REQUEST_PENDING = 'APPROVE_GENRE_REQUEST_PENDING';
export const APPROVE_GENRE_REQUEST_REJECTED = 'APPROVE_GENRE_REQUEST_REJECTED';
export const APPROVE_GENRE_REQUEST_FULFILLED = 'APPROVE_GENRE_REQUEST_FULFILLED';

export const DECLINE_GENRE_REQUEST = 'DECLINE_CLAIM_REQUEST';
export const DECLINE_GENRE_REQUEST_PENDING = 'DECLINE_CLAIM_REQUEST_PENDING';
export const DECLINE_GENRE_REQUEST_REJECTED = 'DECLINE_CLAIM_REQUEST_REJECTED';
export const DECLINE_GENRE_REQUEST_FULFILLED = 'DECLINE_CLAIM_REQUEST_FULFILLED';

export const SEND_EMAIL_REPORT = 'SEND_EMAIL_REPORT';
export const SEND_EMAIL_REPORT_PENDING = 'SEND_EMAIL_REPORT_PENDING';
export const SEND_EMAIL_REPORT_REJECTED = 'SEND_EMAIL_REPORT_REJECTED';
export const SEND_EMAIL_REPORT_FULFILLED = 'SEND_EMAIL_REPORT_FULFILLED';

export const GET_CHURNED_USERS = 'GET_CHURNED_USERS';
export const GET_CHURNED_USERS_PENDING = 'GET_CHURNED_USERS_PENDING';
export const GET_CHURNED_USERS_REJECTED = 'GET_CHURNED_USERS_REJECTED';
export const GET_CHURNED_USERS_FULFILLED = 'GET_CHURNED_USERS_FULFILLED';

export const UPDATE_CHURNED_USER_COMMENT = 'UPDATE_CHURNED_USER_COMMENT';
export const UPDATE_CHURNED_USER_COMMENT_PENDING = 'UPDATE_CHURNED_USER_COMMENT_PENDING';
export const UPDATE_CHURNED_USER_COMMENT_REJECTED = 'UPDATE_CHURNED_USER_COMMENT_REJECTED';
export const UPDATE_CHURNED_USER_COMMENT_FULFILLED = 'UPDATE_CHURNED_USER_COMMENT_FULFILLED';

export const ADD_LABEL_CORRECTION = 'ADD_LABEL_CORRECTION';
export const ADD_LABEL_CORRECTION_PENDING = 'ADD_LABEL_CORRECTION_PENDING';
export const ADD_LABEL_CORRECTION_REJECTED = 'ADD_LABEL_CORRECTION_REJECTED';
export const ADD_LABEL_CORRECTION_FULFILLED = 'ADD_LABEL_CORRECTION_FULFILLED';

export const GET_LABEL = 'GET_LABEL';
export const GET_LABEL_PENDING = 'GET_LABEL_PENDING';
export const GET_LABEL_REJECTED = 'GET_LABEL_REJECTED';
export const GET_LABEL_FULFILLED = 'GET_LABEL_FULFILLED';

export const GET_ASSOCIATED_LABEL = 'GET_ASSOCIATED_LABEL';
export const GET_ASSOCIATED_LABEL_PENDING = 'GET_ASSOCIATED_LABEL_PENDING';
export const GET_ASSOCIATED_LABEL_REJECTED = 'GET_ASSOCIATED_LABEL_REJECTED';
export const GET_ASSOCIATED_LABEL_FULFILLED = 'GET_ASSOCIATED_LABEL_FULFILLED';

export const ADD_ASSOCIATED_LABEL = 'ADD_ASSOCIATED_LABEL';
export const ADD_ASSOCIATED_LABEL_PENDING = 'ADD_ASSOCIATED_LABEL_PENDING';
export const ADD_ASSOCIATED_LABEL_REJECTED = 'ADD_ASSOCIATED_LABEL_REJECTED';
export const ADD_ASSOCIATED_LABEL_FULFILLED = 'ADD_ASSOCIATED_LABEL_FULFILLED';

export const UPDATE_ASSOCIATED_LABEL = 'UPDATE_ASSOCIATED_LABEL';
export const UPDATE_ASSOCIATED_LABEL_PENDING = 'UPDATE_ASSOCIATED_LABEL_PENDING';
export const UPDATE_ASSOCIATED_LABEL_REJECTED = 'UPDATE_ASSOCIATED_LABEL_REJECTED';
export const UPDATE_ASSOCIATED_LABEL_FULFILLED = 'UPDATE_ASSOCIATED_LABEL_FULFILLED';

export const ADD_TRIAL_SUBSCRIPTION = 'ADD_TRIAL_SUBSCRIPTION';
export const ADD_TRIAL_SUBSCRIPTION_PENDING = 'ADD_TRIAL_SUBSCRIPTION_PENDING';
export const ADD_TRIAL_SUBSCRIPTION_REJECTED = 'ADD_TRIAL_SUBSCRIPTION_REJECTED';
export const ADD_TRIAL_SUBSCRIPTION_FULFILLED = 'ADD_TRIAL_SUBSCRIPTION_FULFILLED';

export const GET_MAIN_GENRE_TAG_LIST = 'GET_MAIN_GENRE_TAG_LIST';
export const GET_MAIN_GENRE_TAG_LIST_PENDING = 'GET_MAIN_GENRE_TAG_LIST_PENDING';
export const GET_MAIN_GENRE_TAG_LIST_REJECTED = 'GET_MAIN_GENRE_TAG_LIST_REJECTED';
export const GET_MAIN_GENRE_TAG_LIST_FULFILLED = 'GET_MAIN_GENRE_TAG_LIST_FULFILLED';

export const GET_SUB_GENRE_TAG_LIST = 'GET_SUB_GENRE_TAG_LIST';
export const GET_SUB_GENRE_TAG_LIST_PENDING = 'GET_SUB_GENRE_TAG_LIST_PENDING';
export const GET_SUB_GENRE_TAG_LIST_REJECTED = 'GET_SUB_GENRE_TAG_LIST_REJECTED';
export const GET_SUB_GENRE_TAG_LIST_FULFILLED = 'GET_SUB_GENRE_TAG_LIST_FULFILLED';

export const UPDATE_TRACK_GENRE = 'UPDATE_TRACK_GENRE';
export const UPDATE_TRACK_GENRE_PENDING = 'UPDATE_TRACK_GENRE_PENDING';
export const UPDATE_TRACK_GENRE_REJECTED = 'UPDATE_TRACK_GENRE_REJECTED';
export const UPDATE_TRACK_GENRE_FULFILLED = 'UPDATE_TRACK_GENRE_FULFILLED';

export const TRANSFER_FAVORITES = 'TRANSFER_FAVORITES';
export const TRANSFER_FAVORITES_PENDING = 'TRANSFER_FAVORITES_PENDING';
export const TRANSFER_FAVORITES_REJECTED = 'TRANSFER_FAVORITES_REJECTED';
export const TRANSFER_FAVORITES_FULFILLED = 'TRANSFER_FAVORITES_FULFILLED';

export const CLEAN_UP_STRIPE = 'CLEAN_UP_STRIPE';
export const CLEAN_UP_STRIPE_PENDING = 'CLEAN_UP_STRIPE_PENDING';
export const CLEAN_UP_STRIPE_REJECTED = 'CLEAN_UP_STRIPE_REJECTED';
export const CLEAN_UP_STRIPE_FULFILLED = 'CLEAN_UP_STRIPE_FULFILLED';

export const UPDATE_ARTIST_GENRE = 'UPDATE_ARTIST_GENRE';
export const UPDATE_ARTIST_GENRE_PENDING = 'UPDATE_ARTIST_GENRE_PENDING';
export const UPDATE_ARTIST_GENRE_REJECTED = 'UPDATE_ARTIST_GENRE_REJECTED';
export const UPDATE_ARTIST_GENRE_FULFILLED = 'UPDATE_ARTIST_GENRE_FULFILLED';

export const GET_PAGE_HISTORY_STATISTICS = 'GET_PAGE_HISTORY_STATISTICS';
export const GET_PAGE_HISTORY_STATISTICS_PENDING = 'GET_PAGE_HISTORY_STATISTICS_PENDING';
export const GET_PAGE_HISTORY_STATISTICS_REJECTED = 'GET_PAGE_HISTORY_STATISTICS_REJECTED';
export const GET_PAGE_HISTORY_STATISTICS_FULFILLED = 'GET_PAGE_HISTORY_STATISTICS_FULFILLED';

export const GET_USER_SESSION_COUNTS = 'GET_USER_SESSION_COUNTS';
export const GET_USER_SESSION_COUNTS_PENDING = 'GET_USER_SESSION_COUNTS_PENDING';
export const GET_USER_SESSION_COUNTS_REJECTED = 'GET_USER_SESSION_COUNTS_REJECTED';
export const GET_USER_SESSION_COUNTS_FULFILLED = 'GET_USER_SESSION_COUNTS_FULFILLED';

export const GET_SUGGESTIONS = 'GET_SUGGESTIONS';
export const GET_SUGGESTIONS_PENDING = 'GET_SUGGESTIONS_PENDING';
export const GET_SUGGESTIONS_REJECTED = 'GET_SUGGESTIONS_REJECTED';
export const GET_SUGGESTIONS_FULFILLED = 'GET_SUGGESTIONS_FULFILLED';
