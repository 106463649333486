import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import ReactTable from 'react-table';
import { useDeleteCacheKey } from '@Services/system';
import { Button, DataTable, Panel } from '../../../../shared/Style';
import { useGetStripeCoupons } from '../../../../services/campaign';
import { getDateFormatFromEpoch } from '../../../../utilities';
import SearchInput from '../../../../shared/Table/SearchInput';
import { Flex } from 'antd';

const COUPON_LIST_CACHE_KEY = 'Admin: getStripeCoupons: []';

const COLUMNS = (handleSelectCoupon, productId, couponCode, priceItem) => [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: props => <b>{props.value}</b>,
    width: 300,
  },
  {
    Header: 'Id',
    accessor: 'id',
  },
  {
    Header: 'Discount Amount',
    accessor: 'amount_off',
    Cell: props => (props.value ? `${props.value / 100}$` : ''),
  },
  {
    Header: 'Discount Percent',
    accessor: 'percent_off',
    Cell: props => (props.value ? `${props.value}%` : ''),
  },
  {
    Header: 'Type',
    accessor: 'duration',
    Cell: props =>
      props.value === 'repeating' ? `${props.original.duration_in_months} months` : props.value,
  },
  {
    Header: 'Created At',
    accessor: 'created',
    Cell: props => getDateFormatFromEpoch(props.value),
  },
  {
    Header: 'Applicable Product',
    accessor: 'applies_to.products',
  },

  {
    Header: 'Apply Coupon',
    accessor: 'id',
    Cell: props => {
      // Checking logic whether coupon can be applied to the plan.
      const matchBillingCycle =
        priceItem?.recurring.interval === 'year' && props.original.duration === 'repeating'
          ? props.original.duration_in_months >= 12
          : true;
      const isAppliesTo =
        isEmpty(props.original.applies_to) ||
        props.original.applies_to?.products.includes(productId);
      return (
        isAppliesTo &&
        matchBillingCycle && (
          <Button
            className={props.value === couponCode ? 'green' : ''}
            onClick={handleSelectCoupon(props.original)}
          >
            Select Coupon
          </Button>
        )
      );
    },
  },
];

function StripeCouponPanel({
  enableCoupon,
  onSelectCoupon,
  products,
  productId,
  priceId,
  selectedCoupon,
}) {
  const {
    data: stripeCoupons,
    isLoading: isLoadingCoupons,
    refetch: fetchCoupons,
  } = useGetStripeCoupons();
  const { execute: deleteCacheKey, isLoading } = useDeleteCacheKey();
  const [filteredData, setFilteredData] = useState<undefined | any[]>(undefined);

  useEffect(() => {
    if (isEmpty(stripeCoupons)) {
      fetchCoupons();
    }
  }, []);

  const getProductItem = productId => products?.products?.find(e => e.id === productId);

  const getPriceItem = (productId, priceId) =>
    products?.prices?.[productId]?.find(e => e.id === priceId);

  const handleClickCreateCoupon = () => {
    window.open('https://dashboard.stripe.com/coupons/create', '_blank');
  };

  const handleClickRefreshCouponList = () => {
    deleteCacheKey({
      query: {
        key: COUPON_LIST_CACHE_KEY,
      },
    }).then(() => fetchCoupons());
  };

  const handleSearch = (filteredData = []) => {
    setFilteredData(filteredData);
  };

  return (
    enableCoupon && (
      <Panel
        title={`Coupon List ${productId ? `: ${getProductItem(productId)?.name}` : ''}`}
        description="If not found / work certain coupon, the coupon should have promotion code. Please check it in the Stripe dashboard."
        extra={
          <Flex gap={2}>
            <Button className="green" onClick={handleClickCreateCoupon}>
              Create Coupon
            </Button>
            <Button className="blue" onClick={handleClickRefreshCouponList}>
              Refresh List
            </Button>
          </Flex>
        }
      >
        {stripeCoupons?.length > 0 ? (
          <>
            <SearchInput originalData={stripeCoupons} onChange={handleSearch} />
            <br />
            <DataTable
              loading={isLoadingCoupons || isLoading}
              key="couponTable"
              data={(filteredData !== undefined ? filteredData : stripeCoupons).filter(
                e => e.valid === true
              )}
              columns={COLUMNS(
                onSelectCoupon,
                productId,
                selectedCoupon?.id,
                getPriceItem(productId, priceId)
              )}
              defaultPageSize={50}
            />
          </>
        ) : (
          <p>No coupon</p>
        )}
      </Panel>
    )
  );
}

export default StripeCouponPanel;
