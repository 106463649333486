import React, { useState, Component } from 'react';
import { connect } from 'react-redux';
import { Flex, Modal, Popconfirm } from 'antd';
import * as actions from '../../../actions';
import SearchInput from '../../../shared/Table/SearchInput';
import { Button, Panel, DataTable } from '../../../shared/Style';
import GlobalSearch from '../../../shared/GlobalSearch/GlobalSearch';
import { Link } from 'react-router-dom';
import { generateInternalLink, getDateFormat } from '../../../utilities';

const columns = deleteAdminUser => [
  {
    Header: 'User Id',
    accessor: 'user_info', // String-based value accessors!
  },
  {
    Header: 'First Name',
    accessor: 'first_name',
  },
  {
    Header: 'Last Name',
    accessor: 'last_name',
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: props => (
      <Link
        to={generateInternalLink('user/user-profile', {
          qs: props.value,
        })}
      >
        {props.value}
      </Link>
    ),
  },
  {
    Header: 'Company',
    accessor: 'company',
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    Cell: props => getDateFormat(props.value),
  },
  {
    Header: 'Delete',
    Cell: props => (
      <Flex justify="center">
        <Popconfirm
          title={
            <span>
              Are you sure you want to delete <strong>{props.original.email}</strong> from admin
              user?
            </span>
          }
          onConfirm={() => deleteAdminUser(props.original.user_info)}
        >
          <Button color="red" icon="delete" />
        </Popconfirm>
      </Flex>
    ),
  },
];

function AddAdminUserModal({ open, handleInsert, isFetching, setOpen }) {
  const [inputValue, setInputValue] = useState('');
  const onSubmit = () => {
    handleInsert(inputValue);
  };

  return (
    <Modal
      title="Add Trusted User"
      open={open}
      onOk={onSubmit}
      okButtonProps={{
        loading: isFetching,
      }}
      onCancel={() => setOpen(false)}
      okText="Add"
      cancelText="Close"
    >
      <GlobalSearch
        placeholder="Search for user"
        onChange={({ value }) => setInputValue(value)}
        loading={isFetching}
        types={['user']}
        icon="user"
        variant="filled"
        size="middle"
      />
    </Modal>
  );
}

class AdminUsers extends Component {
  state = {
    filteredData: [],
    open: false,
  };

  componentDidMount() {
    this.props.getAdminUsers();
  }

  handleInsert = value => {
    this.props.addAdminUser(value).then(() => {
      this.props.getAdminUsers();
      this.setState({ open: false });
    });
  };

  handleDelete = q => {
    this.props.deleteAdminUser(q).then(() => this.props.getAdminUsers());
  };

  onSearch = filteredData => {
    this.setState({
      filteredData,
    });
  };

  render() {
    const { open } = this.state;
    const { adminUserData: data, isFetching } = this.props;
    return (
      <>
        <Panel
          icon="farUserGroupCrown"
          title="Admin Users"
          extra={
            <Button
              disabled={isFetching}
              color="green"
              icon="plus"
              onClick={() =>
                this.setState({
                  open: true,
                })
              }
            >
              Add Admin User
            </Button>
          }
        >
          <Flex vertical gap="middle">
            <SearchInput originalData={data} onChange={this.onSearch} />
            <DataTable
              loading={isFetching || !typeof data === 'object' || Object.keys(data).length === 0}
              data={this.state.filteredData}
              columns={columns(this.handleDelete)}
            />
          </Flex>
        </Panel>
        <AddAdminUserModal
          open={open}
          handleInsert={this.handleInsert}
          isFetching={isFetching}
          setOpen={open => this.setState({ open })}
        />
      </>
    );
  }
}

const mapStateToProps = state => state.authority;
export default connect(mapStateToProps, actions)(AdminUsers);
