import { useState, useEffect } from 'react';

interface AppTimeRecord {
  date: string;
  timeSpent: number;
}

function useAppTime(): { timeSpent: number; getTimeRecords: () => AppTimeRecord[] } {
  const [timeSpent, setTimeSpent] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(true);

  useEffect(() => {
    if (process.env?.NODE_ENV === 'development') return;
    const today = new Date().toISOString().split('T')[0];
    const storedData = JSON.parse(localStorage.getItem('appTimes') ?? '{}') || {};

    if (!storedData[today]) {
      storedData[today] = { date: today, timeSpent: 0 };
      localStorage.setItem('appTimes', JSON.stringify(storedData));
      setTimeSpent(0);
    } else {
      setTimeSpent(storedData[today].timeSpent || 0);
    }

    const interval = setInterval(() => {
      if (isActive) {
        setTimeSpent(prevTime => {
          const updatedTime = prevTime + 1;
          storedData[today].timeSpent = updatedTime;
          localStorage.setItem('appTimes', JSON.stringify(storedData));
          return updatedTime;
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isActive]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsActive(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  const getTimeRecords = (): AppTimeRecord[] => {
    const storedData = JSON.parse(localStorage.getItem('appTimes') ?? '{}') || {};
    return Object.values(storedData);
  };

  return { timeSpent, getTimeRecords };
}

export default useAppTime;
