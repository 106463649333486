import React from 'react';

import { ActionInput, AntdIcon, DataTable, Panel } from '@Shared/Style';
import { useAddTeamManager, useDeleteTeamManager, useGetTeamManagers } from '@Services/team';
import { generateInternalLink, getDateFormat } from '@Utils';
import { Link } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import { GlobalSearch } from '@Shared';

const ActionCell = ({ value }) => {
  const { execute, isLoading } = useDeleteTeamManager();
  const { refetch } = useGetTeamManagers();

  return (
    <Popconfirm
      title="Warning"
      onConfirm={() => execute({ query: { q: value } }).then(() => refetch())}
      description={`Are you sure you want to delete ${(<b>{value}</b>)} from team manager?`}
    >
      <Button loading={isLoading} icon={<AntdIcon name="delete" />} className="red" />
    </Popconfirm>
  );
};

const columns = [
  {
    Header: 'ID',
    accessor: 'id', // String-based value accessors!
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: ({ value }) => (
      <Link to={generateInternalLink('user/user-profile', { email: value })}>{value}</Link>
    ),
  },
  {
    Header: 'Company',
    accessor: 'company',
  },
  {
    Header: 'Time Zone',
    accessor: 'tzone',
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    Cell: ({ value }) => getDateFormat(value),
  },
  {
    Header: 'Action',
    accessor: 'email',
    Cell: ActionCell,
  },
];
const TeamManagers = () => {
  const { data, isLoading, refetch } = useGetTeamManagers();
  const { execute, isLoading: isLoadingAdd } = useAddTeamManager();
  return (
    <>
      <Panel title="Add Team Manager">
        <GlobalSearch
          types={['user']}
          buttonIcon="plus"
          buttonLabel="Add Team Manager"
          loading={isLoadingAdd}
          icon="user"
          size="middle"
          onSubmit={value => {
            execute({ data: { q: value as string } }).then(() => refetch());
          }}
        />
      </Panel>
      <Panel title="Team Managers">
        <DataTable data={data} columns={columns} loading={isLoading} />
      </Panel>
    </>
  );
};

export default TeamManagers;
