import { useModal } from '@Hooks';
import { Modal, ModalProps } from 'antd';
import React from 'react';

interface Props extends Omit<ModalProps, 'children' | 'open' | 'onOk'> {
  Button?: React.ReactNode;
  children: React.ReactNode | ((props: { closeModal: () => void }) => React.ReactNode);
  onOk?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<any> | void;
}

function ModalButton({ Button, children, ...rest }: Props) {
  const { closeModal, modalProps, showModal } = useModal();

  return (
    <>
      <div style={{ cursor: 'pointer' }} onClick={showModal}>
        {Button}
      </div>
      <Modal
        {...modalProps}
        {...rest}
        open={modalProps.show}
        onOk={async e => {
          await Promise.resolve(rest.onOk?.(e));

          closeModal();
        }}
        onCancel={e => {
          closeModal();
          rest.onCancel?.(e);
        }}
      >
        {typeof children === 'function' ? children({ closeModal }) : children}
      </Modal>
    </>
  );
}

export default ModalButton;
