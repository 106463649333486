import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { Panel } from '../../../shared/Style';
import GlobalSearch from '../../../shared/GlobalSearch/GlobalSearch';

class EmailReport extends Component {
  onSubmit = email => {
    this.props.sendGeneralEmailReport({ email });
  };

  render() {
    const {
      action: { isProceeding },
    } = this.props;

    return (
      <Panel title="Send General Email Report Manually">
        <GlobalSearch
          loading={isProceeding}
          icon="user"
          types={['user']}
          onSubmit={this.onSubmit}
          name="send-email-report"
          size="middle"
          buttonIcon="mail"
          buttonLabel="Send"
        />
      </Panel>
    );
  }
}

const mapStateToProps = state => ({ action: state.action });
export default connect(mapStateToProps, actions)(EmailReport);
