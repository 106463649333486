import { showInfoMessage, showErrorMessage } from '../utilities';
import {
  ADD_ADMIN_USER_FULFILLED,
  ADD_ADMIN_USER_PENDING,
  ADD_ADMIN_USER_REJECTED,
  ADD_TRUSTED_USER_FULFILLED,
  ADD_TRUSTED_USER_PENDING,
  ADD_TRUSTED_USER_REJECTED,
  DELETE_ADMIN_USER_FULFILLED,
  DELETE_ADMIN_USER_PENDING,
  DELETE_ADMIN_USER_REJECTED,
  DELETE_TRUSTED_USER_FULFILLED,
  DELETE_TRUSTED_USER_PENDING,
  DELETE_TRUSTED_USER_REJECTED,
  GET_ADMIN_USER_FULFILLED,
  GET_ADMIN_USER_PENDING,
  GET_ADMIN_USER_REJECTED,
  GET_TRUSTED_USER_FULFILLED,
  GET_TRUSTED_USER_PENDING,
  GET_TRUSTED_USER_REJECTED,
} from './types';

const INITIAL_STATE = {
  data: '',
  trustedUserData: '',
  adminUserData: '',
  isFetching: false,
  error: null,
};

const MESSAGES = {
  SUCCESS: 'Request succeeded!',
  ALREADY_TRUSTED_USER: 'User is already a trusted user',
  ALREADY_ADMIN_USER: 'User is already a admin user',
  ERROR: 'Something Wrong With Update Operation (Please Contact Chartmetric Team).',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_USER_PENDING:
    case GET_TRUSTED_USER_PENDING:
    case ADD_ADMIN_USER_PENDING:
    case ADD_TRUSTED_USER_PENDING:
    case DELETE_TRUSTED_USER_PENDING:
    case DELETE_ADMIN_USER_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case ADD_ADMIN_USER_FULFILLED:
    case ADD_TRUSTED_USER_FULFILLED:
    case DELETE_ADMIN_USER_FULFILLED:
    case DELETE_TRUSTED_USER_FULFILLED:
      showInfoMessage(MESSAGES.SUCCESS);

    // eslint-disable-next-line no-fallthrough
    case GET_ADMIN_USER_FULFILLED: {
      if (action.payload.data?.error) {
        showErrorMessage(action.payload.data?.error);
        return {
          ...state,
          error: action.payload.data?.error,
          isFetching: false,
        };
      }

      return {
        adminUserData: action.payload.data,
        isFetching: false,
      };
    }

    case GET_TRUSTED_USER_FULFILLED:
      if (action.payload.data?.error) {
        showErrorMessage(action.payload.data?.error);
        return {
          ...state,
          error: action.payload.data?.error,
          isFetching: false,
        };
      }

      return {
        trustedUserData: action.payload.data,
        isFetching: false,
      };
    case ADD_ADMIN_USER_REJECTED:
      showErrorMessage(MESSAGES.ALREADY_ADMIN_USER);
      return {
        ...state,
        error: MESSAGES.ERROR,
        isFetching: false,
      };
    case ADD_TRUSTED_USER_REJECTED:
      showErrorMessage(MESSAGES.ALREADY_TRUSTED_USER);
      return {
        ...state,
        error: MESSAGES.ERROR,
        isFetching: false,
      };
    case GET_ADMIN_USER_REJECTED:
    case GET_TRUSTED_USER_REJECTED:
    case DELETE_ADMIN_USER_REJECTED:
    case DELETE_TRUSTED_USER_REJECTED:
      showErrorMessage(MESSAGES.ERROR);
      return {
        ...state,
        error: MESSAGES.ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};
