import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import * as actions from '../../../actions';

class AddForm extends Component {
  state = {};

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleInsert = () => {
    const { addCouponCode, fetchData } = this.props;
    addCouponCode(this.state).then(() => fetchData());
  };

  render() {
    return (
      <Form onSubmit={this.handleInsert}>
        <Form.Group widths={3}>
          <Form.Input
            placeholder="Code"
            name="code"
            value={this.state.code}
            onChange={this.handleChange}
          />
          <Form.Input
            placeholder="Expiration Date (MM/DD/YYYY)"
            name="expirationDate"
            value={this.state.expirationDate}
            onChange={this.handleChange}
          />
          <Form.Input
            placeholder="Number of Days"
            name="numOfDays"
            value={this.state.numOfDays}
            onChange={this.handleChange}
          />
          <Form.Button content="Insert New Code" />
        </Form.Group>
      </Form>
    );
  }
}

export default connect(null, actions)(AddForm);


// const AddForm = () => {
//   const { execute } = useCreateCoupon();
//   const { refetch } = useGetCoupons();

//   return (
//     <ModalButton
//       title="Add New Code"
//       // onOk={() => {
//       //   execute({
//       //     data: {
//       //       code
//       //     }
//       //   })
//       // }}
//       Button={
//         <Button type="primary" style={{ marginBottom: 10 }}>
//           Add New Code
//         </Button>
//       }
//     >
//       <Table
//         layout="horizontal"
//         options={[
//           {
//             label: 'Code',
//             component: <Input placeholder="Code" />,
//             span: 4,
//           },
//           {
//             label: 'Expiration Date (MM/DD/YYYY)',
//             component: <Input placeholder="Expiration Date" />,
//             span: 4,
//           },
//           {
//             label: 'Number of Days',
//             component: <Input placeholder="Number of Days" />,
//             span: 4,
//           },
//         ]}
//       />
//     </ModalButton>
//   );
// };

// export default AddForm;