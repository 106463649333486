import { useUpdateTeam, TeamTable } from '@Services/Onesheet/team';
import {
  CognitoBasicUser,
  CognitoUser,
  refineCognitoUser,
  useUserCognito,
} from '@Services/Onesheet/user';
import { checkIsEmail, checkIsUUID4 } from '@Services/Onesheet/utils';
import { ImageCropper } from '@Shared';
import { ActionInput, AntdIcon, Panel, Table } from '@Shared/Style';
import { ImageData } from '@Shared/ImageCropper/ImageCropper';
import { generateInternalLink, showErrorMessage, showInfoMessage } from '@Utils';
import { Button, Divider, Flex, Input, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

type Form = Omit<TeamTable, 'id' | 'customLogoKey' | 'customLogoUrl'>;

function CreateTeam() {
  const { createTeam, fetching: updating, updateLogo } = useUpdateTeam();
  const [users, setUsers] = React.useState<CognitoBasicUser[]>([]);
  const { fetch, fetching } = useUserCognito();
  const [createdTeam, setCreatedTeam] = React.useState<TeamTable | null>(null);
  const [file, setFile] = useState<ImageData | null>(null);

  const handleAddUser = async (q: string) => {
    if (users.find(({ email: userEmail, id }) => userEmail === q || id === q)) {
      return showErrorMessage('User already added');
    }

    const cognitoUser = await fetch(q);
    const refinedUser = cognitoUser ? refineCognitoUser(cognitoUser) : null;

    if (!refinedUser) {
      return showErrorMessage('User not found');
    }

    setUsers(old => [...old, refinedUser]);
  };

  const handleSubmit = async (values: Form) => {
    const { name, owner, sheetLimit } = values;

    const newTeam = await createTeam(
      {
        name,
        owner,
        sheetLimit,
      },
      users
    );

    if (!newTeam) return;

    if (file) await updateLogo(newTeam.id, file);

    setCreatedTeam(newTeam);
  };

  return (
    <Panel title="Create Team">
      <Typography.Title level={5}>Select Members (optional)</Typography.Title>

      <Flex vertical gap="small">
        <ActionInput
          description='User Permission and Admin rights can be assigned after team creation from the "Search Team" section.'
          id="userName"
          placeholder="Enter User Email"
          icon="farUser"
          validation={value => {
            if (!value) return true;
            if (!checkIsEmail(value as string) && !checkIsUUID4(value as string))
              return 'Invalid email / user ID';
            return true;
          }}
          clearAfterAction
          action={{
            disabledEmpty: true,
            loading: fetching,
            icon: 'farPlus',
            content: 'Add',
            onClick: e => handleAddUser(e.target.value),
          }}
        />
        {users.map(({ email, id }, idx) => (
          <Flex key={id} gap="small">
            {idx + 1}. {email}
            <AntdIcon
              name="close"
              color="red"
              onClick={() => setUsers(old => old.filter(({ id: userId }) => userId !== id))}
            />
          </Flex>
        ))}
      </Flex>
      <br />
      <Table<Form>
        title="Information"
        form={{
          initialValues: {
            name: '',
            owner: '',
            sheetLimit: 0,
          },
          onSubmit: handleSubmit,
          loading: updating,
          submitPosition: 'bottom',
        }}
        options={[
          {
            label: 'Team Name',
            component: <Input placeholder="Team Name" />,
            value: 'name',
            rules: [
              {
                required: true,
                message: 'Please enter team name',
              },
            ],
          },
          {
            label: 'Owner Email',
            component: <Input placeholder="Owner" />,
            value: 'owner',
            rules: [
              {
                required: true,
                message: 'Please enter owner email',
                type: 'email',
              },
            ],
          },
          {
            label: 'Sheet Limit',
            component: <Input placeholder="Sheet Limit" type="number" />,
            value: 'sheetLimit',
            rules: [
              {
                required: true,
                message: 'Please enter number type',
              },
            ],
          },
          {
            label: 'Logo',
            component: <ImageCropper onCropComplete={setFile} />,
          },
        ]}
      />
      {createdTeam && (
        <>
          <Divider />
          <Typography.Text>
            Team <b>{createdTeam.name}</b> has been created. <br />
            Team ID: {createdTeam.id}{' '}
            <Button
              onClick={() => {
                navigator.clipboard.writeText(createdTeam.id);
                showInfoMessage('Copied to clipboard');
              }}
              type="link"
            >
              Copy
            </Button>
            <br />
            <Link
              to={generateInternalLink('onesheet/team-manager#/search-team', {
                qts: createdTeam.id,
              })}
            >
              <Button type="primary">Edit team details</Button>
            </Link>
          </Typography.Text>
        </>
      )}
    </Panel>
  );
}

export default CreateTeam;
