import React from 'react';
import { Flex, Tag } from 'antd';
import { generateCmUrl } from '@Utils';

function LinksCell({ currentUrl, newUrl, targetId }) {
  return (
    <Flex vertical justify="start" gap="small">
      <Flex>
        <Tag style={{ width: '70px' }}>Current</Tag>
        <a href={currentUrl} target="_blank" rel="noopener noreferrer">
          {currentUrl}
        </a>
      </Flex>
      <Flex>
        <Tag color="blue" style={{ width: '70px', textAlign: 'center' }}>
          New
        </Tag>
        <a href={newUrl} target="_blank" rel="noopener noreferrer">
          {newUrl}
        </a>
      </Flex>
    </Flex>
  );
}

export default LinksCell;
