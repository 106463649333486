/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
// This is from one-sheet, latest version 2024-01-18

export const AWS_CONFIG = {
  aws_project_region: 'eu-west-2',
  aws_appsync_graphqlEndpoint:
    'https://emqdh3jbjzeynk33xum6dvkd3e.appsync-api.eu-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-jrnaqztidjbrvgsp35cbfpyvum',
  aws_cloud_logic_custom: [
    {
      name: 'stripe',
      endpoint: 'https://b1l4nm2rr1.execute-api.eu-west-2.amazonaws.com/prebeta',
      region: 'eu-west-2',
    },
  ],
  aws_cognito_identity_pool_id: 'eu-west-2:20763305-bb0e-4a5a-99f0-d839f9470424',
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: 'eu-west-2_2gdmEu9wA',
  aws_user_pools_web_client_id: '35a678ooudvjaucil04pjv5jgd',
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_content_delivery_bucket: 'onesheet-20200216155323-host-prebeta',
  aws_content_delivery_bucket_region: 'eu-west-2',
  aws_content_delivery_url: 'https://d152ic6dxxhbxj.cloudfront.net',
  aws_user_files_s3_bucket: 'onesheet084c41e38e5647e1afc519081c17aaaa114835-prebeta',
  aws_user_files_s3_bucket_region: 'eu-west-2',
};

export const AWS_ACCESS_CONFIG = {
  USER_POOL_ID: 'eu-west-2_lbRlobSRW',
  REGION: 'eu-west-2',
  ACCESS_KEY_ID: 'AKIA3ODWVKJUP2IIRAWF',
  SECRET_ACCESS_KEY: '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',
  S3_BUCKET: 'onesheet084c41e38e5647e1afc519081c17aaaa190005-beta',
};

export const AWS_ENV_KEY = {
  develop: 'e7fyamjknjhttfjfozqrqloefe-lambda',
  sandbox: 'rbagtin5kbhmlhanlrue3su3sa-sandbox',
  beta: '2biuz5p33zabjmki7lnaloaesu-beta',
};
