import React, { useEffect, useState } from 'react';
import { Button, Card, Descriptions, Divider, Flex, Form, FormInstance, Typography } from 'antd';
import WhatsThis from '@Shared/Style/WhatsThis';
import { Rule } from 'antd/es/form';
import AntdIcon, { ANTD_ICON_TYPES } from './AntdIcon';
import { DescriptionsItemType, DescriptionsProps } from 'antd/es/descriptions';
import { useForm } from 'antd/es/form/Form';
import { Store } from 'antd/es/form/interface';
import { capitalize } from 'lodash';
import { addComma } from '@Utils/number';
import FAIcon, { FAIcons } from './FAIcon';
import * as FA from '@Constants/icons';

interface Option {
  label: string | React.ReactNode;
  tooltip?: string;
  description?: string;
  value?: string;
  component?: React.ReactNode;
  labelIcon?: ANTD_ICON_TYPES | FAIcons;
  formatter?: (value: any) => string;
  span?: DescriptionsItemType['span'];
  /**
   * When form is passed and using Checkbox component
   */
  isCheckbox?: boolean;
  rules?: Rule[];
  autoFormat?: boolean;
}

interface Props<F> {
  options: Option[];
  title?: string;
  extra?: React.ReactNode;
  layout?: DescriptionsProps['layout'];
  panelStyle?: boolean;
  form?: {
    initialValues?: F;
    name?: string;
    submitPosition?: 'top' | 'bottom' | 'bottomRight';
    onSubmit?: (values: F) => void;
    loading?: boolean;
  };
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const TableContext = React.createContext<{
  formInstance: FormInstance | null;
  form?: Props<any>['form'];
  disabled: boolean;
}>({
  disabled: false,
  formInstance: null,
});

function Table<F extends Record<string, any>>({
  options,
  panelStyle,
  style,
  title,
  layout,
  extra,
  form,
  children,
  className,
}: Props<F>) {
  const [formInstance] = useForm<F>();

  const [disabled, setDisabled] = useState(false);
  const values = Form.useWatch([], formInstance);

  const autoFormatting = (value: unknown) => {
    if (typeof value === 'string') {
      return capitalize(value.trim());
    }
    if (typeof value === 'number') {
      return addComma(value);
    }
  };

  useEffect(() => {
    if (!form) return;
    formInstance
      .validateFields({ validateOnly: true })
      .then(() => setDisabled(false))
      .catch(() => setDisabled(true));
  }, [form, values]);

  const renderComponent = (option: Option) => {
    if (form) {
      return (
        <Flex vertical gap={4}>
          <Form.Item
            rules={option.rules}
            name={option.value as string}
            valuePropName={option.isCheckbox ? 'checked' : undefined}
          >
            {option.component}
          </Form.Item>
          {option.description && (
            <Typography.Text type="secondary">{option.description}</Typography.Text>
          )}
        </Flex>
      );
    }

    return option.component;
  };

  const render = () => (
    <>
      {!panelStyle && <Divider />}
      <Descriptions
        className={panelStyle ? `panel ${className}` : className}
        style={
          !panelStyle
            ? {
                ...style,
                marginBottom: 16,
              }
            : { ...style }
        }
        layout={layout || 'vertical'}
        bordered
        title={
          title && (
            <Typography.Text
              className="text-xl w-full"
              style={{
                marginBottom: 0,
                display: 'flex',
                alignItems: 'center',
                gap: 4,
                color: '#61666c',
              }}
            >
              {title}
            </Typography.Text>
          )
        }
        extra={
          <>
            {extra && (
              <>
                <Flex justify="center">{extra}</Flex>
              </>
            )}
            {form && form.submitPosition === 'top' && (
              <Button
                icon={<FAIcon name="fasCheckCircle" color="green" />}
                disabled={disabled}
                className="green"
                htmlType="submit"
              >
                Submit
              </Button>
            )}
          </>
        }
        items={options.map(option => {
          const { label, value, labelIcon, tooltip, autoFormat = true } = option;
          return {
            span: option.span,
            label: (
              <Flex gap="small" style={{ padding: '2px 0px' }} align="center">
                {labelIcon && labelIcon in FA ? (
                  <FAIcon color="gray" name={labelIcon as FAIcons} />
                ) : (
                  <AntdIcon color="gray" name={labelIcon as ANTD_ICON_TYPES} />
                )}
                <Typography.Text
                  type="secondary"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {label}
                  {form && option.rules?.find(rule => rule?.['required']) && (
                    <span style={{ marginLeft: '4px' }}> *</span>
                  )}
                  {tooltip && <WhatsThis title={label}>{tooltip}</WhatsThis>}
                </Typography.Text>
              </Flex>
            ),
            children: option.component
              ? renderComponent(option)
              : autoFormat
              ? autoFormatting(option.value)
              : option.value,
          };
        })}
      ></Descriptions>
      {form && (form.submitPosition === 'bottom' || form.submitPosition === 'bottomRight') && (
        <Flex
          justify={form.submitPosition === 'bottomRight' ? 'flex-end' : 'center'}
          className="w-full mt-5"
        >
          <Button
            icon={<FAIcon name="fasCheckCircle" color={disabled ? 'gray' : 'green'} />}
            disabled={disabled}
            className="green"
            htmlType="submit"
            loading={form.loading}
          >
            Submit
          </Button>
        </Flex>
      )}
    </>
  );

  return (
    <TableContext.Provider value={{ formInstance, form, disabled }}>
      {form ? (
        <Form
          onFinish={form.onSubmit}
          initialValues={form.initialValues as Store}
          form={formInstance}
        >
          {panelStyle ? <Card>{render()}</Card> : render()}
          {children}
        </Form>
      ) : panelStyle ? (
        <Card>{render()}</Card>
      ) : (
        render()
      )}
      {!form && children}
    </TableContext.Provider>
  );
}

const SubmitButton = () => {
  const { formInstance, form, disabled } = React.useContext(TableContext);

  return (
    <Flex justify="center">
      <Button
        icon={<FAIcon name="fasCheckCircle" color="green" />}
        disabled={disabled}
        className="green"
        htmlType="submit"
        loading={form?.loading}
      >
        Submit
      </Button>
    </Flex>
  );
};

export default Table;
Table.SubmitButton = SubmitButton;
