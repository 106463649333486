import React, { Component, useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Flex, Modal, Select, Typography } from 'antd';
import AddForm from './AddForm';
import ApplyCouponForm from './ApplyCouponForm';
import { showInfoMessage } from '../../../utilities';
import SearchInput from '../../../shared/Table/SearchInput';
import { useDeleteCoupon, useGetCoupons } from '../../../services/purchase';
import useModal from '../../../hooks/useModal';
import { AntdIcon, DataTable, Panel } from '../../../shared/Style';
import UpdateModal from './UpdateModal';

const TYPE_OPTIONS = [
  {
    value: false,
    name: 'Valid Coupons',
    label: 'Valid Coupons',
  },
  {
    value: true,
    name: 'Expired Coupons',
    label: 'Expired Coupons',
  },
];

function ManageCoupon() {
  const COLUMNS = [
    {
      Header: 'Code',
      accessor: 'code', // String-based value accessors!
    },
    {
      Header: 'Expriation Date',
      accessor: 'expiration_date',
      Cell: props => moment(props.value).format('l'),
    },
    {
      Header: 'Days For Free Trial',
      accessor: 'num_of_days',
    },
    {
      Header: 'Total Active Users',
      accessor: 'total_active_users',
    },
    {
      Header: 'Total Inactive Users',
      accessor: 'total_inactive_users',
    },
    {
      Header: 'URL',
      accessor: 'code',
      Cell: props => (
        <Button
          className="green"
          icon={<AntdIcon name="copy" />}
          id={props.value}
          onClick={copyJoinURL(props.value)}
        />
      ),
    },
    {
      Header: 'Users',
      accessor: 'user_emails',
      Cell: props =>
        props.value && (
          <Button
            onClick={e => {
              e.stopPropagation();
              showEmailModal({ emails: props.value });
            }}
            icon={<AntdIcon name="list" />}
          />
        ),
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: props => (
        <Flex justify="center" gap="small">
          <Button
            className="blue"
            icon={<AntdIcon name="edit" />}
            onClick={e => {
              e.stopPropagation();
              showUpdateModal({ id: props.value });
            }}
          />
          <Button
            className="red"
            icon={<AntdIcon name="delete" />}
            onClick={() => deleteCoupon({ query: { id: props.value } })}
          />
        </Flex>
      ),
    },
  ];

  const {
    closeModal: closeEmailModal,
    modalProps: emailModalProps,
    showModal: showEmailModal,
  } = useModal<{ emails: string[] }>();
  const {
    closeModal: closeUpdateModal,
    modalProps: updateModalProps,
    showModal: showUpdateModal,
  } = useModal<{ id: number }>();

  const [expired, setExpired] = useState(false);
  const { data, refetch, isLoading } = useGetCoupons({
    data: {
      expired,
    },
  });

  useEffect(() => {
    refetch();
  }, [expired]);

  const { execute: deleteCoupon } = useDeleteCoupon();

  const [filteredData, setFilteredData] = useState<object | undefined>(undefined);

  const onChange = (e, { value, name }) => {
    if (expired !== value) {
      setExpired(value);
    }
  };

  const copyJoinURL = id => e => {
    navigator.clipboard.writeText(`https://app.chartmetric.com/join/trial/${id}`);
    showInfoMessage('Copied!');
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <>
      <Panel title="Coupon Manager">
        <AddForm fetchData={refetch} />
        <ApplyCouponForm fetchData={refetch} />
      </Panel>
      <Panel title="Coupon List">
        <SearchInput originalData={data} onChange={setFilteredData} />
        <br />
        <Flex style={{ width: '100%' }} gap="middle">
          <Select
            placeholder="Select Product"
            options={TYPE_OPTIONS}
            onChange={onChange}
            value={expired}
          />
        </Flex>
        <br />
        <DataTable loading={isLoading} data={filteredData ?? data} columns={COLUMNS} />
      </Panel>
      <Modal
        title="User Emails"
        open={emailModalProps.show}
        onOk={closeEmailModal}
        onCancel={closeEmailModal}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        {emailModalProps?.['emails']?.map((email, i) => (
          <>
            {i + 1}. <Typography.Text style={{ marginBottom: '4px' }}>{email}</Typography.Text>
          </>
        ))}
      </Modal>
      <UpdateModal
        refetch={refetch}
        setClose={closeUpdateModal}
        open={updateModalProps.show}
        {...data?.find(e => e.id === updateModalProps['id'])}
      />
    </>
  );
}

export default ManageCoupon;
