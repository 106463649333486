import { create } from 'zustand';

type SidebarState = {
  showSidebar: boolean;
  toggleSidebar: (value?: boolean) => void;
};

const useSidebar = create<SidebarState>(set => ({
  showSidebar: false,
  toggleSidebar: value => set(state => ({ showSidebar: value ?? !state.showSidebar })),
}));

export default useSidebar;
