import { useGetTeamMembers } from '@Services/team';
import { GlobalSearch } from '@Shared';
import { AntdIcon, Table } from '@Shared/Style';
import { generateInternalLink, getDateFormat, getStripeCustomerUrl } from '@Utils';
import { Button, Divider, Flex, Typography } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import AddMemberModal from './AddMemeberModal';
import Members from './Members';
import { CSVLink } from 'react-csv';

const CSV_FORMAT = [
  { label: 'Status', key: 'status' },
  { label: 'Name', key: 'name' },
  { label: 'Email', key: 'email' },
  { label: 'Verified Email', key: 'verified' },
  { label: 'Registered At', key: 'register_date' },
  { label: 'Added At', key: 'last_added_at' },
  { label: 'Removed At', key: 'last_removed_at' },
  { label: 'is admin', key: 'admin' },
  { label: 'Joined team', key: 'joined' },
  { label: 'Stripe ID', key: 'stripeId' },
];

const ManageTeam = () => {
  const [q, setQ] = React.useState('');
  const { data, isLoading } = useGetTeamMembers(
    {
      data: { q },
    },
    {
      disable: isEmpty(q),
    }
  );

  const getCSVData = () => {
    if (!data) return [];
    const format = (status: 'current' | 'past', data: any) => {
      return data.map(e => ({
        ...e,
        verified: e.verified ? 'Yes' : 'No',
        admin: e?.teamInfo?.admin ? 'Yes' : 'No',
        joined: e?.teamInfo?.verified ? 'Yes' : 'No',
        register_date: getDateFormat(e.register_date),
        last_added_at: getDateFormat(e.last_added_at),
        last_removed_at: getDateFormat(e.last_removed_at),
        stripeId: e.customerInfo.id,
        status,
      }));
    };

    return [...format('current', data.members), ...format('past', data.pastMembers)];
  };

  return (
    <>
      <GlobalSearch
        types={['user']}
        buttonIcon="search"
        buttonLabel="Search"
        icon="user"
        loading={isLoading}
        className="top-search-box"
        variant="filled"
        onSubmit={value => setQ(value as any)}
      />
      <Divider />
      {data && (
        <>
          <Table
            extra={
              <Flex gap="small">
                <CSVLink
                  data={getCSVData()}
                  headers={CSV_FORMAT}
                  filename={`Team Members - ${data.manager.company}.csv`}
                >
                  <Button className="blue" icon={<AntdIcon name="csv" />}>
                    Download CSV (Members)
                  </Button>
                </CSVLink>

                <AddMemberModal managerId={data.manager.id} managerEmail={data.manager.email} />
              </Flex>
            }
            panelStyle
            title="Team"
            options={[
              {
                label: 'Company',
                value: data.manager.company,
              },
              {
                label: 'Manager',
                component: (
                  <>
                    {data.manager.name} (
                    <Link
                      to={generateInternalLink('user/user-profile', {
                        qs: data.manager.email,
                      })}
                    >
                      {data.manager.email}
                    </Link>
                    )
                  </>
                ),
              },
              {
                label: 'Stripe ID',
                component: (
                  <Typography.Link href={getStripeCustomerUrl(data.manager.customer_id)}>
                    {data.manager.customer_id}
                  </Typography.Link>
                ),
              },
            ]}
          />
          <Members data={data.members} type="current" manager={data.manager} />
          <Members data={data.pastMembers} type="past" manager={data.manager} />
        </>
      )}
    </>
  );
};

export default ManageTeam;
