import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Label, Input, Divider, Button, Icon } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import * as actions from '../../../actions';

class AddMetadata extends Component {
  state = {
    artist_q: '',
    playlist_q: '',
    track_q: '',

    // eslint-disable-next-line react/no-unused-state
    showMainPanel: false,
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleAddArtist = () => {
    const { addArtist } = this.props;
    const { artist_q } = this.state;
    if (artist_q) {
      addArtist(artist_q);
    }
  };

  render() {
    const { artist_q, playlist_q, track_q } = this.state;
    const { isFetching } = this.props;
    return (
      <Segment raised>
        <h3>Add Artist Metadata</h3>
        <Label>Add music platform url to create Chartmetric data automatically</Label>
        <Divider hidden />
        <Input
          fluid
          action={{
            labelPosition: 'right',
            icon: 'plus',
            content: 'Add Artist',
            onClick: this.handleAddArtist,
            loading: isFetching,
            className: 'blue',
          }}
          size="large"
          placeholder="Artist URL. ex: https://www.deezer.com/us/artist/4050763"
          name="artist_q"
          value={artist_q}
          onChange={this.handleChange}
        />
      </Segment>
    );
  }
}

const mapStateToProps = state => state.metadata.add;
export default connect(mapStateToProps, actions)(AddMetadata);
