import React from 'react';
import { Divider, Flex, Input, Tag } from 'antd';
import { useAddSimilarTrack, useUpdateSimilarTrack } from '@Services/track';
import { AntdIcon, ActionInput, Panel } from '../../../../shared/Style';
import { generateCmUrl } from '@Utils';
import { EditEntityProps } from '..';
import { useGetEntity } from '@Services/search';
import { isEmpty } from 'lodash';
import { GlobalSearch } from '@Shared';

function SimilarTracks({ q }: EditEntityProps) {
  const { isLoading: isAdding, execute: addSimilarTrack } = useAddSimilarTrack();
  const { data, isFetching, refetch } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );

  return (
    <Panel title="Similar Tracks (Related ISRC)">
      <GlobalSearch
        types={['track']}
        variant="outlined"
        size="middle"
        buttonIcon="plus"
        buttonLabel="Add"
        icon="plus"
        // TODO: clear after
        onSubmit={(q: string) => {
          const id = q.split('track')[1].split('/')[1];
          addSimilarTrack({
            query: {
              trackId1: Number(id),
              trackId2: Number(q),
            },
          }).then(() => {
            refetch();
          });
        }}
      />
      {data?.similarTracks.length > 0 && (
        <>
          <Divider />
          <SimilarTracksList q={q} />
        </>
      )}
    </Panel>
  );
}

export function SimilarTracksList({ q }: EditEntityProps) {
  const { data, isFetching, refetch } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );
  const { isLoading: isUpdating, execute: updateSimilarTrack } = useUpdateSimilarTrack();
  const similarTracks = data?.similarTracks || [];

  return (
    <Flex wrap="wrap" gap={8}>
      {similarTracks.map(({ id: tagId, hidden, manual }) => (
        <Tag
          onClick={() => {
            window.open(generateCmUrl('track', { id: tagId }));
          }}
          style={{
            width: 'auto',
            cursor: 'pointer',
          }}
          color="success"
          icon={
            <AntdIcon
              loading={isUpdating}
              name={hidden ? 'eyeDisable' : 'eyeEnable'}
              onClick={() => {
                updateSimilarTrack({
                  query: {
                    trackId1: data.id,
                    trackId2: tagId,
                    hidden: !hidden,
                  },
                }).then(() => refetch());
              }}
            />
          }
        >
          {tagId}
        </Tag>
      ))}
    </Flex>
  );
}

export default SimilarTracks;
