import React from 'react';
import { Empty } from 'antd';
import { AntdIcon, DataTable, Panel } from '../../../../shared/Style';
import { getDateFormat } from '@Utils';
import { capitalize } from 'lodash';

function ArtistLinksDetails({ artist_links_details }) {
  const columns = [
    {
      Header: 'Social Type',
      accessor: 'type',
      // TODO: add brand Icon
      Cell: ({ value }) => capitalize(value),
    },
    {
      Header: 'Account ID',
      accessor: 'account_id',
    },
    {
      Header: 'URL',
      accessor: 'url',
    },
    {
      Header: 'User ID',
      accessor: 'user_id',
    },
    {
      Header: 'User Email',
      accessor: 'email',
    },
    {
      Header: 'Confidence',
      accessor: 'confidence',
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      Cell: ({ value }) => getDateFormat(value),
    },
    {
      Header: 'Modified At',
      accessor: 'modified_at',
      Cell: ({ value }) => getDateFormat(value),
    },
    {
      Header: 'Locked',
      accessor: 'locked',
      Cell: ({ value }) => value === 'true' && <AntdIcon name="check" color="green" />,
    },
  ];
  return (
    <Panel title="Artist Links Details">
      {!artist_links_details || !artist_links_details.length ? (
        <Empty />
      ) : (
        <DataTable data={artist_links_details} columns={columns} />
      )}
    </Panel>
  );
}

export default ArtistLinksDetails;
