import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Badge, Checkbox, Divider, Flex, Radio } from 'antd';
import { generateCmUrl, getDateFormat } from '../../../utilities';
import SearchInput from '../../../shared/Table/SearchInput';
import { DataTable, Panel } from '../../../shared/Style';
import useRequestTable from '../../../hooks/useRequstTable';
import {
  useGetGenreRequest,
  useApproveGenreRequest,
  useDeclineGenreRequest,
} from '../../../services/request';
import RequestSubmitButton from '../../../shared/Table/SubmitModal';
import { useDeleteCacheKey } from '../../../services/system';

const CACHE_KEY = 'Admin: getGenreRequests: []';

const SUBMIT_MODAL_COLUMNS = [
  {
    Header: 'Request Id',
    accessor: 'id', // String-based value accessors!
    width: 50,
  },
  {
    Header: 'Artist',
    Cell: ({ original }) => (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={generateCmUrl('artist', { id: original.cm_artist })}
      >
        {original.artist}
      </a>
    ),
  },
  {
    Header: 'Genre',
    accessor: 'genre',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    Cell: ({ value }) => <span>{getDateFormat(value)}</span>,
  },
];

const COLUMNS = ({
  onClickApprove,
  onClickDecline,
  approves,
  declines,
  onClickSelectApproveAll,
  onClickSelectDeclineAll,
  data,
}) => [
  {
    Header: 'Artist',
    accessor: 'artist',
    Cell: props => (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={generateCmUrl('artist', { id: props.original.cm_artist })}
      >
        {props.value}
      </a>
    ),
  },
  {
    Header: 'Genre',
    accessor: 'genre',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    Cell: ({ value }) => <span>{getDateFormat(value)}</span>,
  },
  {
    accessor: 'id',
    Cell: ({ value }) => (
      <Flex justify="center">
        <Radio
          name={`approve_${value}`}
          checked={approves.includes(value) ?? false}
          onClick={() => onClickApprove(value)}
        />
      </Flex>
    ),
    Header: () => (
      <Flex justify="center" align="center" gap="small">
        Approve
        <Checkbox
          checked={approves.length === data?.length && data?.length > 0}
          onClick={onClickSelectApproveAll}
        />
      </Flex>
    ),
  },
  {
    accessor: 'id',
    Cell: ({ value }) => (
      <Flex justify="center">
        <Radio
          name={`decline_${value}`}
          checked={declines.includes(value) ?? false}
          onClick={() => onClickDecline(value)}
        />
      </Flex>
    ),
    Header: () => (
      <Flex justify="center" align="center" gap="small">
        Decline
        <Checkbox
          checked={declines.length === data?.length && data?.length > 0}
          onClick={onClickSelectDeclineAll}
        />
      </Flex>
    ),
  },
];

function CustomGenreRequest() {
  const {
    data,
    isLoading,
    onClickApprove,
    onClickDecline,
    selected,
    refetch,
    onSearch,
    onClickSelectApproveAll,
    onClickSelectDeclineAll,
    state,
  } = useRequestTable(useGetGenreRequest, null);

  const { execute: approve, isLoading: isApproving } = useApproveGenreRequest();
  const { execute: decline, isLoading: isDeclining } = useDeclineGenreRequest();
  const { execute: deleteCache } = useDeleteCacheKey();

  const onClickSubmit = async () =>
    Promise.all([
      ...state.approves.map(id =>
        approve({
          path: { id },
        })
      ),
      ...state.declines.map(id => decline({ path: { id } })),
    ])
      .then(() => deleteCache({ query: { key: CACHE_KEY } }))
      .then(() => refetch())
      .then(() => {
        onSearch(undefined);
      });

  return (
    <Panel
      extra={<SearchInput originalData={data} onChange={onSearch} />}
      title={
        <Flex align="center" gap="small">
          Custom Genre Request
          <Badge count={data?.length ?? '0'} color="blue" overflowCount={9999} />
        </Flex>
      }
    >
      <DataTable
        key="table"
        loading={isLoading}
        data={!state.filteredData ? data : state.filteredData}
        columns={COLUMNS({
          onClickApprove,
          onClickDecline,
          approves: state.approves,
          declines: state.declines,
          onClickSelectApproveAll,
          onClickSelectDeclineAll,
          data,
        })}
      />
      <Divider />
      <Flex justify="center" gap="small">
        <RequestSubmitButton
          columns={SUBMIT_MODAL_COLUMNS}
          onClickSubmit={onClickSubmit}
          approveRequest={state.approves}
          declineRequest={state.declines}
          data={data ?? []}
          loading={isApproving || isDeclining}
          selected={selected}
        />
      </Flex>
    </Panel>
  );
}

export default CustomGenreRequest;
