import { combineReducers } from 'redux';
import { showInfoMessage, showErrorMessage } from '../utilities';
import {
  FETCH_COUPON_CODES_PENDING,
  FETCH_COUPON_CODES_FULFILLED,
  FETCH_COUPON_CODES_REJECTED,
  UPDATE_COUPON_CODE_PENDING,
  UPDATE_COUPON_CODE_FULFILLED,
  UPDATE_COUPON_CODE_REJECTED,
} from './types';

const INITIAL_STATE = {
  ALL: {
    data: null,
    isFetching: false,
    error: null,
  },
  UPDATE: {
    data: null,
    isFetching: false,
    error: null,
  },
};

const MESSAGES = {
  ERROR: 'Something Wrong With API (Please Contact Chartmetric Team).',
  DBERROR: 'Something Wrong With Database (Please Contact Chartmetric Team).',
};

const all = (state = INITIAL_STATE.ALL, action) => {
  switch (action.type) {
    case FETCH_COUPON_CODES_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_COUPON_CODES_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      return {
        data,
        isFetching: false,
      };
    }
    case FETCH_COUPON_CODES_REJECTED:
      showErrorMessage(MESSAGES.ERROR);
      return {
        error: MESSAGES.ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};
const update = (state = INITIAL_STATE.UPDATE, action) => {
  switch (action.type) {
    case UPDATE_COUPON_CODE_PENDING:
      return {
        ...INITIAL_STATE.UPDATE,
        isFetching: true,
      };
    case UPDATE_COUPON_CODE_FULFILLED:
      {
        const { data } = action.payload;
        if (data?.error) {
          showErrorMessage(data?.error);
          return {
            error: data?.error,
            isFetching: false,
          };
        }
        showInfoMessage(data);
        return {
          data,
          isFetching: false,
        };
      }
    case UPDATE_COUPON_CODE_REJECTED:
      return {
        error: MESSAGES.DBERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  all,
  update,
});
