import {
  useAddCompanyUnivIp,
  useAddCompanyUnivDomain,
  useDeleteCompanyUnivIp,
  useDeleteCompanyUnivDomain,
  useGetCompanyUnivPools,
} from '@Services/userPool';
import { ModalButton, AntdIcon, ActionInput } from '@Shared/Style';
import FAIcon from '@Shared/Style/FAIcon';
import { Button, Flex, Typography } from 'antd';
import { capitalize } from 'lodash';
import React, { useState } from 'react';

function EditAddressButton({
  id,
  addresses: oldValues,
  type,
}: {
  id: number;
  addresses: string[];
  type: 'ip' | 'domain';
}) {
  const { refetch } = useGetCompanyUnivPools();

  const { execute: addAddress, isLoading: isLoadingAddAddress } =
    type === 'ip' ? useAddCompanyUnivIp() : useAddCompanyUnivDomain();
  const { execute: deleteAddress, isLoading: isLoadingDeleteAddress } =
    type === 'ip' ? useDeleteCompanyUnivIp() : useDeleteCompanyUnivDomain();

  const [addresses, setAddresses] = useState(oldValues ?? []);

  return (
    <>
      <ModalButton
        cancelText="Close"
        okButtonProps={{
          style: { display: 'none' },
        }}
        Button={
          <Button
            type="text"
            className={type === 'ip' ? `purple` : `green`}
            icon={<FAIcon name="farPlus" />}
          />
        }
        onCancel={() => {
          if (JSON.stringify(addresses) !== JSON.stringify(oldValues ?? [])) refetch();
        }}
        title={`Edit ${capitalize(type)}`}
      >
        <>
          {addresses?.map((address, idx) => (
            <Flex key={address} gap="small" align="center">
              <Typography.Text>
                {idx + 1}. {address}
              </Typography.Text>
              <FAIcon
                loading={isLoadingDeleteAddress}
                name="farTrash"
                color="red"
                onClick={() => {
                  deleteAddress({
                    path: {
                      id,
                    },
                    //@ts-ignore
                    data:
                      type === 'ip'
                        ? {
                            ipRange: address,
                          }
                        : {
                            domain: address,
                          },
                  }).then(() => {
                    setAddresses(old => old.filter(d => d !== address));
                  });
                }}
              />
            </Flex>
          ))}
          <br />
          <ActionInput
            className="mb-4"
            label={{
              content: `Add new ${type}`,
            }}
            placeholder={type === 'ip' ? 'IP Address' : 'Email domain'}
            icon="farEnvelope"
            action={{
              color: 'green',
              content: 'Add',
              loading: isLoadingAddAddress,
              icon: 'farPlus',
              onClick: e => {
                addAddress({
                  path: {
                    id,
                  },
                  //@ts-ignore
                  data: {
                    [type === 'ip' ? 'ipRange' : 'domain']: e.target.value,
                  },
                }).then(() => {
                  setAddresses(old => [...old, e.target.value]);
                });
              },
            }}
          />
        </>
      </ModalButton>
    </>
  );
}

export default EditAddressButton;
