import React, { useMemo } from 'react';

import { AntdIcon } from '@Shared/Style';
import { Button, Flex, Popover, Tooltip, TooltipProps } from 'antd';

interface Props {
  children: React.ReactNode;
  title?: any;
  color?: string;
  arrow?: 'Hide' | 'Show' | TooltipProps['arrow'];
}

/* <WhatsThis />
============================================================================= */
function WhatsThis({ children, title, arrow, color = 'gray' }: Props) {
  const mergedArrow = useMemo<TooltipProps['arrow']>(() => {
    if (arrow === 'Hide') {
      return false;
    }

    if (arrow === 'Show') {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  return (
    <Tooltip
      placement="topLeft"
      title={
        <Flex vertical>
          {title}
          {children}
        </Flex>
      }
      arrow={mergedArrow}
    >
      <Button
        size="small"
        type="text"
        shape="circle"
        icon={<AntdIcon name="questionCircle" color={color} />}
        style={{ zIndex: 100 }}
      />
    </Tooltip>
  );
}

export default WhatsThis;
