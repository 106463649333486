import { useCallback, useEffect, useState } from 'react';

interface Props {
  defaultLimit?: number;
}

const usePagination = (props?: Props) => {
  const defaultLimit = props?.defaultLimit ?? 20;
  const [limit, setLimit] = useState(defaultLimit);
  const [offset, setOffset] = useState(0);

  const handleChangePage = useCallback(
    (page: number) => {
      const newOffset = page * limit;
      if (newOffset !== offset) {
        setOffset(newOffset);
      }
    },
    [limit, offset]
  );

  const handleChangePageSize = useCallback(
    (pageSize: number) => {
      if (pageSize !== limit) {
        const newOffset = Math.floor((offset / limit) * pageSize);
        setOffset(newOffset);
        setLimit(pageSize);
      }
    },
    [limit, offset]
  );

  const reset = useCallback(() => {
    setOffset(0);
    setLimit(defaultLimit);
  }, [defaultLimit]);

  return {
    limit,
    setLimit,
    offset,
    setOffset,
    handleChangePage,
    handleChangePageSize,
    reset,
  };
};

export default usePagination;
