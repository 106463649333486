import { showInfoMessage } from '../utilities';
import {
  GET_CHURNED_USERS_FULFILLED,
  GET_CHURNED_USERS_PENDING,
  GET_PAGE_HISTORY_STATISTICS_FULFILLED,
  GET_PAGE_HISTORY_STATISTICS_PENDING,
  GET_PAGE_HISTORY_STATISTICS_REJECTED,
  UPDATE_CHURNED_USER_COMMENT_FULFILLED,
  UPDATE_CHURNED_USER_COMMENT_REJECTED,
} from './types';

const INITIAL_STATE = {
  data: [],
  isLoading: false,
  isFetching: false,
};

const MESSAGES = {
  UPDATE_COMMENT_SUCCESSFULLY: 'Comment was updated successfully.',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CHURNED_USERS_PENDING:
    case GET_PAGE_HISTORY_STATISTICS_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CHURNED_USERS_FULFILLED:
    case GET_PAGE_HISTORY_STATISTICS_FULFILLED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case UPDATE_CHURNED_USER_COMMENT_FULFILLED:
      showInfoMessage(MESSAGES.UPDATE_COMMENT_SUCCESSFULLY);
      return {
        ...state,
      };
    case UPDATE_CHURNED_USER_COMMENT_REJECTED:
    case GET_PAGE_HISTORY_STATISTICS_REJECTED:
      throw new Error();
    default:
      return state;
  }
};
