import React, { useEffect, useState } from 'react';

import { Button, Flex } from 'antd';
import FAIcon, { FAIcons } from './FAIcon';

interface Props {
  tabs: {
    title: string;
    content: React.ReactNode;
    icon?: FAIcons;
  }[];
}

function Tabs({ tabs }: Props) {
  const [activeTab, setActiveTab] = useState(tabs[0].title);

  const handleChange = (key: string) => {
    window.history.pushState({}, '', `?${window.location.search}`);
    window.location.hash = key.toLowerCase().replace('/', '-').replaceAll(' ', '-');
    setActiveTab(key);
  };

  useEffect(() => {
    const { hash } = window.location;
    const tabHash = hash.replace('#', '').replaceAll('-', ' ').replace('/', '-').split('?')[0];
    const tabOriginalName = tabs.find(tab => tab.title.toLowerCase() === tabHash);
    setActiveTab(tabOriginalName?.title || tabs[0].title);
  }, [window.location.hash]);

  return (
    <>
      <Flex wrap align="center" gap="small" style={{ marginBottom: 16 }}>
        {tabs.map(tab => (
          <Button
            key={tab.title}
            type={activeTab === tab.title ? 'primary' : 'dashed'}
            onClick={() => handleChange(tab.title)}
          >
            <Flex align="center" gap={4}>
              {tab.icon && <FAIcon name={tab.icon} />} {tab.title}
            </Flex>
          </Button>
        ))}
      </Flex>
      {tabs.find(tab => tab.title === activeTab)?.content}
    </>
  );
}

export default Tabs;
