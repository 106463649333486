import {
  useAddCompanyUnivUser,
  useAddCompanyUnivDomain,
  useGetCompanyUnivPools,
} from '@Services/userPool';
import { AntdIcon, ModalButton, Table } from '@Shared/Style';
import FAIcon from '@Shared/Style/FAIcon';
import { Button, Input, Typography } from 'antd';
import React, { useState } from 'react';

const CreateUserPoolModal = () => {
  const { execute: addOrgUser, isLoading: isLoadingAddOrgUser } = useAddCompanyUnivUser();
  const { execute: addOrgDomain, isLoading: isLoadingAddOrgDomain } = useAddCompanyUnivDomain();
  const { refetch } = useGetCompanyUnivPools();

  const handleAddOrgUser = ({ name, domain, stripeCustomerId }) => {
    addOrgUser({
      data: {
        name,
        stripeCustomerId,
      },
      query: {
        createCustomer: !stripeCustomerId,
      },
    }).then(e => {
      const {
        data,
      }: {
        data: {
          data: {
            poolId: number;
          };
        };
      } = e;

      const { poolId } = data.data;

      addOrgDomain({
        path: {
          id: poolId,
        },
        data: {
          domain,
        },
      }).then(() => {
        refetch();
      });
    });
  };
  return (
    <ModalButton
      okButtonProps={{
        style: {
          display: 'none',
        },
      }}
      cancelButtonProps={{
        style: {
          display: 'none',
        },
      }}
      title="Add Company-wide/University Users Access (User Pools)"
      Button={
        <Button className="green" icon={<FAIcon name="fasPlus" />}>
          Create New Organization
        </Button>
      }
    >
      <Table
        form={{
          initialValues: {
            name: '',
            domain: '',
            stripeCustomerId: '',
          },
          loading: isLoadingAddOrgUser || isLoadingAddOrgDomain,
          name: 'Create New Organization',
          onSubmit: handleAddOrgUser,
          submitPosition: 'bottomRight',
        }}
        layout="vertical"
        options={[
          {
            span: 4,
            label: 'Organization Name',
            rules: [{ required: true, message: 'Please input organization name!' }],
            value: 'name',
            component: (
              <Input
                name="name"
                prefix={<FAIcon name="farUser" />}
                placeholder="Organization Name"
              />
            ),
          },
          {
            label: 'Email domain',
            span: 4,
            value: 'domain',
            rules: [
              {
                required: true,
                message: 'Please input email domain!',
              },
            ],
            description:
              'Only one domain is allowed when you create a new user pool. and, you can add more domains via add button of the table below.',
            component: <Input prefix={<FAIcon name="farEnvelope" />} placeholder="Email domain" />,
          },
          {
            label: 'Stripe ID',
            span: 4,
            value: 'stripeCustomerId',
            description:
              'If you want to use the same stripe customer id, you can input it. If you do not have it, it will be created automatically.',
            component: (
              <Input
                name="stripeCustomerId"
                prefix={<FAIcon name="fadTag" />}
                placeholder="Stripe ID (Optional)"
              />
            ),
          },
        ]}
      />
    </ModalButton>
  );
};

export default CreateUserPoolModal;
