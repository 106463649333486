import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import * as actions from '../../../actions';

class ApplyCouponForm extends Component {
  state = {};

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    const { applyFreeTrial, fetchData } = this.props;
    const { q, code } = this.state;
    applyFreeTrial(code, q).then(() => fetchData());
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group widths={3}>
          <Form.Input
            placeholder="Code"
            name="code"
            value={this.state.code}
            onChange={this.handleChange}
          />
          <Form.Input
            placeholder="Chartmetric User ID or User Email Address"
            name="q"
            value={this.state.q}
            onChange={this.handleChange}
          />
          <Form.Button content="Assign Coupon To User" />
        </Form.Group>
      </Form>
    );
  }
}

export default connect(null, actions)(ApplyCouponForm);
