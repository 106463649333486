import React from 'react';
import styles from './Clock.module.scss';

const Countdown = ({ value }) => {
  const minutes = Math.floor(value / 60);
  const seconds = value % 60;
  const hours = Math.floor(minutes / 60);

  return (
    <div className={styles.countDownMain}>
      <TimerUnit label="hours" value={hours} />
      <TimerUnit label="minutes" value={minutes} />
      <TimerUnit label="seconds" value={seconds} />
    </div>
  );
};

const TimerUnit = ({ label, value }) => {
  return (
    <div className={styles.timer}>
      <div style={{ display: 'flex', gap: 4 }}>
        <div className={styles.timerContainer}>
          <span className={styles.countdownElement}>
            {value.toString().length === 1 ? `0` : value.toString()[0]}
          </span>
        </div>
        <div className={styles.timerContainer}>
          <span className={styles.countdownElement}>
            {value.toString().length === 1 ? value.toString()[0] : value.toString()[1]}
          </span>
        </div>
      </div>
      <p className={styles.timerLabel}>{label}</p>
    </div>
  );
};

export default Countdown;
