import { combineReducers } from 'redux';
import { showInfoMessage, showErrorMessage } from '../utilities';
import {
  FETCH_TEAM_MANAGERS_PENDING,
  FETCH_TEAM_MANAGERS_FULFILLED,
  FETCH_TEAM_MANAGERS_REJECTED,
  ADD_TEAM_MANAGER_PENDING,
  ADD_TEAM_MANAGER_FULFILLED,
  ADD_TEAM_MANAGER_REJECTED,
  DELETE_TEAM_MANAGER_PENDING,
  DELETE_TEAM_MANAGER_FULFILLED,
  DELETE_TEAM_MANAGER_REJECTED,
  FETCH_TEAM_MEMBERS_PENDING,
  FETCH_TEAM_MEMBERS_FULFILLED,
  FETCH_TEAM_MEMBERS_REJECTED,
  UPDATE_TEAM_MEMBERS_PENDING,
  UPDATE_TEAM_MEMBERS_FULFILLED,
  UPDATE_TEAM_MEMBERS_REJECTED,
} from './types';

const INITIAL_STATE = {
  MANAGERS: {
    data: null,
    isFetching: false,
    error: null,
  },
  ADD_MANAGER: {
    data: null,
    isFetching: false,
    error: null,
  },
  DELETE_MANAGER: {
    data: null,
    isFetching: false,
    error: null,
  },
  SEARCH_MEMBER: {
    data: null,
    isFetching: false,
    error: null,
  },
  UPDATE_MEMBER: {
    data: null,
    isFetching: false,
    error: null,
  },
};

const MESSAGES = {
  ERROR: 'Something Wrong With API (Please Contact Chartmetric Team).',
};

const managers = (state = INITIAL_STATE.MANAGERS, action) => {
  switch (action.type) {
    case FETCH_TEAM_MANAGERS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_TEAM_MANAGERS_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(action.payload?.data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      return {
        data,
        isFetching: false,
      };
    }
    case FETCH_TEAM_MANAGERS_REJECTED:
      showErrorMessage(action.payload?.data?.error);
      return {
        error: MESSAGES.ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};

const addManager = (state = INITIAL_STATE.ADD_MANAGER, action) => {
  switch (action.type) {
    case ADD_TEAM_MANAGER_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case ADD_TEAM_MANAGER_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(action.payload?.data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      showInfoMessage(data);
      return {
        data,
        isFetching: false,
      };
    }
    case ADD_TEAM_MANAGER_REJECTED:
      showErrorMessage(action.payload?.data?.error);
      return {
        error: MESSAGES.ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};
const removeManager = (state = INITIAL_STATE.DELETE_MANAGER, action) => {
  switch (action.type) {
    case DELETE_TEAM_MANAGER_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_TEAM_MANAGER_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(action.payload?.data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      showInfoMessage(data);
      return {
        data,
        isFetching: false,
      };
    }
    case DELETE_TEAM_MANAGER_REJECTED:
      showErrorMessage(action.payload?.data?.error);
      return {
        error: MESSAGES.ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};
const searchMember = (state = INITIAL_STATE.SEARCH_MEMBER, action) => {
  switch (action.type) {
    case FETCH_TEAM_MEMBERS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_TEAM_MEMBERS_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(action.payload?.data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      return {
        data,
        isFetching: false,
      };
    }
    case FETCH_TEAM_MEMBERS_REJECTED:
      showErrorMessage(action.payload?.data?.error);
      return {
        error: MESSAGES.ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};

const updateMember = (state = INITIAL_STATE.UPDATE_MEMBER, action) => {
  switch (action.type) {
    case UPDATE_TEAM_MEMBERS_PENDING:
      return {
        ...INITIAL_STATE.UPDATE_MEMBER,
        isFetching: true,
      };
    case UPDATE_TEAM_MEMBERS_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(action.payload?.data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      showInfoMessage(data);
      return {
        data,
        isFetching: false,
      };
    }
    case UPDATE_TEAM_MEMBERS_REJECTED:
      showErrorMessage(action.payload?.data?.error);
      return {
        error: MESSAGES.ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  managers,
  manager: combineReducers({
    add: addManager,
    remove: removeManager,
  }),
  member: combineReducers({
    search: searchMember,
    update: updateMember,
  }),
});
