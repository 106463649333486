import React, { useState } from 'react';
import { capitalize } from 'lodash';
import { Card, Button, Flex } from 'antd';
import { Panel, Table } from '@Shared/Style';
import { getDateFormat, getDateFormatFromEpoch, getStripeCustomerUrl } from '../../../utilities';
import { useSyncUserStripe } from '../../../services/system';

function Subscription({ churnInfo, customerInfo, email, userId }) {
  const [clickedSyncStripe, setClickedSyncStripe] = useState(null);
  const { execute: syncUserStripe, isLoading: isLoadingSyncUserStripe } = useSyncUserStripe();
  const prodPlan = customerInfo?.subscription;
  const apiPlan = customerInfo?.addOns?.sort((a, b) => b.created - a.created)?.[0];
  const allTimeRevenue = churnInfo
    ? churnInfo.total_spend
    : `${
        (customerInfo?.history ?? []).reduce((all, one) => {
          const amount = one?.amount ?? 0;
          return all + amount;
        }, 0) / 100
      } (Not includes amount of API usage)`;

  const handleOnClickSyncStripe = () => {
    syncUserStripe({ data: { id: userId } });
    setClickedSyncStripe(email);
  };

  return customerInfo ? (
    <Panel title="Subscription">
      <Table
        title="Stripe"
        options={[
          {
            label: 'Stripe Customer ID',
            component: (
              <Flex style={{ width: '100%' }} gap="middle" align="center">
                {customerInfo ? (
                  <a
                    href={getStripeCustomerUrl(customerInfo.id)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {customerInfo.id}
                  </a>
                ) : (
                  'N/A'
                )}
                <Button
                  className="green"
                  disabled={clickedSyncStripe === email}
                  onClick={handleOnClickSyncStripe}
                  loading={isLoadingSyncUserStripe}
                >
                  {clickedSyncStripe === email ? 'Updated' : 'Sync Stripe'}
                </Button>
              </Flex>
            ),
          },
          {
            label: 'All time revenue',
            value: `$${allTimeRevenue}`,
          },
        ]}
      />

      {churnInfo && (
        <Table
          title="Churned User Information"
          options={[
            {
              label: 'Status',
              value: capitalize(churnInfo?.status?.replace('_', ' ')),
            },
            {
              label: 'Churned on',
              value: getDateFormat(churnInfo?.churned_on),
            },
            {
              label: 'Activated on',
              value: getDateFormat(churnInfo?.activated_on),
            },
            {
              label: 'Latest Plan',
              value: churnInfo?.plan,
            },
            {
              label: 'MRR',
              value: churnInfo?.mrr,
            },
          ]}
        />
      )}
      {[prodPlan, apiPlan].map((plan, i) => {
        const isTrial =
          plan?.trial_start &&
          plan.trial_start <= new Date().getTime() / 1000 &&
          new Date().getTime() / 1000 < plan.trial_end;
        return (
          plan?.plan?.nickname && (
            <Table
              title={
                (i === 0 ? 'Production Plan' : `API Plan`) + (plan.trial_start ? ' (Trial)' : '')
              }
              options={[
                {
                  label: 'Subscription ',
                  value: plan?.plan?.nickname ?? 'N/A',
                },
                {
                  label: 'Plan',
                  value: plan?.plan?.product ?? 'N/A',
                },
                {
                  label: 'Price',
                  value:
                    plan?.plan?.amount === 0
                      ? 'Free'
                      : plan?.plan?.amount
                      ? `${(plan?.plan?.amount ?? 0) / 100} (${
                          plan?.currency?.toUpperCase() ?? ''
                        }) / ${plan?.plan?.interval}`
                      : 'N/A',
                },
                ...(isTrial
                  ? [
                      {
                        label: 'Trial Days',
                        value: `${
                          Math.ceil((plan?.trial_end ?? 0) - (plan?.trial_start ?? 0)) / 86400
                        } day(s)`,
                      },
                      {
                        label: 'First Date',
                        value: plan?.created ? getDateFormatFromEpoch(plan?.created) : 'N/A',
                      },
                    ]
                  : [
                      {
                        label: 'First Date',
                        value: plan?.created ? getDateFormatFromEpoch(plan?.created) : 'N/A',
                      },
                      {
                        label: 'Renewed Date',
                        value: plan?.current_period_start
                          ? getDateFormatFromEpoch(plan?.current_period_start)
                          : 'N/A',
                      },
                    ]),
                {
                  label: 'End Date',
                  value: plan?.cancel_at ? getDateFormatFromEpoch(plan?.cancel_at) : 'Not canceled',
                },
                {
                  label: 'Metadata',
                  value: JSON.stringify(plan?.plan?.metadata),
                },
              ]}
            />
          )
        );
      })}
    </Panel>
  ) : null;
}

export default Subscription;
