import { showErrorMessage, showInfoMessage } from '../utilities';
import {
  ADD_CAMPAIGN_FULFILLED,
  ADD_CAMPAIGN_PENDING,
  ADD_CAMPAIGN_REJECTED,
  CREATE_STRIPE_CHECKOUT_SESSION_FULFILLED,
  CREATE_STRIPE_CHECKOUT_SESSION_PENDING,
  CREATE_STRIPE_CHECKOUT_SESSION_REJECTED,
  DELETE_CAMPAIGN_FULFILLED,
  DELETE_CAMPAIGN_REJECTED,
  GET_CAMPAIGN_LIST_FULFILLED,
  GET_CAMPAIGN_LIST_PENDING,
  GET_STRIPE_COUPON_LIST_FULFILLED,
  GET_STRIPE_COUPON_LIST_PENDING,
  GET_STRIPE_COUPON_LIST_REJECTED,
  GET_STRIPE_PRODUCT_LIST_FULFILLED,
  GET_STRIPE_PRODUCT_LIST_PENDING,
  GET_STRIPE_PRODUCT_LIST_REJECTED,
} from './types';

const INITIAL_STATE = {
  couponData: [],
  isLoadingCoupon: false,
  optionData: {},
  isLoadingOption: false,
  isFetching: false,
  campaignData: [],
  isLoadingCampaign: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_LIST_PENDING:
      return { ...state, isLoadingCampaign: true };
    case GET_STRIPE_COUPON_LIST_PENDING:
      return {
        ...state,
        isLoadingCoupon: true,
      };
    case GET_STRIPE_PRODUCT_LIST_PENDING:
      return {
        ...state,
        isLoadingOption: true,
      };
    case CREATE_STRIPE_CHECKOUT_SESSION_PENDING:
    case ADD_CAMPAIGN_PENDING:
      return { ...state, isFetching: true };
    case GET_CAMPAIGN_LIST_FULFILLED:
      return {
        ...state,
        campaignData: action.payload.data,
        isLoadingCampaign: false,
      };
    case GET_STRIPE_COUPON_LIST_FULFILLED:
      return {
        ...state,
        couponData: action.payload.data,
        isLoadingCoupon: false,
      };
    case GET_STRIPE_PRODUCT_LIST_FULFILLED:
      return {
        ...state,
        optionData: action.payload.obj,
        isLoadingOption: false,
      };
    case ADD_CAMPAIGN_FULFILLED:
    case ADD_CAMPAIGN_REJECTED:
    case DELETE_CAMPAIGN_FULFILLED:
    case DELETE_CAMPAIGN_REJECTED:
    case CREATE_STRIPE_CHECKOUT_SESSION_FULFILLED:
    case CREATE_STRIPE_CHECKOUT_SESSION_REJECTED:
      return { ...state, isFetching: false };
    case GET_STRIPE_COUPON_LIST_REJECTED:
      return {
        ...state,
        isLoadingCoupon: false,
      };
    case GET_STRIPE_PRODUCT_LIST_REJECTED:
      return {
        ...state,
        isLoadingOption: false,
      };
    default:
      return state;
  }
};
