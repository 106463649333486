import { message as Toast } from 'antd';
import imageCompression, { Options } from 'browser-image-compression';
import qs from 'qs';
import { FILE_SIZE_LIMIT } from './constants';
import { isEmpty } from 'lodash';
export * as StyleUtil from './style';

// TODO: move to separate file

/*
  Alert Services
 */
export const showInfoMessage = (message: string) => {
  if (!isEmpty(message)) Toast.success(message);
};
export const showErrorMessage = (message: string) => {
  if (!isEmpty(message)) Toast.error(message);
};

export const generateCmUrl = (urlRoot, params) => {
  const cmUrl = 'https://app.chartmetric.com';
  const { service, id, chart_type, chartParam, hash, ...realParams } = params;
  const queryParams = Object.entries({ ...realParams, id })
    .filter(([, value]) => value != null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  const hashString = hash ? `#${hash}` : '';
  if (
    !id &&
    [
      'label',
      'track',
      'album',
      'artist',
      'radio-station',
      'playlist',
      'curator',
      'country',
      'city',
      'songwriter',
    ].includes(urlRoot)
  ) {
    return '';
  }

  if (urlRoot === 'artist' || urlRoot === 'track' || urlRoot === 'album') {
    return `${cmUrl}/${urlRoot}/${id}/about`;
  }

  if (urlRoot === 'playlist' || urlRoot === 'curator') {
    const urlMiddlePart = service === 'itunes' ? 'applemusic' : service;

    return `${cmUrl}/${urlRoot}/${urlMiddlePart}/${id}`;
  }

  return `${cmUrl}/${urlRoot}?${queryParams}${hashString}`;
};

export const getDateFormat = (date, withTime = false) => {
  if (!date) return '';
  const [year, month, day] = String(date).split('T')[0].split('-');
  const dateObj = new Date(String(date).split('T')[0]);
  const time = String(date).split('T')?.[1]?.split('.')?.[0];
  return `${dateObj.toLocaleString('default', {
    month: 'short',
  })} ${day}, ${year}
  ${withTime ? `, ${time}` : ''}`;
};

export const getDateFormatFromEpoch = date => {
  const dateObj = new Date(date * 1000);
  return `${dateObj.toLocaleString('default', {
    month: 'short',
  })} ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
};

export const getDateFormatFromDatePicker = date => {
  const dateObj = new Date(date);
  return `${dateObj.toLocaleString('default', {
    month: 'short',
  })} ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
};

export const getDate = timestamp => {
  if (timestamp) {
    const d = new Date(timestamp);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  }
  return '';
};

/**
 *
 * @param date
 * @returns 'YYYY-MM-DD'
 */
export const getDateToString = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
};

export const convertURLtoImageFile = async (url: string) => {
  const response = await fetch(url);
  const data = await response.blob();
  const ext = url.split('.').pop();
  const filename = url.split('/').pop();
  const metadata = { type: `image/${ext}` };
  return new File([data], filename!, metadata);
};

export const compressFile = async (file: File) => {
  if (file.type.slice(0, 5) !== 'image') {
    return file;
  }

  const options: Options = {
    maxSizeMB: FILE_SIZE_LIMIT,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const compressedFile = await imageCompression(file, options);
  return new File([compressedFile], 'image.png', { type: 'image/png' });
};

export const getStripeCustomerUrl = (customerId: string) =>
  `https://dashboard.stripe.com/customers/${customerId}`;

export const replaceCapitalize = (inputString, replaceString = ' ') =>
  inputString.replace(/([A-Z])/g, `${replaceString}$1`).trim();

export function capitalizeEveryWord(words: string): string {
  if (words == null) {
    return '';
  }

  return words.replace(/(\b[a-z](?!\s))/g, letter => letter.toUpperCase());
}

export const generateInternalLink = (
  urlRoot:
    | 'user/user-profile'
    | 'onesheet/user-profile'
    | 'onesheet/team-manager#/search-team'
    | 'onesheet/team-manager#/search-user'
    | 'campaign/company-wide/university-organizations#/hierarchy',
  params?: Record<string, string | number>
) =>
  `/${urlRoot.split('#')[0]}?${qs.stringify(params)}${
    urlRoot.includes('#') ? `#${urlRoot.split('#')[1]}` : ''
  }`;

export const getUrlWithPathParams = (
  endpoint: string,
  pathParams?: Record<string, string | number>,
  queryParams?: Record<string, unknown>
) => {
  const res = endpoint
    .split(':')
    .map((s, i) => {
      if (i === 0) return s;

      for (const key in pathParams) {
        if (s.includes(key)) return s.replace(key, String(pathParams[key]));
      }
      return s;
    })
    .join('');
  if (queryParams) {
    return `${res}?${qs.stringify(queryParams)}`;
  }
  return res;
};
