import React, { Component, useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { useUser } from '@Services/auth';
//Route components
import Section from './components/Global/Section';
import Sidebar from './components/Sidebar/Sidebar';
import LoginForm from './Login';
import { MENUS } from './constants/menus';
import LocalIntercom from './components/Intercom/Intercom';
import useAppTime from '@Hooks/useAppTime';
import styles from './root.module.scss';
import { generateRoute as generateRoutePath, ROUTE_PATH } from './constants/path';

function Root() {
  const { isAuthed } = useUser();
  const _appTime = useAppTime();
  const loginRoute = `${ROUTE_PATH.LOGIN}?redirect=${window.location.pathname}${window.location.search}${window.location.hash}`;

  return (
    <>
      {isAuthed && <LocalIntercom />}
      <div id={styles.rootContainer}>
        {isAuthed && <Sidebar />}
        <Switch>
          {MENUS.map(menu => {
            const Component = menu.component ?? Section;
            if (menu.items.length === 0) {
              return (
                <Route
                  key={menu.name}
                  path={menu.route}
                  exact
                  render={props =>
                    isAuthed ? (
                      <Component {...props} menu={menu} itemKey="" />
                    ) : (
                      <Redirect to={loginRoute} />
                    )
                  }
                />
              );
            }

            return menu.items.map(item => (
              <Route
                key={`${menu.name}-${item.name}`}
                path={generateRoutePath(menu.route, item.name)}
                render={props =>
                  isAuthed ? (
                    <Component {...props} menu={menu} itemKey={item.name} />
                  ) : (
                    <Redirect to={loginRoute} />
                  )
                }
              />
            ));
          })}

          <Route exact path={ROUTE_PATH.LOGIN} component={LoginForm} />
        </Switch>
      </div>
    </>
  );
}

export default Root;
