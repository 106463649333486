import React, { useState } from 'react';
import { DataTable, Panel, Table } from '@Shared/Style';
import { useStates } from '@Hooks';
import { Button, Flex, Input, Typography } from 'antd';
import { useAddTrialSubscription } from '@Services/purchase';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { generateInternalLink, getDateFormatFromEpoch } from '@Utils';
import { toast } from 'react-toastify';

function TrialSubscription() {
  const { execute, isLoading, data } = useAddTrialSubscription();
  const [lastUserEmail, setLastUserEmail] = useState<string>();

  const { states, setAllStates } = useStates({
    email: '',
    duration: 14,
  });

  const handleChangeInput = e => {
    const { name, value } = e.target;
    setAllStates({ [name]: value });
  };

  const handleSubmit = type => {
    const { email, duration } = states;
    execute({
      data: { email, days: Number(duration), type },
    }).then(() => {
      setLastUserEmail(email);
      setAllStates({ email: '', duration: 14 });
    });
  };

  const disabled = isEmpty(states.email) || states.duration <= 0;

  return (
    <>
      <Table
        title="Trial Subscription"
        panelStyle
        extra={
          <Flex align="center" gap="small" vertical>
            <Flex gap="large">
              <Button
                loading={isLoading}
                disabled={disabled}
                className="green"
                onClick={() => handleSubmit('api')}
              >
                Grant API Trial
              </Button>
              <Button
                loading={isLoading}
                disabled={disabled}
                className="blue"
                onClick={() => handleSubmit('premium')}
              >
                Grant Premium Trial
              </Button>
            </Flex>
            <Typography.Text type="secondary">
              API trial: Please send the refresh token through
              <Link to={generateInternalLink('user/user-profile')}> Search User </Link> to user
              after this.
            </Typography.Text>
          </Flex>
        }
        options={[
          {
            label: 'Email',
            component: (
              <Input type="email" name="email" value={states.email} onChange={handleChangeInput} />
            ),
            labelIcon: 'mail',
          },
          {
            label: 'Duration',
            component: (
              <Input
                type="number"
                name="duration"
                value={states.duration}
                onChange={handleChangeInput}
              />
            ),
            labelIcon: 'calendar',
          },
        ]}
      />
      {data?.obj?.subscriptions && (
        <Panel title={`User Subscriptions (${lastUserEmail})`}>
          <DataTable
            columns={[
              {
                Header: 'Plan',
                accessor: 'items.data[0].plan.nickname',
              },
              {
                Header: 'Start Date',
                accessor: 'start_date',
                Cell: ({ value }) => getDateFormatFromEpoch(value),
              },
              {
                Header: 'End Date',
                accessor: 'cancel_at',
                Cell: ({ value }) => (value ? getDateFormatFromEpoch(value) : 'N/A'),
              },
              {
                Header: 'Trial Days',
                Cell: ({ original }) =>
                  original['trial_start']
                    ? Math.ceil((original['trial_end'] - original['trial_start']) / 86400)
                    : 'N/A',
              },
              {
                Header: 'Metadata',
                accessor: 'items.data[0].plan.metadata',
                Cell: ({ value }) => JSON.stringify(value),
              },
            ]}
            data={data?.obj?.subscriptions}
          />
        </Panel>
      )}
    </>
  );
}

export default TrialSubscription;
