/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { capitalize } from 'lodash';
import { Input, Button, Select, Checkbox, Flex, Typography, Divider, Dropdown } from 'antd';

import { generateCmUrl } from '@Utils';
import { BrandIcon, Panel, Table } from '@Shared/Style';
import ImageCropper from '@Shared/ImageCropper';
import { useStates } from '@Hooks';
import { COUNTRY_OPTIONS, GENDER_OPTIONS, PRONOUN_OPTIONS } from '@Utils/options';
import { CAREER_STAGE_MAPPER, RECENT_MOMENTUM_MAPPER } from '@Utils/constants';
import { WhatsThis } from '@Shared';
import ResponsiveSection from '@Shared/Style/ResponsiveSection';
import { EditArtistProps, useArtistInfo } from './useArtistInfo';
import { States, TAGS, CAREER_STAGE_CHANGE_OPTIONS } from './constants';
import { useGetCityList } from '@Services/city';
import { useGetEntity } from '@Services/search';
import FAIcon from '@Shared/Style/FAIcon';
import { AUTHENTICITY_RISK_PLATFORM_MAP } from '@Services/meta';
import { EditEntityProps } from '..';

function Info({ q }: EditEntityProps) {
  const { data, refetch } = useGetEntity({
    data: {
      q,
    },
  });

  const {
    onChangeState,
    onDispatchState,
    onChangeInput,
    onChangeCheckBox,
    onChangeSelector,
    onChangeGPTSummary,
    onSubmit,
    onChangeImage,
    isFetching,
    props,
    states,
  } = useArtistInfo(q);

  const careerStageChangeOption =
    props.artist_stage.career_stage !== states.override_stage
      ? CAREER_STAGE_CHANGE_OPTIONS[props.artist_stage.career_stage]
      : props.artist_stage.career_stage;

  const renderCitySelector = () => {
    const { data, isLoading } = useGetCityList(
      {
        data: {
          code2s: [states.code2],
        },
      },
      {
        disable: !states.code2,
      }
    );

    return (
      <Select
        onChange={onChangeSelector('current_city_id')}
        placeholder="Select City"
        options={[...(data?.map(({ id, name }) => ({ label: name, value: id })) ?? [])]}
        value={states.current_city_id}
        loading={isLoading}
        showSearch
      />
    );
  };

  // TODO: Apply Form
  return (
    <Panel.ResponsiveBody>
      <Table
        layout="vertical"
        panelStyle
        title="Basic Information"
        extra={
          <Button
            type="primary"
            loading={isFetching}
            onClick={onSubmit}
            icon={<FAIcon name="farPenNib" />}
          >
            Update All
          </Button>
        }
        options={[
          {
            label: 'Name',
            component: (
              <>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={generateCmUrl('artist', { id: data.id })}
                >
                  {data.name}
                </a>{' '}
              </>
            ),
            labelIcon: 'tag',
            span: 1,
          },
          {
            label: 'Country',
            component: (
              <Select
                onChange={onChangeSelector('code2')}
                options={COUNTRY_OPTIONS}
                value={states.code2 ?? ''}
                filterOption={(input, option) =>
                  String(option?.label)?.toLowerCase().includes(input.toLowerCase())
                }
                showSearch
              />
            ),
            labelIcon: 'country',
          },
          {
            label: 'City',
            component: renderCitySelector(),
            labelIcon: 'country',
          },
          {
            label: 'Image Url',
            tooltip:
              'Recommended to use the image uploader above rather than editing the image_url directly. Generally we should not be pointing this to urls hosted on third-party domains, as if the domain goes down it impacts our application. Deleting this image_url deletes it from cm_artist.image_url but will now trigger the fallback logic for artist images to then use Tivo, Spotify, and other options.',
            component: (
              <>
                <ImageCropper
                  key={states.image_url}
                  defaultImageUrl={states.image_url}
                  onCropComplete={onChangeImage}
                />
                <Input
                  name="image_url"
                  value={states.image_url ?? ''}
                  onChange={onChangeInput}
                  disabled={Boolean(states.image_blob?.imageSrc)}
                />
                <Divider />
                <Checkbox
                  name="hide_cover_image"
                  checked={states.hide_cover_image}
                  onChange={onChangeCheckBox}
                >
                  Hide cover image from artist profile modal
                </Checkbox>
              </>
            ),
            labelIcon: 'image',
            span: 4,
          },

          {
            label: 'Gender',
            component: (
              <Flex gap="small" vertical>
                {!data.hide_gender && (
                  <Select
                    onChange={onChangeSelector('gender')}
                    options={GENDER_OPTIONS}
                    value={states.gender ?? ''}
                  />
                )}
                <Checkbox
                  name={'hide_gender' as TAGS}
                  checked={states.hide_gender}
                  onChange={onChangeCheckBox}
                >
                  Hide from profile
                </Checkbox>
              </Flex>
            ),
            labelIcon: 'gender',
          },
          {
            label: 'Pronoun',
            component: (
              <Flex gap="small" vertical>
                {!data.hide_pronoun && (
                  <Select
                    onChange={onChangeSelector('pronoun_title')}
                    options={PRONOUN_OPTIONS}
                    value={states.pronoun_title ?? ''}
                  />
                )}
                <Checkbox
                  name={'hide_pronoun' as TAGS}
                  checked={states.hide_pronoun}
                  onChange={onChangeCheckBox}
                >
                  Hide from profile
                </Checkbox>
              </Flex>
            ),
            labelIcon: 'gender',
          },
          {
            label: 'Birthday',
            component: (
              <Flex gap="small" vertical>
                {!data.hide_birthday && (
                  <Input
                    type="date"
                    name="date_of_birth"
                    value={
                      states.date_of_birth
                        ? new Date(states.date_of_birth).toISOString().split('T')[0]
                        : ''
                    }
                    onChange={onChangeInput}
                  />
                )}
                <Checkbox
                  name={'hide_birthday' as TAGS}
                  checked={states.hide_birthday}
                  onChange={onChangeCheckBox}
                >
                  Hide from profile
                </Checkbox>
              </Flex>
            ),
            labelIcon: 'calendar',
            span: 2,
          },
          {
            label: 'Band',
            component: (
              <Checkbox name="band" checked={states.band} onChange={onChangeCheckBox}>
                Band
              </Checkbox>
            ),
            labelIcon: 'tag',
            span: 3,
          },
          {
            label: 'Description',
            component: (
              <>
                <Input.TextArea
                  name="cm_artist_description"
                  value={states.cm_artist_description ?? ''}
                  onChange={onChangeInput}
                  style={{ height: '400px' }}
                />
                <Typography.Text type="secondary">
                  If this field is blank, Platform&apos;s will be displayed.
                </Typography.Text>
              </>
            ),
            labelIcon: 'edit',
            span: 3,
          },
        ]}
      />
      <section>
        <Table
          panelStyle
          title="Career Stage & Growth"
          options={[
            {
              label: 'Career Growth',
              component: (
                <Flex gap="small" vertical>
                  {CAREER_STAGE_MAPPER[data.artist_stage.career_stage]}
                  {['legendary', 'superstar'].includes(data.artist_stage.career_stage) && (
                    <Checkbox
                      name={`override_for_artist_stage_${careerStageChangeOption}`}
                      checked={states[`override_for_artist_stage_${careerStageChangeOption}`]}
                      onChange={onChangeCheckBox}
                    >
                      <Flex align="center">
                        Override to {capitalize(careerStageChangeOption)}
                        <WhatsThis title="Override Career Stage">
                          Override the career stage to the opposite of the current stage. This is
                          used to manually set the career stage of an artist once every two weeks.
                        </WhatsThis>
                      </Flex>
                    </Checkbox>
                  )}
                </Flex>
              ),
            },
            {
              label: 'Recent Momentum',
              component: RECENT_MOMENTUM_MAPPER[data.artist_stage.career_growth],
            },
          ]}
        />
        <Table
          title="Authenticity Risk"
          panelStyle
          options={Object.entries(AUTHENTICITY_RISK_PLATFORM_MAP).map(([key, platform]) => {
            const overridden = states.authenticity_risk_overrides?.[key] ?? 0;
            const original = props.authenticity_risk?.[key] ?? 0;

            const value = overridden ? overridden : original;

            return {
              label: (
                <Flex gap={4}>
                  <BrandIcon platform={platform.toLowerCase() as any} />{' '}
                  {AUTHENTICITY_RISK_PLATFORM_MAP[key]} {capitalize(key.split('_')[1])}
                </Flex>
              ),
              component: (
                <Flex align="start" gap={4} vertical>
                  <Select
                    value={value}
                    options={Array.from({ length: 6 }).map((_, i) => ({
                      label: i,
                      value: i,
                    }))}
                    onChange={onChangeState(`authenticity_risk_overrides.${key}` as any)}
                  />
                  <Typography.Text type="secondary">
                    {overridden ? `Overridden. Origin: ${original}` : ''}
                  </Typography.Text>
                </Flex>
              ),
            };
          })}
        />
        <Table
          panelStyle
          title="Contact Information"
          options={[
            {
              label: 'Booking Agent',
              component: (
                <Input.TextArea
                  style={{ height: '125px' }}
                  name="booking_agent"
                  value={states.booking_agent ?? ''}
                  onChange={onChangeInput}
                />
              ),
            },
            {
              label: 'Record Label',
              component: (
                <Input.TextArea
                  style={{ height: '125px' }}
                  name="record_label"
                  value={states.record_label ?? ''}
                  onChange={onChangeInput}
                />
              ),
            },
            {
              label: 'Press Contact',
              component: (
                <Input.TextArea
                  style={{ height: '125px' }}
                  name="press_contact"
                  value={states.press_contact ?? ''}
                  onChange={onChangeInput}
                />
              ),
            },
            {
              label: 'General Manager',
              component: (
                <Input.TextArea
                  name="general_manager"
                  value={states.general_manager ?? ''}
                  onChange={onChangeInput}
                />
              ),
            },
          ]}
        />
        <Table
          panelStyle
          title="Optional"
          options={[
            {
              label: 'Duplicate artist',
              component: (
                <Checkbox
                  onChange={onChangeCheckBox}
                  name="is_duplicate"
                  checked={states.is_duplicate}
                />
              ),
            },
            {
              label: 'Hide / Non artist?',
              component: (
                <Checkbox
                  onChange={onChangeCheckBox}
                  name="is_non_artist"
                  checked={states.is_non_artist}
                />
              ),
            },
          ]}
        />
        {states?.gpt_summary && (
          <Table
            panelStyle
            title="Bio Summary"
            options={Object.entries(states.gpt_summary).map(([key, value]) => ({
              label: capitalize(key.replace('_', ' ')),
              component: <Input value={value as string} onChange={onChangeGPTSummary} name={key} />,
            }))}
          />
        )}
      </section>
    </Panel.ResponsiveBody>
  );
}

export default React.memo(Info);
