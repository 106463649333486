import React, { Component, useState } from 'react';
import ReactTable from 'react-table';
import { Select, Flex, Input } from 'antd';
import { Panel, Button, ActionInput, Table, DataTable } from '../../../shared/Style';
import {
  useSetApiLimitOverride,
  useGetApiLimitOverride,
  useDeleteApiLimitOverride,
} from '../../../services/restriction';
import SearchInput from '../../../shared/Table/SearchInput';
import useStates from '../../../hooks/useStates';

function DeleteButton({ email, refetch }) {
  const { execute, isLoading } = useDeleteApiLimitOverride();

  const deleteEmail = email => {
    execute({ query: { email } }).then(() => refetch({}));
  };

  return (
    <Button loading={isLoading} icon="delete" color="red" onClick={() => deleteEmail(email)} />
  );
}

const getColumns = refetch => [
  {
    Header: 'Email',
    accessor: 'email',
    width: 200,
    Cell: ({ value }) => (
      <a target="_blank" rel="noopener noreferrer" href={`/user/search-user?qs=${value}`}>
        {value}
      </a>
    ),
  },
  {
    Header: 'User ID',
    accessor: 'id',
  },
  {
    Header: 'Max',
    accessor: 'max',
  },
  {
    Header: 'Duration',
    accessor: 'duration',
  },
  {
    Header: 'Stripe Max',
    accessor: 'original_max',
  },
  {
    Header: 'Stripe Duration',
    accessor: 'original_duration',
  },
  {
    Header: 'Actions',
    accessor: 'email',
    width: 100,
    Cell: props => (
      <Flex justify="center">
        <DeleteButton email={props.value} refetch={refetch} />
      </Flex>
    ),
  },
];

const DURATION_OPTIONS = [
  { label: 'Per-Second', value: '1s' },
  { label: 'Per-Minute', value: '1m' },
  { label: 'Per-Hour', value: '1h' },
  { label: 'Per-Day', value: '1d' },
];

function APILimitOverrides() {
  const { data, refetch, isLoading } = useGetApiLimitOverride();
  const { execute: fetchAddDomain, isLoading: isAddingDomain } = useSetApiLimitOverride();
  const [filteredData, setFilteredData] = useState();
  const { states, setAllStates } = useStates<{
    email: string;
    max: number;
    duration: string;
  }>({
    email: '',
    max: 0,
    duration: 'm',
  });

  const onSearch = filteredData => {
    setFilteredData(filteredData);
  };

  const onChangeInput = ({ target: { id, value } }) => {
    setAllStates({ [id]: value });
  };

  return (
    <>
      <Table
        panelStyle
        title="Add Limit Override"
        options={[
          {
            label: 'Email',
            labelIcon: 'mail',
            component: <Input id="email" value={states.email} onChange={onChangeInput} />,
          },
          {
            label: 'Max Count',
            labelIcon: 'number',
            component: <Input id="max" type="number" value={states.max} onChange={onChangeInput} />,
          },
          {
            label: 'Duration',
            labelIcon: 'clock',
            component: (
              <Select
                onChange={value => setAllStates({ duration: value })}
                options={DURATION_OPTIONS}
                value={states.duration ?? ''}
              />
            ),
          },
        ]}
        extra={
          <Button
            loading={isAddingDomain}
            onClick={() => fetchAddDomain({ query: states }).then(() => refetch({}))}
          >
            Set API Limit Override
          </Button>
        }
      />

      <Panel title="Limit Overrides">
        <SearchInput originalData={data} onChange={onSearch} />
        <br />
        <DataTable
          data={filteredData || data}
          columns={getColumns(refetch)}
          loading={isLoading || isAddingDomain}
          defaultPageSize={10}
        />
      </Panel>
    </>
  );
}

// class APILimitOverrides extends Component {
//   state = {
//     isFetching: true,
//     overrides: [],
//     email: '',
//     max: '',
//     duration: 'm',
//   };
//   componentDidMount() {
//     this.fetchData();
//   }

//   handleEmailChange = (_, { value }) => {
//     this.setState({ email: value });
//   };

//   handleMaxChange = (_, { value }) => {
//     this.setState({ max: value });
//   };

//   handleDurationChange = (_, { value }) => {
//     this.setState({ duration: value });
//   };

//   handleClick = () => this.createOverride(this.state.email, this.state.max, this.state.duration);

//   fetchData() {
//     // update ui
//     this.setState({ isFetching: true });

//     // fetch overrides
//     getAllAPILimitOverridesPromise()
//       // store overrides and update ui
//       .then(res => {
//         this.setState({
//           isFetching: false,
//           overrides: res.data,
//         });
//       });
//   }

//   deleteOverride(email) {
//     this.setState({ isFetching: true });
//     deleteAPILimitOverridePromise(email)
//       .then(res => {
//         if (res.status === 200) {
//           showInfoMessage(res.data);
//         } else {
//           showErrorMessage(`Unable to delete override (status code ${res.status})`);
//         }
//       })
//       .then(setTimeout(this.fetchData.bind(this), 1000));
//   }

//   createOverride(email, max, duration = 'm') {
//     this.setState({ isFetching: true });
//     setAPILimitOverridePromise(email, max, '1' + duration)
//       .then(res => {
//         showInfoMessage(res.data);
//       })
//       .then(setTimeout(this.fetchData.bind(this), 1000))
//       .catch(error => {
//         showErrorMessage(error.response.data?.error);
//       });
//   }

//   render() {
//     const { isFetching } = this.state;
//     return (
//       <Segment raised loading={isFetching}>
//         <Input
//           fluid
//           label={{ icon: 'user' }}
//           size="large"
//           labelPosition="left"
//           placeholder="Email of user to set override for"
//           onChange={this.handleEmailChange}
//         />
//         <div style={{ flex: true }}>
//           <Input
//             fluid
//             label={{ icon: 'world' }}
//             size="large"
//             labelPosition="left"
//             placeholder="Requests"
//             onChange={this.handleMaxChange}
//           />
//           <Dropdown
//             fluid
//             selection
//             label={{ basic: true, content: 'Time Unit' }}
//             labelPosition="left"
//             placeholder="Requests"
//             options={durationOptions}
//             value={this.state.duration}
//             onChange={this.handleDurationChange}
//           />
//         </div>
//         <Button fluid secondary size="large" onClick={this.handleClick}>
//           Set
//         </Button>
//         <Divider hidden />

//         <table width="100%">
//           <thead>
//             <tr>
//               <th>Email</th>
//               <th>User ID</th>
//               <th>Max</th>
//               <th>Duration</th>
//               <th>Stripe Max</th>
//               <th>Stripe Duration</th>
//               <th>Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {this.state.overrides.map(override => {
//               return (
//                 <tr className="float-right padded" key={`override-${override.id}`}>
//                   <td>{override.email}</td>
//                   <td>{override.id}</td>
//                   <td>{override.max}</td>
//                   <td>{override.duration}</td>
//                   <td>{override.original_max}</td>
//                   <td>{override.original_duration}</td>
//                   <td>
//                     <Button content="Delete" onClick={_ => this.deleteOverride(override.email)} />
//                   </td>
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </Segment>
//     );
//   }
// }

export default APILimitOverrides;
