import React from 'react';
import {
  // Brands
  AmazonCircleFilled,
  FacebookFilled,
  InstagramFilled,
  TwitterCircleFilled,
  YoutubeFilled,
  SpotifyFilled,
  AppleFilled,
  TikTokFilled,
  // Regular
  SearchOutlined,
  UserOutlined,
  FolderOpenOutlined,
  OrderedListOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CloseCircleFilled,
  CheckOutlined,
  PlusOutlined,
  MinusOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CalendarOutlined,
  PictureOutlined,
  ManOutlined,
  FlagOutlined,
  TagOutlined,
  MailOutlined,
  MobileOutlined,
  IdcardOutlined,
  CheckCircleFilled,
  DatabaseOutlined,
  SendOutlined,
  UndoOutlined,
  HomeOutlined,
  MinusCircleOutlined,
  PaperClipOutlined,
  HeartOutlined,
  CreditCardOutlined,
  MenuUnfoldOutlined,
  LineChartOutlined,
  LogoutOutlined,
  FileExcelOutlined,
  ToTopOutlined,
  NumberOutlined,
  ClockCircleOutlined,
  EyeFilled,
  EyeInvisibleFilled,
  CopyOutlined,
  ArrowRightOutlined,
  LoginOutlined,
  QuestionCircleOutlined,
  QuestionCircleFilled,
  SaveFilled,
  EditFilled,
  LockOutlined,
  ArrowLeftOutlined,
  FullscreenExitOutlined,
  ExpandAltOutlined,
} from '@ant-design/icons';

const ICONS = {
  search: SearchOutlined,
  user: UserOutlined,
  album: FolderOpenOutlined,
  list: OrderedListOutlined,
  edit: EditOutlined,
  delete: DeleteOutlined,
  close: CloseOutlined,
  closeCircle: CloseCircleFilled,
  check: CheckOutlined,
  plus: PlusOutlined,
  minus: MinusOutlined,
  loading: LoadingOutlined,
  info: InfoCircleOutlined,
  warning: ExclamationCircleOutlined,
  success: CheckCircleOutlined,
  calendar: CalendarOutlined,
  image: PictureOutlined,
  gender: ManOutlined,
  country: FlagOutlined,
  tag: TagOutlined,
  mail: MailOutlined,
  mobile: MobileOutlined,
  id: IdcardOutlined,
  checkCircle: CheckCircleFilled,
  data: DatabaseOutlined,
  send: SendOutlined,
  undo: UndoOutlined,
  home: HomeOutlined,
  music: MinusCircleOutlined,
  paperclip: PaperClipOutlined,
  heart: HeartOutlined,
  log: LineChartOutlined,
  menuUnfold: MenuUnfoldOutlined,
  money: CreditCardOutlined,
  logout: LogoutOutlined,
  csv: FileExcelOutlined,
  toTop: ToTopOutlined,
  number: NumberOutlined,
  clock: ClockCircleOutlined,
  eyeEnable: EyeFilled,
  eyeDisable: EyeInvisibleFilled,
  copy: CopyOutlined,
  login: LoginOutlined,
  amazon: AmazonCircleFilled,
  facebook: FacebookFilled,
  instagram: InstagramFilled,
  twitter: TwitterCircleFilled,
  youtube: YoutubeFilled,
  spotify: SpotifyFilled,
  itunes: AppleFilled,
  tiktok: TikTokFilled,
  questionCircle: QuestionCircleFilled,
  save: SaveFilled,
  editFilled: EditFilled,
  lockedOut: LockOutlined,
  leftArrow: ArrowLeftOutlined,
  rightArrow: ArrowRightOutlined,
  fold: FullscreenExitOutlined,
  unfold: ExpandAltOutlined,
};

export type ANTD_ICON_TYPES = keyof typeof ICONS;

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  name: ANTD_ICON_TYPES;
  color?: string;
  size?: number;
  loading?: boolean;
}


/**
 * @deprecated use FAIcon
 */
function AntdIcon({ name, color, size, onClick, loading, id, ...rest }: Props) {
  const Icon = ICONS[name];

  return ICONS[name] ? (
    <Icon
      {...rest}
      id={id}
      spin={loading}
      size={size}
      onClick={onClick}
      style={{
        cursor: onClick ? 'pointer' : 'default',
        color,
      }}
    />
  ) : null;
}

/**
 * @Deprecated
 */
export default React.memo(AntdIcon);
