import { useRequestTable } from '@Hooks';
import { useGetTrackArtistRequest, useSubmitTrackArtistRequest } from '@Services/request';
import { RequestSubmitButton, SearchInput } from '@Shared';
import { AntdIcon, DataTable, Panel } from '@Shared/Style';
import { generateCmUrl, getDate } from '@Utils';
import { Badge, Checkbox, Divider, Flex, Radio } from 'antd';
import React from 'react';

function TrackArtistRequest() {
  const {
    data,
    isLoading,
    state,
    onClickApprove,
    onClickDecline,
    onClickSelectApproveAll,
    onClickSelectDeclineAll,
    onSearch,
    selected,
    isSubmitting,
    submit,
  } = useRequestTable(useGetTrackArtistRequest, useSubmitTrackArtistRequest);

  const handleSubmit = () =>
    submit(
      (id, status) => ({ id, process: status }),
      (approves, declines) => ({ data: { requests: [...approves, ...declines] } })
    );

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      width: 50,
    },
    {
      Header: 'Track',
      accessor: 'cm_track',
      Cell: (props: any) => (
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={generateCmUrl('track', { id: props.original.cm_track })}
        >
          {props.original.track_name}
        </a>
      ),
    },
    {
      Header: 'Old Artists',
      accessor: 'old_artists',
      Cell: ({ value }) =>
        value?.map(({ id, name }, index) => (
          <>
            <a
              key={id}
              target="_blank"
              rel="noreferrer noopener"
              href={generateCmUrl('artist', { id })}
            >
              {name}
            </a>
            {index !== value.length - 1 && ', '}
          </>
        )),
    },
    {
      Header: (
        <span>
          <AntdIcon name="plus" />
          Add Artists
        </span>
      ),
      accessor: 'add_artists',
      Cell: ({ value }) =>
        value?.map(({ id, name }, index) => (
          <>
            <a
              key={id}
              target="_blank"
              rel="noreferrer noopener"
              href={generateCmUrl('artist', { id })}
            >
              {name}
            </a>
            {index !== value.length - 1 && ', '}
          </>
        )),
    },
    {
      Header: (
        <span>
          <AntdIcon name="delete" />
          Delete Artists
        </span>
      ),
      accessor: 'delete_artists',
      Cell: ({ value }) =>
        value?.map(({ id, name }, index) => (
          <>
            <a
              key={id}
              target="_blank"
              rel="noreferrer noopener"
              href={generateCmUrl('artist', { id })}
            >
              {name}
            </a>
            {index !== value.length - 1 && ', '}
          </>
        )),
    },
    {
      Header: 'Requested By',
      accessor: 'user_email',
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      Cell: ({ value }) => getDate(value),
      width: 100,
    },
    {
      Header: () => (
        <Flex justify="center" align="center" gap="small">
          Approve
          <Checkbox
            checked={state.approves.length === data?.length && data.length > 0}
            onClick={onClickSelectApproveAll}
          />
        </Flex>
      ),
      Cell: (props: any) => (
        <Radio
          id={`approve-${props.original.id}`}
          checked={state.approves.includes(props.original.id)}
          onClick={() => onClickApprove(props.original.id)}
        />
      ),
      width: 100,
    },
    {
      Header: () => (
        <Flex justify="center" align="center" gap="small">
          Decline
          <Checkbox
            checked={state.declines.length === data?.length && data.length > 0}
            onClick={onClickSelectDeclineAll}
          />
        </Flex>
      ),
      Cell: (props: any) => (
        <Radio
          id={`decline-${props.original.id}`}
          checked={state.declines.includes(props.original.id)}
          onClick={() => onClickDecline(props.original.id)}
        />
      ),
      width: 100,
    },
  ];

  return (
    <Panel
      extra={<SearchInput onChange={onSearch} originalData={data} />}
      title={
        <Flex align="center" gap="small">
          Track&apos;s Artist Change Request
          <Badge count={data?.length ?? '0'} color="blue" overflowCount={9999} />
        </Flex>
      }
      description="Temporary disabled request from web app."
    >
      <DataTable columns={columns} data={state.filteredData} loading={isLoading} />
      <Divider />
      <Flex justify="center">
        <RequestSubmitButton
          //   @ts-ignore
          columns={columns.slice(0, columns.length - 3)}
          onClickSubmit={handleSubmit}
          approveRequest={state.approves}
          declineRequest={state.declines}
          data={data ?? []}
          loading={isSubmitting}
          selected={selected}
        />
      </Flex>
    </Panel>
  );
}

export default TrackArtistRequest;
