import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Button,
  Divider,
  Input,
  Label,
  Segment,
  Dropdown,
  Checkbox,
} from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactTable from 'react-table';
import * as actions from '../../../actions';
import { showErrorMessage } from '../../../utilities';
import GlobalSearch from '../../../shared/GlobalSearch/GlobalSearch';
import { Panel } from '../../../shared/Style';

const style = {
  position: 'absolute',
  height: '500px',
  left: '25%',
  top: '25%',
  marginTop: '-50px',
  marginLeft: '-100px',
};

const typeOptions = [
  { key: 'current', text: 'Current Association', value: 'current' },
  { key: 'prior', text: 'Prior Association', value: 'prior' },
];

const columns = updateAssociatedLabel => [
  {
    Header: 'Label',
    accessor: 'cm_label',
  },
  {
    Header: 'Label Id',
    accessor: 'cm_label_id',
    width: 100,
  },
  {
    Header: 'SubLabel',
    accessor: 'cm_sublabel',
  },
  {
    Header: 'SubLabel Id',
    accessor: 'cm_sublabel_id',
    width: 100,
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 80,
  },
  {
    Header: 'Hidden',
    accessor: 'hidden',
    width: 80,
    Cell: props => (
      <Checkbox
        checked={props.value}
        onChange={() => {
          updateAssociatedLabel(props.original);
        }}
      />
    ),
  },
  {
    Header: 'Manual',
    accessor: 'manual',
    width: 80,
    Cell: props => <span>{String(Boolean(props.value))}</span>,
  },
];

class LabelAssociation extends React.Component {
  state = {
    q: '',
    hidden: false,
  };

  handleChange = (e, { value, name }) => {
    this.setState({ [name]: value });
  };

  handleChangeCheckbox = (e, { checked, name }) => {
    this.setState({ [name]: checked });
  };

  handleSubmit = () => {
    const { cm_artist } = this.props.data;
    const { label, subLabel, type, hidden } = this.state;

    this.props
      .addAssociatedLabel({
        cm_artist,
        label,
        subLabel: subLabel !== '' ? subLabel : null,
        type,
        hidden,
      })
      .then(() => {
        this.handleSearch();
      });
  };

  handleSearch = q => {
    this.props.getAssociatedLabel({ q });
  };

  handleCheckLabel = (e, { value, name }) => {
    const label = name === 'label' ? this.state.label : this.state.subLabel;

    this.props.getLabel({ label }).then(({ value }) => {
      if (Object.entries(value.data).length === 0) return showErrorMessage('Label does not exist');

      this.setState({ open: true, modalType: name });
    });
  };

  handleChangeHiddenCheckbox = ({
    cm_artist,
    cm_label_id: label,
    cm_sublabel_id: subLabel,
    type,
    hidden,
    manual,
  }) => {
    this.props
      .updateAssociatedLabel({ cm_artist, label, subLabel, type, hidden: !hidden, manual })
      .then(() => {
        this.handleSearch();
      });
  };

  handleSelectLabel = (type, id) => {
    this.setState({ [type]: id, open: false });
  };

  render() {
    const { data, checkLabelData, isLoading, isLoadingCheckLabel, isFetching } = this.props;
    const { q, type, label, subLabel, hidden, open, modalType } = this.state;
    return (
      <>
        <Panel title="Modifying Associated Label Of Artist">
          <Label>Manually set current and prior labels for an artist</Label>
          <Divider hidden />
          <GlobalSearch onSubmit={this.handleSearch} types={['artist']} loading={isLoading} />
        </Panel>
        {data && (
          <Segment raised loading={isLoading}>
            <Input
              fluid
              label={{ basic: true, content: 'Artist ID' }}
              labelPosition="left"
              placeholder=""
              size="large"
              disabled
              value={data.cm_artist}
            />
            <br />
            <Input
              fluid
              action={{
                labelPosition: 'right',
                icon: 'search',
                content: 'Check',
                onClick: this.handleCheckLabel,
                loading: isLoadingCheckLabel,
                name: 'label',
              }}
              label={{ basic: true, content: 'Label' }}
              labelPosition="left"
              name="label"
              placeholder="Label ID or label name to check"
              size="large"
              onChange={this.handleChange}
              value={label}
            />
            <br />
            <Input
              fluid
              action={{
                labelPosition: 'right',
                icon: 'search',
                content: 'Check',
                onClick: this.handleCheckLabel,
                loading: isLoadingCheckLabel,
                name: 'subLabel',
              }}
              label={{ basic: true, content: 'Sub label' }}
              labelPosition="left"
              name="subLabel"
              placeholder="Label ID or label name to check"
              size="large"
              onChange={this.handleChange}
              value={subLabel}
            />
            <br />
            <Dropdown
              fluid
              selection
              placeholder="Type"
              options={typeOptions}
              onChange={this.handleChange}
              value={type}
              name="type"
            />
            <br />
            <Checkbox
              name="hidden"
              label="Set hidden"
              onChange={this.handleChangeCheckbox}
              checked={hidden}
            />
            <Divider hidden />
            <Button
              disabled={!type || !label}
              fluid
              icon="plus"
              labelPosition="right"
              loading={isFetching}
              secondary
              size="large"
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
            <Divider hidden />
            <Divider />
            <Divider hidden />
            <ReactTable data={data.labels} columns={columns(this.handleChangeHiddenCheckbox)} />
          </Segment>
        )}
        <Modal onOpen={() => this.setState({ open: false })} open={open} style={style}>
          <Modal.Header>Select label for {modalType}</Modal.Header>
          <Modal.Content>
            {Object.entries(checkLabelData).map(([id, label]) => (
              <Button
                onClick={() => {
                  this.handleSelectLabel(modalType, id);
                }}
              >
                {label.name} ({id})
              </Button>
            ))}
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => state.label;
export default connect(mapStateToProps, actions)(LabelAssociation);
