import React, { useEffect, useState } from 'react';
import { Divider, Flex } from 'antd';
import { useParams } from 'react-router-dom';
import useURLParams from '@Hooks/useURLParams';
import { useUserCognito } from '../../../services/Onesheet/user';
import { Panel, ActionInput, Table } from '../../../shared/Style';
import { capitalizeEveryWord, replaceCapitalize } from '../../../utilities';
import useStates from '../../../hooks/useStates';

const SUPPORT_TO_EDIT = ['custom:sheetLimit'];

function EditComponent({
  value,
  onChange,
  id,
  onSubmit,
  loading,
}: {
  value: string | number;
  onChange: (value: any) => void;
  id: string;
  onSubmit: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
}) {
  return (
    <ActionInput
      id={id}
      value={value}
      onChange={e => {
        onChange({
          [id]: e.target.value,
        });
      }}
      action={{
        icon: 'farEdit',
        content: 'Edit',
        onClick: onSubmit,
        loading,
      }}
      placeholder="Enter new value"
      type="number"
    />
  );
}

function UserProfile() {
  const { fetch: getUser, update: updateUser, data, fetching, requesting } = useUserCognito();
  const { states, setAllStates } = useStates<{ sheetLimit?: number }>({});

  const { setParams, params } = useURLParams({
    qs: value => {
      getUser(value);
    },
  });

  const onClickSearch = e => {
    setParams({ qs: e.target.value });
  };

  const onClickUpdate = e => {
    updateUser({ [e.target.id]: states[e.target.id] });
  };

  const getLabel = (name: string) =>
    capitalizeEveryWord(replaceCapitalize(name).replace('custom:', '').replace('_', ' '));

  const getKey = (name: string) =>
    replaceCapitalize(name, '').replace('custom:', '').replace('_', '');

  const getTooltip = (name: string) =>
    ({
      'custom:sheetLimit': 'This is not updating their Stripe account',
    }[name]);

  return (
    <>
      <ActionInput
        id="user-email"
        action={{
          icon: 'farSearch',
          content: 'Search',
          color: 'green',
          onClick: onClickSearch,
          disabledEmpty: true,
          loading: fetching,
        }}
        defaultValue={params.qs}
        placeholder="User Email"
        size="large"
        icon="farSearch"
        variant="filled"
        className="top-search-box"
      />

      <Divider />
      {data && (
        <Table
          panelStyle
          title="User Profile"
          options={[
            {
              label: 'Name',
              value: data.Username,
            },
            ...(data?.Attributes?.map(({ Name, Value }) =>
              SUPPORT_TO_EDIT.includes(Name)
                ? {
                    label: getLabel(Name),
                    tooltip: getTooltip(Name),
                    component: (
                      <EditComponent
                        onSubmit={onClickUpdate}
                        value={states[getKey(Name)] ?? Value}
                        id={getKey(Name)}
                        onChange={setAllStates}
                        loading={requesting[getKey(Name)]}
                      />
                    ),
                  }
                : {
                    label: getLabel(Name),
                    tooltip: getTooltip(Name),
                    value: Value,
                  }
            ).sort((a, b) => a.label.localeCompare(b.label, 'en', { sensitivity: 'base' })) || []),
            {
              label: 'Status',
              value: data.UserStatus,
            },
            {
              label: 'Created',
              value: data.UserCreateDate && new Date(data.UserCreateDate).toLocaleString(),
            },
            {
              label: 'Last Modified',
              value:
                data.UserLastModifiedDate && new Date(data.UserLastModifiedDate).toLocaleString(),
            },
            {
              label: 'Enabled',
              value: String(data.Enabled),
            },
          ]}
        />
      )}
    </>
  );
}

export default UserProfile;
