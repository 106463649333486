import React from 'react';
import { connect } from 'react-redux';
import { Button, Table, Input, Segment, Card, Divider } from 'semantic-ui-react';

import * as actions from '../../../actions';

class AddNewArtist extends React.Component {
  state = {
    artistName: '',
    imageUrl: '',
    showMainPanel: false,
  };

  handleArtistNameChange = (e, { value }) => this.setState({ artistName: value });

  handleImageUrlChange = (e, { value }) => this.setState({ imageUrl: value });

  handleButtonClick = () => {
    const { artistName, imageUrl } = this.state;
    const data = { name: artistName, image_url: imageUrl };
    this.props.addNewArtist(data);
  };

  render() {
    const { response, info } = this.props;
    const { artistName, imageUrl, showMainPanel } = this.state;

    return (
      <Segment raised>
        <h3>Add New Artist</h3>
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>
                <Input
                  name="artistName"
                  onChange={this.handleArtistNameChange}
                  placeholder="Artist's Name."
                  size="large"
                  value={artistName}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Image URL</Table.Cell>
              <Table.Cell>
                <Input
                  fluid
                  name="imageUrl"
                  onChange={this.handleImageUrlChange}
                  placeholder="Image URL."
                  size="large"
                  value={imageUrl}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Button
          disabled={!artistName || !imageUrl}
          className="blue"
          loading={response.isFetching}
          onClick={this.handleButtonClick}
        >
          Submit
        </Button>
        {response.data && (
          <Card.Description>
            <Divider hidden />

            <div>
              <strong>Artist ID:</strong> {response.data.artistId}
            </div>
            <div>
              <strong>Artist Link:</strong> {response.data.artistLink}
            </div>
          </Card.Description>
        )}
      </Segment>
    );
  }
}

const mapStateToProps = state => ({ response: state.metadata.add });
export default connect(mapStateToProps, actions)(AddNewArtist);
