import {
  FETCH_ARTIST_IDENTITY_CHANGE_REQUEST_FULFILLED,
  BULK_UPDATE_ARTIST_IDENTITY_CHANGE_REQUEST_FULFILLED,
  BULK_UPDATE_ARTIST_IDENTITY_CHANGE_REQUEST_PENDING,
  FETCH_ARTIST_IDENTITY_CHANGE_REQUEST_PENDING,
} from './types';

const INITIAL_STATE = {
  isFetching: false,
  artistIdentityRequestsChange: [],
};

const artistIdentityRequests = (state = INITIAL_STATE.artistIdentityRequestsChange, action) => {
  switch (action.type) {
    case FETCH_ARTIST_IDENTITY_CHANGE_REQUEST_FULFILLED:
    case BULK_UPDATE_ARTIST_IDENTITY_CHANGE_REQUEST_FULFILLED:
      return {
        ...state,
        isFetching: false,
        artistIdentityRequestsChange: action.payload.data,
      };
    case BULK_UPDATE_ARTIST_IDENTITY_CHANGE_REQUEST_PENDING:
    case FETCH_ARTIST_IDENTITY_CHANGE_REQUEST_PENDING:
      return {
        ...state,
        isFetching: true,
        artistIdentityRequestsChange: [],
      };
    default:
      return state;
  }
};

export default artistIdentityRequests;
