import { mutateToUrl, persistGetToUrl } from './helpers';

const ENDPOINT = {
  LINK_COMPLETENESS: `/admin/link-completeness/:target`,
  CHECK_LINK_COMPLETENESS: '/admin/link-completeness/:target/:targetId/check',
  LINK_COMPLETENESS_STATISTICS: '/admin/link-completeness/:target/statistics',
  LINK_COMPLETENESS_STATISTICS_HISTORY: '/admin/link-completeness/:target/statistics/history',
};

type LinkItem = {
  id: number;
  url: string[];
  active: boolean;
  modified_at: string;
  domain: string;
  modifier_email: string;
  is_verified: boolean;
  status: string;
  target_id: number;
};
export type NoteItem = {
  id: number;
  user_info: number;
  user_email: string;
  body: string;
  timestp: string;
  target: 'cm_artist' | 'cm_track';
  target_id: number;
  note_date: string;
};
export type LinkCompletenessItem = {
  id: number;
  name: string;
  tier: number;
  image_url: string;
  cm_artist_rank: number;
  cm_artist_score: number;
  code2: string;
  isrc: string;
  links: {
    instagram: LinkItem;
    youtube: LinkItem;
    spotify: LinkItem;
    pandora: LinkItem;
    shazam: LinkItem;
  };
  artists: { name: string; id: number }[];
  complete?: boolean;
  completed_at?: string;
  notes: NoteItem[];
};

type Tier = (1 | 2 | 3 | 4 | 5)[];

export type LinkCompletenessOptions = {
  tier?: Tier;
  completed?: boolean;
  hasSpotifyURL?: boolean;
  hasPandoraURL?: boolean;
  hasInstagramURL?: boolean;
  hasTiktokURL?: boolean;
  hasYoutubeURL?: boolean;
  hasShazamURL?: boolean;
};

export const getLinkCompletenessCacheKey = (params: any) => [
  'LINK_COMPLETENESS',
  params.data,
  params.path.target,
];

export type getLinkCompletenessResponse =
  | {
      data: LinkCompletenessItem[];
      total: number;
      offset: number;
    }
  | null
  | undefined;

export const useGetLinkCompleteness = persistGetToUrl<
  {
    path: {
      target: 'artist' | 'track';
    };
    data: LinkCompletenessOptions & {
      limit: number;
      offset: number;
    };
  },
  getLinkCompletenessResponse
>(ENDPOINT.LINK_COMPLETENESS, {
  parse: data => data.data,
  key: getLinkCompletenessCacheKey,
});

export const useCheckLinkCompleteness = mutateToUrl<{
  path: {
    target: 'artist' | 'track';
    targetId: number;
  };
  data: {
    status: boolean;
  };
}>('PATCH', ENDPOINT.CHECK_LINK_COMPLETENESS);

export const useGetLinkCompletenessStatistics = persistGetToUrl<
  {
    path: {
      target: 'artist' | 'track';
    };
    data: { tier?: Tier };
  },
  {
    overallCount: number;
    completedCount: number;
    noSpotifyURLCount: number;
    noInstagramURLCount: number;
    noTiktokURLCount: number;
    noShazamURLCount: number;
    noPandoraURLCount: number;
    noYoutubeURLCount: number;
  }
>(ENDPOINT.LINK_COMPLETENESS_STATISTICS);

export interface LinkCompletenessStatisticsHistoryResponse {
  spotify_count: number;
  instagram_count: number;
  tiktok_count: number;
  shazam_count: number;
  pandora_count: number;
  youtube_count: number;
  total: number;
}
[];

export const useGetLinkCompletenessStatisticsHistory = persistGetToUrl<
  {
    path: {
      target: 'artist' | 'track';
    };
    data: {
      tier?: Tier;
      fromDaysAgo: number;
    };
  },
  LinkCompletenessStatisticsHistoryResponse
>(ENDPOINT.LINK_COMPLETENESS_STATISTICS_HISTORY);
