import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { useGetClaimedEntityUsers } from '@Services/meta';
import AntdTable from '../../../../shared/Table/AntdTable';
import { TARGET_MAPPER } from '../../../../utilities/constants';
import { AntdIcon } from '../../../../shared/Style';
import { getDateFormat } from '../../../../utilities';

const USER_LIST_MODAL_COLUMNS = [
  {
    Header: 'User Email',
    sorter: true,
    accessor: 'email',
  },
  {
    Header: 'Register At',
    accessor: 'register_date',
    sorter: true,
    Cell: ({ value }) => <div>{getDateFormat(value)}</div>,
  },
];

function ClaimedEntityUsersModal({ original }) {
  const { data, refetch, isLoading } = useGetClaimedEntityUsers(
    {
      data: {
        target: original.target,
        targetId: original.id,
      },
    },
    { disable: !original }
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) refetch();
  }, [open]);

  return (
    <>
      <Button
        className="green"
        onClick={() => {
          setOpen(true);
        }}
        icon={<AntdIcon name="list" />}
      />
      <Modal
        style={{
          minWidth: '98vw',
        }}
        open={open}
        title={`Claimed Users for ${TARGET_MAPPER[original.target]} ${original.name}`}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        okText="Close"
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <AntdTable columns={USER_LIST_MODAL_COLUMNS} data={data ?? []} loading={isLoading} />
      </Modal>
    </>
  );
}

export default ClaimedEntityUsersModal;
