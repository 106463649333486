import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Header, Input, Divider, Button, Icon, Card } from 'semantic-ui-react';
import * as actions from '../../../actions';
import { showErrorMessage } from '../../../utilities';

class ClearCachePanel extends Component {
  state = {
    key: '',
    cacheValue: '',
    showCache: false,
    isFetching: true,
  };

  // handle change lookup key
  onChange = (_, { value }) => {
    this.setState({ key: value });
  };

  // handle look up cache key value
  handleLookup = () => {
    if (this.state.key === '') {
      showErrorMessage('Cache Key Cannot Be Empty.');
      this.setState({ showCache: false });
    } else {
      console.log('handling cache key lookup');
      this.props.getCacheKey(this.state.key).then(this.setState({ showCache: true }));
    }
  };

  // handle delete cache key value
  handleDeleteCache = key => {
    this.setState({
      isFetching: true,
    });
    console.log('handleDeleteCache');
    this.props.deleteCacheKey(key).then(this.setState({ showCache: false }));
  };

  render() {
    const { key, cacheValue, isFetching } = this.state;
    const { response } = this.props;

    return (
      <Segment raised>
        <Input
          fluid
          action={{
            labelPosition: 'right',
            icon: 'search',
            content: 'Lookup',
            onClick: () => this.handleLookup(this.state.key),
            loading: isFetching,
          }}
          size="large"
          placeholder='Enter a Redis cache key e.g. Track: GetBasicInfoV6{"0":"cm","1":"81299652"}'
          value={key}
          onChange={this.onChange}
        />
        <Divider hidden />
        {this.props.response && this.state.showCache && (
          <div>
            <Divider hidden />
            <Divider hidden />
            <Card fluid>
              <Card.Content textAlign="center">
                <Card.Header>Cached Value</Card.Header>
                <Card.Description>
                  <textarea
                    style={{
                      width: '100%',
                      height: '400px',
                      fontFamily: 'DejaVu Sans Mono, monospace',
                    }}
                    value={JSON.stringify(response, null, 2)}
                    onChange={() =>
                      this.setState({ cacheValue: JSON.stringify(response, null, 2) })
                    }
                  />
                </Card.Description>
              </Card.Content>
            </Card>
            <Button
              loading={isFetching}
              content="Delete?"
              fluid
              onClick={() => this.handleDeleteCache(this.state.key)}
            />
          </div>
        )}
      </Segment>
    );
  }
}

const mapStateToProps = state => ({ response: state.cache.cacheDelete.data });
export default connect(mapStateToProps, actions)(ClearCachePanel);
