import { showErrorMessage, showInfoMessage } from '../utilities';
import {
  ADD_TRIAL_SUBSCRIPTION_FULFILLED,
  ADD_TRIAL_SUBSCRIPTION_PENDING,
  ADD_TRIAL_SUBSCRIPTION_REJECTED,
  CLEAN_UP_STRIPE_FULFILLED,
  CLEAN_UP_STRIPE_PENDING,
  CLEAN_UP_STRIPE_REJECTED,
  SEND_EMAIL_REPORT_FULFILLED,
  SEND_EMAIL_REPORT_PENDING,
  SEND_EMAIL_REPORT_REJECTED,
  TRANSFER_FAVORITES_FULFILLED,
  TRANSFER_FAVORITES_PENDING,
  TRANSFER_FAVORITES_REJECTED,
} from './types';

const INITIAL_STATE = {
  isProceeding: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_TRIAL_SUBSCRIPTION_PENDING:
    case SEND_EMAIL_REPORT_PENDING:
    case TRANSFER_FAVORITES_PENDING:
    case CLEAN_UP_STRIPE_PENDING:
      return {
        isProceeding: true,
      };
    case ADD_TRIAL_SUBSCRIPTION_FULFILLED:
    case SEND_EMAIL_REPORT_FULFILLED:
    case TRANSFER_FAVORITES_FULFILLED:
    case CLEAN_UP_STRIPE_FULFILLED:
      showInfoMessage(action.payload.data ?? action.payload.message);
      return {
        isProceeding: false,
      };
    case ADD_TRIAL_SUBSCRIPTION_REJECTED:
    case SEND_EMAIL_REPORT_REJECTED:
    case TRANSFER_FAVORITES_REJECTED:
    case CLEAN_UP_STRIPE_REJECTED:
      return {
        isProceeding: false,
      };
    default:
      return state;
  }
};
