import { mutateToUrl, persistGetToUrl } from './helpers';

const ENDPOINT = {
  CLAIMED: {
    LIST: '/admin/claimed/list',
    USERS: '/admin/claimed/users',
  },
  SNS: {
    VERIFY: '/sns/verify-single',
  },
  ARTIST: {
    LINKING: '/admin/artist/linking',
    EDIT: '/admin/artist',
  },
  TRACK: {
    LINKING: '/admin/track/linking',
  },
  ALBUM: {
    LINKING: '/admin/album/linking',
    EDIT: '/admin/album',
  },
  TAGS: {
    UPDATE: '/admin/:entity/:id/tag',
    GENRE: '/artist/genreTags/:type',
    ARTIST: '/artist/tags',
  },
  CITY: {
    UPDATE: '/artist/:id/countryCity',
  },
  AUTHENTICITY_RISK: {
    UPDATE: '/admin/:target/:id/authenticity-risk',
  },
};

export const useUpdateArtist = mutateToUrl<{
  query: {
    id: number;
  };
  data: FormData;
}>('PUT', ENDPOINT.ARTIST.EDIT);

export const useUpdateArtistCountryAndCity = mutateToUrl<{
  path: {
    id: number;
  };
  data: {
    code2: string;
    cityId: number;
  };
}>('PUT', ENDPOINT.CITY.UPDATE);

export const useGetClaimedEntities = persistGetToUrl<{
  data: {
    limit: number;
    offset: number;
    target: 'cm_artist';
    search?: string;
  };
}>(ENDPOINT.CLAIMED.LIST);

export const useGetClaimedEntityUsers = persistGetToUrl<{
  data: {
    target: string;
    targetId: number;
  };
}>(ENDPOINT.CLAIMED.USERS, {
  manual: true,
});

export const useUpdateAlbum = mutateToUrl<{
  query: {
    id: number;
  };
  data: {
    label: string;
  };
}>('PUT', ENDPOINT.ALBUM.EDIT);

export const AUTHENTICITY_RISK_PLATFORM_MAP = {
  tiktok_followers: 'TikTok',
  ins_followers: 'Instagram',
  sp_followers: 'Spotify',
  deezer_fans: 'Deezer',
  soundcloud_followers: 'Soundcloud',
  vcs_subscribers: 'YouTube',
  normalized_suspicion_level: 'Suspicious',
} satisfies Record<string, string>;

export type AuthenticityRiskKeys = keyof typeof AUTHENTICITY_RISK_PLATFORM_MAP;

export const useUpdateAuthenticityRisk = mutateToUrl<{
  path: {
    target: 'cm_artist' | 'spotify_curator';
    id: number;
  };
  data: {
    values: {
      [key in AuthenticityRiskKeys]: number;
    };
  };
}>('PUT', ENDPOINT.AUTHENTICITY_RISK.UPDATE);
