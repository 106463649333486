import { useGetTeam } from '@Services/Onesheet/team';
import { ActionInput } from '@Shared/Style';
import { Divider } from 'antd';
import React from 'react';
import useURLParams from '@Hooks/useURLParams';
import Team from './components/Team';

function SearchTeam() {
  const { search, searchData, loading } = useGetTeam();

  const { params, setParams } = useURLParams({
    qts: value => search(value),
  });

  return (
    <>
      <ActionInput
        action={{
          icon: 'farSearch',
          onClick: e => {
            setParams({ qts: e.target.value });
          },
          content: 'Search',
          disabledEmpty: true,
          loading,
        }}
        defaultValue={params.qts}
        variant="filled"
        size="large"
        placeholder="Search Team Name or ID"
        icon="farSearch"
        className="top-search-box"
      />
      <Divider />
      {searchData?.map(data => (
        <Team key={data.id} teamId={data.id} title={data.name} />
      ))}
    </>
  );
}

export default SearchTeam;
