import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useURLParams = (query?: Record<string, (param: string) => void>) => {
  const [urlParams, setURLParams] = useState<URLSearchParams>(
    new URLSearchParams(window.location.search)
  );

  const history = useHistory();

  useEffect(() => {
    setParams(urlSearchParamsToRecord(urlParams));
  }, [window.location.search]);


  function urlSearchParamsToRecord(searchParams?: URLSearchParams): Record<string, string> {
    const record = {};
    if (!searchParams) return record;
    // @ts-ignore
    for (const [key, value] of searchParams.entries()) {
      record[key] = value;
    }
    return record;
  }

  useEffect(() => {
    if (!query) return;
    Object.entries(query).forEach(([key, value]) => {
      const param = urlParams.get(key);
      if (param) {
        value(param);
      }
    });
  }, [urlParams]);

  const setParams = (params: Record<string, string>) => {
    const search = new URLSearchParams();
    const hash = window.location.hash;
    console.log(window.history.state);
    Object.entries(params).forEach(([key, value]) => {
      search.set(key, value);
    });
    setURLParams(search);
    window.history.pushState({}, '', `?${search.toString()}`);
    history.push(window.location.pathname + window.location.search + hash);
  };

  return { setParams, params: urlSearchParamsToRecord(urlParams) };
};

export default useURLParams;
