import React from 'react';
import { Tabs } from '../../../shared/Style';
import ClaimRequest from './Request';
import ClaimList from './List';

function Claim() {
  return (
    <Tabs
      tabs={[
        {
          title: 'Claim Request',
          content: <ClaimRequest />,
        },
        {
          title: 'Claimed Artists',
          content: <ClaimList />,
        },
      ]}
    />
  );
}

export default Claim;
