import {
  FETCH_DSP_LINKING_REQUEST_FULFILLED,
  BULK_UPDATE_DSP_LINKING_REQUEST_FULFILLED,
  BULK_UPDATE_DSP_LINKING_REQUEST_PENDING,
  FETCH_DSP_LINKING_REQUEST_PENDING,
} from './types';

const INITIAL_STATE = {
  isFetching: false,
  dspLinkingRequest: [],
};

const dspRequests = (state = INITIAL_STATE.dspLinkingRequest, action) => {
  switch (action.type) {
    case BULK_UPDATE_DSP_LINKING_REQUEST_PENDING:
    case FETCH_DSP_LINKING_REQUEST_PENDING:
      return {
        ...state,
        isFetching: true,
        dspLinkingRequest: [],
      };
    case BULK_UPDATE_DSP_LINKING_REQUEST_FULFILLED:
    case FETCH_DSP_LINKING_REQUEST_FULFILLED:
      return {
        ...state,
        isFetching: false,
        dspLinkingRequest: action.payload.data,
      };
    default:
      return state;
  }
};

export default dspRequests;
