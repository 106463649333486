import { showInfoMessage, showErrorMessage } from '../utilities';
import { SEARCH_CM_DATA_PENDING, SEARCH_CM_DATA_FULFILLED, SEARCH_CM_DATA_REJECTED } from './types';

const INITIAL_STATE = {
  data: {},
  isFetching: false,
  error: null,
};

const MESSAGES = {
  ERROR: 'Something Wrong With Search (Please Contact Chartmetric Team).',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_CM_DATA_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case SEARCH_CM_DATA_FULFILLED: {
      const { data } = action.payload;

      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      return {
        data,
        isFetching: false,
      };
    }
    case SEARCH_CM_DATA_REJECTED:
      showErrorMessage(action.payload?.data?.error || action.payload?.error);
      return {
        error: action.payload?.data?.error,
        isFetching: false,
      };
    default:
      return state;
  }
};
