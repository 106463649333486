import { SuggestionItem } from '@Services/system';
import Country from '@Shared/Style/Country';
import { List, Skeleton, Avatar, Flex } from 'antd';
import React from 'react';
import { G_CLOUD_STORAGE } from 'resource';
import styles from '../GlobalSearch.module.scss';
import { AntdIcon } from '@Shared/Style';
import { HUMAN_ENTITY } from '@Constants/entity/index';

interface Props {
  list: SuggestionItem[];
  type: string;
  isLoading: boolean;
  platform?: string;
  onClick: (type: string, id: number, platform?: string) => (e: any) => void;
}

function ItemList({ list, type, isLoading, onClick, platform }: Props) {
  return (
    <List<SuggestionItem>
      itemLayout="vertical"
      dataSource={
        isLoading ? (Array.from({ length: 5 }).map((_, i) => ({ key: type + i })) as any[]) : list
      }
      renderItem={(item, index) => (
        <Skeleton className={styles.skeletonItem} loading={isLoading} active avatar>
          <List.Item key={type + index} onClick={onClick(type, item?.id, platform)}>
            <List.Item.Meta
              className={styles.listItem}
              avatar={
                <Avatar
                  shape={HUMAN_ENTITY.includes(type) ? 'circle' : 'square'}
                  size="large"
                  src={item.imageUrl ?? G_CLOUD_STORAGE.COVER_IMG_FALLBACK}
                />
              }
              title={
                <Flex
                  gap={4}
                  style={{
                    textAlign: 'left',
                  }}
                >
                  {item?.name}
                  {item?.targetExtras?.verified && <AntdIcon name="checkCircle" color="blue" />}
                </Flex>
              }
              description={
                <Flex
                  align="center"
                  gap={4}
                  style={{
                    textAlign: 'left',
                  }}
                >
                  ID:{item?.id}
                  {item?.targetExtras?.code2 && (
                    <>
                      , <Country value={item.targetExtras.code2} />
                    </>
                  )}
                  {item?.targetExtras?.ownerName ? `, By ${item?.targetExtras.ownerName}` : ''}
                </Flex>
              }
            />
          </List.Item>
        </Skeleton>
      )}
    />
  );
}

export default ItemList;
