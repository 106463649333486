import axios from 'axios';
import Promise from 'bluebird';
import qs from 'qs';
import ms from 'ms';
import { showErrorMessage } from '../utilities/index';
import { API_BASED_URLs } from '../services/helpers';

/*
  Constants
 */


const API_BASED_URL = API_BASED_URLs[process.env.REACT_APP_ENV || process.env.NODE_ENV];

const API_ENDPOINTS = {
  CREATE_PORTAL_STRIPE_SESSION: `/stripe/portal`,
  LOGIN: '/admin/login',
  LOGOUT: '/admin/logout',
  USER: '/admin/user',
  AUTH: '/user/auth',
  GET_SUGGESTIONS: args => `/search/suggestion?limit=100&offset=0&${qs.stringify(args)}`,
  GET_SUGGESTIONS_USER: args => `/admin/suggestion/user?limit=100&offset=0&${qs.stringify(args)}`,
  USER_FOLLOWED_ARTISTS: id => `/admin/user/${id}/followed/artists`,
  USER_VIEWABLE_ARTISTS: id => `/admin/user/${id}/viewable/artists`,
  SEARCH: q => `/admin/search?${qs.stringify({ q })}`,
  ARTIST_TRACKS: q => `/admin/artist/tracks?${qs.stringify({ q })}`,
  UPDATE_ARTIST_TRACKS: `/admin/artist/tracks/update`,
  ARTIST_ALBUMS: q => `/admin/artist/albums?${qs.stringify({ q })}`,
  UPDATE_ARTIST_ALBUMS: `/admin/artist/albums/update`,
  PLAYLIST: (streamingType, id, storefront) =>
    `/admin/playlist?${qs.stringify({ streamingType, id, storefront })}`,
  GET_CANCELLATIONS: `/admin/getCancellations`,
  DELETE_PLAYLIST: (streamingType, id) =>
    `/admin/deletePlaylist?${qs.stringify({ streamingType, id })}`,
  ARTIST: id => (id ? `/admin/artist?${qs.stringify({ id })}` : '/admin/artist'),
  ALBUM: id => (id ? `/admin/album?${qs.stringify({ id })}` : '/admin/album'),
  ALBUM_LINKING: id => `/admin/album/linking?${id ? qs.stringify({ id }) : ''}`,
  ARTIST_LINKING: id => `/admin/artist/linking?${id ? qs.stringify({ id }) : ''}`,
  TRACK_LINKING: id => `/admin/track/linking?${id ? qs.stringify({ id }) : ''}`,
  ADD_SIMILAR_TRACK: (id, trackId) =>
    `/admin/similarTracks?${qs.stringify({ trackId1: id, trackId2: trackId })}`,
  DELETE_SIMILAR_TRACK: (id, trackId, hidden) =>
    `/admin/similarTracks?${qs.stringify({
      trackId1: id,
      trackId2: trackId,
      hidden,
    })}`,
  MERGE_ARTISTS: (primaryArtistUrl, secondaryArtistUrl) =>
    `/admin/merge-artists?${qs.stringify({
      primaryArtistUrl,
      secondaryArtistUrl,
    })}`,
  GRANT_API_ACCESS: q => `/admin/api-access?${qs.stringify({ q })}`,
  REMOVE_API_ACCESS: q => `/admin/remove-api-access?${qs.stringify({ q })}`,
  REMOVE_SSO_LOGIN: q => `/admin/remove-sso-login?${qs.stringify({ q })}`,
  VERIFY_USER_EMAIL: q => `/admin/verify-user-email?${qs.stringify({ q })}`,
  TRUSTED_USER: '/admin/trusted-user',
  ADMIN_USER: '/admin/admin-user',
  MERGE_SOCIAL_STAT: (mediaType, primaryId, secondaryId) =>
    `/admin/merge-social-stat?${qs.stringify({
      mediaType,
      primaryId,
      secondaryId,
    })}`,
  ADD_NEW_ARTIST: `/admin/artist/new`,
  ADD_NEW_TRACK: `/admin/track/new`,
  EDIT_LABEL_GROUP: (parentLabel, subLabel) =>
    `/admin/edit-label-group?${qs.stringify({
      parentLabel,
      subLabel,
    })}`,
  GET_LABEL_GROUP: label =>
    `/admin/get-label-group?${qs.stringify({
      label,
    })}`,
  RESET_USER_PASSWORD: q => `/admin/reset-user-password?${qs.stringify({ q })}`,
  TEAM_MANAGEMENT: {
    MEMBERS: `/admin/teamMembers`,
    MANAGERS: `/admin/teamManagers`,
    MANAGER: '/admin/teamManager',
  },
  FOLLOW_ARTIST: (id, ids, type) => `/admin/batch-follow?${qs.stringify({ id, ids, type })}`,
  COUPON: ({ id, expired }) =>
    id ? `/admin/coupon?${qs.stringify({ id })}` : `/admin/coupon?${qs.stringify({ expired })}`,
  FREE_TRIAL: '/admin/free-trial',
  BLOCKLIST: q => `/admin/blocklist?${qs.stringify({ q })}`,
  API_USAGE: {
    USER: {
      STATS: `/admin/api-usage/user/stats`,
      DETAILS: `/admin/api-usage/user/details`,
    },
    RECENT_STATS: since => `/admin/api-usage/stats?${qs.stringify({ since })}`,
  },
  POWER_USER_CURVE: fromDaysAgo => `/admin/powerUserCurve?${qs.stringify({ fromDaysAgo })}`,
  USER_ACTIVITIES: (mode, limit, offset) =>
    `/admin/user-activities/${mode}?${qs.stringify({ limit, offset })}`,
  MAIN_DB_TABLE_STATS: fromDaysAgo => `/admin/mainDBTableStats?${qs.stringify({ fromDaysAgo })}`,
  CM_URLS: id => `/admin/cm-url/${id}`,
  CM_URL_CHANGE_REQUEST: '/admin/cm-url/requests',
  CM_URL_CHANGE_REQUEST_BULK_UPDATE: '/admin/cm-url/requests/bulkUpdate',
  CM_DSP_LINKING_REQUEST: '/admin/links/requests',
  CM_DSP_LINKING_REQUEST_BULK_UPDATE: '/admin/links/requests/bulkUpdate',
  CM_ARTIST_IDENTITY_CHANGE_REQUEST: '/admin/artistIdentity/requests',
  CM_ARTIST_IDENTITY_CHANGE_REQUEST_BULK_UPDATE: '/admin/artistIdentity/requests/bulkUpdate',
  RESET_ARTIST_PLAN: userId => `/admin/artist-tier/selection/reset?${qs.stringify({ userId })}`,
  STRIPE_SESSION: '/stripe/session/campaign',
  STRIPE_COUPON_CODES: '/admin/stripe/coupon',
  STRIPE_PRODUCTS: '/admin/stripe/products',
  BLOCKED_DOMAINS: '/admin/blocked-domains',
  STRICT_DOMAINS: '/admin/strict-domains',
  CACHE_KEY: key => `/admin/cache?${qs.stringify({ key })}`,
  API_LIMIT_OVERRIDE: args => `/admin/api-limit${args ? `?${qs.stringify(args)}` : ''}`,
  GET_CLAIM_REQUEST: '/admin/claim-request',
  GET_CLAIM_REQUEST_STATUS: code => `/admin/claim-request/status/${code}`,
  APPROVE_CLAIM_REQUEST: id => `/admin/claim-request/accept/${id}`,
  DECLINE_CLAIM_REQUEST: id => `/admin/claim-request/deny/${id}`,
  RESEND_CLAIM_EMAIL: `/admin/email/claim-request-created`,
  GET_GENRE_REQUEST: `/admin/artist/genre-request`,
  UPDATE_GENRE_REQUEST: id => `/admin/artist/genre-request/${id}`,
  SEND_GENERAL_EMAIL_REPORT: '/admin/email-report/general',
  GET_CHURNED_USERS: '/admin/churned-users',
  UPDATE_CHURNED_USER_COMMENT: id => `/admin/churned-users/${id}/comment`,
  ADD_LABEL_CORRECTION: `/admin/label-correction`,
  GET_ASSOCIATED_LABEL: q => `/admin/label-association?${qs.stringify({ q })}`,
  ADD_ASSOCIATED_LABEL: `/admin/label-association`,
  GET_LABEL: label => `/label/search?${qs.stringify({ label })}`,
  ADD_TRIAL_SUBSCRIPTION: type => `/admin/trial-subscription/${type}`,
  GET_GENRE_TAG_LIST: type => `/artist/genreTags/${type}`,
  UPDATE_TRACK_GENRE_TAG: (id, tag) => `/admin/track/${id}/tag?${qs.stringify({ tag })}`,
  UPDATE_ARTIST_GENRE_TAG: id => `/admin/artist/${id}/tag`,
  TRANSFER_FAVORITES: `/admin/transfer/favorites`,
  CLEAN_UP_STRIPE: `/admin/clean-up-stripe`,
  ARTIST_GENRE_TAG: (id, type) => `/artist/${id}/genreTags/${type}`,
  PAGE_HISTORY_STATISTICS: daysAgo => `/admin/page-history-statistics?${qs.stringify({ daysAgo })}`,
  USER_SESSION_COUNTS: (type, email, date) =>
    `/admin/user/sessions/${type}?${qs.stringify({ email, date })}`,
  CAMPAIGN: id => (id ? `/admin/campaign/${id}` : `/admin/campaign`),
};
/*
  Helpers
 */
const AXIOS_CONFIGS = {
  baseURL: API_BASED_URL,
  withCredentials: true,
  transformResponse: [
    response => {
      try {
        const json = JSON.parse(response);
        return json;
      } catch (e) {
        return {
          error: e,
        };
      }
    },
  ],
};
const apiPublicRequestInstance = axios.create({
  ...AXIOS_CONFIGS,
});
export const apiPrivateRequestInstance = axios.create({
  ...AXIOS_CONFIGS,
});

/*
  Public Requests
*/
export const loginUserPromise = (email, password) =>
  apiPublicRequestInstance.post(API_ENDPOINTS.LOGIN, {
    email,
    password,
  });
export const logoutUserPromise = () => apiPublicRequestInstance.get(API_ENDPOINTS.LOGOUT);
export const authUserPromise = () => apiPublicRequestInstance.get(API_ENDPOINTS.AUTH);

// Config interceptor
// apiPrivateRequestInstance.interceptors.request.use(config => {
//   let originalRequest = config;
//   return getAccessToken()
//     .then(res => {
//       if (res.data) {
//         originalRequest.headers.Authorization = `Bearer ${res.data.token}`;
//       }
//       return Promise.resolve(originalRequest);
//     });
// }, (err) => {
//   return Promise.reject(err);
// });
apiPrivateRequestInstance.interceptors.response.use(
  response => response?.data ?? response,
  error => {
    if (error.response.status === 401) {
      console.log('here');
      window.location = '/login';
    }
    if (error.response.status === 303) {
      window.open(error.response.data.url, '_blank');
      return error.response;
    }
    const errorMessage = 'Something went wrong, please contact the engineering team';
    const errorResponse = error.response.data;
    showErrorMessage(errorResponse.error || errorResponse.message || errorMessage);
    return Promise.reject(errorResponse);
  }
);

/*
  Stripe
*/
export const createPortalSessionPromise = email =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.CREATE_PORTAL_STRIPE_SESSION, { email });

export const getStripeCouponsPromise = () =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.STRIPE_COUPON_CODES);

export const getStripeProductsPromise = () =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.STRIPE_PRODUCTS);

/*
  Private Requests
*/
export const getSuggestionsPromise = ({ type, q }) =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.GET_SUGGESTIONS({ type, q }));

export const getSuggestionsUserPromise = ({ q }) =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.GET_SUGGESTIONS_USER({ q }));

export const searchCMDataPromise = q => apiPrivateRequestInstance.get(API_ENDPOINTS.SEARCH(q));

export const getArtistTracksPromise = q =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.ARTIST_TRACKS(q));
export const updateArtistTracksPromise = (q, useApi) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.UPDATE_ARTIST_TRACKS, { q, useApi });
export const getArtistAlbumsPromise = q =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.ARTIST_ALBUMS(q));
export const updateArtistAlbumsPromise = q =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.UPDATE_ARTIST_ALBUMS, { q });

export const updateCMURLsPromise = (id, metadata) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.CM_URLS(id), metadata);

export const updatePlaylistPromise = (streamingType, id, storefront, metadata) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.PLAYLIST(streamingType, id, storefront), metadata);

export const updateArtistPromise = (id, metadata) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.ARTIST(id), metadata);

export const updateAlbumPromise = (id, metadata) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.ALBUM(id), metadata);

export const updateAlbumLinkingPromise = (id, q) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.ALBUM_LINKING(id), { q });

export const updateArtistLinkingPromise = (id, q, isDelete) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.ARTIST_LINKING(id), { q, isDelete });

export const updateTrackLinkingPromise = (id, target, isrc, name, q, artists) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.TRACK_LINKING(id), {
    target,
    isrc,
    name,
    q,
    artists,
  });

export const deleteSimilarTrackPromise = (id, trackId, hidden) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.DELETE_SIMILAR_TRACK(id, trackId, hidden));

export const addSimilarTrackPromise = (id, trackId) =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.ADD_SIMILAR_TRACK(id, trackId));

export const addArtistPromise = q => apiPrivateRequestInstance.post(API_ENDPOINTS.ARTIST(), { q });

export const mergeArtistsPromise = (primaryArtistUrl, secondaryArtistUrl) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.MERGE_ARTISTS(primaryArtistUrl, secondaryArtistUrl));

export const grantAPIAccessPromise = q =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.GRANT_API_ACCESS(q));

export const removeAPIAccessPromise = q =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.REMOVE_API_ACCESS(q));

export const removeSSOLoginPromise = q =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.REMOVE_SSO_LOGIN(q));

export const verifyUserEmailPromise = q =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.VERIFY_USER_EMAIL(q));

export const resetUserPasswordPromise = q =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.RESET_USER_PASSWORD(q));
export const changeUserEmailPromise = (id, email) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.CHANGE_USER_EMAIL(id, email));

export const followArtistsPromise = (id, ids, type) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.FOLLOW_ARTIST(id, ids, type));

export const getTrustedUsersPromise = () =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.TRUSTED_USER);

export const addTrustedUserPromise = q =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.TRUSTED_USER, { q });

export const deleteTrustedUserPromise = q =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.TRUSTED_USER, { q });

export const getAdminUsersPromise = () => apiPrivateRequestInstance.get(API_ENDPOINTS.ADMIN_USER);

export const addAdminUserPromise = q =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.ADMIN_USER, { q });

export const deleteAdminUserPromise = q =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.ADMIN_USER, { q });

export const resetArtistPlanSelectionPromise = userId =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.RESET_ARTIST_PLAN(userId));

export const mergeSocialStatPromise = (mediaType, primaryId, secondaryId) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.MERGE_SOCIAL_STAT(mediaType, primaryId, secondaryId));

export const addNewArtistPromise = requests =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.ADD_NEW_ARTIST, requests);

export const addNewTrackPromise = requests =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.ADD_NEW_TRACK, requests);

export const editLabelGroupPromise = (parentLabel, subLabel) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.EDIT_LABEL_GROUP(parentLabel, subLabel));

export const getLabelGroupPromise = label =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.GET_LABEL_GROUP(label));

export const deletePlaylistPromise = (streamingType, id) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.DELETE_PLAYLIST(streamingType, id));

export const getCancellationsPromise = () =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.GET_CANCELLATIONS);

export const fetchTeamManagersPromise = () =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.TEAM_MANAGEMENT.MANAGERS);
export const addTeamManagerPromise = q =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.TEAM_MANAGEMENT.MANAGER, { q });
export const deleteTeamManagerPromise = q =>
  apiPrivateRequestInstance.delete(API_ENDPOINTS.TEAM_MANAGEMENT.MANAGER, {
    params: { q },
  });
export const fetchTeamMembersPromise = q =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.TEAM_MANAGEMENT.MEMBERS, {
    params: { q },
  });
export const addTeamMemberPromise = (teamManagerUserId, memberEmail, startTime) =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.TEAM_MANAGEMENT.MEMBERS, {
    teamManagerUserId,
    memberEmail,
    startTime,
  });
export const deleteTeamMemberPromise = (teamManagerUserId, memberUserId) =>
  apiPrivateRequestInstance.delete(API_ENDPOINTS.TEAM_MANAGEMENT.MEMBERS, {
    params: { teamManagerUserId, memberUserId },
  });
export const updateTeamMemberPromise = (teamManagerUserId, memberUserId, body) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.TEAM_MANAGEMENT.MEMBERS, {
    teamManagerUserId,
    memberUserId,
    ...body,
  });

export const fetchCouponCodesPromise = expired =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.COUPON({ expired }));
export const addCouponCodePromise = body =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.COUPON({}), body);
export const deleteCouponCodePromise = id =>
  apiPrivateRequestInstance.delete(API_ENDPOINTS.COUPON({ id }));
export const updateCouponCodePromise = (id, body) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.COUPON({ id }), body);
export const applyFreeTrialPromise = (code, q) =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.FREE_TRIAL, { code, q });

export const fetchUserInfoPromise = q =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.USER, { params: { q } });
export const deleteUserPromise = q =>
  apiPrivateRequestInstance.delete(API_ENDPOINTS.USER, { params: { q } });
export const getUserFollowedArtistsPromise = id =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.USER_FOLLOWED_ARTISTS(id));
export const followArtistPromise = (id, body) =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.USER_FOLLOWED_ARTISTS(id), body);
export const unfollowArtistPromise = (id, data) =>
  apiPrivateRequestInstance.delete(API_ENDPOINTS.USER_FOLLOWED_ARTISTS(id), {
    data,
  });
export const getUserViewableArtistsPromise = id =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.USER_VIEWABLE_ARTISTS(id));
export const subscribeToArtistPromise = (id, body) =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.USER_VIEWABLE_ARTISTS(id), body);
export const unsubscribeFromArtistPromise = (id, data) =>
  apiPrivateRequestInstance.delete(API_ENDPOINTS.USER_VIEWABLE_ARTISTS(id), {
    data,
  });
export const fetchUserAPIUsageStatsPromise = (q, fromDaysAgo, toDaysAgo) =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.API_USAGE.USER.STATS, {
    params: { q, fromDaysAgo, toDaysAgo },
  });
export const fetchUserAPIUsageDetailsPromise = (q, limit, offset) =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.API_USAGE.USER.DETAILS, {
    params: { q, limit, offset },
  });
export const fetchLinksByUserDetailsPromise = (q, fromDaysAgo, toDaysAgo) =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.LINKS_BY_USER.DETAILS, {
    params: { q, fromDaysAgo, toDaysAgo },
  });
export const fetchLinksByUserHistoryPromise = (q, limit, offset) =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.LINKS_BY_USER.HISTORY, {
    params: { q, limit, offset },
  });
export const blockUserPromise = q => apiPrivateRequestInstance.post(API_ENDPOINTS.BLOCKLIST(q));
export const unblockUserPromise = q => apiPrivateRequestInstance.delete(API_ENDPOINTS.BLOCKLIST(q));
export const fetchPowerUserCurvePromise = fromDaysAgo =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.POWER_USER_CURVE(fromDaysAgo));
export const fetchUserActivitiesPromise = (mode, limit, offset) =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.USER_ACTIVITIES(mode, limit, offset));
export const fetchMainDBTableStatsPromise = fromDaysAgo =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.MAIN_DB_TABLE_STATS(fromDaysAgo));
export const fetchRecentAPIUsagePromise = since =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.API_USAGE.RECENT_STATS(since));

export const fetchURLChangeRequestPromise = () =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.CM_URL_CHANGE_REQUEST);
export const fetchDSPLinkingRequestPromise = () =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.CM_DSP_LINKING_REQUEST);

export const fetchArtistIdentityChangeRequestPromise = () =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.CM_ARTIST_IDENTITY_CHANGE_REQUEST);

export const bulkUpdateURLChangeRequestPromise = requests =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.CM_URL_CHANGE_REQUEST_BULK_UPDATE, requests);

export const bulkUpdateDSPLinkingRequestPromise = requests =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.CM_DSP_LINKING_REQUEST_BULK_UPDATE, requests);

export const bulkUpdateArtistIdentityChangeRequestPromise = requests =>
  apiPrivateRequestInstance.post(
    API_ENDPOINTS.CM_ARTIST_IDENTITY_CHANGE_REQUEST_BULK_UPDATE,
    requests
  );

export const getClaimRequestsPromise = () =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.GET_CLAIM_REQUEST);

export const getClaimRequestStatusPromise = code =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.GET_CLAIM_REQUEST_STATUS(code));

export const approveClaimRequestPromise = id =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.APPROVE_CLAIM_REQUEST(id));

export const declineClaimRequestPromise = (id, body) =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.DECLINE_CLAIM_REQUEST(id), body);

export const resendClaimEmailRequestPromise = body =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.RESEND_CLAIM_EMAIL, body);

export const stripeCreateCheckoutSessionPromise = (priceId, email, artistIds, couponCode) =>
  apiPrivateRequestInstance
    .post(API_ENDPOINTS.STRIPE_SESSION, {
      priceId,
      email,
      artistIds,
      couponCode,
    })
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return res.statusCode;
    })
    .catch(error => error);

export const fetchBlockedDomainsPromise = () =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.BLOCKED_DOMAINS).catch(error => error);

export const deleteBlockedDomainPromise = domain =>
  apiPrivateRequestInstance.delete(API_ENDPOINTS.BLOCKED_DOMAINS, {
    params: { domain },
  });

export const createBlockedDomainPromise = domain =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.BLOCKED_DOMAINS, {
    domain,
  });

export const fetchStrictDomainsPromise = () =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.STRICT_DOMAINS).catch(error => error);

export const deleteStrictDomainPromise = domain =>
  apiPrivateRequestInstance.delete(API_ENDPOINTS.STRICT_DOMAINS, {
    params: { domain },
  });

export const createStrictDomainPromise = domain =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.STRICT_DOMAINS, {
    domain,
  });

export const deleteCacheKeyPromise = key =>
  apiPrivateRequestInstance.delete(API_ENDPOINTS.CACHE_KEY(key));

export const getCacheKeyPromise = key => {
  if (key === '') throw new Error('Cache Key Cannot Be Empty');
  return apiPrivateRequestInstance
    .get(API_ENDPOINTS.CACHE_KEY(key))
    .then(res => {
      if (res.data && res.data !== null) {
        return res.data;
      }
      return res.statusCode;
    })
    .catch(error => error);
};

export const getAllAPILimitOverridesPromise = () =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.API_LIMIT_OVERRIDE());

export const getAPILimitOverridePromise = email =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.API_LIMIT_OVERRIDE({ email }));

export const setAPILimitOverridePromise = (email, max, duration = '1m') =>
  apiPrivateRequestInstance.put(
    API_ENDPOINTS.API_LIMIT_OVERRIDE({
      email,
      max,
      duration,
    })
  );

export const deleteAPILimitOverridePromise = email =>
  apiPrivateRequestInstance.delete(API_ENDPOINTS.API_LIMIT_OVERRIDE({ email }));

export const getGenreRequestsPromise = () =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.GET_GENRE_REQUEST);
export const approveGenreRequestPromise = id =>
  apiPrivateRequestInstance.patch(API_ENDPOINTS.UPDATE_GENRE_REQUEST(id));
export const declineGenreRequestPromise = id =>
  apiPrivateRequestInstance.delete(API_ENDPOINTS.UPDATE_GENRE_REQUEST(id));

export const sendGeneralEmailReportPromise = ({ id, email }) =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.SEND_GENERAL_EMAIL_REPORT, { id, email });

export const getChurnedUsersPromise = () =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.GET_CHURNED_USERS);

export const updateChurnedUserCommentPromise = (id, comment) =>
  apiPrivateRequestInstance.patch(API_ENDPOINTS.UPDATE_CHURNED_USER_COMMENT(id), {
    comment,
  });

export const addTrialSubScriptionPromise = ({ type, email, duration }) =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.ADD_TRIAL_SUBSCRIPTION(type), {
    email,
    duration,
  });

export const addLabelCorrectionPromise = ({
  flagAudiobooks,
  ignoreBefore,
  ignoreOriginalLabel,
  originalLabel,
  revisedLabel,
  subLabel,
  type,
}) =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.ADD_LABEL_CORRECTION, {
    flagAudiobooks,
    ignoreBefore,
    ignoreOriginalLabel,
    originalLabel,
    revisedLabel,
    subLabel,
    type,
  });
export const getLabelPromise = ({ label }) =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.GET_LABEL(label));
export const getAssociatedLabelPromise = ({ q }) =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.GET_ASSOCIATED_LABEL(q));
export const addAssociatedLabelPromise = ({ cm_artist, label, subLabel, type, hidden }) =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.ADD_ASSOCIATED_LABEL, {
    cm_artist,
    label,
    subLabel,
    type,
    hidden,
  });
export const updateAssociatedLabelPromise = ({
  cm_artist,
  label,
  subLabel,
  type,
  hidden,
  manual,
}) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.ADD_ASSOCIATED_LABEL, {
    cm_artist,
    label,
    subLabel,
    type,
    hidden,
    manual,
  });
export const getGenreTagListPromise = ({ type }) =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.GET_GENRE_TAG_LIST(type));
export const addTrackGenreTagPromise = ({ id, tag }) =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.UPDATE_TRACK_GENRE_TAG(id, tag));
export const deleteTrackGenreTagPromise = ({ id, tag }) =>
  apiPrivateRequestInstance.delete(API_ENDPOINTS.UPDATE_TRACK_GENRE_TAG(id, tag));
export const transferFavoritesPromise = ({ from_email, to_email }) =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.TRANSFER_FAVORITES, { from_email, to_email });
export const cleanUpStripePromise = () =>
  apiPrivateRequestInstance.put(API_ENDPOINTS.CLEAN_UP_STRIPE);
export const addArtistGenreTagPromise = ({ id, tag, type }) =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.ARTIST_GENRE_TAG(id, type), {
    toAdd: [{ id: tag }],
    toRemove: [],
  });
export const deleteArtistGenreTagPromise = ({ id, tag, type }) =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.ARTIST_GENRE_TAG(id, type), {
    toAdd: [],
    toRemove: [{ id: tag }],
  });
export const getPageHistoryStatisticsPromise = ({ fromDaysAgo }) =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.PAGE_HISTORY_STATISTICS(fromDaysAgo));
export const fetchUserSessionCountsPromise = ({ type, email, date }) =>
  apiPrivateRequestInstance.get(API_ENDPOINTS.USER_SESSION_COUNTS(type, email, date));

export const getCampaignListPromise = () => apiPrivateRequestInstance.get(API_ENDPOINTS.CAMPAIGN());

export const addCampaignPromise = ({ domain, priceId, couponCode, contentId }) =>
  apiPrivateRequestInstance.post(API_ENDPOINTS.CAMPAIGN(), {
    domain,
    priceId,
    couponCode,
    contentId,
  });

export const deleteCampaignPromise = ({ id }) =>
  apiPrivateRequestInstance.delete(API_ENDPOINTS.CAMPAIGN(id));
