import React, { useState } from 'react';
import { Button, Divider, Flex, Select, Tag as AntdTag, Popconfirm, Typography, Card } from 'antd';
import { capitalize, isEmpty } from 'lodash';
import { PLATFORMS_COLOR } from '@Utils/constants';
import { AntdIcon, Panel } from '../Style';
import { capitalizeEveryWord } from '../../utilities';
import ModalButton from '@Shared/Style/ModalButton';
import { InternalTagType, LinkType, Tag, useTagList, useUpdateTag } from '@Services/tag';
import { addSpaceBeforeCapitalLetter } from '@Utils/string';

interface EntityTag extends Tag {
  hidden: boolean;
  link_type: LinkType;
}

interface Props {
  entity: 'artist' | 'track';
  tags: EntityTag[];
  callback: () => void;
  id: number;
}

const ARTIST_TAG_TYPES = ['main', 'mainV2', 'sub', 'artist', 'artistPriority'];
const TRACK_TAG_TYPES = ['main', 'mainV2'];

const TAG_TYPES = {
  artist: ARTIST_TAG_TYPES,
  track: TRACK_TAG_TYPES,
};

function TagPanel({ entity, tags: entityTags, callback, id }: Props) {
  const { tagMap: entireTagMap } = useTagList();

  if (isEmpty(entireTagMap.main)) return null;

  const tagMap = entityTags.reduce<Record<InternalTagType, Record<string, EntityTag[]>>>(
    (all, one) => {
      let findGroup = false;
      TAG_TYPES[entity].map(type => {
        if (entireTagMap[type].find(e => e.id === one.id)) {
          all[type][one.link_type] = [...(all[type][one.link_type] ?? []), one];
          findGroup = true;
        }
      });
      if (!findGroup) {
        all.others[one.link_type] = [...(all.others[one.link_type] ?? []), one];
      }
      return all;
    },
    {
      main: {},
      mainV2: {},
      sub: {},
      artist: {},
      artistPriority: {},
      others: {},
    }
  );

  return (
    <Panel title="Genre / Tags">
      <Divider />
      <Panel.ResponsiveBody>
        {Object.keys(tagMap)
          .filter(type => TAG_TYPES[entity].includes(type))
          .map((type: InternalTagType) => (
            <Panel
              title={addSpaceBeforeCapitalLetter(type)}
              innerStyle
              extra={
                type !== 'others' && (
                  <AddModal
                    entity={entity}
                    list={entireTagMap[type]}
                    internalTagType={type}
                    entityId={id}
                    callback={callback}
                  />
                )
              }
            >
              <Flex wrap="wrap" gap="small">
                {Object.keys(tagMap[type]).map((linkType, index) =>
                  linkType === 'undefined' ? (
                    tagMap[type][linkType].map(tag => (
                      <Label
                        entity={entity}
                        artistId={id}
                        tag={tag}
                        refetch={callback}
                        internalTagType={type}
                      />
                    ))
                  ) : (
                    <Card title={linkType} type="inner">
                      <Flex wrap="wrap" gap="small">
                        {tagMap[type][linkType].map(tag => (
                          <Label
                            entity={entity}
                            artistId={id}
                            tag={tag}
                            refetch={callback}
                            internalTagType={type}
                          />
                        ))}
                      </Flex>
                    </Card>
                  )
                )}
              </Flex>
            </Panel>
          ))}
      </Panel.ResponsiveBody>
    </Panel>
  );
}

function AddModal({
  entity,
  internalTagType,
  entityId,
  callback,
  list,
}: {
  entity: 'artist' | 'track';
  list: Tag[];
  internalTagType: InternalTagType;
  entityId: number;
  callback: () => void;
}) {
  const { getLinkType, handleAddArtistGenreTag, isAdding } = useUpdateTag(
    entity,
    entityId,
    callback
  );
  const [newTag, setTag] = useState<number>();

  return (
    <ModalButton
      title={`Add ${capitalizeEveryWord(internalTagType.replace('_', ' '))} Tag`}
      Button={<Button className="green" icon={<AntdIcon name="plus" />} />}
      onOk={async () => handleAddArtistGenreTag(newTag as number)}
      okText="Add"
      okButtonProps={{
        loading: isAdding,
      }}
    >
      <Select
        options={list?.map(({ name, id }) => ({ label: name, value: id, key: id }))}
        onChange={(value: number) => setTag(value)}
        style={{ width: '100%' }}
        placeholder={`Add ${capitalize(internalTagType)} Tag`}
        filterOption={(input, option) =>
          String(option?.label)?.toLowerCase().includes(input.toLowerCase())
        }
        showSearch
      />
      {newTag && (
        <Typography.Text type="secondary">Link type: {getLinkType(newTag)}</Typography.Text>
      )}
      <Flex justify="end" style={{ paddingBottom: 4 }}>
        <Button
          type="primary"
          icon={<AntdIcon name="plus" />}
          loading={isAdding}
          onClick={() => {
            handleAddArtistGenreTag(newTag as number);
          }}
        >
          Add
        </Button>
      </Flex>
    </ModalButton>
  );
}

function Label({
  entity,
  tag,
  refetch,
  artistId,
  internalTagType,
}: {
  entity: 'artist' | 'track';
  tag: EntityTag;
  refetch: () => void;
  artistId: number;
  internalTagType: InternalTagType;
}) {
  const { id, name, source, hidden } = tag;
  const { handleAddArtistGenreTag, handleHiddenArtistGenreTag, isAdding, isHiding } = useUpdateTag(
    entity,
    artistId,
    refetch
  );

  return (
    <AntdTag
      style={{
        width: 'min-content',
      }}
      color={
        hidden && internalTagType !== 'others' && internalTagType !== 'artist'
          ? 'gray'
          : source
          ? PLATFORMS_COLOR[source.toLowerCase()]
          : 'blue'
      }
    >
      <Flex gap="middle">
        {capitalizeEveryWord(name.replaceAll('_', ' '))} {source ? `(${capitalize(source)})` : ''}
        <Flex gap="small">
          {entity === 'artist' && internalTagType !== 'others' && internalTagType !== 'artist' && (
            <AntdIcon
              loading={isHiding || isAdding}
              onClick={event => {
                if (hidden) {
                  handleAddArtistGenreTag(id, tag.link_type);
                } else {
                  handleHiddenArtistGenreTag(id, tag.link_type);
                }
              }}
              name={hidden ? 'eyeDisable' : 'eyeEnable'}
            />
          )}
        </Flex>
      </Flex>
    </AntdTag>
  );
}

export default TagPanel;
