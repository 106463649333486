import React, { Ref, useEffect, useRef, useState } from 'react';
import { Input as AntdInput, Button, Flex, InputRef, Typography } from 'antd';
import classNames from 'classnames';
import uuid4 from 'uuid4';
import { isEmpty } from 'lodash';
import { ButtonColor, Size } from './types';
import AntdIcon, { ANTD_ICON_TYPES } from './AntdIcon';
import FAIcon, { FAIcons } from './FAIcon';

interface Props {
  defaultValue?: string | number;
  value?: string | number;
  onChange?:
    | ((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void)
    | undefined;
  placeholder?: string;
  disabled?: boolean;
  description?: string;
  name?: string;
  isTextArea?: boolean;
  id?: string;
  height?: number;
  type?: 'text' | 'number' | 'email';
  icon?: FAIcons;
  clearAfterAction?: boolean;
  action?: {
    icon: FAIcons;
    content?: string;
    onClick: (
      inputElement: React.ChangeEvent<HTMLInputElement>,
      buttonElement: React.MouseEvent<HTMLElement, MouseEvent>,
      value: string | number
    ) => Promise<any> | void;
    className?: string;
    loading?: boolean;
    color?: ButtonColor;
    disabledEmpty?: boolean;
  };
  label?: {
    content: string;
    icon?: ANTD_ICON_TYPES;
  };
  size?: Size;
  variant?: 'outlined' | 'borderless' | 'filled' | undefined;
  className?: string;
  validation?: (value: string | number | undefined) => string | boolean;
  maxLength?: number;
}

function ActionInput({
  maxLength,
  defaultValue,
  value: parentValue,
  onChange,
  placeholder,
  disabled,
  description,
  name,
  isTextArea,
  id,
  label,
  height,
  type,
  action,
  size,
  icon,
  variant,
  className,
  validation,
  clearAfterAction,
}: Props) {
  const inputId = useRef(id ?? uuid4());
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onClickAction = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (validation && typeof validation(value) === 'string') {
      return;
    }

    const inputElement = document.getElementById(inputId.current) as HTMLInputElement;
    // eslint-disable-next-line no-unused-expressions
    action?.onClick &&
      Promise.resolve(
        action.onClick(
          {
            target: inputElement,
          } as React.ChangeEvent<HTMLInputElement>,
          e,
          document.getElementById(inputId.current)?.['value']
        )
      ).finally(() => {
        if (clearAfterAction) setValue('');
      });
  };

  return (
    <div className={classNames(className, 'max-w-[1280px]')}>
      {isTextArea ? (
        <AntdInput.TextArea
          id={id}
          name={name}
          value={parentValue ?? value}
          style={{ width: '100%', height }}
          onChange={e => onChange?.(e) ?? setValue(e.target.value)}
          disabled={disabled}
          placeholder={placeholder}
        />
      ) : (
        <Flex vertical>
          {label && (
            <Typography.Text type="secondary">
              {label.icon && <AntdIcon name={label.icon} />}
              {label.content}
            </Typography.Text>
          )}
          <Flex gap="middle">
            <Flex vertical gap="small" style={{ width: '100%' }}>
              <AntdInput
                maxLength={maxLength}
                onPressEnter={e => {
                  onClickAction(
                    document.getElementById(
                      `${inputId.current}-button`
                    ) as unknown as React.MouseEvent<HTMLElement, MouseEvent>
                  );
                }}
                allowClear
                status={validation && typeof validation(value) === 'string' ? 'error' : ''}
                variant={variant}
                size={size}
                type={type ?? 'text'}
                disabled={disabled}
                name={name}
                placeholder={placeholder}
                value={parentValue ?? value}
                onChange={e => {
                  // eslint-disable-next-line no-unused-expressions
                  onChange?.(e) ?? setValue(e.target.value);
                }}
                id={inputId.current}
                prefix={icon && <FAIcon color="lightGray" name={icon} />}
              />
              {validation && typeof validation(value) === 'string' && (
                <Typography.Text type="danger">{validation(value)}</Typography.Text>
              )}
            </Flex>
            {action && (
              <Button
                id={`${inputId.current}-button`}
                size={size}
                className={classNames(
                  action.className,
                  action.icon === 'farSearch' ? 'green' : action.color
                )}
                type="primary"
                icon={<FAIcon name={action.icon} />}
                onClick={onClickAction}
                loading={action.loading}
                disabled={
                  (action.disabledEmpty && isEmpty(value)) ||
                  (validation && typeof validation(value) === 'string')
                }
              >
                {action.content}
              </Button>
            )}
          </Flex>
        </Flex>
      )}
      {description && <Typography.Text type="secondary">{description}</Typography.Text>}
    </div>
  );
}

export default ActionInput;
