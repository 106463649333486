import React from 'react';
import { G_CLOUD_STORAGE } from '../../resource';

/* Font Awesome Icons */
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

/* Brand Icons */
import { faAmazon } from '@fortawesome/free-brands-svg-icons/faAmazon';
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faItunes } from '@fortawesome/free-brands-svg-icons/faItunes';
import { faLine } from '@fortawesome/free-brands-svg-icons/faLine';
import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest';
import { faQq } from '@fortawesome/free-brands-svg-icons/faQq';
import { faReddit } from '@fortawesome/free-brands-svg-icons/faReddit';
import { faSoundcloud } from '@fortawesome/free-brands-svg-icons/faSoundcloud';
import { faSpotify } from '@fortawesome/free-brands-svg-icons/faSpotify';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faWikipediaW } from '@fortawesome/free-brands-svg-icons/faWikipediaW';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { capitalize } from 'lodash';
import { PLATFORM_COLORS } from '@Constants/platform/colors';
import { faBroadcastTower } from '@fortawesome/pro-solid-svg-icons/faBroadcastTower';
import { faSignalStream } from '@fortawesome/pro-solid-svg-icons/faSignalStream';

import { faHeadphones } from '@fortawesome/pro-regular-svg-icons/faHeadphones';
import { faGem } from '@fortawesome/pro-solid-svg-icons/faGem';

export const BRAND_ICONS = {
  spotify: faSpotify,
  applemusic: faApple,
  apple_music: faApple,
  apple: faApple,
  youtube: faYoutube,
  youtubeforartist: faYoutube,
  amazon: faAmazon,
  itunes: faItunes,
  qq: faQq,
  tiktok: faTiktok,
  facebook: faFacebook,
  instagram: faInstagram,
  soundcloud: faSoundcloud,
  twitch: faTwitch,
  twitter: faTwitter,
  wikipedia: faWikipediaW,
  reddit: faReddit,
  pinterest: faPinterest,
  acr: faBroadcastTower,
  airplay: faBroadcastTower,
  radio: faBroadcastTower,
  beatport: faHeadphones,
  genius: faGem,
  line: faLine,
  siriusxm: faSignalStream,
  deezer: 'https://storage.googleapis.com/cm-app-assets/icons/deezer.svg',
  tunefind: 'https://storage.googleapis.com/cm-app-assets/icons/tunefind-logo.svg',
  tencent: 'https://storage.googleapis.com/cm-app-assets/icons/TENCENT_LOGO.svg',
  shazam: 'https://storage.googleapis.com/cm-app-assets/icons/shazam-logo.png',
  pandora: 'https://storage.googleapis.com/cm-app-assets/icons/pandora-logo.svg',
  netease: 'https://storage.googleapis.com/cm-app-assets/icons/NETEASE_LOGO.svg',
  melon: 'https://storage.googleapis.com/cm-app-assets/icons/melon.svg',
  songkick: 'https://storage.googleapis.com/cm-app-assets/icons/songkick.png',
  chartmetric: G_CLOUD_STORAGE.CHARTMETRIC_LOGO,
  boomplay: 'https://storage.googleapis.com/cm-app-assets/icons/boomplay.svg',
  bandsintown: 'https://storage.googleapis.com/cm-app-assets/icons/bandsintown.png',
  anghami: 'https://storage.googleapis.com/cm-app-assets/icons/anghami.svg',
};

interface Props {
  platform: keyof typeof BRAND_ICONS;
  color?: 'white' | 'platform';
  style?: React.CSSProperties;
}

const BrandIcon = ({ platform, color, style }: Props) => {
  if (color === 'platform') color = undefined;

  const platformColor = PLATFORM_COLORS[platform];

  return (
    <div
      title={capitalize(platform)}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      {typeof BRAND_ICONS[platform] === 'string' || typeof BRAND_ICONS[platform] === 'number' ? (
        <img
          src={
            typeof BRAND_ICONS[platform] === 'string'
              ? (BRAND_ICONS[platform] as string)
              : (BRAND_ICONS[platform] as unknown as string)
          }
          style={{ width: 12, height: 12, ...style }}
        />
      ) : (
        <FontAwesomeIcon
          icon={BRAND_ICONS[platform] as FontAwesomeIconProps['icon']}
          color={color ?? platformColor}
          style={{ width: 12, height: 12, ...style }}
        />
      )}
    </div>
  );
};

export default React.memo(BrandIcon);
