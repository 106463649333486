import { combineReducers } from 'redux';
import { showInfoMessage, showErrorMessage } from '../utilities';
import {
  UPDATE_PLAYLIST_PENDING,
  UPDATE_PLAYLIST_FULFILLED,
  UPDATE_PLAYLIST_REJECTED,
  UPDATE_ARTIST_PENDING,
  UPDATE_ARTIST_FULFILLED,
  UPDATE_ARTIST_REJECTED,
  UPDATE_TRACK_PENDING,
  UPDATE_TRACK_FULFILLED,
  UPDATE_TRACK_REJECTED,
  ADD_SIMILAR_TRACK_PENDING,
  ADD_SIMILAR_TRACK_FULFILLED,
  ADD_SIMILAR_TRACK_REJECTED,
  DELETE_SIMILAR_TRACK_PENDING,
  DELETE_SIMILAR_TRACK_FULFILLED,
  DELETE_SIMILAR_TRACK_REJECTED,
  ADD_ARTIST_PENDING,
  ADD_ARTIST_FULFILLED,
  ADD_ARTIST_REJECTED,
  MERGE_ARTISTS_PENDING,
  MERGE_ARTISTS_FULFILLED,
  MERGE_ARTISTS_REJECTED,
  MERGE_SOCIAL_STAT_PENDING,
  MERGE_SOCIAL_STAT_FULFILLED,
  MERGE_SOCIAL_STAT_REJECTED,
  DELETE_PLAYLIST_PENDING,
  DELETE_PLAYLIST_FULFILLED,
  DELETE_PLAYLIST_REJECTED,
  ADD_TRACK_PENDING,
  ADD_TRACK_FULFILLED,
  ADD_TRACK_REJECTED,
  UPDATE_ALBUM_FULFILLED,
  UPDATE_ALBUM_PENDING,
  UPDATE_ALBUM_REJECTED,
  ADD_LABEL_CORRECTION_PENDING,
  ADD_LABEL_CORRECTION_FULFILLED,
  ADD_LABEL_CORRECTION_REJECTED,
  GET_MAIN_GENRE_TAG_LIST_PENDING,
  GET_MAIN_GENRE_TAG_LIST_FULFILLED,
  GET_SUB_GENRE_TAG_LIST_PENDING,
  GET_SUB_GENRE_TAG_LIST_FULFILLED,
  UPDATE_TRACK_GENRE_PENDING,
  UPDATE_TRACK_GENRE_FULFILLED,
  UPDATE_TRACK_GENRE_REJECTED,
  UPDATE_ARTIST_GENRE_PENDING,
  UPDATE_ARTIST_GENRE_FULFILLED,
  UPDATE_ARTIST_GENRE_REJECTED,
} from './types';

const INITIAL_STATE = {
  GET: {
    tags: null,
    subTags: null,
    isFetching: false,
    error: null,
  },
  UPDATE: {
    data: null,
    isFetching: false,
    error: null,
  },
  ADD: {
    data: null,
    isFetching: false,
    error: null,
  },
  DELETE: {
    data: null,
    isFetching: false,
    error: null,
  },
};

const MESSAGES = {
  UPDATE_ERROR: 'Something Wrong With Update Operation (Please Contact Chartmetric Team).',
  ADD_ERROR: 'Something Wrong With Insert Operation (Please Contact Chartmetric Team).',
  DELETE_ERROR: 'Something Wrong With Delete Operation (Please Contact Chartmetric Team).',
  DELETE_FULFILLED: 'Successfully Deleted!',
};

const update = (state = INITIAL_STATE.UPDATE, action) => {
  switch (action.type) {
    case MERGE_ARTISTS_PENDING:
    case UPDATE_PLAYLIST_PENDING:
    case UPDATE_ARTIST_PENDING:
    case UPDATE_TRACK_PENDING:
    case UPDATE_ALBUM_PENDING:
    case ADD_SIMILAR_TRACK_PENDING:
    case DELETE_SIMILAR_TRACK_PENDING:
    case MERGE_SOCIAL_STAT_PENDING:
    case UPDATE_TRACK_GENRE_PENDING:
    case UPDATE_ARTIST_GENRE_PENDING:
      return {
        ...INITIAL_STATE.UPDATE,
        isFetching: true,
      };
    case MERGE_ARTISTS_FULFILLED:
    case UPDATE_PLAYLIST_FULFILLED:
    case UPDATE_ARTIST_FULFILLED:
    case UPDATE_TRACK_FULFILLED:
    case UPDATE_ALBUM_FULFILLED:
    case ADD_SIMILAR_TRACK_FULFILLED:
    case DELETE_SIMILAR_TRACK_FULFILLED:
    case MERGE_SOCIAL_STAT_FULFILLED:
    case UPDATE_TRACK_GENRE_FULFILLED:
    case UPDATE_ARTIST_GENRE_FULFILLED: {
      const { data } = action.payload;
      if (data) {
        showErrorMessage(data?.error ?? data);
        return {
          error: data?.error ?? data,
          isFetching: false,
        };
      }
      showInfoMessage(action.payload.message ?? data);
      return {
        data,
        isFetching: false,
      };
    }
    case MERGE_ARTISTS_REJECTED:
    case UPDATE_PLAYLIST_REJECTED:
    case UPDATE_ARTIST_REJECTED:
    case UPDATE_TRACK_REJECTED:
    case UPDATE_ALBUM_REJECTED:
    case ADD_SIMILAR_TRACK_REJECTED:
    case DELETE_SIMILAR_TRACK_REJECTED:
    case MERGE_SOCIAL_STAT_REJECTED:
    case UPDATE_TRACK_GENRE_REJECTED:
    case UPDATE_ARTIST_GENRE_REJECTED:
      return {
        error: MESSAGES.UPDATE_ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};
const add = (state = INITIAL_STATE.ADD, action) => {
  switch (action.type) {
    case ADD_LABEL_CORRECTION_PENDING:
    case ADD_ARTIST_PENDING:
    case ADD_TRACK_PENDING:
      return {
        ...INITIAL_STATE.ADD,
        isFetching: true,
      };
    case ADD_ARTIST_FULFILLED:
    case ADD_TRACK_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      return {
        data,
        isFetching: false,
      };
    }
    case ADD_LABEL_CORRECTION_FULFILLED:
      showInfoMessage(action.payload.message);
      return {
        data: action.payload.message,
        isFetching: false,
      };
    case ADD_ARTIST_REJECTED:
    case ADD_TRACK_REJECTED:
    case ADD_LABEL_CORRECTION_REJECTED:
      return {
        error: MESSAGES.ADD_ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};

const deletes = (state = INITIAL_STATE.DELETE, action) => {
  switch (action.type) {
    case DELETE_PLAYLIST_PENDING:
      return {
        ...INITIAL_STATE.DELETE,
        isFetching: true,
      };
    case DELETE_PLAYLIST_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      showInfoMessage(data);
      showInfoMessage(MESSAGES.DELETE_FULFILLED);
      return {
        data,
        isFetching: false,
      };
    }
    case DELETE_PLAYLIST_REJECTED:
      return {
        error: MESSAGES.DELETE_ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};

const get = (state = INITIAL_STATE.GET, action) => {
  switch (action.type) {
    case GET_MAIN_GENRE_TAG_LIST_PENDING:
    case GET_SUB_GENRE_TAG_LIST_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_MAIN_GENRE_TAG_LIST_FULFILLED:
      return {
        ...state,
        tags: action.payload.obj,
        isFetching: false,
      };
    case GET_SUB_GENRE_TAG_LIST_FULFILLED:
      return {
        ...state,
        subTags: action.payload.obj,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  update,
  add,
  deletes,
  get,
});
