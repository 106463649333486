import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider } from 'antd';
import { isEmpty } from 'lodash';
import * as actions from '../../../actions';
import Playlist from './Playlist';
import GlobalSearch from '../../../shared/GlobalSearch/GlobalSearch';

class DeleteCMdataPanel extends Component {
  handleSearch = q => this.props.searchCMData(q);

  render() {
    const { data, isFetching } = this.props;
    return (
      <>
        <section className="top-search-box">
          <GlobalSearch
            variant="filled"
            types={['playlist']}
            onSubmit={this.handleSearch}
            loading={isFetching}
          />
        </section>
        <Divider />
        {!isEmpty(data) && data && data.type === 'playlist' && (
          <Playlist fetchData={this.handleSearch} {...data} />
        )}
      </>
    );
  }
}

const mapStateToProps = state => state.search;
export default connect(mapStateToProps, actions)(DeleteCMdataPanel);
