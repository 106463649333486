import { getDateFormat } from '@Utils';
import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import MatchedIPsModal from './MatchedIPsModal';
import { useGetCompanyUnivPoolMembers } from '@Services/userPool';
import { useModal } from '@Hooks';
import { SearchInput } from '@Shared';
import { AntdIcon, DataTable } from '@Shared/Style';
import FAIcon from '@Shared/Style/FAIcon';

const MEMBER_COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Name',
    accessor: 'first_name',
    Cell: ({ value, original }) => `${value} ${original.last_name}`,
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    Cell: ({ value }) => getDateFormat(value),
  },
  {
    Header: 'Last Login',
    accessor: 'last_login',
    Cell: ({ value }) => getDateFormat(value),
  },
  {
    Header: 'Most Recent IP',
    accessor: 'matched_ip_addresses',
    Cell: ({ value }) =>
      value ? value?.sort((a, b) => b.last_login - a.last_login)[0]?.ip_address : '',
  },
  {
    Header: "Matched IP's",
    accessor: 'matched_ip_addresses',
    Cell: MatchedIPsModal,
  },
  {
    Header: 'Stripe Customer ID',
    accessor: 'customer_id',
  },
];

const MembersModal = ({ id }) => {
  const {
    data,
    isLoading: isLoadingGetMembers,
    refetch: getMembers,
  } = useGetCompanyUnivPoolMembers({
    path: { id },
  });
  const { showModal, closeModal, modalProps } = useModal<{ id?: number }>();

  const [filteredData, setFilteredData] = useState(data?.members ?? []);

  useEffect(() => {
    setFilteredData(data?.members ?? []);
  }, [data]);

  return (
    <>
      <Button
        className="green"
        onClick={e => {
          e.stopPropagation();
          showModal();
          getMembers();
        }}
        icon={<FAIcon name="faListUl" />}
      />
      <Modal
        style={{
          minWidth: '80%',
        }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Close"
        title="Company-wide/University Users"
        onCancel={closeModal}
        open={modalProps.show}
      >
        <SearchInput onChange={setFilteredData} originalData={data?.members ?? []} />
        <br />
        <DataTable loading={isLoadingGetMembers} data={filteredData} columns={MEMBER_COLUMNS} />
      </Modal>
    </>
  );
};

export default MembersModal;
