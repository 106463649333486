import { Panel } from '@Shared/Style';
import React from 'react';
import { LinkCompletenessOptions } from '@Services/linkCompleteness';
import { Flex, Segmented, Select } from 'antd';
import Current from './Current';
import History from './History';

export interface LinkCompletenessStatsProps {
  target: 'artist' | 'track';
  state: LinkCompletenessOptions;
  onChangeFilter: (key: string, value: any) => void;
}

type Type = 'current' | 'history';

const Statistics = ({ target, state, onChangeFilter }: LinkCompletenessStatsProps) => {
  const [type, setType] = React.useState<Type>('current');

  return (
    <Panel
      title="Statistics"
      extra={
        <Flex gap="small" align="flex-start">
          <Segmented
            value={type}
            onChange={value => {
              setType(value as Type);
            }}
            options={[
              {
                label: 'Current',
                value: 'current',
              },
              ...(target === 'artist'
                ? [
                    {
                      label: '30 Days History',
                      value: 'history',
                    },
                  ]
                : []),
            ]}
          />
          <Select
            placeholder="Filter By Tier"
            style={{ width: 160 }}
            value={state.tier}
            mode="multiple"
            onChange={value => {
              if (value === null) {
                onChangeFilter('tier', undefined);
                return;
              }
              onChangeFilter('tier', value);
            }}
            options={[1, 2, 3, 4, 5].map(tier => ({
              label: `Tier ${tier}`,
              value: tier,
            }))}
          />
        </Flex>
      }
    >
      {type === 'current' ? (
        <Current target={target} state={state} onChangeFilter={onChangeFilter} />
      ) : (
        <History target={target} state={state} onChangeFilter={onChangeFilter} />
      )}
    </Panel>
  );
};
export default Statistics;
