import React, { useState } from 'react';
import { Button, Modal, Tag } from 'antd';
import AntdTable from './AntdTable';

interface Props {
  onClickSubmit: () => Promise<void>;
  approveRequest: number[];
  declineRequest: number[];
  data: any[];
  loading: boolean;
  selected: number;
  columns: {
    Header: string | React.JSX.Element;
    accessor?: string;
    Cell?: any;
    width?: number;
  }[];
}

function RequestSubmitButton({
  onClickSubmit,
  approveRequest,
  declineRequest,
  data,
  loading,
  columns,
  selected,
}: Props) {
  const [open, setOpen] = useState(false);
  const selectedData = data.filter(
    c => approveRequest.includes(c.id) || declineRequest.includes(c.id)
  );
  return (
    <>
      <Button
        className="green"
        onClick={() => setOpen(true)}
        disabled={approveRequest.length === 0 && declineRequest.length === 0}
      >
        SUBMIT ({selected ?? 0})
      </Button>
      <Modal
        style={{
          minWidth: '98vw',
        }}
        open={open}
        onOk={() => {
          onClickSubmit().finally(() => setOpen(false));
        }}
        okButtonProps={{ loading }}
        onCancel={() => setOpen(false)}
        title={`Confirm handling requests (${selectedData.length})`}
      >
        <AntdTable
          columns={[
            ...columns,
            {
              Header: 'Status',
              accessor: 'id',
              Cell: ({ value }) => (
                <Tag color={approveRequest.includes(value) ? 'green' : 'red'}>
                  {approveRequest.includes(value) ? 'Approve' : 'Decline'}
                </Tag>
              ),
            },
          ]}
          data={selectedData}
        />
      </Modal>
    </>
  );
}

export default RequestSubmitButton;
