import { useGetGenreGroup, useGetGenreInfo } from '@Services/tag';
import { isEmpty } from 'lodash';
import React, { createContext, useContext, useState } from 'react';

const EditGenreHierarchyContext = createContext<{
  useGenreRelatives: () => ReturnType<typeof useGetGenreGroup>;
  useGenre: () => ReturnType<typeof useGetGenreInfo>;
  selectedGenre?: any;
  setSelectedGenre: React.Dispatch<React.SetStateAction<number | undefined>>;
}>(
  // @ts-ignore
  {}
);

export const EditGenreHierarchyProvider = ({ children }) => {
  const [selectedGenre, setSelectedGenre] = useState<number>();

  console.log(selectedGenre);

  const useGenreGroup = () =>
    useGetGenreGroup(
      {
        path: { id: selectedGenre! },
      },
      {
        disable: !selectedGenre,
      }
    );

  const useGenre = () =>
    useGetGenreInfo(
      { path: { id: selectedGenre! } },
      {
        disable: !selectedGenre,
      }
    );

  return (
    <EditGenreHierarchyContext.Provider
      value={{
        useGenreRelatives: useGenreGroup,
        useGenre,
        selectedGenre,
        setSelectedGenre,
      }}
    >
      {children}
    </EditGenreHierarchyContext.Provider>
  );
};

export const useEditGenreHierarchy = () => {
  return useContext(EditGenreHierarchyContext);
};
