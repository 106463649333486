import React, { useState } from 'react';
import ReactTable from 'react-table';
import { Badge, Checkbox, Divider, Empty, Flex, Radio } from 'antd';
import { RequestSubmitButton } from '@Shared';
import { Panel, Button, DataTable, Tabs } from '../../../shared/Style';
import {
  useGetNewsLinkDomains,
  useGetNewsLinkRequest,
  useUpdateNewsLinkRequest,
} from '../../../services/request';
import { generateCmUrl, getDate } from '../../../utilities';
import useRequestTable from '../../../hooks/useRequstTable';
import SearchInput from '../../../shared/Table/SearchInput';
import Pagination from '../../../shared/Table/Paignation';

function NewsLinkRequest() {
  const { data: domains, isLoading: isDomainLoading } = useGetNewsLinkDomains();
  const { execute, isLoading: isFetching } = useUpdateNewsLinkRequest();

  const {
    data,
    isLoading,
    state,
    onClickApprove,
    onClickDecline,
    onClickSelectApproveAll,
    onClickSelectDeclineAll,
    refetch,
    onSearch,
    selected,
    clear,
  } = useRequestTable(useGetNewsLinkRequest, null);

  const COLUMNS = [
    {
      Header: 'ID',
      accessor: 'id',
      width: 50,
    },
    {
      Header: 'Artist',
      accessor: 'artist_name',
      Cell: (props: any) => (
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={generateCmUrl('artist', { id: props.original.cm_artist })}
        >
          {props.value}
        </a>
      ),
    },
    {
      Header: 'URL',
      accessor: 'url',
      Cell: ({ value }) => (
        <a target="_blank" rel="noreferrer noopener" href={value}>
          {value}
        </a>
      ),
    },
    {
      Header: 'Requested By',
      accessor: 'user_email',
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      Cell: ({ value }) => getDate(value),
    },
    {
      Header: () => (
        <Flex justify="center" align="center" gap="small">
          Approve
          <Checkbox
            checked={state.approves.length === data?.length && data.length > 0}
            onClick={onClickSelectApproveAll}
          />
        </Flex>
      ),
      Cell: (props: any) => (
        <Radio
          id={`approve-${props.original.id}`}
          checked={state.approves.includes(props.original.id)}
          onChange={() => onClickApprove(props.original.id)}
        />
      ),
      width: 100,
    },
    {
      Header: () => (
        <Flex justify="center" align="center" gap="small">
          Decline
          <Checkbox
            checked={state.declines.length === data?.length && data.length > 0}
            onClick={onClickSelectDeclineAll}
          />
        </Flex>
      ),
      Cell: (props: any) => (
        <Radio
          id={`decline-${props.original.id}`}
          checked={state.declines.includes(props.original.id)}
          onChange={() => onClickDecline(props.original.id)}
        />
      ),
      width: 100,
    },
  ];

  const COLUMNS_DOMAIN = [
    {
      Header: 'Domain',
      accessor: 'domain',
      Cell: (props: any) => (
        <a target="_blank" rel="noreferrer noopener" href={`https://${props.value}`}>
          {props.value}
        </a>
      ),
    },
    {
      Header: 'Count',
      accessor: 'count',
    },
  ];

  const SUBMIT_MODAL_COLUMNS = [
    {
      Header: 'Artist',
      accessor: 'artist_name',
      Cell: (props: any) => (
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={generateCmUrl('artist', { id: props.original.cm_artist })}
        >
          {props.value}
        </a>
      ),
    },
    {
      Header: 'URL',
      accessor: 'url',
      Cell: ({ value }) => (
        <a target="_blank" rel="noreferrer noopener" href={value}>
          {value}
        </a>
      ),
    },
    {
      Header: 'Requested By',
      accessor: 'user_email',
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      Cell: ({ value }) => getDate(value) ?? '',
    },
  ];

  const handleSubmit = () =>
    execute({
      data: {
        requests: state.approves
          .map(id => ({
            id,
            process: true,
            cm_artist: data.find((item: any) => item.id === id).cm_artist,
            url: data.find((item: any) => item.id === id).url,
          }))
          .concat(
            state.declines.map(id => ({
              id,
              process: false,
              cm_artist: data.find((item: any) => item.id === id).cm_artist,
              url: data.find((item: any) => item.id === id).url,
            }))
          ),
      },
    }).then(() => {
      refetch();
      clear();
    });

  return (
    <Tabs
      tabs={[
        {
          title: 'News Link Request',
          content: (
            <Panel
              extra={<SearchInput onChange={onSearch} originalData={data} />}
              title={
                <Flex align="center" gap="small">
                  News Link Request
                  <Badge count={data?.length ?? '0'} color="blue" overflowCount={9999} />
                </Flex>
              }
            >
              <DataTable loading={isLoading} columns={COLUMNS} data={state.filteredData} />
              <Divider />
              <Flex justify="center">
                <RequestSubmitButton
                  columns={SUBMIT_MODAL_COLUMNS}
                  onClickSubmit={handleSubmit}
                  approveRequest={state.approves}
                  declineRequest={state.declines}
                  data={data ?? []}
                  loading={isFetching}
                  selected={selected}
                />
              </Flex>
            </Panel>
          ),
        },
        {
          title: 'Frequently Requested Domains',
          content: (
            <Panel title="Frequently Requested Domains">
              <DataTable loading={isDomainLoading} columns={COLUMNS_DOMAIN} data={domains} />
            </Panel>
          ),
        },
      ]}
    />
  );
}

export default React.memo(NewsLinkRequest);
