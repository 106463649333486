import { Panel } from '@Shared/Style';
import { Button, Timeline } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

interface Update {
  type: 'feature' | 'bug' | 'improvement';
  comment: string[] | string;
  route?: string;
  date?: string;
}

const UPDATES: Update[] = [
  {
    date: '2024-09-18',
    type: 'feature',
    comment: 'Support User pool hierarchy',
    route: '/campaign/company-wide-and-university-organizations#hierarchy',
  },
  {
    type: 'feature',
    comment: 'Bulk Linking with excel',
    route: '/linking/bulk-url-update',
  },
  {
    date: '2024-09-05',
    type: 'feature',
    comment: ['Added memo column to Link completeness'],
    route: '/logs/link-completeness',
  },
  {
    type: 'feature',
    comment: 'Link suggestions now lunched',
    route: '/logs/link-completeness',
  },
  {
    type: 'feature',
    comment: 'Support authenticity risk override',
    route: '/entity-edit/edit-entity',
  },
];

const UpdateNotes = () => {
  return (
    <Panel title="Updates" icon="fadBell" className="2xl:w-auto w-full">
      <Timeline
        mode="alternate"
        items={UPDATES.map((update, index) => ({
          title: update.comment,
          label: update.date,
          children: update.route ? (
            <Link to={update.route}>
              {update.comment instanceof Array ? (
                <ul>
                  {update.comment.map((comment, index) => (
                    <li key={index}>{comment}</li>
                  ))}
                </ul>
              ) : (
                <p>{update.comment}</p>
              )}
            </Link>
          ) : update.comment instanceof Array ? (
            <ul>
              {update.comment.map((comment, index) => (
                <li key={index}>{comment}</li>
              ))}
            </ul>
          ) : (
            <p>{update.comment}</p>
          ),
          color: update.type === 'feature' ? 'green' : update.type === 'bug' ? 'red' : 'blue',
          key: index,
        }))}
      />
    </Panel>
  );
};

export default UpdateNotes;
