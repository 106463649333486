import { AntdIcon, ModalButton } from '@Shared/Style';
import { Typography, Divider, Flex, Tag, Checkbox, Button, SelectProps, Select } from 'antd';
import React from 'react';
import styles from './Hierarchy.module.scss';
import TagFilter from '@Features/Genre/EditArtistGenreBatch/Components/Selector/TagFilter';
import { useTagList } from '@Services/tag';
import { capitalize } from 'lodash';
import FAIcon from '@Shared/Style/FAIcon';

export interface Item {
  id: number;
  name: string;
  position: 'parent' | 'child';
}

interface Props {
  onSubmit: (values: Item[]) => Promise<void>;
  options: SelectProps['options'];
  title: string;
  rootId: number;
}

const AddHierarchyModal = ({ onSubmit, options, title, rootId }: Props) => {
  const [selectedItems, setSelectedItems] = React.useState<Item[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClickCheckbox = (id: number, relation: 'parent' | 'child') => {
    setSelectedItems(old => old.map(tag => (tag.id === id ? { ...tag, position: relation } : tag)));
  };

  return (
    <ModalButton
      style={{
        minWidth: '65vw',
      }}
      title={title}
      okButtonProps={{
        loading: isLoading,
        icon: <FAIcon name="fasCheckCircle" />,
        className: 'green',
      }}
      okText="Submit"
      onOk={() => {
        setIsLoading(true);
        return onSubmit(selectedItems).finally(() => {
          setSelectedItems([]);
          setIsLoading(false);
        });
      }}
      Button={
        <Button
          loading={isLoading}
          style={{ width: '100%' }}
          className="green"
          icon={<FAIcon name="fasPlus" />}
        >
          Add Relatives
        </Button>
      }
    >
      <Flex vertical>
        <Typography.Text type="secondary">Select Parent / Child</Typography.Text>
        <Select
          showSearch
          filterOption={(input, option) =>
            JSON.stringify(option).toLowerCase().includes(input.toLowerCase())
          }
          style={{ width: 200 }}
          placeholder="Select an item"
          options={options?.filter(
            e => e.value !== rootId && !selectedItems.some(t => t.id === e.value)
          )}
          onChange={id => {
            if (!selectedItems.some(t => t.id === id)) {
              setSelectedItems(old => [
                ...old,
                {
                  id: id,
                  name: options?.find(e => e.value === id)?.label as string,
                  position: 'parent',
                },
              ]);
            }
          }}
        />
      </Flex>
      {selectedItems.length > 0 && <Divider />}
      <Flex wrap gap="middle">
        {selectedItems.map(tag => (
          <Tag className={tag.position === 'parent' ? styles.parent : styles.child} key={tag.id}>
            <Flex justify="flex-end">
              <FAIcon
                name="farXmark"
                className={styles.xMark}
                onClick={() => setSelectedItems(old => old.filter(t => t.id !== tag.id))}
              />
            </Flex>
            <Flex vertical justify="center" align="center">
              <Typography.Text strong>{capitalize(tag.name)}</Typography.Text>
              <Flex style={{ marginTop: 4 }}>
                <Checkbox
                  onChange={e => {
                    handleClickCheckbox(tag.id, 'child');
                  }}
                  checked={tag.position === 'child'}
                >
                  Child
                </Checkbox>
                <Checkbox
                  onChange={e => {
                    handleClickCheckbox(tag.id, 'parent');
                  }}
                  checked={tag.position === 'parent'}
                >
                  Parent
                </Checkbox>
              </Flex>
            </Flex>
          </Tag>
        ))}
      </Flex>
    </ModalButton>
  );
};

export default AddHierarchyModal;
