import { showInfoMessage, showErrorMessage } from '../utilities';
import {
  UPDATE_ARTIST_ALBUMS_PENDING,
  UPDATE_ARTIST_TRACKS_PENDING,
  UPDATE_ARTIST_TRACKS_FULFILLED,
  UPDATE_ARTIST_ALBUMS_FULFILLED,
  UPDATE_ARTIST_TRACKS_REJECTED,
  UPDATE_ARTIST_ALBUMS_REJECTED,
} from './types';

const INITIAL_STATE = {
  data: '',
  isFetching: false,
  error: null,
};

const MESSAGES = {
  ERROR: 'Something Wrong With Search (Please Contact Chartmetric Team).',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ARTIST_TRACKS_PENDING:
    case UPDATE_ARTIST_ALBUMS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_ARTIST_TRACKS_FULFILLED:
    case UPDATE_ARTIST_ALBUMS_FULFILLED: {
      const { data } = action.payload;

      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      showInfoMessage(data);
      return {
        data,
        isFetching: false,
      };
    }
    case UPDATE_ARTIST_TRACKS_REJECTED:
    case UPDATE_ARTIST_ALBUMS_REJECTED:
      showErrorMessage(MESSAGES.ERROR);
      return {
        error: MESSAGES.ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};
