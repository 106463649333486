import React, { useState } from 'react';
import { Button, Flex, Input, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { AntdIcon, Panel } from '../shared/Style';
import { G_CLOUD_STORAGE } from '../resource';
import { enumerateMenus } from './constants/menus';
import { useUser } from '@Services/auth';
import AppTime from './components/Home/AppTime';
import FAIcon from '@Shared/Style/FAIcon';
import rootStyles from '../Routers/root.module.scss';
import UpdateNotes from './components/Home/UpdateNotes';
import useSidebar from './hooks/useSidebar';

function Home() {
  const [searchCriteria, setSearchCriteria] = useState<string>('');
  const { userData } = useUser();
  const { toggleSidebar } = useSidebar();

  const checkItemSearched = (item: any) =>
    item.name
      .toLowerCase()
      .replaceAll(' ', '')
      .includes(searchCriteria.toLowerCase().replaceAll(' ', ''));

  const checkEmptyGrid = (menu: any) => {
    if (menu.items) {
      return !menu.items.some((item: any) => checkItemSearched(item));
    }
    return false;
  };

  return (
    <main className="pt-9">
      <Flex gap="large" vertical>
        <Flex align="center" gap="large" className="mobile-vertical">
          <Flex align="center" gap="small" className="w-full">
            <Typography.Title
              className="!text-gray-500"
              style={{
                whiteSpace: 'nowrap',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
              }}
              level={4}
            >
              <FAIcon
                id={rootStyles.hamburger}
                name="fasBars"
                onClick={() => toggleSidebar(true)}
              />
              {new Date().getDay() === 5
                ? '🎉 Happy Friday, '
                : new Date().getHours() < 6
                ? '😴 Good night, '
                : new Date().getHours() < 12
                ? '👋🏻 Good morning, '
                : new Date().getHours() < 14
                ? '🍔 Happy lunch, '
                : new Date().getHours() < 18
                ? '🌞 Good afternoon, '
                : '🌟 Good evening, '}
              {userData?.name}!
            </Typography.Title>
          </Flex>
        </Flex>
        <Flex className="w-full" wrap gap="middle">
          <AppTime />
          <UpdateNotes />
        </Flex>
        <Flex gap="middle" align="center">
          <Typography.Title level={4} className="!text-gray-500 !mb-0">
            Features
          </Typography.Title>
          <Input
            className="max-w-[500px]"
            placeholder="Tab to search"
            prefix={<AntdIcon color="lightgrey" name="search" />}
            size="large"
            variant="borderless"
            onChange={({ target: { value } }) => setSearchCriteria(value as string)}
          />
        </Flex>
        <article
          className="grid 
         grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5"
        >
          {enumerateMenus().map(
            menu =>
              menu.name !== 'Home' &&
              !checkEmptyGrid(menu) && (
                <Panel icon={menu.icon} title={menu.name}>
                  <Flex vertical gap="small">
                    {menu.items &&
                      menu.items.map(
                        item =>
                          item.name
                            .toLowerCase()
                            .replaceAll(' ', '')
                            .includes(searchCriteria.toLowerCase().replaceAll(' ', '')) && (
                            <Link
                              to={`${menu.route}/${item.name.toLowerCase().replaceAll(' ', '-')}`}
                            >
                              <Button
                                type="text"
                                key={item.name}
                                className="w-full flex flex-row items-center gap-1"
                              >
                                {item.icon && <FAIcon name={item.icon} />}
                                <span className="truncate">{item.name}</span>
                                {item.labelSuffix}
                                {item.isNew && (
                                  <img
                                    alt="new"
                                    className="w-[30px]"
                                    src={G_CLOUD_STORAGE.NEW_ICON}
                                  />
                                )}
                              </Button>
                            </Link>
                          )
                      )}
                  </Flex>
                </Panel>
              )
          )}
        </article>
      </Flex>
    </main>
  );
}

export default Home;
