import { useAddTeamMember, useGetTeamMembers } from '@Services/team';
import { GlobalSearch } from '@Shared';
import { AntdIcon } from '@Shared/Style';
import ModalButton from '@Shared/Style/ModalButton';
import { Button } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';

const AddMemberModal = ({
  managerId,
  managerEmail,
}: {
  managerId: number;
  managerEmail: string;
}) => {
  const { execute: addTeamMember, isLoading: isLoadingAdd } = useAddTeamMember();
  const [q, setQ] = React.useState('');
  const { data, isLoading, refetch } = useGetTeamMembers(
    {
      data: { q },
    },
    {
      disable: isEmpty(q),
    }
  );

  return (
    <ModalButton
      title="Add Member"
      confirmLoading={isLoadingAdd}
      okButtonProps={{
        loading: isLoadingAdd,
      }}
      onOk={async () => {
        await addTeamMember({
          data: {
            memberEmail: q,
            teamManagerUserId: managerId,
          },
        });
        refetch();
      }}
      Button={
        <Button icon={<AntdIcon name="plus" />} className="green" loading={isLoadingAdd}>
          Add Member
        </Button>
      }
    >
      <GlobalSearch types={['user']} onChange={setQ} icon="user" size="middle" variant="filled" />
    </ModalButton>
  );
};

export default AddMemberModal;
