import React from 'react';
import { Flex, Tabs, Tag } from 'antd';
import { useHistory } from 'react-router-dom';
import FAIcon from '@Shared/Style/FAIcon';

function SectionTab({ root, items, itemKey }) {
  const history = useHistory();

  const handleChange = key => {
    const item = items[key];
    const path = `${root}/${item.name.toLowerCase().replaceAll(' ', '-')}`;
    history.push(path);
  };

  return (
    <Tabs
      onChange={handleChange}
      activeKey={items.findIndex(item => item.name === itemKey).toString()}
      style={{ marginBottom: 32 }}
      items={items.map((item, i) => ({
        label: (
          <Flex align="center">
            <FAIcon name={item.icon} style={{ marginRight: 8 }} />
            {item.name}
          </Flex>
        ),
        key: String(i),
        children: item.component,
      }))}
    />
  );
}

export default SectionTab;
