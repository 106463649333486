import React from 'react';
import { Breadcrumb, Flex, Space } from 'antd';
import { Link } from 'react-router-dom';
import { AntdIcon } from '@Shared/Style';
import SectionTab from './SectionTab';
import { MenuType } from '../../constants/menus';
import styles from '../../root.module.scss';
import FAIcon from '@Shared/Style/FAIcon';
import useSidebar from 'Routers/hooks/useSidebar';

interface Props {
  menu: MenuType;
  itemKey: string;
}

function Section({ menu, itemKey }: Props) {
  const { toggleSidebar } = useSidebar();
  return (
    <main style={{ paddingTop: '38px' }}>
      <Flex gap="large" align="center">
        <AntdIcon id={styles.hamburger} name="menuUnfold" onClick={() => toggleSidebar(true)} />

        <Breadcrumb
          separator=">"
          items={[
            {
              title: (
                <Link to="/">
                  <AntdIcon name="home" />
                </Link>
              ),
            },
            {
              title: (
                <Flex align="center" gap={4}>
                  <FAIcon name={menu.icon} />
                  <span>{menu.name}</span>
                </Flex>
              ),
            },
          ]}
        />
      </Flex>
      <Flex style={{ paddingBottom: 24 }} gap="large" vertical>
        <SectionTab root={menu.route} items={menu.items} itemKey={itemKey} />
      </Flex>
    </main>
  );
}

export default Section;
