import React from 'react';
import { capitalize, isEmpty } from 'lodash';
import { Table } from '../../../../shared/Style';
import { generateCmUrl } from '../../../../utilities';
import { useGetEntity } from '@Services/search';
import { EditEntityProps } from '..';
import { Avatar, Flex, Typography } from 'antd';

function Info({ q }: EditEntityProps) {
  const { data, isFetching, refetch } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );

  return (
    <article>
      <Table
        layout="vertical"
        panelStyle
        title="Basic Information"
        options={[
          {
            label: 'Name',
            component: (
              <Flex gap="small" align="center">
                <Avatar
                  src={data.image_url}
                  size="large"
                  shape="square"
                  style={{ marginRight: 6 }}
                />
                <Typography.Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={generateCmUrl('track', { id: data.id })}
                >
                  {data.name}
                </Typography.Link>{' '}
              </Flex>
            ),
            labelIcon: 'tag',
          },
          {
            label: 'Artists',
            component: data.artists?.map(({ name, id }, index) => (
              <a
                key={id}
                target="_blank"
                rel="noopener noreferrer"
                href={generateCmUrl('artist', { id })}
                style={{ marginRight: 6 }}
              >
                {capitalize(name)}
                {index !== (data.artists?.length ?? 0) - 1 && ', '}
              </a>
            )),
            labelIcon: 'user',
          },
          {
            label: 'ISRC',
            value: data.isrc,
            labelIcon: 'tag',
          },
        ]}
      />
    </article>
  );
}

export default Info;
