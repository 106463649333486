import { persistGetToUrl, mutateToUrl } from './helpers';

const ENDPOINT = {
  CAMPAIGN_CONTENT: '/admin/campaign/content',
  CAMPAIGN_HISTORY: '/admin/campaign/log',
  UPDATE_CAMPAIGN_CONTENT: '/admin/campaign/content/:id',
  STRIPE_PRODUCTS: '/admin/stripe/products',
  STRIPE_COUPONS: '/admin/stripe/coupon',
};

export const useGetCampaignContent = persistGetToUrl(ENDPOINT.CAMPAIGN_CONTENT, {
  manual: true,
});
export const useAddCampaignContent = mutateToUrl<{
  data: {
    title: string;
    sentence: string;
    imageUrl: string;
    buttonLabel: string;
  };
}>('POST', ENDPOINT.CAMPAIGN_CONTENT);
export const useEditCampaignContent = mutateToUrl<{
  path: {
    id: number;
  };
  data: {
    title: string;
    sentence: string;
    imageUrl: string;
    buttonLabel: string;
  };
}>('PATCH', ENDPOINT.UPDATE_CAMPAIGN_CONTENT);
export const useDeleteCampaignContent = mutateToUrl<{
  path: {
    id: number;
  };
}>('DELETE', ENDPOINT.UPDATE_CAMPAIGN_CONTENT);
export const useGetCampaignContentById = persistGetToUrl(ENDPOINT.UPDATE_CAMPAIGN_CONTENT, {
  manual: true,
});
export const useGetCampaignHistory = persistGetToUrl(ENDPOINT.CAMPAIGN_HISTORY);

export const useGetStripeProducts = persistGetToUrl(ENDPOINT.STRIPE_PRODUCTS);
export const useGetStripeCoupons = persistGetToUrl(ENDPOINT.STRIPE_COUPONS, {
  manual: true,
});
