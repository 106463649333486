import { FAIcons } from '@Shared/Style/FAIcon';

export const HUMAN_ENTITY = ['artist', 'curator'];

export const ENTITY_ICON = {
  ARTIST: 'fadMicrophoneStand',
  TRACK: 'fadCompactDisc',
  ALBUM: 'fadMusic',
  PLAYLIST: 'fadListMusic',
  CURATOR: 'fadUserMusic',
  STATION: 'fasBroadcastTower',
  CITY: 'farCity',
  LABEL: 'fadTag',
  MILESTONE: 'farStar',
  CHART: 'farChartMixed',
  AIRPLAY: 'farRadio',
  TUNEFIND: 'farCameraMovie',
  STATS: 'farGauge',
  ABOUT: 'farGridHorizontal',
  AUDIENCE: 'farUsers',
  COMPARE: 'farPeopleArrows',
  COLLABORATION: 'farPeopleGroup',
  BRAND: 'farBuildingUser',
  CONCERTS: 'farTicket',
  GENRE: 'fadGuitar',
  USER: 'farUser',
} satisfies Record<string, FAIcons>;
