import useAppTime from '@Hooks/useAppTime';
import Clock from '@Shared/Clock/Clock';
import LineGraph from '@Shared/LineGraph/LineGraph';
import { Panel } from '@Shared/Style';
import { Flex } from 'antd';
import React from 'react';

const AppTime = () => {
  const { timeSpent, getTimeRecords } = useAppTime();

  return (
    <Panel title="Your Daily App Time" icon="fadClock" className="w-full 2xl:max-w-[1200px] h-fit">
      <Flex vertical gap="small">
        <Clock value={timeSpent} />
        <Flex wrap="wrap" justify="center">
          <LineGraph
            height={200}
            renderLabel={value => {
              // @ts-ignore
              const hours = Math.floor(value / 60);
              // @ts-ignore
              const minutes = (value % 60).toFixed(0);
              return `${hours}h ${minutes}m`;
            }}
            renderTooltip={(tooltipItem, data) => {
              // @ts-ignore
              const label = data.datasets?.[tooltipItem.datasetIndex]?.label;
              // @ts-ignore
              const hours = Math.floor(tooltipItem.yLabel / 60);
              // @ts-ignore
              const minutes = (tooltipItem.yLabel % 60).toFixed(0);
              return `${label}: ${hours}h ${minutes}m`;
            }}
            chartData={{
              labels: getTimeRecords()
                .slice(-7)
                .map(({ date }) => date),
              datasets: [
                {
                  label: 'App time',
                  data: getTimeRecords()
                    .slice(-7)
                    .map(({ timeSpent }) => timeSpent / 60),
                  fill: false,
                  backgroundColor: 'rgb(9, 95, 253)',
                  borderColor: 'rgb(9, 95, 253)',
                },
              ],
            }}
          />
        </Flex>
      </Flex>
    </Panel>
  );
};

export default React.memo(AppTime);
