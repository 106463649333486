import { Panel, Table } from '@Shared/Style';
import { addComma } from '@Utils/number';
import { Flex, Skeleton, Typography } from 'antd';
import React from 'react';
import AddGenreHierarchyModalButton from './Components/AddGenreHierarchyModalButton';
import { capitalize } from 'lodash';
import { EditGenreHierarchyProvider, useEditGenreHierarchy } from './Context';
import ActionButtons from './Components/ActionButtons';
import AddNewGenreModal from './Components/AddNewGenreModal';
import TagFilter from '../EditArtistGenreBatch/Components/Selector/TagFilter';
import Tree from '@Shared/Hierarchy/Hierarchy';
import { useGetGenreInfo, useHiddenTagFromAllArtists } from '@Services/tag';
import { showInfoMessage } from '@Utils';

const GenreTree = ({ treeData, data }) => {
  const { execute: hiddenTag, isLoading: isLoadingHidden } = useHiddenTagFromAllArtists();
  const [hidingId, setHidingId] = React.useState<number | null>(null);

  const { refetch } = useGetGenreInfo(
    { path: { id: hidingId! } },
    {
      disable: !hidingId,
      manual: true,
      onSuccess: data => {
        if (data) {
          showInfoMessage(`Proceed to hide genre ${data.name} from ${data.counts.artists} artists`);
        }
      },
    }
  );

  const handleHiddenTag = async (id: number) => {
    setHidingId(id);
    refetch().then(({ data }) => {
      if (data)
        showInfoMessage(`Proceed to hide genre ${data.name} from ${data.counts.artists} artists`);
    });
    hiddenTag({
      data: {
        hidden: true,
        tagId: id,
      },
    });
  };

  return (
    treeData && (
      <Tree
        action={{
          icon: 'farEyeSlash',
          onClick: handleHiddenTag,
          popconfirmMessage:
            'Are you sure you want to hide this genre(tag) from all artists belonging to?',
        }}
        items={treeData}
        name={data.name}
        id={data.id}
      />
    )
  );
};

const EditGenreHierarchy = () => {
  const { useGenre, useGenreRelatives, selectedGenre, setSelectedGenre } = useEditGenreHierarchy();

  const { data, isLoading } = useGenre();
  const { isLoading: isLoadingRelatives, data: treeData } = useGenreRelatives();

  return (
    <Panel
      title="Genre (v2) Hierarchy"
      extra={<AddNewGenreModal />}
      description={
        <Flex vertical wrap="wrap">
          If you need to delete a genre, please contact the data team.
        </Flex>
      }
    >
      <Typography.Text type="secondary">Select Genre</Typography.Text>
      <Flex style={{ maxWidth: 300 }} gap="small">
        <TagFilter onChange={setSelectedGenre} value={selectedGenre} tagType="mainV2" />
      </Flex>
      {isLoading || isLoadingRelatives ? (
        <>
          <br />
          <Skeleton.Node
            active
            style={{
              width: '100% !important',
              minHeight: '400px',
            }}
          />
        </>
      ) : data ? (
        <>
          <br />
          <Flex gap="middle" className="mobile-vertical">
            <Flex vertical gap="middle" style={{ marginBottom: 16, width: 350 }}>
              <Table
                layout="horizontal"
                options={[
                  {
                    label: 'Name',
                    value: capitalize(data.name),
                    span: 4,
                  },
                  {
                    label: 'ID',
                    value: data.id.toString(),
                    span: 4,
                  },
                  {
                    label: 'Artist Count',
                    value: addComma(data.counts.artists),
                    span: 4,
                  },
                  {
                    label: 'Track Count',
                    value: addComma(data.counts.tracks),
                    span: 4,
                  },
                  {
                    label: 'Type',
                    value: capitalize(data.type),
                    span: 4,
                  },
                  {
                    label: 'Source',
                    value: capitalize(data.source),
                    span: 4,
                  },
                ]}
              />
              <ActionButtons />
              <AddGenreHierarchyModalButton />
            </Flex>
            <GenreTree data={data} treeData={treeData} />
          </Flex>
        </>
      ) : null}
    </Panel>
  );
};

export default () => (
  <EditGenreHierarchyProvider>
    <EditGenreHierarchy />
  </EditGenreHierarchyProvider>
);
