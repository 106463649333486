import React, { useEffect } from 'react';
import ReactTable from 'react-table';
import { useGetLinksByUserDetails } from '../../../../services/user';

import { DataTable, Panel } from '../../../../shared/Style';

const LINKS_BY_USER_DEFAULT_DAYS_AGO = {
  FROM: 9999,
  TO: 0,
};

export function LinksByUserDetails({ q }) {
  const { data, isLoading, refetch } = useGetLinksByUserDetails({
    data: {
      q,
      fromDaysAgo: LINKS_BY_USER_DEFAULT_DAYS_AGO.FROM,
      toDaysAgo: LINKS_BY_USER_DEFAULT_DAYS_AGO.TO,
    },
  });

  useEffect(() => {
    refetch();
  }, [q]);

  const COLUMNS = [
    {
      Header: 'Social Type',
      accessor: 'type',
    },
    {
      Header: 'Artist',
      accessor: 'artist',
    },
    {
      Header: 'Artist ID',
      accessor: 'artist_id',
    },
    {
      Header: 'URL',
      accessor: 'url',
    },
    {
      Header: 'Date Added',
      accessor: 'date_added',
    },
    {
      Header: 'Date Modified',
      accessor: 'date_modified',
    },
    {
      Header: 'Locked',
      accessor: 'locked',
    },
  ];

  return (
    <Panel title="All Links Activated By User">
      <DataTable data={data} columns={COLUMNS} loading={isLoading} />
    </Panel>
  );
}
