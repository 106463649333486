import React from 'react';
import { Checkbox as SCheckbox } from 'semantic-ui-react';

interface Props {
  onChange: (e: any, data: any) => void;
  name?: string;
  checked?: boolean;
  label?: string;
  isToggle?: boolean;
}
/**
 * @deprecated
 */
function Checkbox({ onChange, name, checked, label, isToggle = false }: Props) {
  return (
    <SCheckbox name={name} onChange={onChange} checked={checked} label={label} toggle={isToggle} />
  );
}

export default Checkbox;
