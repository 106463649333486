import { combineReducers } from 'redux';
import { showInfoMessage, showErrorMessage } from '../utilities';
import {
  FETCH_USER_API_USAGE_STATS_PENDING,
  FETCH_USER_API_USAGE_STATS_FULFILLED,
  FETCH_USER_API_USAGE_STATS_REJECTED,
  FETCH_USER_API_USAGE_DETAILS_PENDING,
  FETCH_USER_API_USAGE_DETAILS_FULFILLED,
  FETCH_USER_API_USAGE_DETAILS_REJECTED,
  FETCH_LINKS_BY_USER_DETAILS_PENDING,
  FETCH_LINKS_BY_USER_DETAILS_FULFILLED,
  FETCH_LINKS_BY_USER_DETAILS_REJECTED,
  FETCH_LINKS_BY_USER_HISTORY_PENDING,
  FETCH_LINKS_BY_USER_HISTORY_FULFILLED,
  FETCH_LINKS_BY_USER_HISTORY_REJECTED,
  FETCH_USER_INFO_PENDING,
  FETCH_USER_INFO_FULFILLED,
  FETCH_USER_INFO_REJECTED,
  DELETE_USER_PENDING,
  DELETE_USER_FULFILLED,
  DELETE_USER_REJECTED,
  GRANT_API_ACCESS_PENDING,
  GRANT_API_ACCESS_FULFILLED,
  GRANT_API_ACCESS_REJECTED,
  REMOVE_API_ACCESS_PENDING,
  REMOVE_API_ACCESS_FULFILLED,
  REMOVE_API_ACCESS_REJECTED,
  REMOVE_SSO_LOGIN_PENDING,
  REMOVE_SSO_LOGIN_FULFILLED,
  REMOVE_SSO_LOGIN_REJECTED,
  VERIFY_USER_EMAIL_PENDING,
  VERIFY_USER_EMAIL_FULFILLED,
  VERIFY_USER_EMAIL_REJECTED,
  USER_BLOCKLIST_PENDING,
  USER_BLOCKLIST_FULFILLED,
  USER_BLOCKLIST_REJECTED,
  RESET_USER_PASSWORD_PENDING,
  RESET_USER_PASSWORD_FULFILLED,
  RESET_USER_PASSWORD_REJECTED,
  FETCH_POWER_USER_CURVE_PENDING,
  FETCH_POWER_USER_CURVE_FULFILLED,
  FETCH_POWER_USER_CURVE_REJECTED,
  FETCH_MAIN_DB_TABLE_STATS_PENDING,
  FETCH_MAIN_DB_TABLE_STATS_FULFILLED,
  FETCH_MAIN_DB_TABLE_STATS_REJECTED,
  FETCH_RECENT_API_USAGE_PENDING,
  FETCH_RECENT_API_USAGE_FULFILLED,
  FETCH_RECENT_API_USAGE_REJECTED,
  CHANGE_USER_EMAIL_PENDING,
  CHANGE_USER_EMAIL_FULFILLED,
  CHANGE_USER_EMAIL_REJECTED,
  FOLLOW_ARTISTS_PENDING,
  FOLLOW_ARTISTS_FULFILLED,
  FOLLOW_ARTISTS_REJECTED,
  FETCH_USER_FOLLOWED_ARTISTS_PENDING,
  FETCH_USER_FOLLOWED_ARTISTS_FULFILLED,
  FETCH_USER_FOLLOWED_ARTISTS_REJECTED,
  DELETE_FOLLOWED_ARTIST_PENDING,
  DELETE_FOLLOWED_ARTIST_REJECTED,
  DELETE_FOLLOWED_ARTIST_FULFILLED,
  FETCH_USER_VIEWABLE_ARTISTS_PENDING,
  FETCH_USER_VIEWABLE_ARTISTS_FULFILLED,
  FETCH_USER_VIEWABLE_ARTISTS_REJECTED,
  DELETE_VIEWABLE_ARTIST_PENDING,
  DELETE_VIEWABLE_ARTIST_REJECTED,
  DELETE_VIEWABLE_ARTIST_FULFILLED,
  SUBSCRIBE_TO_ARTIST_PENDING,
  SUBSCRIBE_TO_ARTIST_FULFILLED,
  SUBSCRIBE_TO_ARTIST_REJECTED,
  FOLLOW_ARTIST_BY_USER_PENDING,
  FOLLOW_ARTIST_BY_USER_FULFILLED,
  FOLLOW_ARTIST_BY_USER_REJECTED,
  GET_USER_SESSION_COUNTS_PENDING,
  GET_USER_SESSION_COUNTS_FULFILLED,
  GET_USER_SESSION_COUNTS_REJECTED,
} from './types';

const INITIAL_STATE = {
  USER_SESSION_COUNTS: {
    data: null,
    isFetching: false,
    error: null,
  },
  USER_API_USAGE_STATS: {
    data: null,
    isFetching: false,
    error: null,
  },
  USER_API_USAGE_DETAILS: {
    data: null,
    isFetching: false,
    error: null,
  },
  LINKS_BY_USER: {
    data: null,
    isFetching: false,
    error: null,
  },
  USER_INFO: {
    data: null,
    isFetching: false,
    error: null,
  },
  UPDATE_USER: {
    data: null,
    isFetching: false,
    error: null,
  },
  USER_BLOCKLIST: {
    data: null,
    isFetching: false,
    error: null,
  },
  POWER_USER_CURVE: {
    data: [],
    isFetching: false,
    error: null,
  },
  MAIN_DB_TABLE_STATS: {
    data: [],
    isFetching: false,
    error: null,
  },
  RECENT_API_USAGE: {
    data: [],
    isFetching: false,
    error: null,
  },
  FOLLOWED_ARTISTS: {
    data: [],
    isFetching: false,
    error: null,
  },
  VIEWABLE_ARTISTS: {
    data: [],
    isFetching: false,
    error: null,
  },
};

const MESSAGES = {
  UPDATE_ERROR: 'Something Wrong With Update Operation (Please Contact Chartmetric Team).',
  FETCH_POWER_USER_CURVE_ERROR:
    'Something Wrong While Fetching Power User Curve (Please Contact Chartmetric Team).',
  FETCH_RECENT_API_USAGE_ERROR:
    'Something Wrong While Fetching API Usage (Please Contact Chartmetric Team).',
  FETCH_LINKS_BY_USER_ERROR:
    'Something Wrong While Fetching Links By User (Please Contact Chartmetric Team).',
};

const userSession = (state = INITIAL_STATE.USER_SESSION_COUNTS, action) => {
  switch (action.type) {
    case GET_USER_SESSION_COUNTS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case GET_USER_SESSION_COUNTS_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      return {
        data,
        isFetching: false,
      };
    }
    case GET_USER_SESSION_COUNTS_REJECTED:
      showErrorMessage(action.payload?.data?.error || action.payload?.error);
      return {
        error: MESSAGES.UPDATE_ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};

const userAPIUsageStats = (state = INITIAL_STATE.USER_API_USAGE_STATS, action) => {
  switch (action.type) {
    case FETCH_USER_API_USAGE_STATS_PENDING:
      return {
        ...INITIAL_STATE.USER_API_USAGE_STATS,
        isFetching: true,
      };
    case FETCH_USER_API_USAGE_STATS_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      return {
        data,
        isFetching: false,
      };
    }
    case FETCH_USER_API_USAGE_STATS_REJECTED:
      showErrorMessage(action.payload?.data?.error || action.payload?.error);
      return {
        error: MESSAGES.UPDATE_ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};
const userFollowedArtists = (state = INITIAL_STATE.FOLLOWED_ARTISTS, action) => {
  switch (action.type) {
    case FETCH_USER_FOLLOWED_ARTISTS_PENDING:
    case FOLLOW_ARTIST_BY_USER_PENDING:
    case DELETE_FOLLOWED_ARTIST_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case FOLLOW_ARTIST_BY_USER_FULFILLED:
    case DELETE_FOLLOWED_ARTIST_FULFILLED:
      if (action.payload?.data) {
        showInfoMessage(action.payload.data);
      }
      return {
        ...state,
        isFetching: false,
      };
    case FETCH_USER_FOLLOWED_ARTISTS_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        return {
          error: data?.error,
          data: [],
          isFetching: false,
        };
      }
      return {
        data,
        isFetching: false,
      };
    }
    case FETCH_USER_FOLLOWED_ARTISTS_REJECTED:
    case DELETE_FOLLOWED_ARTIST_REJECTED:
    case FOLLOW_ARTIST_BY_USER_REJECTED:
      return {
        error: MESSAGES.UPDATE_ERROR,
        data: [],
        isFetching: false,
      };
    default:
      return state;
  }
};
const userViewableArtists = (state = INITIAL_STATE.VIEWABLE_ARTISTS, action) => {
  switch (action.type) {
    case FETCH_USER_VIEWABLE_ARTISTS_PENDING:
    case DELETE_VIEWABLE_ARTIST_PENDING:
    case SUBSCRIBE_TO_ARTIST_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case SUBSCRIBE_TO_ARTIST_FULFILLED:
    case DELETE_VIEWABLE_ARTIST_FULFILLED:
      if (action.payload?.data) {
        showInfoMessage(action.payload.data);
      }
      return {
        ...state,
        isFetching: false,
      };
    case FETCH_USER_VIEWABLE_ARTISTS_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        return {
          error: data?.error,
          data: [],
          isFetching: false,
        };
      }
      return {
        data,
        isFetching: false,
      };
    }
    case FETCH_USER_VIEWABLE_ARTISTS_REJECTED:
    case SUBSCRIBE_TO_ARTIST_REJECTED:
    case DELETE_VIEWABLE_ARTIST_REJECTED:
      return {
        error: MESSAGES.UPDATE_ERROR,
        data: [],
        isFetching: false,
      };
    default:
      return state;
  }
};
const userAPIUsageDetails = (state = INITIAL_STATE.USER_API_USAGE_DETAILS, action) => {
  switch (action.type) {
    case FETCH_USER_API_USAGE_DETAILS_PENDING:
      return {
        ...INITIAL_STATE.USER_API_USAGE_STATS,
        isFetching: true,
      };
    case FETCH_USER_API_USAGE_DETAILS_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      return {
        data,
        isFetching: false,
      };
    }
    case FETCH_USER_API_USAGE_DETAILS_REJECTED:
      showErrorMessage(action.payload?.data?.error || action.payload?.error);
      return {
        error: MESSAGES.UPDATE_ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};

const linksByUserDetails = (state = INITIAL_STATE.LINKS_BY_USER, action) => {
  switch (action.type) {
    case FETCH_LINKS_BY_USER_DETAILS_PENDING:
      return {
        ...INITIAL_STATE.LINKS_BY_USER,
        isFetching: true,
      };
    case FETCH_LINKS_BY_USER_DETAILS_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      return {
        data,
        isFetching: false,
      };
    }
    case FETCH_LINKS_BY_USER_DETAILS_REJECTED:
      showErrorMessage(action.payload?.data?.error || action.payload?.error);
      return {
        error: MESSAGES.FETCH_LINKS_BY_USER_ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};

const linksByUserHistory = (state = INITIAL_STATE.LINKS_BY_USER, action) => {
  switch (action.type) {
    case FETCH_LINKS_BY_USER_HISTORY_PENDING:
      return {
        ...INITIAL_STATE.LINKS_BY_USER,
        isFetching: true,
      };
    case FETCH_LINKS_BY_USER_HISTORY_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      return {
        data,
        isFetching: false,
      };
    }
    case FETCH_LINKS_BY_USER_HISTORY_REJECTED:
      showErrorMessage(action.payload?.data?.error || action.payload?.error);
      return {
        error: MESSAGES.FETCH_LINKS_BY_USER_ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};

const userInfo = (state = INITIAL_STATE.USER_INFO, action) => {
  switch (action.type) {
    case FETCH_USER_INFO_PENDING:
      return {
        ...INITIAL_STATE.USER_INFO,
        isFetching: true,
      };
    case FETCH_USER_INFO_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      return {
        data,
        isFetching: false,
      };
    }
    case FETCH_USER_INFO_REJECTED:
      showErrorMessage(action.payload?.data?.error || action.payload?.error);
      return {
        error: MESSAGES.UPDATE_ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};
const updateUser = (state = INITIAL_STATE.UPDATE_USER, action) => {
  switch (action.type) {
    case GRANT_API_ACCESS_PENDING:
    case REMOVE_API_ACCESS_PENDING:
    case REMOVE_SSO_LOGIN_PENDING:
    case VERIFY_USER_EMAIL_PENDING:
    case DELETE_USER_PENDING:
    case RESET_USER_PASSWORD_PENDING:
    case CHANGE_USER_EMAIL_PENDING:
    case FOLLOW_ARTISTS_PENDING:
      return {
        ...INITIAL_STATE.UPDATE_USER,
        isFetching: true,
      };
    case GRANT_API_ACCESS_FULFILLED:
    case REMOVE_API_ACCESS_FULFILLED:
    case REMOVE_SSO_LOGIN_FULFILLED:
    case VERIFY_USER_EMAIL_FULFILLED:
    case DELETE_USER_FULFILLED:
    case RESET_USER_PASSWORD_FULFILLED:
    case CHANGE_USER_EMAIL_FULFILLED:
    case FOLLOW_ARTISTS_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      showInfoMessage(action.payload?.data?.message || data);
      return {
        data,
        isFetching: false,
      };
    }
    case GRANT_API_ACCESS_REJECTED:
    case REMOVE_API_ACCESS_REJECTED:
    case REMOVE_SSO_LOGIN_REJECTED:
    case VERIFY_USER_EMAIL_REJECTED:
    case DELETE_USER_REJECTED:
    case RESET_USER_PASSWORD_REJECTED:
    case CHANGE_USER_EMAIL_REJECTED:
    case FOLLOW_ARTISTS_REJECTED:
      showErrorMessage(action.payload?.data?.error || action.payload?.error);
      return {
        error: MESSAGES.UPDATE_ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};

const userBlocklist = (state = INITIAL_STATE.USER_BLOCKLIST, action) => {
  switch (action.type) {
    case USER_BLOCKLIST_PENDING:
      return {
        ...INITIAL_STATE.USER_BLOCKLIST,
        isFetching: true,
      };
    case USER_BLOCKLIST_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      showInfoMessage(data);
      return {
        data,
        isFetching: false,
      };
    }
    case USER_BLOCKLIST_REJECTED:
      showErrorMessage(action.payload?.data?.error || action.payload?.error);
      return {
        error: MESSAGES.UPDATE_ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};
const powerUserCurve = (state = INITIAL_STATE.POWER_USER_CURVE, action) => {
  switch (action.type) {
    case FETCH_POWER_USER_CURVE_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_POWER_USER_CURVE_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      return {
        data,
        isFetching: false,
      };
    }
    case FETCH_POWER_USER_CURVE_REJECTED:
      showErrorMessage(MESSAGES.FETCH_POWER_USER_CURVE_ERROR);
      return {
        error: MESSAGES.FETCH_POWER_USER_CURVE_ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};
const mainDBTableStats = (state = INITIAL_STATE.MAIN_DB_TABLE_STATS, action) => {
  switch (action.type) {
    case FETCH_MAIN_DB_TABLE_STATS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_MAIN_DB_TABLE_STATS_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      return {
        data,
        isFetching: false,
      };
    }
    case FETCH_MAIN_DB_TABLE_STATS_REJECTED:
      showErrorMessage(MESSAGES.FETCH_POWER_USER_CURVE_ERROR);
      return {
        error: MESSAGES.FETCH_POWER_USER_CURVE_ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};
const recentAPIUsage = (state = INITIAL_STATE.RECENT_API_USAGE, action) => {
  switch (action.type) {
    case FETCH_RECENT_API_USAGE_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_RECENT_API_USAGE_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      return {
        data,
        isFetching: false,
      };
    }
    case FETCH_RECENT_API_USAGE_REJECTED:
      showErrorMessage(MESSAGES.FETCH_RECENT_API_USAGE_ERROR);
      return {
        error: MESSAGES.FETCH_RECENT_API_USAGE_ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  search: combineReducers({
    user: combineReducers({
      apiUsage: combineReducers({
        stats: userAPIUsageStats,
        details: userAPIUsageDetails,
        blocklist: userBlocklist,
      }),
      linksByUser: combineReducers({
        details: linksByUserDetails,
        history: linksByUserHistory,
      }),
      info: userInfo,
      update: updateUser,
      followedArtists: userFollowedArtists,
      viewableArtists: userViewableArtists,
      userSession,
    }),
  }),
  powerUserCurve,
  mainDBTableStats,
  recentAPIUsage,
});
