import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Image,
  Icon,
  Form,
  Input,
  TextArea,
  Button,
  Select,
  Flag,
  Segment,
} from 'semantic-ui-react';
import * as actions from '../../../actions';
import { Panel } from '../../../shared/Style';

class Playlist extends Component {
  state = {};

  componentDidMount() {
    this.setState({
      ...this.props,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
    });
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleDeleteMetadata = () => {
    const { id, streamingType, deletePlaylist } = this.props;

    deletePlaylist(streamingType, id);
  };

  render() {
    const {
      playlist_id,
      name,
      image_url,
      description,
      tags,
      //API Response
      isFetching,
    } = this.props;

    return (
      <Panel>
        <Card fluid>
          <Card.Content>
            <Image floated="right" size="small" src={image_url} />
            <Card.Header>
              <div>{name}</div>
            </Card.Header>
            <Card.Description>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </Card.Description>
          </Card.Content>
          <Card.Content textAlign="center" extra>
            Tags: {tags && tags.map(tag => tag.name).join(', ')}
          </Card.Content>
        </Card>
        <Form onSubmit={this.handleDeleteMetadata}>
          <Form.Button loading={isFetching} content="Delete" fluid />
        </Form>
      </Panel>
    );
  }
}
const mapStateToProps = state => ({ response: state.metadata.deletes });
export default connect(mapStateToProps, actions)(Playlist);
