import { MOOD_COLORS_CLASSES, MOOD_EXPLANATIONS, MOOD_LIST } from '@Constants/platform/moods';
import { useDeleteTrackMood, useInsertTrackMood } from '@Services/mood';
import { AntdIcon, ModalButton, Panel, WhatsThis } from '@Shared/Style';
import { capitalizeEveryWord } from '@Utils/string';
import { Badge, Button, Flex, Select, Tag, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
const convertToPastel = (colorClass: string): string => {
  const pastelColors: { [key: string]: string } = {
    Blue: '#6699cc',
    Grey: '#9B9B9B',
    Red: '#cc6666',
    Purple: '#9966cc',
    Orange: '#ff9933',
    Green: '#66cc99',
    Yellow: '#E3B52D',
    Pink: '#ff6699',
  };
  return pastelColors[colorClass] || '#B8B8B8'; // Default to Grey if colorClass not found
};
interface Mood {
  name: string;
  number_of_playlists: number;
  pct: number;
  tag_type: TagType;
}

interface Props {
  id: number;
  moods: Mood[];
  callback: () => void;
}

const TAG_TYPE = ['MOOD', 'ACTIVITY / THEME'] as const;
type TagType = (typeof TAG_TYPE)[number];

const MoodPanel = ({ id, moods, callback }: Props) => {
  const groupedMoods: Record<TagType, string[]> = moods.reduce<{
    [key: string]: string[];
  }>((acc, mood) => {
    if (!acc[mood.tag_type]) {
      acc[mood.tag_type] = [];
    }
    acc[mood.tag_type].push(mood.name);
    acc[mood.tag_type] = Array.from(new Set(acc[mood.tag_type]));

    return acc;
  }, {}) as Record<TagType, string[]>;

  return (
    <Panel title="Moods">
      <Panel.ResponsiveBody>
        {TAG_TYPE.map(type => {
          const moods = groupedMoods[type];
          const sortedMoods = moods?.sort((a, b) => {
            const colorA = MOOD_COLORS_CLASSES[a.replace(/\s+/g, '_').toUpperCase()];
            const colorB = MOOD_COLORS_CLASSES[b.replace(/\s+/g, '_').toUpperCase()];
            return colorA.localeCompare(colorB);
          });
          return (
            <Panel
              innerStyle
              key={type}
              title={capitalizeEveryWord(type)}
              // extra={<AddMoodModal entityId={id} type={type} callback={callback} />}
            >
              <Flex wrap="wrap" gap="small">
                {sortedMoods?.map(mood => (
                  <Label name={mood} type={type as TagType} entityId={id} callback={callback} />
                ))}
              </Flex>
            </Panel>
          );
        })}
      </Panel.ResponsiveBody>
    </Panel>
  );
};

const AddMoodModal = ({
  entityId,
  type,
  callback,
}: {
  entityId: number;
  type: TagType;
  callback: () => void;
}) => {
  const { execute, isLoading } = useInsertTrackMood();
  const [selectedMood, setSelectedMood] = useState<string | undefined>();

  return (
    <ModalButton
      okButtonProps={{
        loading: isLoading,
      }}
      onOk={async () =>
        execute({
          path: { id: entityId },
          query: { name: selectedMood as string, type },
        }).then(callback)
      }
      title={`Add Mood as ${capitalizeEveryWord(type)} type`}
      Button={<Button loading={isLoading} className="green" icon={<AntdIcon name="plus" />} />}
    >
      <Flex vertical gap="small">
        <Select
          allowClear
          showSearch
          style={{ width: '100%' }}
          onChange={setSelectedMood}
          value={selectedMood}
          placeholder={`Select ${capitalizeEveryWord(type)}`}
          options={MOOD_LIST.filter(e => e.type.toLowerCase() === type.toLowerCase()).map(
            ({ name }) => ({
              label: capitalizeEveryWord(name),
              value: name,
            })
          )}
        />
        {selectedMood && MOOD_EXPLANATIONS[selectedMood.toLowerCase()] && (
          <Typography.Text>
            <Tag
              color={convertToPastel(
                MOOD_COLORS_CLASSES[selectedMood.replace(/\s+/g, '_').toUpperCase()]
              )}
            >
              {capitalizeEveryWord(selectedMood)}
            </Tag>
            {MOOD_EXPLANATIONS[selectedMood.toLowerCase()].split(': ')[1]}
          </Typography.Text>
        )}
      </Flex>
    </ModalButton>
  );
};

const Label = ({
  entityId,
  name,
  type,
  callback,
}: {
  entityId: number;
  name: string;
  type: TagType;
  callback: () => void;
}) => {
  const { execute, isLoading } = useDeleteTrackMood();

  return (
    <Tooltip
      title={
        type === 'MOOD' && (MOOD_EXPLANATIONS[name.toLowerCase()] || 'No description available.')
      }
    >
      <Tag color={convertToPastel(MOOD_COLORS_CLASSES[name])}>
        <Flex align="center" gap={6}>
          {capitalizeEveryWord(name)}
          {type === 'MOOD' && <AntdIcon name="questionCircle" color="white" />}{' '}
          {/* <AntdIcon
            name="delete"
            loading={isLoading}
            onClick={() =>
              execute({
                path: { id: entityId },
                query: { name, type },
              }).then(callback)
            }
          /> */}
        </Flex>
      </Tag>
    </Tooltip>
  );
};

export default MoodPanel;
