import { combineReducers } from 'redux';
import { showInfoMessage, showErrorMessage } from '../utilities';
import {
  APPROVE_CLAIM_REQUEST_FULFILLED,
  APPROVE_CLAIM_REQUEST_PENDING,
  APPROVE_CLAIM_REQUEST_REJECTED,
  APPROVE_GENRE_REQUEST_FULFILLED,
  APPROVE_GENRE_REQUEST_PENDING,
  APPROVE_GENRE_REQUEST_REJECTED,
  DECLINE_CLAIM_REQUEST_FULFILLED,
  DECLINE_CLAIM_REQUEST_PENDING,
  DECLINE_CLAIM_REQUEST_REJECTED,
  DECLINE_GENRE_REQUEST_FULFILLED,
  DECLINE_GENRE_REQUEST_PENDING,
  DECLINE_GENRE_REQUEST_REJECTED,
  GET_CLAIM_REQUEST_FULFILLED,
  GET_CLAIM_REQUEST_PENDING,
  GET_CLAIM_REQUEST_REJECTED,
  GET_CLAIM_REQUEST_STATUS_FULFILLED,
  GET_CLAIM_REQUEST_STATUS_PENDING,
  GET_CLAIM_REQUEST_STATUS_REJECTED,
  GET_GENRE_REQUEST_FULFILLED,
  GET_GENRE_REQUEST_PENDING,
  GET_GENRE_REQUEST_REJECTED,
  REQUEST_RESENT_CLAIM_EMAIL_FULFILLED,
  REQUEST_RESENT_CLAIM_EMAIL_PENDING,
  REQUEST_RESENT_CLAIM_EMAIL_REJECTED,
} from './types';

const INITIAL_STATE = {
  REQUEST: {
    data: '',
    genreRequestData: '',
    claimRequestData: '',
    isFetching: false,
    isRequesting: false,
    isSendingEmail: false,
    error: null,
  },
  STATUS: {
    data: '',
    isFetching: false,
    error: '',
  },
};

const MESSAGES = {
  ERROR: 'Something Wrong With Search (Please Contact Chartmetric Team).',
  SUCCESS_SELECT: 'Approve / Decline Success!',
  SUCCESS_RESEND_EMAIL: 'Re-sent an email!',
};

const status = (state = INITIAL_STATE.STATUS, action) => {
  switch (action.type) {
    // GET STATUS
    case GET_CLAIM_REQUEST_STATUS_PENDING:
      return {
        ...state,
        data: '',
        isFetching: true,
      };
    // GET STATUS FULFILLED
    case GET_CLAIM_REQUEST_STATUS_FULFILLED: {
      const { data } = action.payload;
      return {
        ...state,
        data,
        isFetching: false,
      };
    }
    // GET STATUS REJECTED
    case GET_CLAIM_REQUEST_STATUS_REJECTED:
      showErrorMessage(action.payload.data?.error);
      return {
        ...state,
        data: '',
        error: action.payload.data?.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const request = (state = INITIAL_STATE.REQUEST, action) => {
  switch (action.type) {
    // GET DATA PENDING
    case GET_GENRE_REQUEST_PENDING:
    case GET_CLAIM_REQUEST_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    // ACTION PENDING
    case REQUEST_RESENT_CLAIM_EMAIL_PENDING:
      return {
        ...state,
        isSendingEmail: true,
      };
    // REQUEST PENDING
    case APPROVE_CLAIM_REQUEST_PENDING:
    case DECLINE_CLAIM_REQUEST_PENDING:
    case APPROVE_GENRE_REQUEST_PENDING:
    case DECLINE_GENRE_REQUEST_PENDING:
      return {
        ...state,
        isRequesting: true,
      };
    // GET DATA FULFILLED
    case GET_CLAIM_REQUEST_FULFILLED:
      if (action.payload.data?.error) {
        showErrorMessage(action.payload.data?.error);
        return {
          error: action.payload.data?.error,
          isFetching: false,
        };
      }
      return {
        claimRequestData: action.payload.data,
        isFetching: false,
      };
    case GET_GENRE_REQUEST_FULFILLED:
      if (action.payload.data?.error) {
        showErrorMessage(action.payload.data?.error);
        return {
          error: action.payload.data?.error,
          isFetching: false,
        };
      }
      return {
        genreRequestData: action.payload.data,
        isFetching: false,
      };
    // GET DATA REJECTED
    case GET_CLAIM_REQUEST_REJECTED:
    case GET_GENRE_REQUEST_REJECTED:
      showErrorMessage(MESSAGES.ERROR);
      return {
        error: MESSAGES.ERROR,
        isFetching: false,
        isRequesting: false,
        isSendingEmail: false,
      };
    // REQUEST REJECTED
    case APPROVE_GENRE_REQUEST_REJECTED:
    case DECLINE_GENRE_REQUEST_REJECTED:
    case APPROVE_CLAIM_REQUEST_REJECTED:
    case DECLINE_CLAIM_REQUEST_REJECTED:
    case REQUEST_RESENT_CLAIM_EMAIL_REJECTED:
      showErrorMessage(MESSAGES.ERROR);
      return {
        ...state,
        isFetching: false,
        isRequesting: false,
        isSendingEmail: false,
      };
    // REQUEST FULFILLED
    case APPROVE_CLAIM_REQUEST_FULFILLED:
    case DECLINE_CLAIM_REQUEST_FULFILLED:
    case APPROVE_GENRE_REQUEST_FULFILLED:
    case DECLINE_GENRE_REQUEST_FULFILLED:
      showInfoMessage(MESSAGES.SUCCESS_SELECT);
      return {
        ...state,
        isRequesting: false,
      };
    // ACTION FULFILLED
    case REQUEST_RESENT_CLAIM_EMAIL_FULFILLED:
      showInfoMessage(MESSAGES.SUCCESS_RESEND_EMAIL);
      return {
        ...state,
        isSendingEmail: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  request,
  status,
});
