import React from 'react';
import { connect } from 'react-redux';
import { Card, Image, Icon, List, Label, Input, Form } from 'semantic-ui-react';
import * as actions from '../../../actions';
import { generateCmUrl } from '../../../utilities';
import ExternalLinksPanel from '../../../shared/ExternalLinksPanel/ExternalLinksPanel';

function Info({ image_url, name, upc, id }) {
  return (
    <Card fluid>
      <Card.Content>
        <Image circular floated="right" size="small" src={image_url} />
        <Card.Header>
          <a href={generateCmUrl('album', { id })} target="_blank" rel="noopener noreferrer">
            {name}
          </a>
        </Card.Header>
        <Card.Content>
          <div>UPC : {upc}</div>
        </Card.Content>
      </Card.Content>
    </Card>
  );
}

class Album extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({
      ...this.props,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
    });
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleUpdateMetadata = () => {
    const { id, updateAlbum, fetchData } = this.props;
    const { label } = this.state;
    const metadata = {
      label,
    };
    updateAlbum(id, metadata).then(() => fetchData());
  };

  handleUpdateLinking = () => {
    const { id, updateAlbumLinking, fetchData } = this.props;
    const { q } = this.state;

    if (id && q) {
      updateAlbumLinking(id, q)
        .then(() => fetchData())
        .then(() => this.setState({ q: '' }));
    }
  };

  handleDeleteLinking = q => {
    const { updateAlbumLinking, fetchData, id } = this.props;
    if (q) {
      updateAlbumLinking(null, q).then(() => fetchData());
    }
  };

  render() {
    const { name, image_url, urls, tracks, response, fetchData, id } = this.props;

    const { q, label } = this.state;

    return (
      <div>
        <Info {...this.props} />

        <Card fluid>
          <Card.Content>
            <Form onSubmit={this.handleUpdateMetadata}>
              <Form.TextArea
                fluid
                label="Record Label"
                name="label"
                value={label}
                onChange={this.handleChange}
              />
              <Form.Button loading={response.isFetching} content="Update" fluid />
            </Form>
          </Card.Content>
        </Card>
        <ExternalLinksPanel urls={urls} type="album" callback={fetchData} id={id} />
      </div>
    );
  }
}

const mapStateToProps = state => ({ response: state.metadata.update });
export default connect(mapStateToProps, actions)(Album);
