function parseUrl(url: string) {
  const regex =
    /^(https?):\/\/(?:([^\.]+)\.)?([^\.]+)\.(co\.uk|com|org|net|me|app.link)(\/[^?]*)(?:\?(.*))?$/;
  const match = url.match(regex);

  const protocol = match?.[1];
  const subdomain = match?.[2] || '';
  const domain = match?.[3];
  const topLevelDomain = match?.[4];
  const path = match?.[5] || '';
  const queryString = match?.[6] || '';

  return { protocol, subdomain, domain, topLevelDomain, path, queryString };
}

export const convertUrl = (url: string) => {
  if (!url) return '';
  const { domain, path } = parseUrl(url);

  if (domain === 'x') {
    return `https://twitter.com${path}`;
  }

  return url;
};

export const checkFormatForArtist = (url: string) => {
  if (!url) return false;
  const { domain } = parseUrl(url);
  const pase = parseUrl(url);

  const message = 'Please enter supported URL format.';
  let regex;
  let format;

  if (!domain) return true; //for website url

  if (domain === 'amazon') {
    //only allow amazon url format
    regex = /https:\/\/music\.amazon\.com\/artists\/.*/;
    format = 'https://music.amazon.com/artists/...';
  } else if (domain === 'pandora') {
    //only allow pandora url format
    // https://www.pandora.com/artist/begin/ARwp2lqbgqcKPrZ
    regex = /https:\/\/www\.pandora\.com\/artist\/.*/;
    format = 'https://www.pandora.com/artist/...';
  }

  if (regex && !regex.test(url)) return `${message} ex) ${format}`;

  return true;
};

export const checkFormatForTrack = (url: string) => {
  if (!url) return false;
  const { domain } = parseUrl(url);

  const message = 'Please enter a valid URL.';
  let regex;
  let format;

  if (!domain) return message;

  if (domain === 'pandora') {
    return `${domain.toUpperCase()} is not supported via admin tool. Please contact data team.`;
  }
  if (domain === 'shazam') {
    //only allow shazam url format
    regex = /https:\/\/www\.shazam\.com\/(track|song)\/.*/;
    format = 'https://www.shazam.com/(track|song)/...';
  }

  if (domain === 'deezer') {
    //only allow deezer url format
    regex = /https:\/\/www\.deezer\.com\/track\/.*/;
    format = 'https://www.deezer.com/track/...';
  }

  if (regex && !regex.test(url)) return `${message} ex) ${format}`;

  return true;
};

export const checkFormatForAlbum = (url: string) => {
  if (!url) return false;
  const { domain } = parseUrl(url);

  const message = 'Please enter a valid URL.';
  let regex;
  let format;

  if (!domain) return message;

  if (domain === 'amazon') {
    //only allow amazon url format
    regex = /https:\/\/music\.amazon\.com\/albums\/.*/;
    format = 'https://music.amazon.com/albums/...';
  }

  if (domain === 'deezer') {
    //only allow deezer url format
    regex = /https:\/\/www\.deezer\.com\/album\/.*/;
    format = 'https://www.deezer.com/album/...';
  }

  if (regex && !regex.test(url)) return `${message} ex) ${format}`;

  return true;
};