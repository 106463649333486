import React from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Divider, Input, Label, Segment } from 'semantic-ui-react';

import * as actions from '../../../actions';

const mediaOptions = [
  { key: 'instagram', text: 'Instagram', value: 'instagram', icon: 'instagram' },
];

class MergeSocialStat extends React.Component {
  state = {
    mediaType: undefined,
    primaryId: '',
    secondaryId: '',
    showMainPanel: false,
  };

  handleMediaChange = (e, { value }) => this.setState({ mediaType: value });

  handlePrimaryIdChange = (e, { value }) => this.setState({ primaryId: value });

  handleSecondaryIdChange = (e, { value }) => this.setState({ secondaryId: value });

  handleButtonClick = () => {
    const { mediaType, primaryId, secondaryId } = this.state;
    this.props.mergeSocialStat(mediaType, primaryId, secondaryId);
  };

  render() {
    const { response } = this.props;

    const { mediaType, primaryId, secondaryId, showMainPanel } = this.state;

    return (
      <Segment raised>
        <Label>Merge old social media data to current one</Label>
        <Divider hidden />
        <Dropdown
          fluid
          selection
          placeholder="Social media"
          options={mediaOptions}
          onChange={this.handleMediaChange}
          value={mediaType}
        />
        <br />
        <Input
          fluid
          label={{ basic: true, content: 'Primary Id' }}
          labelPosition="right"
          name="primaryId"
          onChange={this.handlePrimaryIdChange}
          placeholder="Current social id. ex: billieeilish"
          size="large"
          value={primaryId}
        />
        <br />
        <Input
          fluid
          label={{ basic: true, content: 'Secondary Id' }}
          name="secondaryId"
          onChange={this.handleSecondaryIdChange}
          placeholder="Old social id. ex: whereareavocados"
          size="large"
          value={secondaryId}
        />
        <br />
        <Button
          disabled={!mediaType || !primaryId || !secondaryId}
          fluid
          icon="plus"
          labelPosition="right"
          loading={response.isFetching}
          secondary
          size="large"
          onClick={this.handleButtonClick}
        >
          Submit
        </Button>
      </Segment>
    );
  }
}

const mapStateToProps = state => ({ response: state.metadata.update });
export default connect(mapStateToProps, actions)(MergeSocialStat);
