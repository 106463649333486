import React, { useState } from 'react';
import { Input, Select, Button, Flex, Popconfirm, Checkbox } from 'antd';
import { toast } from 'react-toastify';
import { useSendPushNotification, useSendPushNotificationAll } from '@Services/user';
import { Panel, Table, AntdIcon } from '../../../shared/Style';
import GlobalSearch from '../../../shared/GlobalSearch/GlobalSearch';

const TARGET_OPTIONS = [
  {
    key: 'None',
    value: null,
    label: 'None',
  },
  {
    key: 'Artist',
    value: 'Artist',
    label: 'Artist',
  },
  {
    key: 'Track',
    value: 'Track',
    label: 'Track',
  },
  {
    key: 'Album',
    value: 'Album',
    label: 'Album',
  },
  {
    key: 'Playlist',
    value: 'Playlist',
    label: 'Playlist',
  },
  {
    key: 'Curator',
    value: 'Curator',
    label: 'Curator',
  },
];

const SCREEN_OPTIONS = [
  {
    key: 'None',
    value: null,
    label: 'None',
  },
  {
    key: 'Profile',
    value: 'Profile',
    label: 'Profile',
  },
];

const PLATFORM_OPTIONS = [
  {
    key: 'None',
    value: null,
    label: 'None',
  },
  {
    key: 'Spotify',
    value: 'spotify',
    label: 'Spotify',
  },
  {
    key: 'Deezer',
    value: 'deezer',
    label: 'Deezer',
  },
  {
    key: 'Itunes',
    value: 'itunes',
    label: 'Itunes',
  },
];

function PushNotificationManager() {
  const [emails, setEmails] = useState<string[]>([]);
  const [allUsers, setAllUsers] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [data, setData] = useState<Record<string, any>>({
    type: null,
    screen: 'Profile',
    id: null,
    streaming_type: null,
  });
  const { execute: sendPushNotification, isLoading } = useSendPushNotification();
  const { execute: sendPushNotificationAll, isLoading: isLoadingAll } =
    useSendPushNotificationAll();

  const handleSubmit = () => {
    if (allUsers)
      sendPushNotificationAll({
        data: { title, message, data },
      }).then(
        ({
          data: {
            obj: { message },
          },
        }) => toast.success(message)
      );
    else
      sendPushNotification({
        data: { emails, title, message, data },
      }).then(
        ({
          data: {
            obj: { message },
          },
        }) => toast.success(message)
      );
  };

  const handleSearchSubmit = userId => {
    if (emails.includes(userId)) return;
    setEmails(old => [...old, userId]);
  };

  const handleChangeCheckbox = () => {
    setAllUsers(old => !old);
  };

  return (
    <Panel title="Push Notification Manager">
      <h4>Select Users</h4>
      <Flex vertical gap="small" justify="end">
        <Checkbox checked={allUsers} onChange={handleChangeCheckbox}>
          Send to all Users
        </Checkbox>
        <GlobalSearch
          types={['user']}
          onSubmit={handleSearchSubmit}
          name="Search User"
          buttonIcon="search"
          buttonLabel="Add"
          icon="mobile"
          onChange={() => {}}
          disabled={allUsers}
          size="middle"
        />
        {emails.map((userId, idx) => (
          <Flex key={userId} gap="small">
            {idx + 1}. {userId}
            <AntdIcon
              name="close"
              color="red"
              onClick={() => setEmails(old => old.filter(id => id !== userId))}
            />
          </Flex>
        ))}
      </Flex>
      <br />
      <Table
        title="Details"
        options={[
          {
            label: 'Title',
            component: (
              <Input placeholder="Title" value={title} onChange={e => setTitle(e.target.value)} />
            ),
          },
          {
            label: 'Message',
            component: (
              <Input
                placeholder="Message"
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
            ),
          },
          {
            label: 'Target Screen (Optional)',
            component: (
              <Select
                placeholder="Select"
                options={TARGET_OPTIONS}
                value={data.type}
                onChange={value => setData(old => ({ ...old, type: value }))}
              />
            ),
          },
          // {
          //   label: 'Target Section (Optional)',
          //   component: (
          //     <Dropdown
          //       placeholder="Select"
          //       options={SCREEN_OPTIONS}
          //       value={data.screen}
          //       onChange={(e, { name, value }) => setData(old => ({ ...old, screen: value }))}
          //     />
          //   ),
          // },
          {
            label: 'Target ID (Optional)',
            component: (
              <Input
                placeholder="ex) 1234"
                value={data.id}
                onChange={({ target: { name, value } }) => setData(old => ({ ...old, id: value }))}
              />
            ),
          },
          {
            label: 'Target Platform (for Playlist, Curator)',
            component: (
              <Select
                placeholder="Select"
                value={data.streaming_type}
                options={PLATFORM_OPTIONS}
                onChange={value => setData(old => ({ ...old, streaming_type: value }))}
              />
            ),
          },
        ]}
      />
      <Flex justify="center">
        <Popconfirm
          title="Be careful this action"
          okText="Yes"
          cancelText="No"
          onConfirm={handleSubmit}
          description={
            <div>
              Are you sure you want to send push notification to{' '}
              {allUsers ? 'all users using mobile app?' : 'selected users?'}
            </div>
          }
        >
          <Button
            type="primary"
            loading={isLoadingAll || isLoading}
            icon={<AntdIcon name="send" />}
            disabled={(emails.length === 0 && !allUsers) || title === '' || message === ''}
          >
            Send Push notification
          </Button>
        </Popconfirm>
      </Flex>
    </Panel>
  );
}

export default PushNotificationManager;
