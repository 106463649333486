import { mutateToUrl, persistGetToUrl } from './helpers';

const ENDPOINT = {
  GET_LABEL_GROUP: `/admin/get-label-group`,
  EDIT_LABEL_GROUP: `/admin/edit-label-group`,
};

export const useGetLabelGroup = persistGetToUrl<{
  data: {
    label?: string;
  };
}>(ENDPOINT.GET_LABEL_GROUP, {
  manual: true,
});
export const useEditLabelGroup = mutateToUrl<{
  query: {
    parentLabel: string;
    subLabel?: string;
  };
}>('PUT', ENDPOINT.EDIT_LABEL_GROUP);
