import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import DatePicker from 'react-datepicker';
import { getDateFormatFromDatePicker, showInfoMessage, getDate } from '../../../../utilities';
import CampaignContentModal from '../Components/CampaignContentModal';
import { Panel, Table, Dropdown, Checkbox, AntdIcon } from '../../../../shared/Style';
import { useGetStripeProducts } from '../../../../services/campaign';
import StripeCouponPanel from '../Components/StripeCouponPanel';
import useStates from '../../../../hooks/useStates';
import 'react-datepicker/dist/react-datepicker.css';
import { Flex, Popconfirm, Typography, Button, Divider } from 'antd';
import { Content } from '../types';
import { STRIPE_STANDARD_PLANS } from '@Utils/constants';

interface State {
  productId?: string;
  priceId?: string;
  coupon?: Record<string, any>;
  enableCoupon: boolean;
  artistIds: string[];
  content?: Content;
  url?: string;
  expiredDate?: string;
}

function CampaignForUser() {
  const { data: stripeProducts, isLoading: isLoadingProducts } = useGetStripeProducts();

  const {
    setAllStates,
    states: { productId, priceId, coupon, enableCoupon, artistIds, content, expiredDate },
  } = useStates<State>({
    enableCoupon: false,
    artistIds: [],
  });

  const handleSelectProduct = (e, { value }) => {
    setAllStates({
      productId: value,
      priceId: '',
    });
  };

  const handleOnChange = (e, { name, value }) => {
    setAllStates({
      [name]: value,
    });
  };

  const handleSelectContent = (content: Content) => {
    setAllStates({
      content,
    });
  };

  const handleSelectCoupon = coupon => () => {
    setAllStates({
      coupon,
    });
  };

  const handleSelectDate = date => {
    setAllStates({
      expiredDate: date,
    });
  };

  const handleEnableCoupon = (e, { checked, name }) => {
    setAllStates({
      [name]: checked,
    });
  };

  const getPlanProductOptions = () =>
    stripeProducts?.products
      ?.reduce((all, one) => {
        all.push({
          text: one.name,
          value: one.id,
          label: {
            color: STRIPE_STANDARD_PLANS!.includes(one.id) ? 'blue' : 'white',
            empty: true,
            circular: true,
          },
        });
        return all;
      }, [])
      .sort((a, b) => (STRIPE_STANDARD_PLANS!.includes(a.value) ? -1 : 1));

  const getPlanPriceOptions = () =>
    stripeProducts?.prices?.[productId as string]
      ?.reduce((all, one) => {
        all.push({
          text: (
            <>
              ${one.unit_amount / 100} / {one.recurring?.interval_count} {one.recurring?.interval}
              <p>{one.nickname}</p>
            </>
          ),
          value: one.id,
          label: {
            color: STRIPE_STANDARD_PLANS!.includes(one.id) ? 'blue' : 'white',
            empty: true,
            circular: true,
          },
        });
        return all;
      }, [])
      .sort((a, b) => (STRIPE_STANDARD_PLANS!.includes(a.value) ? -1 : 1));

  const checkFormFilled = () => !(isEmpty(priceId) || !expiredDate || (enableCoupon && !coupon));

  const query = btoa(
    `priceId=${priceId as string}${content ? `&content=${content['id']}` : ''}${
      coupon ? `&couponCode=${coupon.id}` : ''
    }${artistIds.length > 0 ? `&artistIds=${artistIds.join(',')}` : ''}${
      expiredDate ? `&expiredDate=${getDate(expiredDate)}` : ''
    }`
  );

  const url = `https://app.chartmetric.com/campaign#code=${query}`;

  const handleCopy = url => e => {
    showInfoMessage('Copied!');
    navigator.clipboard.writeText(url);
  };

  return (
    <>
      <Panel
        title="Campaign For User"
        description="When users approach the link, they will be directed to the Chartmetric
          website. Once there, they can log in or sign up and then navigate to the Campaign page.
        "
      >
        <Table
          options={[
            {
              label: 'Plan *',
              component: (
                <Flex style={{ width: '100%' }} gap="middle">
                  <Dropdown
                    loading={isLoadingProducts}
                    placeholder="Select Product"
                    options={getPlanProductOptions()}
                    onChange={handleSelectProduct}
                  />
                  <Dropdown
                    loading={isLoadingProducts}
                    placeholder="Select Price"
                    options={getPlanPriceOptions()}
                    onChange={handleOnChange}
                    name="priceId"
                  />
                </Flex>
              ),
            },
            {
              label: 'Campaign Expired Date *',
              component: (
                <DatePicker
                  selected={expiredDate}
                  onChange={date => setAllStates({ expiredDate: date })}
                  dateFormat="yyyy-MM-dd"
                  customInput={
                    <Button
                      className={!expiredDate ? 'blue' : undefined}
                      icon={<AntdIcon name="check" />}
                    >
                      {expiredDate ? getDateFormatFromDatePicker(expiredDate) : 'Select'}
                    </Button>
                  }
                />
              ),
            },
            {
              label: 'Coupon',
              component: (
                <Checkbox
                  name="enableCoupon"
                  onChange={handleEnableCoupon}
                  checked={enableCoupon}
                  label={`${coupon ? `${coupon.name}(${coupon.id})` : 'Not selected'}`}
                  isToggle
                />
              ),
            },
            {
              label: 'Page Content',
              component: (
                <>
                  <CampaignContentModal onSelect={handleSelectContent} value={content} />
                  <br />
                </>
              ),
            },
          ]}
        />

        {url && checkFormFilled() && (
          <Flex vertical>
            <Divider />
            Campaign Link{' '}
            <Flex style={{ width: '100%' }} gap="middle" align="center">
              <Typography.Link href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </Typography.Link>
              <Button
                id={url}
                className="green"
                onClick={handleCopy(url)}
                icon={<AntdIcon name="copy" />}
              />
            </Flex>
            <br />
            <Typography.Text type="secondary">
              The Stripe plans provided in the campaign will automatically renew once the plan's
              duration has ended. A plan priced at $0 means that once a user subscribes, they can
              use it for free indefinitely. Avoid using the $0 plan and use a coupon instead.
            </Typography.Text>
            <Typography.Text type="warning">
              Please make sure to test the campaign link to ensure it works as expected.
            </Typography.Text>
          </Flex>
        )}
      </Panel>
      <StripeCouponPanel
        selectedCoupon={coupon}
        enableCoupon={enableCoupon}
        onSelectCoupon={handleSelectCoupon}
        priceId={priceId}
        productId={productId}
        products={stripeProducts}
      />
    </>
  );
}

export default CampaignForUser;
