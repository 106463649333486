import React, { useEffect,  useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Tooltip, Typography } from 'antd';
import { MENUS } from '../../constants/menus';
import { G_CLOUD_STORAGE } from '../../../resource';
import styles from './sidebar.module.scss';
// @ts-ignore
import { ReactComponent as OnesheetLogo } from '../../../assets/one-sheet-logo-blue.svg';

import FAIcon from '@Shared/Style/FAIcon';
import useSidebar from 'Routers/hooks/useSidebar';
import { useUser } from '@Services/auth';
import { generateRoute } from 'Routers/constants/path';

function Sidebar() {
  const isStagingEnv = process.env.REACT_APP_ENV === 'staging';
  const { logout } = useUser();
  const history = useHistory();

  const [expanded, setExpanded] = useState(false);
  const { showSidebar, toggleSidebar } = useSidebar();

  useEffect(() => {
    const triggerSidebar = () => {
      toggleSidebar(window.innerWidth > 964 || showSidebar);
      setExpanded(window.innerWidth > 964);
    };
    triggerSidebar();
    window.addEventListener('resize', triggerSidebar);
    return () => window.removeEventListener('resize', triggerSidebar);
  }, []);

  return (
    <>
      <div
        className={
          showSidebar && !expanded ? classNames(styles.dimmer, styles.show) : styles.dimmer
        }
        onClick={() => toggleSidebar(false)}
      />
      <div
        className={classNames(styles.sideBar, {
          [styles.visible]: showSidebar || expanded,
        })}
      >
        <div className={classNames(styles.logoContainer, 'hlg:!flex !hidden')}>
          <a
            className={styles.logo}
            href="https://app.chartmetric.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={G_CLOUD_STORAGE.CHARTMETRIC_LOGO}
              width={30}
              style={{ paddingBottom: '5px' }}
            />
          </a>

          <a
            className={styles.logo}
            href="https://onesheet.club"
            target="_blank"
            rel="noopener noreferrer"
          >
            <OnesheetLogo style={{ height: 32, width: 80 }} />
          </a>
        </div>
        <div>
          {MENUS.map(menu => (
            <Tooltip key={menu.name} title={menu.name} placement="right">
              <Link
                className={classNames(styles.menu, {
                  [styles.selected]: menu.route === `/${history.location.pathname.split('/')[1]}`,
                })}
                key={menu.name}
                to={
                  menu.items.length > 0
                    ? generateRoute(
                        menu.route,

                        `${menu.items[0].name.toLowerCase().replaceAll(' ', '-').replace('/', '-')}`
                      )
                    : '/'
                }
                onClick={() => {
                  toggleSidebar(false);
                }}
              >
                <FAIcon
                  name={menu.icon}
                  color={
                    menu.route === `/${history.location.pathname.split('/')[1]}`
                      ? '#ffffff'
                      : '#344B73'
                  }
                />

                <span className={styles.menuText}>{menu.name}</span>
              </Link>
            </Tooltip>
          ))}
          <Tooltip
            title="Logout"
            placement="right"
            className="
            hlg:absolute
             md:bottom-20
          bottom-8 left-0 right-0"
          >
            <li className={styles.menu} onClick={logout}>
              <FAIcon name="fadRightFromBracket" color={'#344B73'} onClick={logout} />
              Log out
            </li>
          </Tooltip>
        </div>
        <footer className=" hlg:absolute bottom-0">
          <Typography.Text type="secondary">
            {`version. ${isStagingEnv ? 'STAGING' : process.env.REACT_APP_VERSION}`}
          </Typography.Text>
        </footer>
      </div>
    </>
  );
}

export default Sidebar;
