import React, { useEffect, useRef } from 'react';
import type { GetProp, TableProps } from 'antd';
import { Card, Table } from 'antd';
import { get } from 'lodash';

// Will deprecated in the future and create theme in DataTable
type ColumnsType<T extends object> = GetProp<TableProps<T>, 'columns'>;
type ExpandableConfig<T extends object> = TableProps<T>['expandable'];

type DataType = any;

export interface Column<T> {
  Header: string | React.JSX.Element;
  accessor?: keyof T;
  width?: number;
  Cell?: React.FC<{ value: any; original: T; extraData?: Record<string, any> }> | string;
  align?: 'left' | 'right' | 'center';
  sorter?: ((a, b) => number) | boolean;
}

const getAntdColumns = <T,>(
  columns: Column<T>[],
  extraData?: Record<string, any>
): ColumnsType<any> =>
  columns.map(column => {
    const { Header, accessor, width, Cell, align, sorter } = column;
    return {
      title: Header,
      dataIndex: accessor,
      width: width ?? 100,
      align: align ?? 'center',
      render: (value, record) =>
        typeof Cell === 'string' ? (
          Cell
        ) : Cell ? (
          <Cell value={get(record, accessor ?? '')} original={record} extraData={extraData} />
        ) : (
          get(record, accessor ?? '') ?? ''
        ),
      sorter:
        sorter && accessor
          ? (a, b) => {
              const valueA = get(a, accessor);
              const valueB = get(b, accessor);
              //if date string
              if (
                new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/).test(valueA) &&
                new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/).test(valueB)
              ) {
                return new Date(valueA).getTime() - new Date(valueB).getTime();
              }

              if (typeof valueA === 'string' && typeof valueB === 'string') {
                return valueA.localeCompare(valueB);
              }

              return valueA - valueB;
            }
          : false,
    };
  });

interface Props<T extends Record<string, any>[]> {
  title?: string;
  loading?: TableProps<DataType>['loading'];
  footer?: TableProps<DataType>['footer'];
  expandable?: ExpandableConfig<DataType>;
  data: T | undefined;
  columns: Column<T[number]>[];
  lazyPaginationOptions?: {
    total: number;
    limit: number;
    offset: number;
    handleChangePage: (page: number) => void;
    handleChangePageSize: (pageSize: number) => void;
  };
  extraData?: Record<string, any>;
}

function AntdTable<T extends Record<string, any>[]>({
  lazyPaginationOptions,
  title,
  footer,
  expandable,
  data,
  columns,
  loading,
  extraData,
}: Props<T>) {
  const tableColumns = getAntdColumns(columns, extraData).map(item => ({
    ...item,
    ellipsis: false,
  }));
  const prevData = useRef(data);

  useEffect(() => {
    if (loading) return;
    prevData.current = data;
  }, [data]);

  const tableProps: TableProps<DataType> = {
    loading,
    expandable,
    title: () => title,
    footer,
  };

  return (
    <Table
      {...tableProps}
      pagination={{
        ...(lazyPaginationOptions && {
          current: lazyPaginationOptions.offset / lazyPaginationOptions.limit + 1,
          pageSize: lazyPaginationOptions.limit,
          total: lazyPaginationOptions.total,
        }),
        position: ['bottomRight'],
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        showSizeChanger: true,
      }}
      onChange={pagination => {
        lazyPaginationOptions?.handleChangePage(pagination.current! - 1);
        lazyPaginationOptions?.handleChangePageSize(pagination.pageSize!);
      }}
      columns={tableColumns}
      dataSource={(loading ? prevData?.current : data) ?? []}
      scroll={{
        x: '100%',
      }}
    />
  );
}

export default AntdTable;
