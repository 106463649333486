import React from 'react';
import { Dropdown as SDropdown } from 'semantic-ui-react';
/**
 * @deprecated
 */
interface Props {
  placeholder?: string;
  options: any;
  onChange: (e: any, data: any) => void;
  value?: string | boolean | number;
  loading?: boolean;
  name?: string;
  style?: React.CSSProperties;
}

/**
 * @Deprecated
 */
function Dropdown({ placeholder, options, onChange, value, loading, name, style }: Props) {
  return (
    <SDropdown
      style={style}
      loading={loading}
      fluid
      selection
      search
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      value={value}
      name={name}
    />
  );
}

export default Dropdown;
