import { mutateToUrl, persistGetToUrl } from './helpers';

const ENDPOINT = {
  CREATE_PORTAL_STRIPE_SESSION: `/stripe/portal`,
  CACHE_KEY: `/admin/cache`,
  SYNC_USER_STRIPE: `/admin/user/stripe`,
  SUGGESTIONS: {
    ENTITY: '/search/suggestion/global',
    USER: '/admin/suggestion/user',
    DSP: '/admin/suggestion/by-external-link',
  },
};

export const useCreateStripeSession = mutateToUrl('POST', ENDPOINT.CREATE_PORTAL_STRIPE_SESSION);
export const useDeleteCacheKey = mutateToUrl<{
  query: { key: string };
}>('DELETE', ENDPOINT.CACHE_KEY);
export const useSyncUserStripe = mutateToUrl<{
  data: { id: number };
}>('PATCH', ENDPOINT.SYNC_USER_STRIPE);


export interface SuggestionItem {
  match_strength: number;
  target: string;
  platform: string;
  id: number;
  name: string;
  imageUrl: string;
  targetExtras?: {
    stage?: string;
    code2?: string;
    primaryGenreId?: number;
    rank?: number;
    score?: number;
    verified?: boolean;
    ownerName?: string;
  };
}
export const useGetSuggestions = persistGetToUrl<
  {
    data: { q: string; offset: number; limit: number; targets?: string[] };
  },
  {
    suggestions: SuggestionItem[];
  }
>(ENDPOINT.SUGGESTIONS.ENTITY, {
  manual: true,
});

export interface UserSuggestionItem {
  id: number;
  email: string;
  verified: boolean;
  trusted: boolean;
}

export const useGetUserSuggestions = persistGetToUrl<
  {
    data: { q: string };
  },
  UserSuggestionItem[]
>(ENDPOINT.SUGGESTIONS.USER, {
  manual: true,
});
export const useGetDspSuggestions = persistGetToUrl<{
  data: { q: string };
}>(ENDPOINT.SUGGESTIONS.DSP, {
  manual: true,
});
