import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// CSS
import 'semantic-ui-css/semantic.min.css';
import 'react-table/react-table.css';
import './styles/sanitize.scss';
import './styles/antd.scss';
import './styles/responsible.scss';
import './styles/index.scss';
import './styles/tailwind.css';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Amplify } from 'aws-amplify';
import storeConfig, { history } from './store';
import Root from './Routers/Root';
import { AWS_CONFIG } from './aws-exports';
import { ConfigProvider } from 'antd';
import { ANTD_THEME } from 'styles/antd';

Amplify.configure(AWS_CONFIG);

//Configs
const store = storeConfig();
const client = new QueryClient();

ReactDOM.render(
  <ConfigProvider theme={ANTD_THEME}>
    <QueryClientProvider client={client}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <BrowserRouter>
            <React.StrictMode>
              <Root />
              <ToastContainer
                position="top-right"
                draggable
                pauseOnFocusLoss
                pauseOnHover
                enableMultiContainer
                hideProgressBar
                autoClose={3000}
                style={{ fontSize: 15, fontWeight: '500', borderRadius: '16px' }}
              />
              <div id="whats-this-tooltip" />
            </React.StrictMode>
          </BrowserRouter>
        </ConnectedRouter>
      </Provider>
    </QueryClientProvider>
  </ConfigProvider>,
  document.getElementById('root')
);
