import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { LOGOUT_USER } from './types';
import UserReducer from './UserReducer';
import SearchReducer from './SearchReducer';
import MetadataReducer from './MetadataReducer';
import LabelReducer from './LabelReducer';
import TeamReducer from './TeamReducer';
import CouponReducer from './CouponReducer';
import LogsReducer from './LogsReducer';
import UrlRequestChangeReducer from './UrlRequestChangeReducer';
import DspLinkingRequestReducer from './DspLinkingRequestReducer';
import ArtistIdentityRequestChangeReducer from './ArtistIdentityRequestChangeReducer';
import CacheReducer from './CacheReducer';
import LinkingReducer from './LinkingReducer';
import RequestReducer from './RequestReducer';
import UserAuthorityReducer from './UserAuthorityReducer';
import ActionReducer from './ActionReducer';
import ManagementReducer from './ManagementReducer';
import PurchaseReducer from './PurchaseReducer';

const appReducer = combineReducers({
  logs: LogsReducer,
  coupon: CouponReducer,
  team: TeamReducer,
  metadata: MetadataReducer,
  label: LabelReducer,
  user: UserReducer,
  search: SearchReducer,
  routing: routerReducer,
  urlRequestsChange: UrlRequestChangeReducer,
  dspLinkingRequest: DspLinkingRequestReducer,
  artistIdentityRequestsChange: ArtistIdentityRequestChangeReducer,
  cache: CacheReducer,
  linking: LinkingReducer,
  request: RequestReducer,
  authority: UserAuthorityReducer,
  action: ActionReducer,
  management: ManagementReducer,
  purchase: PurchaseReducer,
});

export default (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = undefined;
  }
  return appReducer(state, action);
};
