export const addComma = (num: number): string => {
  if (!num) return '';
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

 export const numericalScaleFormatter = (num: number, digits = 1) => {
   const si = [
     { value: 1, symbol: '' },
     { value: 1e3, symbol: 'K' },
     { value: 1e6, symbol: 'M' },
     { value: 1e9, symbol: 'B' },
     { value: 1e12, symbol: 'T' },
     { value: 1e15, symbol: 'P' },
     { value: 1e18, symbol: 'E' },
   ];
   const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
   let i;

   for (i = si.length - 1; i > 0; i--) {
     if (Math.abs(num) >= si[i].value) {
       break;
     }
   }

   const formatted = (num / si[i].value).toFixed(digits).replace(rx, '$1');
   if (isNaN(Number(formatted)) || formatted === 'NaN') {
     return '';
   }
   return formatted + si[i].symbol;
 };
