import { useUpdateChurnedUserComment } from '@Services/purchase';
import { AntdIcon } from '@Shared/Style';
import { Input } from 'antd';
import React, { useState, useEffect } from 'react';

function CommentBox({ ...props }) {
  const { execute } = useUpdateChurnedUserComment();

  const [isEditMode, setIsEditMode] = useState(false);
  const [value, setValue] = useState(props?.value ?? '');
  const [editingValue, setEditingValue] = useState(props?.value ?? '');

  useEffect(() => {
    setValue(props.value);
    setEditingValue(props.value);
  }, [props.value]);

  const onClickSave = () => {
    execute({
      data: {
        comment: editingValue,
      },
      path: {
        id: props.original.id,
      },
    }).then(() => {
      setValue(editingValue);
    });
    setIsEditMode(false);
  };

  const onChange = e => {
    setEditingValue(e.target.value);
  };

  return (
    <span style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
      {isEditMode ? (
        <Input.TextArea
          value={editingValue}
          onChange={onChange}
          style={{ width: '100%', borderColor: 'transparent' }}
        />
      ) : (
        <span style={{ flex: 1 }}>{value}</span>
      )}
      {isEditMode ? (
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, gap: 5 }}>
          <AntdIcon name="save" onClick={() => onClickSave()} />
          <AntdIcon name="close" onClick={() => setIsEditMode(false)} />
        </div>
      ) : (
        <AntdIcon name="editFilled" onClick={() => setIsEditMode(true)} />
      )}
    </span>
  );
}

export default CommentBox;
