import classNames from 'classnames';
import React from 'react';
import { Icon as SIcon, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
/**
 * @deprecated
 */
interface Props {
  name: SemanticICONS | string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>, data: any) => void;
  style?: React.CSSProperties;
  loading?: boolean;
  color?: SemanticCOLORS;
}

/**
 * @deprecated
 */
function Icon({ name, className, onClick, style, loading, color }: Props) {
  return (
    <SIcon
      // @ts-ignore
      color={color ?? ''}
      loading={loading}
      name={name as SemanticICONS}
      className={classNames(className, 'icon-button')}
      onClick={onClick}
      style={style}
    />
  );
}

export default Icon;
