import { UserSuggestionItem } from '@Services/system';
import { Avatar, List, Skeleton } from 'antd';
import React from 'react';
import styles from '../GlobalSearch.module.scss';

interface Props {
  list: UserSuggestionItem[];
  loading: boolean;
  onClick: (email: string) => (e) => void;
}

function UserItemList({ list, loading, onClick }: Props) {
  return (
    <List
      itemLayout="vertical"
      dataSource={
        loading ? (Array.from({ length: 2 }).map((_, i) => ({ key: `user${i}` })) as any[]) : list
      }
      renderItem={(
        item: {
          email: string;
          id: number;
        },
        index
      ) => (
        <Skeleton className={styles.skeletonItem} loading={loading} avatar>
          <List.Item onClick={onClick(item?.email)} key={`user${index}`}>
            <List.Item.Meta
              className={styles.listItem}
              avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
              title={
                <div
                  style={{
                    textAlign: 'left',
                  }}
                >
                  {item?.email}
                </div>
              }
              description={
                <div
                  style={{
                    textAlign: 'left',
                  }}
                >
                  ID:{item?.id}
                </div>
              }
            />
          </List.Item>
        </Skeleton>
      )}
    />
  );
}

export default UserItemList;
