import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Divider, Typography } from 'antd';
import useURLParams from '@Hooks/useURLParams';
import { Tabs, ResponsiveSection } from '@Shared/Style';
import { useGetUser } from '../../../services/user';
import GlobalSearch from '../../../shared/GlobalSearch/GlobalSearch';

import SessionCounts from './Components/SessionCounts';
import { FollowedArtist, ViewableArtist } from './Components/UserArtists';
import { LinksByUserDetails } from './Components/LinksBy';
import Info from './Components/Info';
import Claims from './Components/Claims';

const UserProfileContext = React.createContext<{
  refetch: (email?: string) => void;
}>({
  refetch: () => {},
});

export const useUserProfile = () => {
  const context = React.useContext(UserProfileContext);
  if (context === undefined) {
    throw new Error('useUserProfile must be used within a UserProfileContext');
  }
  return context;
};

function UserProfile() {
  const { setParams, params } = useURLParams();

  const {
    data,
    isLoading,
    isFetching,
    refetch: getUser,
  } = useGetUser(
    {
      data: { q: params.qs },
    },
    {
      disable: isEmpty(params.qs),
    }
  );

  const refetch = (email?: string) => {
    if (email) setParams({ qs: email });
    else getUser();
  };

  return (
    <UserProfileContext.Provider value={{ refetch }}>
      <GlobalSearch
        defaultValue={params.qs}
        loading={isLoading || isFetching}
        icon="user"
        types={['user']}
        onSubmit={(value: string) => {
          setParams({ qs: value });
        }}
        name="userSearch"
        variant="filled"
        className="top-search-box"
      />

      <Divider />
      {data?.email !== params.qs &&
        !isLoading &&
        !data?.error(
          <>
            <Typography.Text type="danger">
              This user has changed their email address. You searched previous email.
            </Typography.Text>
            <br />
            <br />
          </>
        )}
      {data && (
        <Tabs
          tabs={[
            {
              title: 'User Info',
              icon: 'farInfoCircle',
              content: <Info {...data} />,
            },
            {
              title: 'Session Counts',
              icon: 'fadChartLineUp',
              content: <SessionCounts q={data?.email} userId={data?.id} />,
            },
            {
              title: 'Followed Entity',
              icon: 'farHeart',
              content: <FollowedArtist userId={data?.id} />,
            },
            {
              title: 'Viewable Artist',
              icon: 'farEye',
              content: <ViewableArtist userId={data?.id} />,
            },
            {
              title: 'Links By User',
              icon: 'farLink',
              content: <LinksByUserDetails q={data?.email} />,
            },
            {
              title: 'Claims',
              icon: 'farHand',
              content: <Claims q={data?.email} />,
            },
          ]}
        />
      )}
    </UserProfileContext.Provider>
  );
}

export default UserProfile;
