import { Divider, Flex, Tag, Button, Typography } from 'antd';
import { capitalize } from 'lodash';
import React from 'react';
import { Panel, ActionInput, AntdIcon, BrandIcon } from '../Style';
import {
  checkFormatForAlbum,
  checkFormatForArtist,
  checkFormatForTrack,
  convertUrl,
} from './utils';
import { PLATFORMS_COLOR, DSP_PLATFORMS } from '@Utils/constants';
import { useVerifyDSP } from '@Services/linking';
import { UpdateLinkingMetaData } from './types';
import LinkSuggestions from './Components/LinkSuggestions';
import { useArtistLinking, useAlbumLinking, useTrackLinking, useCuratorLinking } from './hooks';

type Meta = {
  isrc?: string;
  artistName?: string;
  trackName?: string;
  streamingType?: string;
};

type Callback = (url: string, success: boolean) => void;


export type ExternalLinksPanelProps = {
  urls: Record<string, any>;
  id: number;
  callback: Callback;
  panelStyle?: boolean;
} & (
  | {
      type: any;
      meta?: never;
    }
  | {
      type: 'artist';
      meta: Pick<UpdateLinkingMetaData, 'artistName'>;
    }
  | {
      type: 'track';
      meta: Omit<UpdateLinkingMetaData, 'streamingType'>;
    }
  | {
      type: 'album';
      meta?: Omit<UpdateLinkingMetaData, 'streamingType'>;
    }
  | {
      type: 'curator';
      meta: Pick<UpdateLinkingMetaData, 'streamingType'>;
    }
);

const VerifyButton = ({ platform, id, callback }) => {
  const { execute: verifyDSP, isLoading: isLoadingVerifyDSP } = useVerifyDSP();

  const handleVerify = (platform: string) => {
    verifyDSP({
      data: {
        urlTarget: 'cm_artist',
        targetId: id,
        urlSource: platform,
      },
    }).then(() => callback(platform, true));
  };

  return (
    <Button
      loading={isLoadingVerifyDSP}
      onClick={() => handleVerify(platform)}
      className="green"
      icon={<AntdIcon name="checkCircle" />}
      style={{ marginLeft: 8 }}
    >
      Verify
    </Button>
  );
};

function ExternalLinksPanel({
  urls,
  type,
  meta,
  id,
  callback,
  panelStyle = true,
}: ExternalLinksPanelProps) {
  const { onAdd, onDelete, isLoading } = {
    artist: useArtistLinking,
    album: useAlbumLinking,
    track: useTrackLinking,
    curator: useCuratorLinking,
  }[type](id, callback, meta as UpdateLinkingMetaData);

  const handleUpdate = (
    _: React.ChangeEvent<HTMLInputElement>,
    _2: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string | number
  ) => onAdd(convertUrl(value as string).trim() as string);

  const handleDelete = (url: string) => {
    onDelete(url);
  };

  const render = () => (
    <>
      <ActionInput
        placeholder="Social URL. ex: https://www.deezer.com/us/artist/4050763"
        action={{
          icon: 'farPlus',
          content: 'Add Link',
          onClick: handleUpdate,
          loading: isLoading,
          disabledEmpty: true,
        }}
        clearAfterAction
        validation={
          {
            artist: checkFormatForArtist,
            track: checkFormatForTrack,
            album: checkFormatForAlbum,
          }[type]
        }
      />
      <Divider />
      <LinkSuggestions callback={callback} id={id} type={type} urls={urls} meta={meta} />
      <Typography.Title level={5} className="pb-2">
        Links
      </Typography.Title>
      <Panel.ResponsiveBody>
        {Object.entries(urls)
          .sort((a, b) => (a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1))
          .map(
            ([platform, value]) =>
              value?.url?.length > 0 && (
                <Panel
                  title={
                    <Flex gap="small" align="center" className="w-full" justify="space-between">
                      <Flex gap={4}>
                        <BrandIcon platform={platform as any} style={{ width: 16, height: 16 }} />
                        {capitalize(platform)}
                      </Flex>
                      <Flex align="center" gap={4}>
                        {value.is_verified && <AntdIcon name="checkCircle" color="blue" />}
                        {value.is_verified && value.modifier_email}{' '}
                        {!value.is_verified && type === 'artist' && (
                          <VerifyButton platform={platform} id={id} callback={callback} />
                        )}
                      </Flex>
                    </Flex>
                  }
                  innerStyle
                >
                  <Flex vertical gap="small">
                    {urls[platform]?.url.map(url => (
                      <Tag
                        key={url}
                        className="hoverable"
                        style={{
                          maxWidth: '100%',
                          width: 'fit-content',
                        }}
                        color={PLATFORMS_COLOR[platform]}
                        onClick={() => window.open(url, '_blank')}
                      >
                        <Flex gap="small">
                          <div
                            style={{
                              width: !(type === 'curator' && DSP_PLATFORMS.includes(platform))
                                ? 'calc(100% - 20px)'
                                : '100%',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {url}
                          </div>
                          {!(type === 'curator' && DSP_PLATFORMS.includes(platform)) &&
                            !(type === 'track' && platform === 'instagram') && (
                              <Button
                                style={{ padding: 0, margin: 0, height: 'auto' }}
                                type="text"
                                loading={isLoading}
                                onClick={event => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  handleDelete(url);
                                }}
                                icon={<AntdIcon name="close" />}
                              ></Button>
                            )}
                        </Flex>
                      </Tag>
                    ))}
                  </Flex>
                </Panel>
              )
          )}
      </Panel.ResponsiveBody>
    </>
  );

  if (panelStyle)
    return (
      <Panel
        title="DSP / Social Links"
        description={
          <>
            {type === 'artist'
              ? 'Social stats will be refreshed when new link added'
              : type === 'track'
              ? `Track linking has an issue with Data side. Please send a request to
          #customer-data-issue channel on Slack if you have any issue.`
              : null}
            {type === 'track' ? (
              <>
                <br />
                Pandora track urls do not work —to add Pandora data, add the Pandora artist url to
                the artist.
              </>
            ) : undefined}
          </>
        }
      >
        {render()}
      </Panel>
    );

  return render();
}

export default ExternalLinksPanel;
