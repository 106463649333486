import React from 'react';
import { Button, Card, Flex, Typography } from 'antd';
import ResponsiveSection from './ResponsiveSection';
import FAIcon, { FAIcons } from './FAIcon';

interface Props {
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  children?: React.ReactNode;
  extra?: React.ReactNode;
  innerStyle?: boolean;
  style?: React.CSSProperties;
  icon?: FAIcons;
  className?: string;
}

function Panel({ className, children, title, extra, innerStyle, style, description, icon }: Props) {
  return (
    <Card
      className={className}
      type={innerStyle ? 'inner' : undefined}
      title={
        <Flex vertical justify="flex-start" className="w-full">
          <Flex align="center" className="w-full">
            {innerStyle ? (
              <Typography.Text
                className="w-full"
                style={{ fontWeight: 500, display: 'flex', alignItems: 'center', gap: 4 }}
                type="secondary"
              >
                {icon && <FAIcon name={icon} color="#6f87af" className="pr-1" />}
                {title}
              </Typography.Text>
            ) : (
              <Typography.Text
                className="text-xl w-full"
                style={{
                  marginBottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                  color: '#61666c',
                }}
              >
                {icon && <FAIcon name={icon} color="#6f87af" className="mr-1" fontSize={22} />}
                {title}
              </Typography.Text>
            )}
          </Flex>
          {description && (
            <Typography.Text
              style={{
                fontWeight: 400,
                paddingTop: 10,
                paddingBottom: 10,
              }}
              type="secondary"
            >
              {description}
            </Typography.Text>
          )}
        </Flex>
      }
      extra={extra}
      style={style}
    >
      {children}
    </Card>
  );
}

function ResponsiveBody({ children }) {
  return <ResponsiveSection>{children}</ResponsiveSection>;
}

Panel.ResponsiveBody = ResponsiveBody;

export default Panel;
