import {
  createPortalSessionPromise,
  authUserPromise,
  loginUserPromise,
  logoutUserPromise,
  searchCMDataPromise,
  updateCMURLsPromise,
  updatePlaylistPromise,
  updateArtistPromise,
  updateAlbumPromise,
  updateArtistLinkingPromise,
  updateTrackLinkingPromise,
  deleteSimilarTrackPromise,
  addSimilarTrackPromise,
  addArtistPromise,
  mergeArtistsPromise,
  deletePlaylistPromise,
  grantAPIAccessPromise,
  removeAPIAccessPromise,
  removeSSOLoginPromise,
  verifyUserEmailPromise,
  addTrustedUserPromise,
  mergeSocialStatPromise,
  addNewArtistPromise,
  editLabelGroupPromise,
  getLabelGroupPromise,
  fetchTeamManagersPromise,
  addTeamManagerPromise,
  deleteTeamManagerPromise,
  fetchTeamMembersPromise,
  addTeamMemberPromise,
  deleteTeamMemberPromise,
  updateTeamMemberPromise,
  fetchCouponCodesPromise,
  deleteCouponCodePromise,
  updateCouponCodePromise,
  addCouponCodePromise,
  applyFreeTrialPromise,
  fetchUserInfoPromise,
  deleteUserPromise,
  getUserFollowedArtistsPromise,
  followArtistPromise,
  unfollowArtistPromise,
  getUserViewableArtistsPromise,
  subscribeToArtistPromise,
  unsubscribeFromArtistPromise,
  fetchUserAPIUsageStatsPromise,
  fetchUserAPIUsageDetailsPromise,
  fetchLinksByUserDetailsPromise,
  fetchLinksByUserHistoryPromise,
  blockUserPromise,
  unblockUserPromise,
  resetArtistPlanSelectionPromise,
  resetUserPasswordPromise,
  fetchPowerUserCurvePromise,
  fetchMainDBTableStatsPromise,
  fetchRecentAPIUsagePromise,
  changeUserEmailPromise,
  followArtistsPromise,
  fetchArtistIdentityChangeRequestPromise,
  fetchURLChangeRequestPromise,
  fetchDSPLinkingRequestPromise,
  bulkUpdateArtistIdentityChangeRequestPromise,
  bulkUpdateURLChangeRequestPromise,
  bulkUpdateDSPLinkingRequestPromise,
  updateAlbumLinkingPromise,
  stripeCreateCheckoutSessionPromise,
  getCacheKeyPromise,
  deleteCacheKeyPromise,
  getArtistTracksPromise,
  updateArtistTracksPromise,
  getClaimRequestsPromise,
  approveClaimRequestPromise,
  declineClaimRequestPromise,
  getTrustedUsersPromise,
  deleteTrustedUserPromise,
  addAdminUserPromise,
  deleteAdminUserPromise,
  getAdminUsersPromise,
  resendClaimEmailRequestPromise,
  getGenreRequestsPromise,
  approveGenreRequestPromise,
  declineGenreRequestPromise,
  addNewTrackPromise,
  getClaimRequestStatusPromise,
  sendGeneralEmailReportPromise,
  getChurnedUsersPromise,
  updateChurnedUserCommentPromise,
  addLabelCorrectionPromise,
  getArtistAlbumsPromise,
  updateArtistAlbumsPromise,
  getAssociatedLabelPromise,
  addAssociatedLabelPromise,
  getLabelPromise,
  addTrialSubScriptionPromise,
  getGenreTagListPromise,
  addTrackGenreTagPromise,
  deleteTrackGenreTagPromise,
  updateAssociatedLabelPromise,
  transferFavoritesPromise,
  cleanUpStripePromise,
  deleteArtistGenreTagPromise,
  getPageHistoryStatisticsPromise,
  fetchUserSessionCountsPromise,
  addArtistGenreTagPromise,
  getSuggestionsPromise,
  getStripeCouponsPromise,
  getStripeProductsPromise,
  getCampaignListPromise,
  addCampaignPromise,
  deleteCampaignPromise,
} from './promises';
import {
  AUTH_USER,
  LOGIN_USER,
  LOGOUT_USER,
  SEARCH_CM_DATA,
  UPDATE_CM_URLS,
  UPDATE_PLAYLIST,
  UPDATE_ARTIST,
  UPDATE_TRACK,
  DELETE_SIMILAR_TRACK,
  ADD_SIMILAR_TRACK,
  UPDATE_ALBUM,
  ADD_ARTIST,
  MERGE_ARTISTS,
  GRANT_API_ACCESS,
  REMOVE_API_ACCESS,
  REMOVE_SSO_LOGIN,
  VERIFY_USER_EMAIL,
  ADD_TRUSTED_USER,
  MERGE_SOCIAL_STAT,
  EDIT_LABEL_GROUP,
  GET_LABEL_GROUP,
  FETCH_TEAM_MANAGERS,
  ADD_TEAM_MANAGER,
  DELETE_TEAM_MANAGER,
  FETCH_TEAM_MEMBERS,
  UPDATE_TEAM_MEMBERS,
  FETCH_COUPON_CODES,
  UPDATE_COUPON_CODE,
  FETCH_USER_INFO,
  DELETE_USER,
  FETCH_USER_FOLLOWED_ARTISTS,
  FOLLOW_ARTIST_BY_USER,
  DELETE_FOLLOWED_ARTIST,
  FETCH_USER_VIEWABLE_ARTISTS,
  SUBSCRIBE_TO_ARTIST,
  DELETE_VIEWABLE_ARTIST,
  RESET_ARTIST_PLAN_SELECTION,
  RESET_USER_PASSWORD,
  FETCH_USER_API_USAGE_STATS,
  FETCH_USER_API_USAGE_DETAILS,
  FETCH_LINKS_BY_USER_DETAILS,
  FETCH_LINKS_BY_USER_HISTORY,
  USER_BLOCKLIST,
  FETCH_POWER_USER_CURVE,
  FETCH_MAIN_DB_TABLE_STATS,
  FETCH_RECENT_API_USAGE,
  CHANGE_USER_EMAIL,
  FOLLOW_ARTISTS,
  DELETE_PLAYLIST,
  FETCH_URL_CHANGE_REQUEST,
  FETCH_ARTIST_IDENTITY_CHANGE_REQUEST,
  BULK_UPDATE_URL_CHANGE_REQUEST,
  FETCH_DSP_LINKING_REQUEST,
  BULK_UPDATE_DSP_LINKING_REQUEST,
  BULK_UPDATE_ARTIST_IDENTITY_CHANGE_REQUEST,
  CREATE_STRIPE_CHECKOUT_SESSION,
  GET_CACHE_KEY,
  DELETE_CACHE_KEY,
  GET_CACHE_KEY_FULFILLED,
  DELETE_CACHE_KEY_FULFILLED,
  GET_CACHE_KEY_REJECTED,
  DELETE_CACHE_KEY_REJECTED,
  UPDATE_ARTIST_TRACKS,
  GET_CLAIM_REQUEST,
  APPROVE_CLAIM_REQUEST,
  DECLINE_CLAIM_REQUEST,
  DELETE_TRUSTED_USER,
  GET_TRUSTED_USER,
  GET_ADMIN_USER,
  DELETE_ADMIN_USER,
  ADD_ADMIN_USER,
  REQUEST_RESENT_CLAIM_EMAIL,
  GET_GENRE_REQUEST,
  APPROVE_GENRE_REQUEST,
  DECLINE_GENRE_REQUEST,
  ADD_TRACK,
  GET_CLAIM_REQUEST_STATUS,
  SEND_EMAIL_REPORT,
  CREATE_PORTAL_SESSION_FULFILLED,
  GET_CHURNED_USERS,
  UPDATE_CHURNED_USER_COMMENT,
  ADD_LABEL_CORRECTION,
  GET_ASSOCIATED_LABEL,
  UPDATE_ASSOCIATED_LABEL,
  ADD_ASSOCIATED_LABEL,
  GET_LABEL,
  ADD_TRIAL_SUBSCRIPTION,
  GET_MAIN_GENRE_TAG_LIST,
  GET_SUB_GENRE_TAG_LIST,
  UPDATE_TRACK_GENRE,
  TRANSFER_FAVORITES,
  CLEAN_UP_STRIPE,
  UPDATE_ARTIST_GENRE,
  GET_PAGE_HISTORY_STATISTICS,
  GET_USER_SESSION_COUNTS,
  GET_SUGGESTIONS,
  GET_STRIPE_COUPON_LIST,
  GET_STRIPE_PRODUCT_LIST,
  GET_CAMPAIGN_LIST,
  ADD_CAMPAIGN,
  DELETE_CAMPAIGN,
} from '../reducers/types';

export const createPortalSession = email => ({
  type: CREATE_PORTAL_SESSION_FULFILLED,
  payload: createPortalSessionPromise(email),
});

export const authUser = () => ({
  type: AUTH_USER,
  payload: authUserPromise(),
});

export const loginUser = (email, password) => ({
  type: LOGIN_USER,
  payload: loginUserPromise(email, password),
});
export const logoutUser = () => ({
  type: LOGOUT_USER,
  payload: logoutUserPromise(),
});

export const searchCMData = q => ({
  type: SEARCH_CM_DATA,
  payload: searchCMDataPromise(q),
});

export const getArtistTracks = q => ({
  type: SEARCH_CM_DATA,
  payload: getArtistTracksPromise(q),
});

export const updateArtistTracks = (q, useApi) => ({
  type: UPDATE_ARTIST_TRACKS,
  payload: updateArtistTracksPromise(q, useApi),
});

export const getArtistAlbums = q => ({
  type: SEARCH_CM_DATA,
  payload: getArtistAlbumsPromise(q),
});

export const updateArtistAlbums = q => ({
  type: UPDATE_ARTIST_TRACKS,
  payload: updateArtistAlbumsPromise(q),
});

export const updateCMURLs = (target, target_id, metadata) => ({
  type: UPDATE_CM_URLS,
  payload: updateCMURLsPromise(target, target_id, metadata),
});

export const updatePlaylist = (streamingType, id, storefront, metadata) => ({
  type: UPDATE_PLAYLIST,
  payload: updatePlaylistPromise(streamingType, id, storefront, metadata),
});

export const updateArtist = (id, metadata) => ({
  type: UPDATE_ARTIST,
  payload: updateArtistPromise(id, metadata),
});

export const updateAlbum = (id, metadata) => ({
  type: UPDATE_ALBUM,
  payload: updateAlbumPromise(id, metadata),
});

export const updateAlbumLinking = (id, q) => ({
  type: UPDATE_ALBUM,
  payload: updateAlbumLinkingPromise(id, q),
});

export const updateArtistLinking = (id, q, isDelete) => ({
  type: UPDATE_ARTIST,
  payload: updateArtistLinkingPromise(id, q, isDelete),
});

export const updateTrackLinking = (id, target, isrc, name, q, artists) => ({
  type: UPDATE_TRACK,
  payload: updateTrackLinkingPromise(id, target, isrc, name, q, artists),
});

export const deleteSimilarTrack = (id, trackId, hidden) => ({
  type: DELETE_SIMILAR_TRACK,
  payload: deleteSimilarTrackPromise(id, trackId, hidden),
});

export const addSimilarTrack = (id, trackId) => ({
  type: ADD_SIMILAR_TRACK,
  payload: addSimilarTrackPromise(id, trackId),
});

export const addArtist = q => ({
  type: ADD_ARTIST,
  payload: addArtistPromise(q),
});

export const mergeArtists = (primaryArtistUrl, secondaryArtistUrl) => ({
  type: MERGE_ARTISTS,
  payload: mergeArtistsPromise(primaryArtistUrl, secondaryArtistUrl),
});

export const grantAPIAccess = q => ({
  type: GRANT_API_ACCESS,
  payload: grantAPIAccessPromise(q),
});

export const removeAPIAccess = q => ({
  type: REMOVE_API_ACCESS,
  payload: removeAPIAccessPromise(q),
});

export const removeSSOLogin = q => ({
  type: REMOVE_SSO_LOGIN,
  payload: removeSSOLoginPromise(q),
});

export const verifyUserEmail = q => ({
  type: VERIFY_USER_EMAIL,
  payload: verifyUserEmailPromise(q),
});

export const addTrustedUser = q => ({
  type: ADD_TRUSTED_USER,
  payload: addTrustedUserPromise(q),
});

export const deleteTrustedUser = q => ({
  type: DELETE_TRUSTED_USER,
  payload: deleteTrustedUserPromise(q),
});

export const getTrustedUsers = () => ({
  type: GET_TRUSTED_USER,
  payload: getTrustedUsersPromise(),
});

export const addAdminUser = q => ({
  type: ADD_ADMIN_USER,
  payload: addAdminUserPromise(q),
});

export const deleteAdminUser = q => ({
  type: DELETE_ADMIN_USER,
  payload: deleteAdminUserPromise(q),
});

export const getAdminUsers = () => ({
  type: GET_ADMIN_USER,
  payload: getAdminUsersPromise(),
});

export const resetArtistPlanSelection = q => ({
  type: RESET_ARTIST_PLAN_SELECTION,
  payload: resetArtistPlanSelectionPromise(q),
});

export const mergeSocialStat = (mediaType, primaryId, secondaryId) => ({
  type: MERGE_SOCIAL_STAT,
  payload: mergeSocialStatPromise(mediaType, primaryId, secondaryId),
});

export const addNewArtist = request => ({
  type: ADD_ARTIST,
  payload: addNewArtistPromise(request),
});

export const addNewTrack = request => ({
  type: ADD_TRACK,
  payload: addNewTrackPromise(request),
});

export const editLabelGroup = (parentLabel, subLabel) => ({
  type: EDIT_LABEL_GROUP,
  payload: editLabelGroupPromise(parentLabel, subLabel),
});

export const getLabelGroup = label => ({
  type: GET_LABEL_GROUP,
  payload: getLabelGroupPromise(label),
});

export const deletePlaylist = (streamingType, id) => ({
  type: DELETE_PLAYLIST,
  payload: deletePlaylistPromise(streamingType, id),
});
/*
  Team Management
 */
export const fetchTeamManagers = () => ({
  type: FETCH_TEAM_MANAGERS,
  payload: fetchTeamManagersPromise(),
});
export const addTeamManager = q => ({
  type: ADD_TEAM_MANAGER,
  payload: addTeamManagerPromise(q),
});
export const deleteTeamManager = q => ({
  type: DELETE_TEAM_MANAGER,
  payload: deleteTeamManagerPromise(q),
});
export const fetchTeamMembers = q => ({
  type: FETCH_TEAM_MEMBERS,
  payload: fetchTeamMembersPromise(q),
});
export const addTeamMember = (teamManagerUserId, memberEmail, startTime) => ({
  type: UPDATE_TEAM_MEMBERS,
  payload: addTeamMemberPromise(teamManagerUserId, memberEmail, startTime),
});
export const deleteTeamMember = (teamManagerUserId, memberUserId) => ({
  type: UPDATE_TEAM_MEMBERS,
  payload: deleteTeamMemberPromise(teamManagerUserId, memberUserId),
});
export const updateTeamMember = (teamManagerUserId, memberUserId, body) => ({
  type: UPDATE_TEAM_MEMBERS,
  payload: updateTeamMemberPromise(teamManagerUserId, memberUserId, body),
});
/*
  Coupon Management
 */
export const fetchCouponCodes = expired => ({
  type: FETCH_COUPON_CODES,
  payload: fetchCouponCodesPromise(expired),
});
export const addCouponCode = body => ({
  type: UPDATE_COUPON_CODE,
  payload: addCouponCodePromise(body),
});
export const deleteCouponCode = id => ({
  type: UPDATE_COUPON_CODE,
  payload: deleteCouponCodePromise(id),
});
export const updateCouponCode = (id, body) => ({
  type: UPDATE_COUPON_CODE,
  payload: updateCouponCodePromise(id, body),
});
export const applyFreeTrial = (code, q) => ({
  type: UPDATE_COUPON_CODE,
  payload: applyFreeTrialPromise(code, q),
});
/*
  API Usage
 */
export const fetchUserInfo =
  (q, loadArtists = false) =>
  dispatch => {
    const userInfoPromise = dispatch({
      type: FETCH_USER_INFO,
      payload: fetchUserInfoPromise(q),
    });
    if (loadArtists) {
      userInfoPromise.then(result => {
        dispatch(fetchUserFollowedArtists(result.action.payload.data.id));
        dispatch(fetchUserViewableArtists(result.action.payload.data.id));
      });
    }
  };
export const deleteUser = q => ({
  type: DELETE_USER,
  payload: deleteUserPromise(q),
});
export const fetchUserFollowedArtists = id => ({
  type: FETCH_USER_FOLLOWED_ARTISTS,
  payload: getUserFollowedArtistsPromise(id),
});
export const followArtistByUser = (id, cm_artist) => ({
  type: FOLLOW_ARTIST_BY_USER,
  payload: followArtistPromise(id, { cm_artist }),
});
export const deleteFollowedArtistForUser = (id, cm_artist) => ({
  type: DELETE_FOLLOWED_ARTIST,
  payload: unfollowArtistPromise(id, { cm_artist }),
});
export const fetchUserViewableArtists = id => ({
  type: FETCH_USER_VIEWABLE_ARTISTS,
  payload: getUserViewableArtistsPromise(id),
});
export const subscribeToArtist = (id, cm_artist) => ({
  type: SUBSCRIBE_TO_ARTIST,
  payload: subscribeToArtistPromise(id, { cm_artist }),
});
export const unsubscribeFromArtist = (id, cm_artist) => ({
  type: DELETE_VIEWABLE_ARTIST,
  payload: unsubscribeFromArtistPromise(id, { cm_artist }),
});
export const fetchUserAPIUsageStats = (q, fromDaysAgo, toDaysAgo) => ({
  type: FETCH_USER_API_USAGE_STATS,
  payload: fetchUserAPIUsageStatsPromise(q, fromDaysAgo, toDaysAgo),
});
export const fetchUserAPIUsageDetails = (q, limit, offset) => ({
  type: FETCH_USER_API_USAGE_DETAILS,
  payload: fetchUserAPIUsageDetailsPromise(q, limit, offset),
});
export const fetchLinksByUserDetails = (q, fromDaysAgo, toDaysAgo) => ({
  type: FETCH_LINKS_BY_USER_DETAILS,
  payload: fetchLinksByUserDetailsPromise(q, fromDaysAgo, toDaysAgo),
});
export const fetchLinksByUserHistory = (q, limit, offset) => ({
  type: FETCH_LINKS_BY_USER_HISTORY,
  payload: fetchLinksByUserHistoryPromise(q, limit, offset),
});
export const blockUser = q => ({
  type: USER_BLOCKLIST,
  payload: blockUserPromise(q),
});
export const unblockUser = q => ({
  type: USER_BLOCKLIST,
  payload: unblockUserPromise(q),
});
export const resetUserPassword = q => ({
  type: RESET_USER_PASSWORD,
  payload: resetUserPasswordPromise(q),
});
export const changeUserEmail = (id, email) => ({
  type: CHANGE_USER_EMAIL,
  payload: changeUserEmailPromise(id, email),
});
export const followArtists = (id, ids, type) => ({
  type: FOLLOW_ARTISTS,
  payload: followArtistsPromise(id, ids, type),
});
export const fetchPowerUserCurve = fromDaysAgo => ({
  type: FETCH_POWER_USER_CURVE,
  payload: fetchPowerUserCurvePromise(fromDaysAgo),
});
export const fetchRecentAPIUsage = since => ({
  type: FETCH_RECENT_API_USAGE,
  payload: fetchRecentAPIUsagePromise(since),
});

/*
  Custom Logs
*/
export const fetchMainDBTableStats = fromDaysAgo => ({
  type: FETCH_MAIN_DB_TABLE_STATS,
  payload: fetchMainDBTableStatsPromise(fromDaysAgo),
});

export const fetchDSPLinkingRequest = () => ({
  type: FETCH_DSP_LINKING_REQUEST,
  payload: fetchDSPLinkingRequestPromise(),
});

export const fetchURLChangeRequest = () => ({
  type: FETCH_URL_CHANGE_REQUEST,
  payload: fetchURLChangeRequestPromise(),
});

export const fetchArtistIdentityChangeRequest = () => ({
  type: FETCH_ARTIST_IDENTITY_CHANGE_REQUEST,
  payload: fetchArtistIdentityChangeRequestPromise(),
});

export const bulkUpdateDSPLinkingRequest = requests => ({
  type: BULK_UPDATE_DSP_LINKING_REQUEST,
  payload: bulkUpdateDSPLinkingRequestPromise(requests),
});

export const bulkUpdateURLChangeRequest = requests => ({
  type: BULK_UPDATE_URL_CHANGE_REQUEST,
  payload: bulkUpdateURLChangeRequestPromise(requests),
});

export const bulkUpdateArtistIdentityChangeRequest = requests => ({
  type: BULK_UPDATE_ARTIST_IDENTITY_CHANGE_REQUEST,
  payload: bulkUpdateArtistIdentityChangeRequestPromise(requests),
});

/*
  Stripe
*/
export const stripeCreateCheckoutSession = (priceId, email, artistIds, couponCode) => ({
  type: CREATE_STRIPE_CHECKOUT_SESSION,
  payload: stripeCreateCheckoutSessionPromise(priceId, email, artistIds, couponCode),
});

export const getStripeCoupons = () => ({
  type: GET_STRIPE_COUPON_LIST,
  payload: getStripeCouponsPromise(),
});

export const getStripeProducts = () => ({
  type: GET_STRIPE_PRODUCT_LIST,
  payload: getStripeProductsPromise,
});

/*
  Campaign
*/

export const getCampaignList = () => ({
  type: GET_CAMPAIGN_LIST,
  payload: getCampaignListPromise(),
});
export const addCampaign = ({ domain, priceId, couponCode, contentId }) => ({
  type: ADD_CAMPAIGN,
  payload: addCampaignPromise({ domain, priceId, couponCode, contentId }),
});
export const deleteCampaign = ({ id }) => ({
  type: DELETE_CAMPAIGN,
  payload: deleteCampaignPromise({ id }),
});

/*
  Cache 
*/
export const getCacheKey = key => ({
  type: GET_CACHE_KEY,
  payload: getCacheKeyPromise(key),
});

export const deleteCacheKey = key => ({
  type: DELETE_CACHE_KEY,
  payload: deleteCacheKeyPromise(key),
});

// Claim
export const getClaimRequests = () => ({
  type: GET_CLAIM_REQUEST,
  payload: getClaimRequestsPromise,
});
export const getClaimRequestStatus = code => ({
  type: GET_CLAIM_REQUEST_STATUS,
  payload: getClaimRequestStatusPromise(code),
});
export const approveClaimRequest = id => ({
  type: APPROVE_CLAIM_REQUEST,
  payload: approveClaimRequestPromise(id),
});
export const declineClaimRequest = (id, reason) => ({
  type: DECLINE_CLAIM_REQUEST,
  payload: declineClaimRequestPromise(id, { reason }),
});

export const resendClaimEmail = (email, name, code, targetName, targetId) => ({
  type: REQUEST_RESENT_CLAIM_EMAIL,
  payload: resendClaimEmailRequestPromise({ email, name, code, targetName, targetId }),
});

//Genre Request
export const getGenreRequests = () => ({
  type: GET_GENRE_REQUEST,
  payload: getGenreRequestsPromise(),
});
export const approveGenreRequest = id => ({
  type: APPROVE_GENRE_REQUEST,
  payload: approveGenreRequestPromise(id),
});
export const declineGenreRequests = id => ({
  type: DECLINE_GENRE_REQUEST,
  payload: declineGenreRequestPromise(id),
});

//Email Report
export const sendGeneralEmailReport = ({ id, email }) => ({
  type: SEND_EMAIL_REPORT,
  payload: sendGeneralEmailReportPromise({ id, email }),
});

//Churned User
export const getChurnedUsers = () => ({
  type: GET_CHURNED_USERS,
  payload: getChurnedUsersPromise(),
});

export const updateChurnedUserComment = (id, comment) => ({
  type: UPDATE_CHURNED_USER_COMMENT,
  payload: updateChurnedUserCommentPromise(id, comment),
});

// Label Correction
export const addLabelCorrection = ({
  flagAudiobooks,
  ignoreBefore,
  ignoreOriginalLabel,
  originalLabel,
  revisedLabel,
  subLabel,
  type,
}) => ({
  type: ADD_LABEL_CORRECTION,
  payload: addLabelCorrectionPromise({
    flagAudiobooks,
    ignoreBefore,
    ignoreOriginalLabel,
    originalLabel,
    revisedLabel,
    subLabel,
    type,
  }),
});
export const getLabel = ({ label }) => ({
  type: GET_LABEL,
  payload: getLabelPromise({
    label,
  }),
});

export const getAssociatedLabel = ({ q }) => ({
  type: GET_ASSOCIATED_LABEL,
  payload: getAssociatedLabelPromise({
    q,
  }),
});

export const addAssociatedLabel = ({ cm_artist, label, subLabel, type, hidden }) => ({
  type: ADD_ASSOCIATED_LABEL,
  payload: addAssociatedLabelPromise({
    cm_artist,
    label,
    subLabel,
    type,
    hidden,
  }),
});

export const updateAssociatedLabel = ({ cm_artist, label, subLabel, type, hidden, manual }) => ({
  type: UPDATE_ASSOCIATED_LABEL,
  payload: updateAssociatedLabelPromise({
    cm_artist,
    label,
    subLabel,
    type,
    hidden,
    manual,
  }),
});

export const addTrialSubscription = ({ type, email, duration }) => ({
  type: ADD_TRIAL_SUBSCRIPTION,
  payload: addTrialSubScriptionPromise({ type, email, duration }),
});

export const getMainGenreTagList = () => ({
  type: GET_MAIN_GENRE_TAG_LIST,
  payload: getGenreTagListPromise({ type: 'main' }),
});

export const getSubGenreTagList = () => ({
  type: GET_SUB_GENRE_TAG_LIST,
  payload: getGenreTagListPromise({ type: 'sub' }),
});

export const addTrackGenreTag = ({ id, tag }) => ({
  type: UPDATE_TRACK_GENRE,
  payload: addTrackGenreTagPromise({ id, tag }),
});

export const deleteTrackGenreTag = ({ id, tag }) => ({
  type: UPDATE_TRACK_GENRE,
  payload: deleteTrackGenreTagPromise({ id, tag }),
});

export const transferFavorites = ({ from_email, to_email }) => ({
  type: TRANSFER_FAVORITES,
  payload: transferFavoritesPromise({ from_email, to_email }),
});

export const cleanUpStripe = () => ({
  type: CLEAN_UP_STRIPE,
  payload: cleanUpStripePromise(),
});

export const deleteArtistGenreTag = ({ id, tag, type }) => ({
  type: UPDATE_ARTIST_GENRE,
  payload: deleteArtistGenreTagPromise({ id, tag, type }),
});

export const addArtistGenreTag = ({ id, tag, type }) => ({
  type: UPDATE_ARTIST_GENRE,
  payload: addArtistGenreTagPromise({ id, tag, type }),
});

export const getPageHistoryStatistics = ({ fromDaysAgo }) => ({
  type: GET_PAGE_HISTORY_STATISTICS,
  payload: getPageHistoryStatisticsPromise({ fromDaysAgo }),
});

export const fetchUserSessionCounts = ({ type, email, date }) => ({
  type: GET_USER_SESSION_COUNTS,
  payload: fetchUserSessionCountsPromise({ type, email, date }),
});

export const getSuggestions = ({ type = 'all', q }) => ({
  type: GET_SUGGESTIONS,
  payload: getSuggestionsPromise({ type, q }),
});
