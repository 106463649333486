import React from 'react';
import { isEmpty } from 'lodash';
import styles from './Intercome.module.scss';

function LocalIntercom() {
  if (isEmpty(process.env.REACT_APP_NOTICE)) return null;
  return (
    <div className={styles.intercom}>
      <div className={styles.background} />
      <p
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_NOTICE }}
      />
    </div>
  );
}

export default LocalIntercom;
