import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Label, Divider, Input } from 'semantic-ui-react';
import * as actions from '../../../actions';
import { generateCmUrl } from '../../../utilities';
import SearchInput from '../../../shared/Table/SearchInput';
import { DataTable } from '../../../shared/Style';
import { capitalize } from 'lodash';

const columns = [
  {
    Header: 'Type',
    accessor: 'state',
    width: 125,
    Cell: props => (
      <Label color="orange" size="tiny">
        {capitalize(props.value.split('.')[0])}
      </Label>
    ),
  },
  {
    Header: 'Page',
    accessor: 'state', // String-based value accessors!
    width: 250,
    Cell: props => (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={generateCmUrl(props.value.split('.').slice(1).join('.').replace('.', '/'), {
          id: props.original.page_id ?? undefined,
        })}
      >
        {props.value.replace('mainApp.', '').replace('.', '/')}
      </a>
    ),
  },
  {
    Header: 'ID',
    accessor: 'page_id',
  },
  {
    Header: 'Count',
    accessor: 'count',
  },
];

class PageHistoryStatistics extends Component {
  state = {
    fromDaysAgo: 3,
  };

  componentDidMount() {
    const { fromDaysAgo } = this.state;
    this.props.getPageHistoryStatistics({ fromDaysAgo });
  }

  onSearch = filteredData => {
    this.setState({
      filteredData,
    });
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleClick = () => {
    const { fromDaysAgo } = this.state;
    this.props.getPageHistoryStatistics({ fromDaysAgo });
  };

  render() {
    const { q, fromDaysAgo } = this.state;
    const { data, isLoading } = this.props;

    return (
      <Segment raised loading={isLoading} color="orange">
        <Label>Compile statistics where users visited from {fromDaysAgo} days ago</Label>
        <Divider hidden />
        <Input
          fluid
          label={{ content: 'From Days Ago' }}
          labelPosition="left"
          name="fromDaysAgo"
          onChange={this.handleChange}
          placeholder="From days ago"
          size="large"
          value={fromDaysAgo}
          action={{
            labelPosition: 'right',
            icon: 'search',
            content: 'Search',
            onClick: this.handleClick,
            loading: isLoading,
          }}
        />
        <Divider />
        <SearchInput originalData={data} onChange={this.onSearch} />
        <Divider hidden />
        {typeof data === 'object' && Object.keys(data).length > 0 && (
          <DataTable
            key="genreRequestsTable"
            data={this.state.filteredData !== undefined ? this.state.filteredData : data}
            columns={columns}
          />
        )}
      </Segment>
    );
  }
}

const mapStateToProps = state => state.management;
export default connect(mapStateToProps, actions)(PageHistoryStatistics);
