import React, { useEffect } from 'react';
import { useCustomQuery } from '@Services/helpers';
import { useAlbumLinking, useArtistLinking, useTrackLinking } from '../hooks';
import { UpdateLinkingMetaData } from '../types';
import { convertUrl } from '../utils';
import {
  getLinkSuggestionsCacheKey,
  LINK_SUGGESTION_SUPPORTED_PLATFORMS,
  useLGetLinkSuggestions as useGetLinkSuggestions,
} from '@Services/linking';
import { Button, Divider, Flex, Skeleton, Typography } from 'antd';
import { AntdIcon, BrandIcon, Panel } from '@Shared/Style';
import { ExternalLinksPanelProps } from '../ExternalLinksPanel';
import { capitalize } from 'lodash';

const SuggestionLabel = ({
  url,
  callback,
  id,
  type,
  meta,
  cacheKey,
  title,
}: { url: string; cacheKey: any; title: string } & Omit<
  ExternalLinksPanelProps,
  'urls' | 'panelStyle'
>) => {
  const { onAdd, isLoading } = {
    artist: useArtistLinking,
    album: useAlbumLinking,
    track: useTrackLinking,
  }[type](id, callback, meta as UpdateLinkingMetaData);
  const { data, update } = useCustomQuery(cacheKey);

  return (
    <>
      <Flex gap="small" align="center">
        <Button
          onClick={() => {
            onAdd(convertUrl(url as string).trim() as string).then(() => {
              update(old => {
                const platform = Object.keys(data).find(key =>
                  data[key].some(item => item.url === url)
                );
                if (platform) {
                  return {
                    ...old,
                    [platform]: old[platform].filter(item => item.url !== url),
                  };
                }
                return old;
              });
            });
          }}
          loading={isLoading}
          icon={<AntdIcon name={'plus'} />}
          type="text"
        ></Button>
        <Flex vertical>
          {title && <Typography.Text>{title}</Typography.Text>}
          <Typography.Link target="_blank" href={url}>
            {url}
          </Typography.Link>
        </Flex>
      </Flex>
    </>
  );
};

const LinkSuggestions = ({
  callback,
  id,
  type,
  urls,
  meta,
}: Omit<ExternalLinksPanelProps, 'panelStyle'>) => {
  const missingPlatforms = LINK_SUGGESTION_SUPPORTED_PLATFORMS[type].filter(
    (platform: string) => !(Object.keys(urls) as string[]).includes(platform)
  );
  const params = {
    path: {
      // @ts-ignore
      target: type,
    },
    data: {
      artistName: (meta as UpdateLinkingMetaData)?.artistName,
      trackName: (meta as UpdateLinkingMetaData)?.trackName ?? undefined,
      platforms: missingPlatforms,
    },
  };
  const cacheKey = getLinkSuggestionsCacheKey(params);

  const {
    data: suggestions,
    isLoading,
    refetch,
  } = useGetLinkSuggestions(
    // @ts-ignore
    params,
    {
      disable: !['artist', 'track'].includes(type) || missingPlatforms.length === 0,
    }
  );

  useEffect(() => {
    refetch();
  }, [id]);

  if (isLoading)
    return (
      <Flex vertical gap="small">
        <Typography.Title level={5}>💡 Suggestions</Typography.Title>
        <Skeleton.Node
          active
          style={{
            height: '150px',
          }}
        >
          <span />
        </Skeleton.Node>
        <Divider />
      </Flex>
    );

  return Object.values(suggestions ?? {}).flat().length > 0 ? (
    <Flex vertical gap="small">
      <Typography.Title level={5}>💡 Suggestions</Typography.Title>
      <Panel.ResponsiveBody>
        {Object.entries(suggestions ?? {}).map(
          ([platform, urls]) =>
            urls?.length > 0 && (
              <Panel
                title={
                  <Flex gap={4}>
                    <BrandIcon platform={platform as any} style={{ width: 16, height: 16 }} />
                    {capitalize(platform)}
                  </Flex>
                }
                innerStyle
              >
                <Flex vertical gap="small">
                  {urls.map(({ url, title }) => (
                    <SuggestionLabel
                      cacheKey={cacheKey}
                      url={url}
                      callback={callback}
                      id={id}
                      type={type}
                      meta={meta}
                      title={title}
                    />
                  ))}
                </Flex>
              </Panel>
            )
        )}
      </Panel.ResponsiveBody>
      <Divider />
    </Flex>
  ) : null;
};

export default LinkSuggestions;
