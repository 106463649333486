import { User } from './auth';
import { mutateToUrl, persistGetToUrl } from './helpers';

const ENDPOINT = {
  MANAGERS: `/admin/teamManagers`,
  MANAGER: `/admin/teamManager`,
  MEMBERS: `/admin/teamMembers`,
};

export type TeamManager = {
  id: number;
  customer_id: string;
  name: string;
  email: string;
  image_url: string | null;
  company: string;
  num_members: number;
  stripe_product_ids: string[];
};

export const useGetTeamManagers = persistGetToUrl<{}>(ENDPOINT.MANAGERS);

export const useAddTeamManager = mutateToUrl<{
  data: {
    q: string;
  };
}>('POST', ENDPOINT.MANAGER);

export const useDeleteTeamManager = mutateToUrl<{
  query: {
    q: string;
  };
}>('DELETE', ENDPOINT.MANAGER);

export const useGetTeamMembers = persistGetToUrl<
  {
    data: { q: string };
  },
  {
    manager: TeamManager;
    members: User[];
    pastMembers: User[];
  }
>(ENDPOINT.MEMBERS);

export const useAddTeamMember = mutateToUrl<{
  data: {
    memberEmail: string;
    teamManagerUserId: number;
  };
}>('POST', ENDPOINT.MEMBERS);

export const useUpdateTeamMember = mutateToUrl<{
  data: {
    admin: boolean;
    memberUserId: number;
    teamManagerUserId: number;
    verified: boolean;
  };
}>('PUT', ENDPOINT.MEMBERS);

export const useDeleteTeamMember = mutateToUrl<{
  query: {
    memberUserId: number;
    teamManagerUserId: number;
  };
}>('DELETE', ENDPOINT.MEMBERS);
