import React from 'react';
import { useCustomQuery } from '@Services/helpers';
import { useGetSocialUrlsManual } from '@Services/social';
import { ExternalLinksPanel } from '@Shared';
import { AntdIcon } from '@Shared/Style';
import { Button, Typography } from 'antd';
import { SUPPORT_PLATFORMS, TABS } from '../constants';
import { generateCmUrl } from '@Utils';
import ModalButton from '@Shared/Style/ModalButton';
import { LinkCompletenessItem } from '@Services/linkCompleteness';

export const LinkingPanel = ({
  entity,
  item,
  cacheKey,
}: {
  entity: (typeof TABS)[number];
  item: LinkCompletenessItem;
  cacheKey: any;
}) => {
  const { id, links, isrc, name, artists } = item;

  const { data, update } = useCustomQuery(cacheKey);
  const { refetch: getSocialUrls } = useGetSocialUrlsManual({
    path: {
      id,
      entity: {
        artist: 'cm_artist',
        track: 'cm_track',
      }[entity] as any,
    },
  });

  return (
    <ExternalLinksPanel
      panelStyle={false}
      type={entity as any}
      urls={
        links
          ? Object.entries(links).reduce((acc, [key, value]) => {
              if (SUPPORT_PLATFORMS[entity].includes(key)) {
                acc[key] = value;
              }
              return acc;
            }, {})
          : {}
      }
      id={id}
      meta={
        entity === 'artist'
          ? {
              artistName: name,
            }
          : {
              artistName: artists?.[0]?.name,
              trackName: name,
              isrc: isrc,
            }
      }
      callback={async () => {
        const socialUrls = Object.entries(await getSocialUrls().then(res => res.data)).reduce(
          (acc, [key, value]) => {
            if (SUPPORT_PLATFORMS[entity].includes(key)) {
              acc[key] = value;
            }
            return acc;
          },
          {}
        );

        return update({
          ...data,
          data: data.data.map(item => {
            if (item.id === id) {
              return {
                ...item,
                links: socialUrls,
              };
            }
            return item;
          }),
        });
      }}
    />
  );
};

const LinkingModal = React.memo(
  ({ original, extraData }: { original: LinkCompletenessItem; value: any; extraData: any }) => {
    return (
      <ModalButton
        cancelButtonProps={{ style: { display: 'none' } }}
        style={{
          minWidth: '50vw',
        }}
        title={
          <>
            DSP / Social Links for{' '}
            <Typography.Link
              style={{ fontSize: 16 }}
              target="_blank"
              href={generateCmUrl(extraData.entity, {
                id: original.id,
              })}
            >
              {original.name}
            </Typography.Link>
            {original.artists && original.artists.length > 0 && (
              <Typography.Text type="secondary" style={{ fontSize: 16 }}>
                {` by ${original.artists.map(artist => artist.name).join(', ')}`}
              </Typography.Text>
            )}
          </>
        }
        Button={<Button icon={<AntdIcon name="list" />} type="primary" />}
      >
        <LinkingPanel entity={extraData.entity} item={original} cacheKey={extraData.cacheKey} />
      </ModalButton>
    );
  }
);

export default LinkingModal;
