import { combineReducers } from 'redux';
import { showInfoMessage, showErrorMessage } from '../utilities';
import {
  GET_CACHE_KEY_FULFILLED,
  GET_CACHE_KEY_REJECTED,
  DELETE_CACHE_KEY_FULFILLED,
  DELETE_CACHE_KEY_REJECTED,
} from './types';

const INITIAL_STATE = {
  GET: {
    data: null,
    isFetching: false,
    error: null,
  },
  DELETE: {
    data: null,
    isFetching: false,
    error: null,
  },
};

const MESSAGES = {
  GET_FULFILLED: 'Successfully Retrieved Cached Value!',
  GET_ERROR: 'Cannot Retrieve Cached Value (Please Contact Chartmetric Team).',
  DELETE_ERROR: 'Something Wrong With Delete Operation (Please Contact Chartmetric Team).',
  DELETE_FULFILLED: 'Successfully Deleted Cached Value!',
};

const cacheDelete = (state = INITIAL_STATE.DELETE, action) => {
  switch (action.type) {
    case DELETE_CACHE_KEY_FULFILLED: {
      const data = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      showInfoMessage(MESSAGES.DELETE_FULFILLED);
      return {
        data: null,
        isFetching: false,
      };
    }
    case DELETE_CACHE_KEY_REJECTED: {
      showErrorMessage(MESSAGES.DELETE_ERROR);
      return {
        error: MESSAGES.DELETE_ERROR,
        isFetching: false,
      };
    }
    case GET_CACHE_KEY_FULFILLED: {
      const data = action.payload;
      if (!data || data?.data === null) {
        showErrorMessage('Cached Value Is Null Or Cache Key Was Not Found.');
        return {
          error: 'Cached Value Is Null Or Cache Key Was Not Found.',
          isFetching: false,
        };
      }
      showInfoMessage(MESSAGES.GET_FULFILLED);
      return {
        data,
        isFetching: false,
      };
    }
    case GET_CACHE_KEY_REJECTED: {
      return {
        error: MESSAGES.GET_ERROR,
        isFetching: false,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  cacheDelete,
});
