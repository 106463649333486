import { combineReducers } from 'redux';
import { showInfoMessage, showErrorMessage } from '../utilities';
import {
  EDIT_LABEL_GROUP_PENDING,
  EDIT_LABEL_GROUP_FULFILLED,
  EDIT_LABEL_GROUP_REJECTED,
  GET_LABEL_GROUP_PENDING,
  GET_LABEL_GROUP_FULFILLED,
  GET_LABEL_GROUP_REJECTED,
  GET_ASSOCIATED_LABEL_PENDING,
  GET_ASSOCIATED_LABEL_FULFILLED,
  GET_ASSOCIATED_LABEL_REJECTED,
  UPDATE_ASSOCIATED_LABEL_PENDING,
  UPDATE_ASSOCIATED_LABEL_REJECTED,
  UPDATE_ASSOCIATED_LABEL_FULFILLED,
  ADD_ASSOCIATED_LABEL_PENDING,
  ADD_ASSOCIATED_LABEL_REJECTED,
  ADD_ASSOCIATED_LABEL_FULFILLED,
  GET_LABEL_PENDING,
  GET_LABEL_FULFILLED,
  GET_LABEL_REJECTED,
} from './types';

const INITIAL_STATE = {
  data: null,
  checkLabelData: {},
  isFetching: false,
  isLoading: false,
  isLoadingCheckLabel: false,
  error: null,
};

const MESSAGES = {
  ERROR: 'Something Wrong With API (Please Contact Chartmetric Team).',
  FULFILLED: 'Successfully Updated!',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LABEL_PENDING:
      return {
        ...state,
        isLoadingCheckLabel: true,
      };
    case GET_ASSOCIATED_LABEL_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ASSOCIATED_LABEL_REJECTED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_LABEL_REJECTED:
      return {
        ...state,
        isLoadingCheckLabel: false,
      };
    case GET_LABEL_FULFILLED:
      return {
        ...state,
        checkLabelData: action.payload.data,
        isLoadingCheckLabel: false,
      };
    case GET_LABEL_GROUP_FULFILLED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case GET_ASSOCIATED_LABEL_FULFILLED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case GET_LABEL_GROUP_PENDING:
    case UPDATE_ASSOCIATED_LABEL_PENDING:
    case ADD_ASSOCIATED_LABEL_PENDING:
    case EDIT_LABEL_GROUP_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case EDIT_LABEL_GROUP_FULFILLED:
    {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      if (action.type === EDIT_LABEL_GROUP_FULFILLED) showInfoMessage(MESSAGES.FULFILLED);
      return {
        data,
        isFetching: false,
      };
    }
    case UPDATE_ASSOCIATED_LABEL_FULFILLED:
    case ADD_ASSOCIATED_LABEL_FULFILLED:
      showInfoMessage(MESSAGES.FULFILLED);
      return {
        ...state,
        isFetching: false,
      };

    case GET_LABEL_GROUP_REJECTED:
    case EDIT_LABEL_GROUP_REJECTED:
      showErrorMessage(MESSAGES.ERROR);
      return {
        error: MESSAGES.ERROR,
        isFetching: false,
      };
    case UPDATE_ASSOCIATED_LABEL_REJECTED:
    case ADD_ASSOCIATED_LABEL_REJECTED:
      return {
        ...state,
        error: MESSAGES.ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};
