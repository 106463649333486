import { useGetEntity } from '@Services/search';
import { Panel, Table } from '@Shared/Style';
import React, { useEffect, useState } from 'react';
import { EditEntityProps } from '..';
import { isEmpty } from 'lodash';
import { Avatar, Button, Flex, Input, Typography } from 'antd';
import { generateCmUrl } from '@Utils';
import { useUpdateAlbum } from '@Services/meta';

const Album = ({ q }: EditEntityProps) => {
  const {
    data: trackData,
    isFetching,
    refetch,
  } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );

  const data = trackData.albums[0];

  const [label, setLabel] = useState(data.label);
  const { execute, isLoading } = useUpdateAlbum();

  useEffect(() => {
    setLabel(data.label);
  }, [data]);

  return (
    <Table
      title="Album"
      panelStyle
      options={[
        {
          label: 'Name',
          component: (
            <Flex gap="small" align="center">
              <Avatar src={data.image_url} size="large" shape="square" style={{ marginRight: 6 }} />
              <Typography.Link
                target="_blank"
                rel="noopener noreferrer"
                href={generateCmUrl('album', { id: data.id })}
              >
                {data.name}
              </Typography.Link>{' '}
            </Flex>
          ),
          labelIcon: 'tag',
        },
        {
          label: 'Label',
          labelIcon: 'album',
          component: (
            <Flex gap="small" justify="space-between">
              <Input
                style={{ maxWidth: '300px' }}
                value={data.label}
                onChange={e => {
                  setLabel(e.target.value);
                }}
              />
              <Button
                loading={isLoading}
                className="green"
                onClick={() => {
                  execute({ query: { id: data.id }, data: { label } }).then(() => refetch());
                }}
              >
                Update
              </Button>
            </Flex>
          ),
        },
        {
          label: 'UPC',
          value: data.upc,
          labelIcon: 'tag',
        },
      ]}
    />
  );
};

export default Album;
