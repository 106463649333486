import React from 'react';
import { Tabs } from '@Shared/Style';
import SearchUser from './SearchTeamUser';
import SearchTeam from './SearchTeam';
import TeamList from './TeamList';
import CreateTeam from './CreateTeam';

function TeamManager() {
  return (
    <Tabs
      tabs={[
        {
          title: 'Search User',
          content: <SearchUser />,
        },
        {
          title: 'Search Team',
          content: <SearchTeam />,
        },
        {
          title: 'All Teams',
          content: <TeamList />,
        },
        {
          title: 'Create Team',
          content: <CreateTeam />,
        },
      ]}
    />
  );
}

export default TeamManager;
