import React from 'react';
import { Form, Button, Input, Flex, Typography, Divider, Tag } from 'antd';
import { Redirect } from 'react-router-dom';
import { useUser } from '@Services/auth';
import { G_CLOUD_STORAGE } from '../resource';
import FAIcon from '@Shared/Style/FAIcon';
import { ROUTE_PATH } from './constants/path';

function Login() {
  const { login, isAuthed, isLoggingIn } = useUser();

  const handleLogin = async (email: string, password: string) => {
    login({ username: email, password });
  };

  if (isAuthed)
    return (
      <Redirect
        to={
          window.location.search
            ? window.location.search.replace('?redirect=', '') + window.location.hash
            : ROUTE_PATH.HOME
        }
      />
    );

  return (
    <Flex
      style={{
        height: '100vh',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <Flex
        justify="center"
        align="center"
        style={{
          height: '100%',
          width: '100%',
        }}
        vertical
      >
        <Tag className="cm" style={{ marginBottom: 20 }}>
          Admin Tool
        </Tag>
        <Flex align="center" gap="large" className="show-on-mobile">
          <a
            className="logo"
            href="https://app.chartmetric.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Chartmetric Logo"
          >
            <img
              alt="Chartmetric"
              src={G_CLOUD_STORAGE.CHARTMETRIC_LOGO}
              width={30}
              style={{ paddingBottom: '5px' }}
            />
          </a>
          |
          <a
            className="logo"
            href="https://onesheet.club"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="One Sheet Club Logo"
          >
            <img
              alt="One Sheet Club"
              src={
                'https://cdn.prod.website-files.com/632189deb4ae204cd483ebe2/64d2f144f3927f249eb79222_Blue-OnesheetLogo-p-500.png'
              }
              width={100}
            />
          </a>
        </Flex>

        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={values => {
            handleLogin(values.email, values.password);
          }}
          style={{
            padding: '30px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 20,
          }}
        >
          <Form.Item name="email" rules={[{ required: true, message: 'Enter the email' }]}>
            <Input
              allowClear
              size="large"
              prefix={<FAIcon name="farUser" />}
              placeholder="Email"
              style={{ width: '300px', height: '45px' }}
            />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Enter the password' }]}>
            <Input
              allowClear
              size="large"
              prefix={<FAIcon name="farLockKeyhole" />}
              type="password"
              placeholder="Password"
              style={{ width: '300px', height: '45px' }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              size="large"
              loading={isLoggingIn}
              htmlType="submit"
              className="cm"
              style={{ width: '300px', height: '45px' }}
            >
              Sign In
            </Button>
          </Form.Item>
        </Form>
        <Divider style={{ width: '300px', minWidth: '0px' }} />
        <Typography.Text style={{ fontSize: '12px' }}>
          copyright © Chartmetric. All right reserved.
        </Typography.Text>
      </Flex>
    </Flex>
  );
}

export default Login;
