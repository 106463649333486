import React, { useEffect } from 'react';
import Playlist from './Playlist';
import Album from './Album';

import useURLParams from '@Hooks/useURLParams';
import { useGetEntity } from '@Services/search';
import { GlobalSearch } from '@Shared';
import { Divider } from 'antd';
import EditArtist from './EditArtist';
import { isEmpty } from 'lodash';
import EditCurator from './EditCurator';
import EditTrack from './EditTrack';

export type EditEntityProps = {
  q: string;
};

const EditCMData = () => {
  const {
    setParams,
    params: { q },
  } = useURLParams();

  const { data, isFetching, refetch } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );

  return (
    <>
      <GlobalSearch
        types={['artist', 'playlist', 'track', 'album', 'curator']}
        className="top-search-box"
        icon="edit"
        loading={isFetching}
        onSubmit={q => setParams({ q: q as string })}
        variant="filled"
      />
      <Divider />
      {data?.type === 'artist' && <EditArtist q={q} />}
      {data?.type === 'track' && <EditTrack q={q} />}
      {data?.type === 'playlist' && <Playlist q={q} id={data.id} {...data} fetchData={refetch} />}
      {data?.type === 'album' && <Album q={q} id={data.id} {...data} fetchData={refetch} />}
      {data?.type === 'curator' && <EditCurator q={q} />}
    </>
  );
};

export default EditCMData;
