import { useCallback, useState } from 'react';

/* Local constants & types
============================================================================= */
export type ModalPropsType<T = any> = {
  show: boolean;
} & T;

/* useModal() hook
============================================================================= */
const useModal = <T>() => {
  const [modalProps, setModalProps] = useState<ModalPropsType<T>>({ show: false } as never);

  const closeModal = useCallback(() => {
    setModalProps(old => ({ ...old, show: false }));
  }, []);

  const showModal = useCallback((props?: T) => {
    setModalProps({
      // @ts-ignore
      show: true,
      ...props,
    });
  }, []);

  return { showModal, closeModal, modalProps };
};

export default useModal;
