import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Image,
  Icon,
  Form,
  Input,
  TextArea,
  Button,
  Select,
  Flag,
  Segment,
} from 'semantic-ui-react';
import { capitalize } from 'lodash';
import * as actions from '../../../actions';
import { generateCmUrl, showErrorMessage } from '../../../utilities';

const MESSAGES = {
  STREAMING_TYPE_NOT_AVAILABLE_UPDATE: 'Streaming type not available for update.',
};

const PERSONALIZED_OPTIONS = [
  {
    key: true,
    value: true,
    text: 'Personalized',
  },
  {
    key: false,
    value: false,
    text: 'Not Personalized',
  },
];
class Playlist extends Component {
  state = {};

  componentDidMount() {
    this.setState({
      ...this.props,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
    });
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleUpdateMetadata = () => {
    const { id, streamingType, updatePlaylist, fetchData, code: storefront } = this.props;
    const { code2, personalized } = this.state;
    const metadata = {
      code2,
      personalized,
    };
    //BACKEND never supported update for non spotify playlist. Should let the user know.
    if (streamingType === 'spotify') {
      updatePlaylist(streamingType, id, storefront, metadata).then(() => fetchData());
    } else {
      showErrorMessage(MESSAGES.STREAMING_TYPE_NOT_AVAILABLE_UPDATE);
    }
  };

  render() {
    const {
      playlist_id,
      name,
      image_url,
      description,
      tags,
      //API Response
      response,
      id,
      streamingType,
    } = this.props;

    //Editable states
    const { code2, personalized, monthly_listeners } = this.state;

    return (
      <div>
        <Card fluid>
          <Card.Content>
            <Image floated="right" size="small" src={image_url} />
            <Card.Header>
              <a href={generateCmUrl('playlist', { id })} target="_blank" rel="noopener noreferrer">
                {name}
              </a>
              {code2 && (
                <div>
                  <Flag name={code2} />
                </div>
              )}
            </Card.Header>
            <Card.Meta>Streaming Type: {capitalize(streamingType)}</Card.Meta>
            <Card.Description>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </Card.Description>
          </Card.Content>
          <Card.Content textAlign="center" extra>
            Tags: {tags && tags.map(tag => tag.name).join(', ')}
          </Card.Content>
        </Card>
        <Form onSubmit={this.handleUpdateMetadata}>
          <Form.Group widths="equal">
            {code2 && (
              <Form.Select
                placeholder="Country"
                name="code2"
                value={code2}
                // TODO: bug
                // options={countryOptions}
                onChange={this.handleChange}
              />
            )}
            {personalized != null && (
              <Form.Select
                fluid
                placeholder="Personalized"
                name="personalized"
                value={personalized}
                options={PERSONALIZED_OPTIONS}
                onChange={this.handleChange}
              />
            )}
          </Form.Group>
          <Form.Button loading={response.isFetching} content="Update" fluid />
        </Form>
      </div>
    );
  }
}
const mapStateToProps = state => ({ response: state.metadata.update });
export default connect(mapStateToProps, actions)(Playlist);
