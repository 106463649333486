import localStorage from 'localStorage';
import { combineReducers } from 'redux';
import { showInfoMessage, showErrorMessage } from '../utilities';
import {
  AUTH_USER_PENDING,
  AUTH_USER_FULFILLED,
  AUTH_USER_REJECTED,
  LOGIN_USER_PENDING,
  LOGIN_USER_FULFILLED,
  LOGIN_USER_REJECTED,
} from './types';

const INITIAL_STATE = {
  AUTH: {
    isFetching: false,
    message: null,
    error: null,
  },
};

const MESSAGES = {
  ERROR: 'Unable To Auth User (Please Contact Chartmetric Team).',
  USER_WAS_AUTHED:
    'Welcome to Chartmetric Admin Tool. Please be careful for all the operations here since it could have huge impacts on production.',
};

const auth = (state = INITIAL_STATE.AUTH, action) => {
  switch (action.type) {
    case LOGIN_USER_PENDING:
    case AUTH_USER_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case LOGIN_USER_FULFILLED:
    case AUTH_USER_FULFILLED: {
      const { data } = action.payload;
      if (data?.error) {
        showErrorMessage(data?.error);
        return {
          error: data?.error,
          isFetching: false,
        };
      }
      showInfoMessage(MESSAGES.USER_WAS_AUTHED);
      return {
        authed: true,
        isFetching: false,
      };
    }
    case LOGIN_USER_REJECTED:
    case AUTH_USER_REJECTED:
      showErrorMessage(MESSAGES.ERROR);
      return {
        error: MESSAGES.ERROR,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  auth,
});
