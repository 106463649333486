import React from 'react';
import ReactTable from 'react-table';
import { capitalize } from 'lodash';
import { Badge, Checkbox, Divider, Flex, Input, Radio, Tag } from 'antd';
import { Link } from 'react-router-dom';
import SearchInput from '../../../shared/Table/SearchInput';
import { generateCmUrl, generateInternalLink, getDateFormat } from '../../../utilities';
import { DataTable, Panel } from '../../../shared/Style';
import { useGetDspLinkRequest, useSubmitDspLinkRequest } from '../../../services/request';
import useRequestTable from '../../../hooks/useRequstTable';
import RequestSubmitButton from '../../../shared/Table/SubmitModal';
import LinksCell from '../../../shared/Table/LinksCell';

const SEARCH_FILTERS = [
  {
    label: 'Source From',
    value: 'source_from',
  },
  {
    label: 'Requester',
    value: 'user_email',
  },
  {
    label: 'Created At',
    value: 'created_at',
  },
  {
    label: 'New Url',
    value: 'new_url',
  },
  {
    label: 'Current Url',
    value: 'current_url',
  },
  {
    label: 'Target Id',
    value: 'target_id',
  },
  {
    label: 'Id',
    value: 'id',
  },
];

const SUBMIT_MODAL_COLUMNS = [
  {
    Header: 'Links',
    Cell: ({ original }) => (
      <LinksCell
        currentUrl={original.current_url}
        newUrl={original.new_url}
        targetId={original.target_id}
      />
    ),
  },
  {
    Header: 'Requester',
    Cell: ({ original }) =>
      capitalize(original.user_email ?? original.source_from?.split(':')[0] ?? ''),
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    Cell: ({ value }) => getDateFormat(value),
  },
];

const COLUMNS = ({
  onClickApprove,
  onClickDecline,
  approves,
  declines,
  onClickSelectApproveAll,
  onClickSelectDeclineAll,
  data,
}) => [
  {
    Header: 'Id',
    accessor: 'id',
  },
  {
    Header: 'Source From',
    accessor: 'source_from',
    Cell: props => (props.value ? capitalize(props.value.split(':')[0]) : 'CM'),
  },
  {
    Header: 'Target Id',
    accessor: 'target_id',
    Cell: props => (
      <a
        href={generateCmUrl('artist', { id: props.value })}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.value}
      </a>
    ),
  },
  {
    Header: 'Links',
    accessor: 'new_url',
    Cell: props => (
      <LinksCell
        currentUrl={props.original.current_url}
        newUrl={props.value}
        targetId={props.original.target_id}
      />
    ),
    width: 900,
  },
  {
    Header: 'Requester',
    accessor: 'user_email',
    Cell: props =>
      props.original.source_from?.split(':')[0] === 'cm' ? (
        <Link
          to={generateInternalLink('user/user-profile', {
            qs: props.value ?? props.original.source_from?.split(':')[1] ?? '',
          })}
        >
          {props.value ?? props.original.source_from?.split(':')[1] ?? ''}
        </Link>
      ) : (
        capitalize(props.value ?? props.original.source_from?.split(':')[1] ?? '')
      ),
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    Cell: props => getDateFormat(props.value),
  },
  {
    accessor: 'id',
    Cell: ({ value }) => (
      <Flex justify="center">
        <Radio
          name={`approve_${value}`}
          checked={approves.includes(value) ?? false}
          onClick={() => onClickApprove(value)}
        />
      </Flex>
    ),
    Header: () => (
      <Flex justify="center" align="center" gap="small">
        Approve
        <Checkbox
          checked={approves.length === data?.length && data?.length > 0}
          onClick={onClickSelectApproveAll}
        />
      </Flex>
    ),
    width: 100,
  },
  {
    accessor: 'id',
    Cell: ({ value }) => (
      <Flex justify="center">
        <Radio
          name={`decline_${value}`}
          checked={declines.includes(value) ?? false}
          onClick={() => onClickDecline(value)}
        />
      </Flex>
    ),
    Header: () => (
      <Flex justify="center" align="center" gap="small">
        Decline
        <Checkbox
          checked={declines.length === data?.length && data?.length > 0}
          onClick={onClickSelectDeclineAll}
        />
      </Flex>
    ),
    width: 100,
  },
];

function DSPLinkingRequest() {
  const {
    data,
    isLoading,
    state,
    onSearch,
    onClickApprove,
    onClickDecline,
    submit,
    isSubmitting,
    onClickSelectApproveAll,
    onClickSelectDeclineAll,
    selected,
  } = useRequestTable(useGetDspLinkRequest, useSubmitDspLinkRequest);

  const onClickSubmit = () =>
    submit(
      (id, status) => ({ id, process: status }),
      (approves, declines) => ({ data: { requests: [...approves, ...declines] } })
    );

  return (
    <Panel
      extra={<SearchInput filters={SEARCH_FILTERS} originalData={data} onChange={onSearch} />}
      title={
        <Flex align="center" gap="small">
          DSP Link Request
          <Badge count={data?.length ?? '0'} color="blue" overflowCount={9999} />
        </Flex>
      }
    >
      <DataTable
        key="table"
        loading={isLoading}
        defaultSorted={[{ id: 'created_at', desc: true }]}
        data={!state.filteredData ? data : state.filteredData}
        columns={COLUMNS({
          onClickApprove,
          onClickDecline,
          approves: state.approves,
          declines: state.declines,
          onClickSelectApproveAll,
          onClickSelectDeclineAll,
          data,
        })}
        defaultPageSize={10}
      />
      <Divider />
      <Flex justify="center" gap="small">
        <RequestSubmitButton
          columns={SUBMIT_MODAL_COLUMNS}
          onClickSubmit={onClickSubmit}
          approveRequest={state.approves}
          declineRequest={state.declines}
          data={data ?? []}
          loading={isSubmitting}
          selected={selected}
        />
      </Flex>
    </Panel>
  );
}

export default React.memo(DSPLinkingRequest);
