import { useGetEntity } from '@Services/search';
import { capitalize, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { EditEntityProps } from '.';
import { BrandIcon, Panel, ResponsiveSection, Table } from '@Shared/Style';
import { Avatar, Button, Checkbox, Flex, InputNumber, Tag, Typography } from 'antd';
import { generateCmUrl } from '@Utils';
import { ExternalLinksPanel } from '@Shared';
import { AUTHENTICITY_RISK_PLATFORM_MAP, useUpdateAuthenticityRisk } from '@Services/meta';
import FAIcon from '@Shared/Style/FAIcon';

const EditCurator = ({ q }: EditEntityProps) => {
  const { data, isFetching, refetch } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );

  const [authenticityRiskOverride, setAuthenticityRiskOverride] = useState<number | null>(null);
  const { execute, isLoading } = useUpdateAuthenticityRisk();

  const handleSubmit = () => {
    if (authenticityRiskOverride === null) return;
    execute({
      path: {
        id: data.id,
        target: 'spotify_curator',
      },
      data: {
        // @ts-ignore
        // FIXME:
        values: {
          normalized_suspicion_level: authenticityRiskOverride,
        },
      },
    }).then(() => refetch());
  };

  return (
    <>
      <ResponsiveSection>
        <Table
          panelStyle
          title="Basic Information"
          extra={
            data.streamingType === 'spotify' && (
              <Button
                className="blue"
                icon={<FAIcon name="farPenNib" />}
                onClick={handleSubmit}
                loading={isLoading}
              >
                Update All
              </Button>
            )
          }
          options={[
            {
              label: 'Name',
              component: (
                <Flex align="center" gap="small">
                  <Avatar src={data.image_url} alt={data.name} />
                  <Typography.Link
                    target="_blank"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    href={generateCmUrl('curator', {
                      id: data.id,
                      service: data.streamingType,
                    })}
                  >
                    {data.name}
                  </Typography.Link>
                </Flex>
              ),
            },
            {
              label: 'User ID',
              value: data.user_id,
            },
            {
              label: 'Platform',

              component: (
                <Flex gap="small" align="center">
                  <BrandIcon platform={data.streamingType} />
                  {capitalize(data.streamingType)}
                </Flex>
              ),
            },
            {
              label: 'Tags',
              component: data.tag_names.map(tag => (
                <Tag color="blue" key={tag}>
                  {tag}
                </Tag>
              )),
            },
          ]}
        />
        {data.streamingType === 'spotify' && (
          <Table
            panelStyle
            title="Authenticity Risk"
            options={[
              {
                label: AUTHENTICITY_RISK_PLATFORM_MAP.normalized_suspicion_level,
                component: (
                  <Flex align="start" justify="center" gap={4} vertical>
                    <Checkbox
                      checked={
                        authenticityRiskOverride ??
                        data.authenticity_risk_overrides?.normalized_suspicion_level ??
                        data.suspicion_score
                      }
                      onChange={e => setAuthenticityRiskOverride(e.target.checked ? 1 : 0)}
                    >
                      Suspicious
                    </Checkbox>
                    <Typography.Text type="secondary">
                      {data.authenticity_risk_overrides?.normalized_suspicion_level ||
                      authenticityRiskOverride
                        ? `Overridden. Origin: ${
                            data.suspicion_score > 0 ? 'Suspicious' : 'Not Suspicious'
                          }`
                        : ''}
                    </Typography.Text>
                  </Flex>
                ),
              },
            ]}
          />
        )}
      </ResponsiveSection>
      <ExternalLinksPanel
        type="curator"
        id={data.id}
        urls={data.urls}
        callback={() => refetch()}
        meta={{
          streamingType: data.streamingType,
        }}
      />
    </>
  );
};
export default EditCurator;
